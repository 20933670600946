import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Assignment from "@material-ui/icons/Assignment";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import PagingTable from "views/CommonUseComponents/PagingTable.js";
import SearchBar from "views/CommonUseComponents/SearchBar.js";
import Table from "components/Table/Table.js";
import moment from "moment";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import SweetAlert from "react-bootstrap-sweetalert";

import Loc from "localization";
import fetchAPI from "connectionHandler/FetchAPI.js";
import DataSetType from "Data_set_type";

import styles_alert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styles_tableBtn from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import styles_checkbox from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";

import ICON_ADD from "@material-ui/icons/Add";
import ICON_RESEND from "@material-ui/icons/Replay";


const styles = {
    cardIconTitle: {
      ...cardTitle,
      marginTop: "15px",
      marginBottom: "0px",
    },
  };

  
const useStyles = makeStyles(styles);
const useStyles_alert = makeStyles(styles_alert);
const useStyles_tableBtn = makeStyles(styles_tableBtn);
const useStyles_checkbox = makeStyles(styles_checkbox);

export default function PushNotification_list(props) {

    // alert
    const [alert_info, setAlert_info] = React.useState(null);
    const [alert_confirm, setAlert_confirm] = React.useState(null);
    const [alert_resend, setAlert_resend] = React.useState(null);
    const [alert_result, setResultAlert] = React.useState(null);

    const hideAlert = () => {
        setAlert_info(null);
        setAlert_confirm(null);
        setAlert_resend(null);
        setResultAlert(null);
    };

    const [pathToRedirect, setRedirect] = React.useState("");
    const [itemToEdit, setItemToEdit] = React.useState(null);

    const [data_page, set_page] = React.useState(0);
    const [data_pageCount, set_pageCount] = React.useState(50);
    const [data_isLoaded, set_isLoaded] = React.useState(false);
    const [isLoading, set_isLoading] = React.useState(false);
    const [data_dataList, setData_dataList] = React.useState(null);
    const [data_dataListCount, setData_dataListCount] = React.useState(0);
    const [localizationState, setLocalizationState] = React.useState(null);

    const [preloadDataList, set_preloadDataList] = React.useState(null);

    const [data, setData] = React.useState([]);
    const classes = useStyles();
    const classes_alert = useStyles_alert();
    const classes_tableBtn = useStyles_tableBtn();

    React.useEffect(() => {
        if (localizationState == null) {
            setLocalizationState(props.location.state);
        }
        if (data_dataList == null)
        {
          do_loadDataList();
        }
        else
        {
          processDataWithActions(data_dataList);
        }
    }, [
        localizationState,
        data_pageCount,
        data_page,
        data_dataList,
    ]);

    function showAlert(issuccess, title, content) {
        setAlert_info(
          <SweetAlert
            error={!issuccess}
            success={issuccess}
            closeOnClickOutside={false}
            style={{ display: "block", marginTop: "-100px" }}
            title={title}
            onConfirm={() => hideAlert()}
            onCancel={() => hideAlert()}
            confirmBtnText={Loc.confirm}
            confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
            btnSize="lg"
          >
            {content}
          </SweetAlert>
        );
      }

  function do_loadDataList() {

      const body = {
          collectionName: "data_notification",
          page: data_page,
          pageCount: data_pageCount,
      };

      set_isLoading(true);
      fetchAPI.do_fetch("post", "admin/list-custom", body).then((res) => {
          set_isLoading(false);
          if (res.data == undefined || res.data == null) {
              setData_dataList([]);
          }
          else
          {
              setData_dataList(res.data.data_list);
              setData_dataListCount(res.data.count);
          }
      },
      (error) => {
          console.log("failed: ", error);
          set_isLoading(false);
          showAlert(false, Loc.failed, Loc.data_fetch_failed);
        }
      );
  }

  const check_redirect = () => {
    if (pathToRedirect != "") {
      if (itemToEdit != null) {
        return (
          <Redirect
            to={{
              pathname: pathToRedirect,
              state: itemToEdit,
            }}
          />
        );
      } else {
        return <Redirect to={pathToRedirect} />;
      }
    } else {
      return null;
    }
  };

  function getTableHeaderList() {
    var tableHeaderList = ["#"];

    tableHeaderList.push(Loc.push_notification_date);
    tableHeaderList.push(Loc.push_notification_title);
    tableHeaderList.push(Loc.push_notification_message);
    tableHeaderList.push(Loc.push_notification_type);

    tableHeaderList.push(Loc.actions);

    return tableHeaderList
  }

  function getCustomCellClassesList() {
    var customHeadCellClassesList = [classes_tableBtn.left];

    customHeadCellClassesList.push(classes_tableBtn.center);
    customHeadCellClassesList.push(classes_tableBtn.center);
    customHeadCellClassesList.push(classes_tableBtn.center);
    customHeadCellClassesList.push(classes_tableBtn.center);

    customHeadCellClassesList.push(classes_tableBtn.right);

    return customHeadCellClassesList;
  }

  function getCustomCellStylePos() {
    var customHeaderCellPos = [0];

    customHeaderCellPos.push(1);
    customHeaderCellPos.push(2);
    customHeaderCellPos.push(3);
    customHeaderCellPos.push(4);
    
    customHeaderCellPos.push(5);

    return customHeaderCellPos;
  }

  function processDataWithActions(_dataList) 
  {
    const newData = _dataList.map((prop, key) => {
      var rowToReturn = [];

      // Add index
      rowToReturn.push(key + 1);

      // Add Date
      const str_date = moment(prop.createDate_timestamp).format("YYYY-MM-DD HH:mm:ss")
      rowToReturn.push(str_date);

      // Add Title
      rowToReturn.push(prop.notification.title);

      // Add Msg
      rowToReturn.push(prop.notification.body);

      // Add type
      var str_notificationType = " - ";
      if (prop.notification_type != undefined && prop.notification_type != null)
      {
        if (prop.notification_type == "topic")
        {
          str_notificationType = Loc.push_notification_new_topic;
        }
        else if (prop.notification_type == "specific")
        {
          str_notificationType = Loc.push_notification_new_specific
        }
      }
      rowToReturn.push(str_notificationType);
      
      // Add resend action
      const actionUI = 
        (
          <div className="actions-right">
            <Button
                justIcon
                round
                simple
                onClick={() => 
                  {
                  // show alert ask resent
                  show_resend_alert(prop);
                  }}
                color="info"
                className="edit"
            >
            <ICON_RESEND /> 
            </Button>
          </div>
        )
      rowToReturn = [...rowToReturn,...[actionUI]]

      return rowToReturn;
    });

    setData(newData);
  }

  function show_resend_alert(_data)
  {
    setAlert_resend(
      <SweetAlert
        style={{ display: "block", marginTop: "-100px" }}
        closeOnClickOutside={false}
        title={Loc.resend_notification}
        onConfirm={() => {
          send_notification(_data).then(isSent => {
            if (isSent)
            {
              show_resend_result_alert(true, Loc.success, Loc.push_notification_success);
            }
            else
            {
              show_resend_result_alert(false, Loc.failed, Loc.push_notification_fail);
            }
          });
          hideAlert();
        }}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        cancelBtnCssClass={classes_alert.button + " " + classes_alert.info}
        onCancel={() => {
          hideAlert();
        }}
        confirmBtnText={Loc.confirm}
        cancelBtnText={Loc.cancel}
        btnSize="lg"
        showCancel={true}
      >
        {Loc.confirm_resend_notification}
      </SweetAlert>
    );
  }

  function show_resend_result_alert(issuccess, title, content)
  {
    setResultAlert(
      <SweetAlert
        error={!issuccess}
        success={issuccess}
        style={{ display: "block", marginTop: "-100px" }}
        closeOnClickOutside={false}
        title={title}
        onConfirm={() => {
          hideAlert();
          setData_dataList(null);
        }}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        cancelBtnCssClass={classes_alert.button + " " + classes_alert.info}
        
        confirmBtnText={Loc.confirm}
        btnSize="lg"
        showCancel={false}
      >
        {content}
      </SweetAlert>
    );
  }

  function send_notification(_data)
  {
    return new Promise((onDone) => {
      var body = {
        title: _data.notification.title,
        message: _data.notification.body,
      }
      var apiPath = "admin/create-push-notification";
  
      if (_data.notification_type == "topic")
      {
          body.topicPath = _data.topicPath;
          // body.deviceType = _data.deviceType;
  
          apiPath += "-topic";
      }
      else
      {
          body.registrationToken = _data.token;
          body.notificationData = JSON.stringify(_data.data);
  
          apiPath += "-specific";
      }
  
      console.log(`testing: ${JSON.stringify(body)}`)
      
      fetchAPI.do_fetch("post", apiPath, body).then(
          (res) => {
            console.log("success: ", res);
            // setData_itemName("");
            onDone(true);
          },
          (error) => {
            console.log("failed: ", error);
            onDone(false);
          }
      );
    })
  }


  function _renderExtendedTable() {
    return (
      <Table
        tableHead={getTableHeaderList()}
        tableData={data}
        customCellClasses={getCustomCellClassesList()}
        customClassesForCells={getCustomCellStylePos()}
        customHeadCellClasses={getCustomCellClassesList()}
        customHeadClassesForCells={getCustomCellStylePos()}
      />
    );
  }
      

    return (
        <div>
            {check_redirect()}
            {/* {showloading()} */}
            {alert_info}
            {alert_confirm}
            {alert_resend}
            {alert_result}
            <GridContainer>
            <GridItem xs={12}>
                <Card>
                <CardHeader color="primary" icon>
                    <CardIcon color="primary">
                    <Assignment />
                    </CardIcon>
                    <h4 className={classes.cardIconTitle}>{Loc.push_notification_list}</h4>
                </CardHeader>
                <CardBody>
                    <Button
                        color="success"
                        round
                        onClick={() => {
                            setItemToEdit({
                                data: {isNewData: true, notificationType: "topic" },
                                config: localizationState,
                            });
                            setRedirect(`push-notification-new`);
                        }}
                    >
                        <ICON_ADD />
                        {Loc.push_notification_new_topic}
                    </Button>
                    <Button
                        color="success"
                        round
                        onClick={() => {
                            setItemToEdit({
                                data: {isNewData: true, notificationType: "specific" },
                                config: localizationState,
                            });
                            setRedirect(`push-notification-new`);
                        }}
                    >
                        <ICON_ADD />
                        {Loc.push_notification_new_specific}
                    </Button>

                    {_renderExtendedTable()}
{/* 
                    {_renderSearchBar()}
                    {showExtendedTable
                    ? _renderExtendedTable()
                    : _renderPagingTable()} */}
                </CardBody>
                </Card>
            </GridItem>
            </GridContainer>
        </div>
    );
}