/*eslint-disable*/
import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";

import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import InputLabel from "@material-ui/core/InputLabel";
import Datetime from "react-datetime";
import FormControl from "@material-ui/core/FormControl";
import moment from "moment";
import StoreDropdown from "views/CommonUseComponents/StoreDropdown.js";
import Dropdown from "views/CommonUseComponents/Dropdown.js";
import TagsInput from "react-tagsinput";

// material ui icons
import MailOutline from "@material-ui/icons/MailOutline";
import Contacts from "@material-ui/icons/Contacts";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import SwitchUI from "@material-ui/core/Switch";
import ICON_ADD from "@material-ui/icons/Add";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";

// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import styles_alert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styles_switch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import ImageUpload from "components/CustomUpload/ImageUpload.js";

import ICON_BACK from "@material-ui/icons/ArrowBackIos";
import ICON_DONE from "@material-ui/icons/Done";

const useStyles = makeStyles(styles);
const useStyles_alert = makeStyles(styles_alert);
const useStyles_switch = makeStyles(styles_switch);

import SweetAlert from "react-bootstrap-sweetalert";

import Loc from "localization";
import fetchAPI from "connectionHandler/FetchAPI.js";

import Chip from "@material-ui/core/Chip";
import FaceIcon from "@material-ui/icons/Face";

export default function Roster_add(props) {
  // type validation
  const [isEdit, setIsEdit] = React.useState(false);

  const [data_id, setData_id] = React.useState("");
  const [data_name, setData_itemName] = React.useState("");

  const [data_date, setData_date] = React.useState(null);
  const [data_staffID, setData_staffID] = React.useState("");
  const [data_storeID, setData_storeID] = React.useState("");
  const [data_storeName, setData_storeName] = React.useState("");
  const [data_staffName, setData_staffName] = React.useState("");
  const [data_rosterList, setData_rosterList] = React.useState([]);

  const [data_startTime, setData_startTime] = React.useState(
    new Date().setMinutes(0, 0, 0)
  );
  const [data_multiStartTime, setData_multiStartTime] = React.useState(
    new Date().setMinutes(0, 0, 0)
  );
  const [data_multiEndTime, setData_multiEndTime] = React.useState(
    new Date().setMinutes(0, 0, 0)
  );
  const [data_duration, setData_duration] = React.useState(30);
  const [data_multiDuration, setData_multiDuration] = React.useState(30);
  const [data_durationList, setData_durationList] = React.useState([]);
  const [data_durationIdList, setData_durationIdList] = React.useState([]);
  const [data_dataListArray, setData_dataListArray] = React.useState(null);
  const [data_isPreLoadData, setData_preLoadData] = React.useState(false);

  const [pathToRedirect, setRedirect] = React.useState("");

  const [dateState, setdateState] = React.useState("");

  const [fetchingStatus, setFetchingStatus] = React.useState("");
  const [data_adminInfo, setData_adminInfo] = React.useState(null);
  // const [isPreLoadedData, setIsPreLoadedData] = React.useState(false);
  // const [data_store, setData_store] = React.useState(null);
  // const [data_staff, setData_staff] = React.useState(null);
  const [locationState, setLocationState] = React.useState(null);
  const [historyState, setHistoryState] = React.useState(null);

  // alert
  const [alert_save, setSaveAlert] = React.useState(null);
  const hideAlert = () => {
    setSaveAlert(null);
  };

  React.useEffect(() => {
    var preloadListStr = localStorage.getItem("preloadDataList");
    var preloadList = JSON.parse(preloadListStr);

    // if (!locationState || locationState == null) {
    //   setLocationState(props.location.state);
    //   setHistoryState(props.history.location.state);

    //   // setData_adminInfo(props.location.state.config.adminInfo)
    // } else {
    if (data_dataListArray === null) {
      // const stateConfig = locationState.config;
      getListOfCollection(["Staff", "data_roster"], {}).then((preloadList) => {
        var staffList = preloadList["Staff"];
        setData_staffID(staffList[0].id);
        setData_staffName(staffList[0].itemName);

        setData_durationList([30, 60]);
        setData_durationIdList(["30", "60"]);

        setData_dataListArray(preloadList);
      });
      //   var adminInfo = props.location.state.config.adminInfo;
      //   // console.log("Admin Store :"+ adminInfo)

      //   if(adminInfo.storeID){
      //     console.log("Admin Store :"+ adminInfo.storeName)
      //     setData_storeID(adminInfo.storeID);
      //     setData_storeName(adminInfo.storeName);
      //   }
      //   console.log("data_adminInfo "+JSON.stringify(props.location.state.config.adminInfo))

      // if (preloadList["Staff"].length > 0) {

      // }

      // }

      // set to edit
      if (
        data_dataListArray !== null &&
        locationState !== null &&
        locationState.data !== null &&
        !data_isPreLoadData
      ) {
        const stateData = locationState.data;
        setIsEdit(true);
        setData_preLoadData(true);

        //   setData_id(stateData.id);
        //   setData_itemName(stateData.itemName);
        //   setData_amount(stateData.amount);
        //   setData_remark(stateData.remark);
        //   setData_date(stateData.date);
        //   setData_storeID(stateData.storeID);
        //   setData_staffID(stateData.staffID);
        //   setData_storeName(stateData.storeName);
        //   setData_staffName(stateData.staffName);
        //   setIsConfidential(stateData.isConfidential);
        //   setData_expenditureType(stateData.type);
      }
    }

    // Specify how to clean up after this effect:
    return function cleanup() {};
  }, [data_dataListArray, locationState]);

  function getListOfCollection(collectionsList, onHoldList) {
    var newList = collectionsList;
    var newOnHoldList = onHoldList;

    // console.log(`do_loadListOfCollection =====>`);

    const targetCollection = newList[0];
    return new Promise((onDone) => {
      // set_isLoading(true);
      const body = {
        data_targetCollection: targetCollection,
      };

      fetchAPI.do_fetch("post", "admin/list-all", body).then(
        (res) => {
          const dataList = res.data.data_list;
          newOnHoldList[targetCollection] = dataList.filter(
            (v) => v.isDeleted != true
          );
          newList.splice(0, 1);

          if (newList.length > 0) {
            getListOfCollection(newList, newOnHoldList).then(
              (finalOnHoldList) => {
                onDone(finalOnHoldList);
              }
            );
          } else {
            onDone(newOnHoldList);
          }
          //   onDone(res.data.data_list);
        },
        (error) => {
          console.log("failed: ", error);
          set_isLoading(false);
          onDone(null);
        }
      );
    });
  }

  function do_loadStoreList() {
    return new Promise((onDone) => {
      const body = {
        data_targetCollection: "Store",
      };
      fetchAPI.do_fetch("post", "admin/list-all", body).then(
        (res) => {
          console.log("success: ", res.data);
          onDone(res.data.data_list);
        },
        (error) => {
          console.log("failed: ", error);
          showAlert(false, Loc.failed, "Load store faild");
          onDone(null);
        }
      );
    });
  }

  const validCheck = () => {
    var result = true;
    if (
      data_staffID == "" ||
      data_rosterList.length == 0 ||
      data_date == null
    ) {
      result = false;
    } else if (dateState === "error") {
      result = false;
    }
    return result;
  };

  const backClicked = () => {
    setRedirect("/admin/expenditure-list");
  };

  const doneClicked = () => {
    setFetchingStatus("fetching");
    if (validCheck()) {
      // console.log("data_date  :"  +data_date)
      const idToPass = isEdit ? data_id : null;
      console.log("idToPass: ", idToPass);
      const obj = {
        date_timestamp: new Date(data_date).getTime(),
        // "storeID":data_storeID,
        // "storeName":data_storeName,
        staffName: data_staffName,
        staffID: data_staffID,
        rosterList: data_rosterList,
      };
      const body = {
        collectionName: "data_roster",
        id: null,
        targetID: idToPass,
        content: obj,
      };
      console.log("Result " + JSON.stringify(body));
      fetchAPI.do_fetch("post", "admin/create-custom", body).then(
        (res) => {
          console.log("success: ", res);
          setFetchingStatus("");
          showAlert(true, Loc.success, Loc.data_save_success);
          // setData_itemName("");
        },
        (error) => {
          console.log("failed: ", error);
          setFetchingStatus("");
          showAlert(false, Loc.failed, Loc.data_save_failed);
        }
      );
    } else {
      //   setItemNameState("error");
      setFetchingStatus("");
      showAlert(false, Loc.failed, Loc.data_save_failed);
    }
  };

  function showAlert(issuccess, title, content) {
    setSaveAlert(
      <SweetAlert
        error={!issuccess}
        success={issuccess}
        style={{ display: "block", marginTop: "-100px" }}
        closeOnClickOutside={false}
        title={title}
        onConfirm={() => {
          if (issuccess) {
            // hideAlert()
            setRedirect("/admin");
          } else {
            hideAlert();
          }
        }}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        cancelBtnCssClass={classes_alert.button + " " + classes_alert.info}
        onCancel={() => {
          if (issuccess) {
            setData_rosterList([]);
            setData_date(new Date());

            // window.location.reload(false);
            hideAlert();
          } else {
            hideAlert();
          }
        }}
        confirmBtnText={issuccess ? Loc.done : Loc.confirm}
        cancelBtnText={Loc.continue}
        btnSize="lg"
        showCancel={issuccess && !isEdit}
      >
        {content}
      </SweetAlert>
    );
  }

  const check_redirect = () => {
    if (pathToRedirect != "") {
      return (
        <Redirect
          to={{ pathname: pathToRedirect, state: historyState.config }}
        />
      );
    } else {
      return null;
    }
  };

  const getStringToShow = (defaultStr, fieldList, fieldName, id) => {
    console.log(`getStringToShow ${fieldName} ===>${id}`);
    var strToShow = defaultStr;

    if (fieldList.length > 0) {
      const fieldNameList = getDropDownNameList(fieldName);
      strToShow = fieldNameList[0];
      fieldList.map((prop, key) => {
        if (prop.id === id) {
          strToShow = prop.itemName;
        }
      });
    }
    return strToShow;
  };

  const getDropDownNameList = (fieldName) => {
    const fieldList =
      data_dataListArray == null ? [] : data_dataListArray[fieldName];
    const fieldNameList = [];
    if (fieldList.length > 0) {
      fieldList.map((item) => {
        fieldNameList.push(item.itemName);
      });
    }
    return fieldNameList;
  };

  const handleDelete = (i) => {
    const newTags = [...tags];
    newTags.splice(i, 1);
    setTags(newTags);
  };

  const getSelectMenuItem = (fieldName) => {
    const fieldList =
      data_dataListArray == null ? [] : data_dataListArray[fieldName];
    const menuItems = [];

    // console.log(`getSelectMenuItem   `+ JSON.stringify(data_dataListArray))

    if (fieldList && fieldList.length > 0) {
      
      fieldList.map((item, key) => {
        menuItems.push(
          <MenuItem
            key={key}
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected,
            }}
            value={item.id}
          >
            {item.itemName}
          </MenuItem>
        );
      });
    }

    return menuItems;
  };

  //   const [tags, setTags] = React.useState(["tag1", "tag2", "tag3"]);

  const renderInput = ({ addTag, ...props }) => {
    return <></>; // 渲染成一個空的元素
  };
  function defaultRenderTag(props) {
    let {
      tag,
      key,
      disabled,
      onRemove,
      classNameRemove,
      getTagDisplayValue,
      ...other
    } = props;
    return (
      <span key={key} {...other}>
        {getTagDisplayValue(tag)}
        {!disabled && (
          <a className={classNameRemove} onClick={(e) => onRemove(key)} />
        )}
      </span>
    );
  }
  function handleAddTag() {
    var tag_obj = {
      start_timestamp: new Date(data_startTime).getTime(),
      duration: data_duration,
      status: "available",
    };

    var newList = [...data_rosterList, tag_obj];
    newList.sort((a, b) => (a.start_timestamp > b.start_timestamp ? 1 : -1));
    setData_rosterList(newList);
    // console.log("start_time: ", data_startTime + " duration: " + data_duration);
  }

  function renderAddTagRow(isMulti = false) {
    if (!isMulti) {
      return (
        <Card>
          <CardBody>
            <GridItem xs={12}>
              <GridContainer>
                <GridItem xs={4}>
                  <br />
                  <InputLabel className={classes.label}>Select Time</InputLabel>
                  <br />

                  <Datetime
                    dateFormat={false}
                    defaultValue={data_startTime}
                    inputProps={{ placeholder: "Time Picker Here" }}
                    onChange={(event) => {
                      console.log("event: ", event);
                      setData_startTime(event);
                    }}
                  />
                </GridItem>
                <GridItem xs={4}>
                  <InputLabel className={classes.label}>Duration</InputLabel>
                  <CustomDropdown
                    buttonProps={{
                      round: true,
                      color: "info",
                    }}
                    buttonText={data_duration}
                    dropdownList={data_durationList}
                    itemIDList={data_durationIdList}
                    onClick={(selectedValue) => {
                      setData_duration(selectedValue);
                    }}
                  />
                </GridItem>
                <GridItem xs={4}>
                  <Button
                    color="success"
                    round
                    onClick={() => {
                      handleAddTag();
                    }}
                  >
                    <ICON_ADD />
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </CardBody>
        </Card>
      );
    } else {
      return (
        <Card>
          <CardBody>
            <GridItem xs={12}>
              <GridContainer>
                <GridItem xs={4}>
                  <br />
                  <InputLabel className={classes.label}>Select Time</InputLabel>
                  <br />

                  <Datetime
                    dateFormat={false}
                    defaultValue={data_multiStartTime}
                    inputProps={{ placeholder: "Time Picker Here" }}
                    onChange={(event) => {
                      console.log("event: ", event);
                      setData_multiStartTime(event);
                    }}
                  />
                </GridItem>
                <GridItem xs={4}>
                  <br />
                  <InputLabel className={classes.label}>Select Time</InputLabel>
                  <br />

                  <Datetime
                    dateFormat={false}
                    defaultValue={data_multiEndTime}
                    inputProps={{ placeholder: "Time Picker Here" }}
                    onChange={(event) => {
                      console.log("event: ", event);
                      setData_multiEndTime(event);
                    }}
                  />
                </GridItem>
                <GridItem xs={4}>
                  <InputLabel className={classes.label}>Duration</InputLabel>
                  <CustomDropdown
                    buttonProps={{
                      round: true,
                      color: "info",
                    }}
                    buttonText={data_multiDuration}
                    dropdownList={[30, 60]}
                    itemIDList={["30", "60"]}
                    onClick={(selectedValue) => {
                      setData_multiDuration(selectedValue);
                    }}
                  />
                </GridItem>
                <GridItem xs={4}>
                  <Button
                    color="success"
                    round
                    onClick={() => {
                      //   handleAddTag();
                      //  Auto gen the range
                      var totalHour = data_multiEndTime - data_multiStartTime;
                      var cell_count =
                        totalHour / 1000 / 60 / data_multiDuration;
                      cell_count += 1;
                      console.log("cell_count: ", cell_count);
                      var newList = [...data_rosterList];
                      for (var i = 0; i < cell_count; i++) {
                        console.log(
                          "start_time: ",
                          data_multiStartTime +
                            i * data_multiDuration * 60 * 1000
                        );

                        var tag_obj = {
                          start_timestamp:
                            data_multiStartTime +
                            i * data_multiDuration * 60 * 1000,
                          duration: data_multiDuration,
                          status: "available",
                        };
                        newList.push(tag_obj);
                      }
                      //   newList.push({});
                      newList.sort((a, b) =>
                        a.start_timestamp > b.start_timestamp ? 1 : -1
                      );
                      setData_rosterList(newList);
                    }}
                  >
                    <ICON_ADD />
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </CardBody>
        </Card>
      );
    }
  }

  function renderTagView() {
    if (data_rosterList.length == 0) {
      return null;
    }

    const chips = data_rosterList.map((item, key) => {
      var start_timeStr = moment(item.start_timestamp).format("HH:mm");
      var color = "primary";

      if (key == 0) {
        //(item.status == "available") {
        color = "#42a5f5";
      } else if (key == 1) {
        color = "#ba68c8";
      } else if (key == 2) {
        color = "#66bb6a";
      } else {
        color = "#ef5350";
      }
      return (
        <Chip
          key={"chip_" + key}
          style={{ backgroundColor: color, color: "white" }}
          label={start_timeStr}
          onClick={() => {
            console.info("You clicked the Chip. " + key);
          }}
          onDelete={() => {
            console.info("You clicked the Chip Delete. " + JSON.stringify(key));
            var newList = [...data_rosterList];
            newList.splice(key, 1);
            setData_rosterList(newList);
          }}
        />
      );
    });

    return (
      <GridItem xs={12}>
        <br />
        <legend>Timeslots</legend>
        <Card>
          <CardBody>{chips}</CardBody>
        </Card>
      </GridItem>
    );
  }

  const classes = useStyles();
  const classes_alert = useStyles_alert();

  return (
    <div>
      {check_redirect()}
      {alert_save}
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" text>
              <CardText color="rose">
                <h4 className={classes.cardTitle}>{"Add Roster"}</h4>
              </CardText>
            </CardHeader>
            <CardBody>
              <form>
                <GridContainer>
                  <GridItem sm={12}>
                    <FormControl
                      fullWidth
                      className={
                        classes.selectFormControl +
                        " " +
                        classes.formControlMargins
                      }
                    >
                      <Select
                        MenuProps={{
                          className: classes.selectMenu,
                        }}
                        classes={{
                          select: classes.select,
                        }}
                        value={data_staffID}
                        onChange={(event) => {
                          const selectedID = event.target.value;
                          setData_staffID(selectedID);
                          setData_staffName(
                            getStringToShow(
                              Loc.staff_default,
                              data_dataListArray == null
                                ? []
                                : data_dataListArray["Staff"],
                              "Staff",
                              selectedID
                            )
                          );

                          setData_itemName(
                            getStringToShow(
                              Loc.staff_default,
                              data_dataListArray == null
                                ? []
                                : data_dataListArray["Staff"],
                              "Staff",
                              selectedID
                            ) + Loc.commission_expenditure
                          );
                        }}
                        inputProps={{
                          name: data_staffName,
                          id: data_staffID,
                        }}
                      >
                        {getSelectMenuItem("Staff")}
                        {/* {} */}
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem sm={12}>
                    <br />
                    <InputLabel className={classes.label}>
                      Date Picker
                    </InputLabel>
                    <br />
                    {/* <br /> */}
                    <FormControl fullWidth>
                      <Datetime
                        timeFormat={false}
                        // defaultValue={data_date}
                        utc={true}
                        inputProps={{ placeholder: "Please Select time" }}
                        onChange={(event) => {
                          // console.log("event: ", event);
                          var date = event;
                          setData_date(event);
                          //Call api to get the list of roster
                          console.log("date: ", date);
                          const body = {
                            staffID: "233", //data_staffID,
                            // targetRoster: "data_roster",
                            // targetLookup: "",
                            date: new Date(date).getTime(),
                          };

                          fetchAPI
                            .do_fetch("post", "admin/get-staff-roster", body)
                            .then(
                              (res) => {
                                console.log("success: ", res);
                                setFetchingStatus("");
                                if (res.data != undefined && res.data != null) {
                                  setData_rosterList(res.data.rosterList);
                                } else {
                                  setData_rosterList([]);
                                }
                              },
                              (error) => {
                                console.log("failed: ", error);
                                setFetchingStatus("");
                                showAlert(
                                  false,
                                  Loc.failed,
                                  Loc.data_save_failed
                                );
                              }
                            );
                        }}
                      />
                    </FormControl>
                  </GridItem>
                  {/* {renderAddTagRow()}
                  {renderAddTagRow(true)} */}
                  {renderTagView()}
                </GridContainer>
              </form>
            </CardBody>
            <CardFooter className={classes.justifyContentCenter}>
              <GridContainer
                spacing={2}
                direction="row"
                justify="center"
                alignItems="center"
              >
                <GridItem xs={6} align="center">
                  <Button onClick={backClicked}>
                    <ICON_BACK />
                    {Loc.back}
                  </Button>
                </GridItem>

                <GridItem xs={6} align="center">
                  <Button
                    color="rose"
                    onClick={doneClicked}
                    disabled={fetchingStatus === "fetching" ? true : false}
                  >
                    <ICON_DONE />
                    {isEdit ? Loc.save : Loc.create}
                  </Button>
                </GridItem>
              </GridContainer>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
