/*!

=========================================================
* Material Dashboard PRO React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import AuthLayout from "layouts/Auth.js";
import AdminAuthLayout from "layouts/AdminAuth.js";
import AdminLayout from "layouts/Admin.js";
import StaffLayout from "layouts/Staff.js";
import CustomerLayout from "layouts/Customer.js";
import POSCartLayout from "layouts/POSCart.js";


import "assets/scss/material-dashboard-pro-react.scss?v=1.9.0";

const hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/auth" component={AuthLayout} />
      <Route path="/admin-auth" component={AdminAuthLayout} />
      <Route path="/admin" component={AdminLayout} />
      <Route path="/staff" component={StaffLayout} />
      <Route path="/customer" component={CustomerLayout} />
      <Route path="/poscart" component={POSCartLayout} />
      
      <Redirect from="/" to="/auth" />
    </Switch>
  </Router>,
  document.getElementById("root")
);
