/*eslint-disable*/
import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import SwitchUI from "@material-ui/core/Switch";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";

// material ui icons
import MailOutline from "@material-ui/icons/MailOutline";
import Contacts from "@material-ui/icons/Contacts";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import IconButton from "@material-ui/core/IconButton";

// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import styles_alert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styles_switch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";

import ICON_BACK from "@material-ui/icons/ArrowBackIos";
import ICON_DONE from "@material-ui/icons/Done";
import ICON_ADD from "@material-ui/icons/Add";
import ICON_DELETE from "@material-ui/icons/Clear";
import ICON_UP from "@material-ui/icons/ExpandLess";

import Datetime from "react-datetime";
import moment from "moment";
import firebase from 'connectionHandler/firebase';

const useStyles = makeStyles(styles);
const useStyles_alert = makeStyles(styles_alert);

import SweetAlert from "react-bootstrap-sweetalert";

import Loc from "localization";
import fetchAPI from "connectionHandler/FetchAPI.js";
import DataSetType from "Data_set_type";
import ImageUpload from "components/CustomUpload/ImageUpload.js";
import { OperationCanceledException } from "typescript";


export default function Custom_setting_add(props) {
  const [isEdit, setIsEdit] = React.useState(false);
  const [isChangePassword, setIsChangePassword] = React.useState(false);

  const [data_id, setData_id] = React.useState("");
  const [data_name, setData_itemName] = React.useState("");
  const [data_remark, setData_remark] = React.useState("");
  const [data_customFields, setData_customFields] = React.useState([]);
  const [data_UILayout, setData_UILayout] = React.useState([]);
  const [pathToRedirect, setRedirect] = React.useState("");
  
  const [customInputStates,setCustomInputStates] = React.useState([])
  const [productNameState, setcategoryNameState] = React.useState("");
  const [data_otherTableData, setData_otherTableData] = React.useState([]);

  const [fetchingStatus, setFetchingStatus] = React.useState("");
  const [localizationState,setLocalizationState] = React.useState(null)
  const [historyState,setHistoryState] = React.useState(null)
 

  const [isUploading, set_isUploading] = React.useState(false);

  const [data_customSetting,setData_customSetting] = React.useState(null);
  const [data_customSettingConfig,setData_customSettingConfig] = React.useState(null);
  // const [data_settingName,setData_settingName] = React.useState("")
  const [data_createFieldList, setData_createFieldList] = React.useState([]);
  // alert
  const [alert_save, setSaveAlert] = React.useState(null);
  const hideAlert = () => {
    setSaveAlert(null);
  };

  const useStyles_switch = makeStyles(styles_switch);
  const classes_switch = useStyles_switch();

  React.useEffect(() => {
    // pre load data for edit
    // console.log("testing get state: ", props);
    

    setLocalizationState(props.location.state) 
    setHistoryState(props.history.location.state)
    console.log("Custom_setting_add::::::  "+JSON.stringify(props.location.state))
    if (localizationState){
      if(data_customSetting == null){
        do_loadSettingData();
        console.log("initCustomFields  ");
        initCustomFields();
      }else{
        if(data_customSetting && data_customSetting !== null){
          setIsEdit(true);
          const settingDetail = data_customSetting.settingDetails
          setData_id(localizationState.settingName);
          setData_customFields(settingDetail);
        }
        console.log("initCustomFields  ");
        initCustomFields();
      }
      // initCustomInputStates()
      // if (
      //   localizationState !== null &&
      //   localizationState.data !== null
      // ) {
      
      //   setIsEdit(true);
      //   setData_id(localizationState.settingName);
      //   setData_customFields(localizationState.data);
      // } else {
        
      // }
      
      process_UI_Layout(data_customFields);
      
      // handle_selectFromOtherTable();
    }
    // Specify how to clean up after this effect:
    return function cleanup() {};
  }, [localizationState,historyState,data_customSetting,data_createFieldList]);
  //update ui
  function process_UI_Layout(_data_customFields) {
    const new_layout = _renderUILayout(_data_customFields);
    setData_UILayout(new_layout);
  }

  function handle_selectFromOtherTable()
  {
    const targetDataToLoad = [];
    localizationState.config.createFieldList.map(configItem => {
      if (configItem.fieldType === DataSetType.type_select_from_other_dataset)
      {
        targetDataToLoad.push(configItem.otherTableCollectionName);
      }
    });
    console.log("targetDataToLoad: " + targetDataToLoad);
    // getCustomizedTableList(targetDataToLoad, [], (preloadedData => {
    //   setData_otherTableData(preloadedData);
    // }))
  }

  const validCheck = (isDone) => {
    console.log("uid: " + data_customFields["uid"]);
    var result = true;
    const _customInputStates = customInputStates;
    var key_pw = "";
    var key_pw_confirm = "";
    var val_account_phone = "";
    var val_account_email = "";
    var val_pw = "";
    var val_pw_confirm = "";

    var list_unique_to_check = [];

    const configList = localizationState.config.settingDetails;
    if (configList !== null) {
      
      var has_pw = false;
      var has_pw_confirm = false;
      var has_phone = false;

      // check has field and get value
      configList.map(item=>{
        if(item.mandatory){
          if(data_customFields[item.fieldKey] === null || data_customFields[item.fieldKey] === ""){
            _customInputStates[item.fieldKey] = true;
            result = false;
          }
        }
        else
        {
          _customInputStates[item.fieldKey] = false;
        }
       
        if (item.isUnique)
        {
          var key = item.fieldKey;
          var val = data_customFields[key];
          // console.log(`item.fieldKey : ${key}  ${data_customFields[key]} `)
          var data = {}
            data[item.fieldKey] = val
          list_unique_to_check.push(data);
        }

        if (item.fieldType === DataSetType.type_account_email)
        {
          val_account_email = data_customFields[item.fieldKey];
        }

        if (item.fieldType === DataSetType.type_account_password)
        {
          has_pw = true;
          key_pw = item.fieldKey;
          val_pw = data_customFields[item.fieldKey];
        }
        if (item.fieldType === DataSetType.type_account_password_confirm)
        {
          has_pw_confirm = true;
          key_pw_confirm = item.fieldKey;
          val_pw_confirm = data_customFields[item.fieldKey];
        }
        if (item.fieldType === DataSetType.type_account_phone)
        {
          has_phone = true;
          val_account_phone = data_customFields[item.fieldKey];
          var key_phone = item.fieldKey;

          if (val_account_phone === "")
          {
            _customInputStates[key_phone] = true;
            result = false;
          }
        }
      });

      // check pw & pw_confirm are not empty
      if (result && has_pw && has_pw_confirm)
      {
        if (!isEdit || isChangePassword)
        {
          if (val_pw === "" || val_pw_confirm === "" || val_pw !== val_pw_confirm)
          {
            _customInputStates[key_pw] = true;
            _customInputStates[key_pw_confirm] = true;
            result = false;
          }
          else
          {
            _customInputStates[key_pw] = false;
            _customInputStates[key_pw_confirm] = false;
          }
        }
      }

      setCustomInputStates(_customInputStates);
    }
    // return result;
    if (!result )
    {
      console.log("result is false");
      isDone(result);
    }
    else if (has_pw || has_phone)
    {
      handle_firebaseUserRegister(
        data_customFields["uid"] ? data_customFields["uid"] : null, 
        (!isEdit || isChangePassword) && (val_account_email !== null && val_account_email !== "") ? val_account_email : null, 
        (!isEdit || isChangePassword) && (val_pw !== null && val_pw !== "") ? val_pw : null, 
        (val_account_phone !== null && val_account_phone !== "") ? val_account_phone : null,
        (uid => {
        if (uid !== null)
        {
          var data = data_customFields;
          data["uid"] = uid;
          // remove pw
          if (key_pw !== null && key_pw_confirm !== null)
          {
            delete data[key_pw];
            delete data[key_pw_confirm];
          }
          setData_customFields(data);

          isDone(result);
        }
        else
        {
          isDone(false);
        }
      }));
    }
    else
    {
      if (list_unique_to_check.length > 0){
        handle_checkUniqueField(list_unique_to_check,(checkResult)=>{
          if(checkResult !== null)
          {
            isDone(checkResult)
          }
          else
          {
            isDone(false);
          }
        })
  
      }else{
        isDone(result);
      } 
    }
  };

  function do_loadSettingData() {
    // console.log(JSON.stringify(props.location.state.collectionName));

    // if (localizationState.collectionName !== null) {
      // set_isLoading(true);
      const body = {
        settingName:localizationState.settingName
      };
      fetchAPI.do_fetch("post", "admin/list-custom-setting", body).then(
        (res) => {
          // set_isLoading(false);
          console.log("success: ", res.data);

          setData_customSetting(res.data);
          // setData_dataListCount(res.data.count);
        },
        (error) => {
          console.log("failed: ", error);
          // set_isLoading(false);
          showAlert(false, Loc.failed, Loc.data_fetch_failed);
        }
      );
    // }
  }

  // function do_loadSettingConfig() {
   
  //     const body = {
  //           data_targetCollection: "CustomSettingConfig"
  //         };
  //     fetchAPI.do_fetch("post", "admin/list-all", body).then(
  //       (res) => {
  //         // set_isLoading(false);
  //         console.log("do_loadSettingConfig success: ", res.data);
  //         setData_customSettingConfig(res.data.data_list);
  //         // setData_dataListCount(res.data.count);
  //       },
  //       (error) => {
  //         console.log("failed: ", error);
  //         // set_isLoading(false);
  //         showAlert(false, Loc.failed, Loc.data_fetch_failed);
  //       }
  //     );
  //   // }
  // }
  

  function handle_checkUniqueField(_uniqueFieldList,callback){
    const body = {
      "collectionName":localizationState.config.collectionName,
      "content":{
        uniqueFieldList:_uniqueFieldList
      }
    };

    // console.log(`body ${JSON.stringify(body)}`)

    fetchAPI.do_fetch("post", "admin/custom-unique-checking", body).then(
      (res) => {
        console.log("success: ", res);
        callback(res.data);
      },
      (error) => {
        console.log("failed: ", error);
        callback(null);
      }
    );
  }

  const handle_firebaseUserRegister = (data_uid, data_account_email, data_account_password, data_account_phone = null, callback) => {
    const body = {
      "id": data_uid,
      "content":{
        email:data_account_email, 
        password: data_account_password,
        phone: data_account_phone
      }
    };

    console.log(`body ${JSON.stringify(body)}`)

    fetchAPI.do_fetch("post", "admin/register-user", body).then(
      (res) => {
        console.log("success: ", res);
        callback(res.data.uid);
      },
      (error) => {
        console.log("failed: ", error);
        callback(null);
      }
    );
  };
  

  const backClicked = () => {
    setRedirect(`${props.location.pathname.replace("-new", "")}`);
  };

  const doneClicked = () => {
    setFetchingStatus("fetching");
    // validCheck((isValid) => {
    //   if (isValid) 
    //   {
        const settingName = localizationState.settingName;
        const idToPass = isEdit ? settingName : null;
        console.log("idToPass: ", idToPass);
        // console.log(`data_customFields: ${JSON.stringify(data_createFieldList)}`)
        var obj = {
          settingName:settingName,
        };
        obj = { ...obj, ...data_customFields};
        
        const body = {
          id: data_customSetting == null ? null : data_customSetting.id,
          settingName: settingName,
          content: obj,
        };
        // console.log("Result " + JSON.stringify(body));
        fetchAPI.do_fetch("post", "admin/create-custom-setting", body).then(
          (res) => {
            console.log("success: ", res);
            setFetchingStatus("");
            showAlert(true, Loc.success, Loc.data_save_success);
            // setData_itemName("");
          },
          (error) => {
            console.log("failed: ", error);
            setFetchingStatus("");
            showAlert(false, Loc.failed, Loc.data_save_failed);
          }
        );
      // } else {
  
      //   // checkInvalidInput();
      //   setFetchingStatus("");
      //   showAlert(false, Loc.failed, Loc.data_save_failed);
      // }
    // });
  };

  // function checkInvalidInput(){
  //   const _customInputStates = customInputStates;
   
  //   const configList = props.location.state.config.createFieldList;

  //   configList.map(item =>{
  //     if(item.mandatory && (data_customFields[item.fieldKey] === "" || data_customFields[item.fieldKey] === null)){ //is mandatory but the value is empty
  //       console.log(`checkInvalidInput   ${JSON.stringify(data_customFields)}`)
  //       _customInputStates[item.fieldKey] = true;
  //     }

  //   })
  //   console.log(`checkInvalidInput   ${JSON.stringify(_customInputStates)}`)
  //    setCustomInputStates(_customInputStates)
    
  // }

  function showAlert(issuccess, title, content) {
    setSaveAlert(
      <SweetAlert
        error={!issuccess}
        success={issuccess}
        style={{ display: "block", marginTop: "-100px" }}
        closeOnClickOutside={false}
        title={title}
        onConfirm={() => {
          if (issuccess) {
            setRedirect(`${props.location.pathname.replace("-new", "")}`);
          } else {
            hideAlert();
          }
        }}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        cancelBtnCssClass={classes_alert.button + " " + classes_alert.info}
        onCancel={() => {
          if (issuccess) {
            setData_itemName("");
            setData_remark("");
            // initCustomFields();
            // window.location.reload(false);
            hideAlert();
          } else {
            hideAlert();
          }
        }}
        confirmBtnText={issuccess ? Loc.done : Loc.confirm}
        cancelBtnText={Loc.continue}
        btnSize="lg"
        showCancel={issuccess && !isEdit}
        
      >
        {content}
      </SweetAlert>
    );
  }


  const check_redirect = () => {
    if (pathToRedirect != "") {
      return (
        <Redirect
          to={{
            pathname: pathToRedirect,
            state: historyState.config,
          }}
        />
      );
    } else {
      return null;
    }
  };

  const _renderUILayout = () => {
    if (!localizationState || !localizationState.settingDetails) {
      return null;
    }

    const configList = localizationState.settingDetails;
    var customFields = data_customFields;
    if (data_customFields == null) {
      return null;
    }

    const ui_gridItems = configList.map((configItem) => {

      
        return _renderUIComponentByType(customFields, configItem);
      
      
    });

    return (
      <CardBody>
        <form>
          <GridContainer>{ui_gridItems}</GridContainer>
        </form>
      </CardBody>
    );
  };

  function initCustomFields() {
    const settingDetails = localizationState.settingDetails;
    var customFields = {};
 

    if(data_customSetting != null){
      settingDetails.map((item) => {
        console.log("initCustomFields item : "+JSON.stringify(data_customSetting))

          customFields[item.fieldKey] = data_customSetting[item.fieldKey];
      });
    }else{

      settingDetails.map((item) => {
       

        //set default value
        if (!isEdit) {

          var value = null;
          if (item.fieldType === "bool") {
            value = false;
          } else if (item.fieldType === "date") {
            value = new Date();
          } else if (item.fieldType === "MC") {
            value = item.detailFieldList[0].key;
          } else {
            value = item.mandatory ? value : "";
          }

          customFields[item.fieldKey] = value;
      
      }
        // customFieldList.push(customItem);
      });
      
      // console.log("initCustomFields  : "+JSON.stringify(data_customSetting))
      
    }
    setData_customFields(customFields);

    
  }

  function initCustomInputStates() {
    const configList = localizationState.config.createFieldList;
    var customInputStates = {};

      configList.map((item) => {
        //set default value
        customInputStates[item.fieldKey] = false
      });

      setCustomInputStates(customInputStates)
    
  }

  // function _renderTableDetailCardView(index) {
  //   var createFieldList = data_createFieldList;
  //   var data = createFieldList[index];

  //   var detailFieldList = data.detailFieldList;
  //   //detail field list should be get from create
  //   const ui_gridItems = detailFieldList.map((item, _index) => {
  //     return _renderTableDetailCardRow(
  //       data_createFieldList,
  //       detailFieldList,
  //       index,
  //       _index
  //     );
  //   });

  //   return (
  //     <Card>
  //       <CardBody>
  //         <GridContainer>{ui_gridItems}</GridContainer>
  //         <Button
  //           color="success"
  //           round
  //           onClick={() => {
  //             const newCreateFieldList = [...data_createFieldList,{fieldKey: "", fieldName: "",type:"" }]
  //             // data_createFieldList.push({ key: "", value: "" })
  //             setData_createFieldList(newCreateFieldList);
  //           }}
  //         >
  //           <ICON_ADD />
  //         </Button>
  //       </CardBody>
  //     </Card>
  //   );
  // }

  // need create dict first when
  function _renderUIComponentByType(_customFields, _configItem) {
    if (_configItem !== null) {
      const fieldName = _configItem.fieldName;
      const fieldKey = _configItem.fieldKey;
      const type = _configItem.fieldType;
      const isMandatory = false//_configItem.mandatory;
      // const data = _customFieldList[index];
      const detailFieldList = _configItem.settingDetail;

      var fieldNameList = []; //display name
      var fieldKeyList = [];

      // detailFieldList.map((item) => {
      //   fieldNameList.push(item.value);
      //   fieldKeyList.push(item.key);
      // });

      // console.log("_renderUIComponentByType" + type);
      // const type = "string";
      if (type === DataSetType.type_string || type === DataSetType.type_number) {
       

        return (
          <GridItem xs={12} key={fieldKey}>
            <CustomInput
              // success={requiredState === "success"}
              error={customInputStates === null ? false:customInputStates[fieldKey]}
              labelText={`${fieldName}${isMandatory ? "*" : ""}`}
              id={_configItem.fieldKey}//{`${fieldName}_name`}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: _customFields[fieldKey] || (type === DataSetType.type_string ? "":0),
                onChange: (event) => {
                  // console.log('event.target.value  type: '+event.target.value)
                  _customFields[fieldKey] = type === DataSetType.type_string ? event.target.value:parseFloat(event.target.value);
                  // customInputStates[fieldKey] = false
                  setData_customFields(_customFields);
                  process_UI_Layout(_customFields);
                  // console.log(
                  //   `data_customFieldList data  ${JSON.stringify(
                  //     data_customFields
                  //   )}`
                  // );
                },
                type: type === DataSetType.type_string ? "text" : "number",
              }}
            />
          </GridItem>
        );
      } else if (type === DataSetType.type_date) {
        return (
          <GridItem xs={12} key={fieldKey}>
            <Datetime
              inputProps={{
                placeholder: `${Loc.date}${isMandatory ? "*" : ""}`,
              }}
              timeFormat={true}
              defaultValue={_customFields[fieldKey]}
              value={
                _customFields[fieldKey] === null
                  ? new Date()
                  : moment(_customFields[fieldKey])
              }
              onChange={(m) => {
                if (moment.isMoment(m)) {
                  _customFields[fieldKey] = m.toDate();
                  // console.log(
                  //   `data_customFieldList data   ${JSON.stringify(
                  //     _customFields
                  //   )}`
                  // );
                  setData_customFields(_customFields);
                  process_UI_Layout(_customFields);
                } else {
                  // setFromDateState("error");
                }
              }}
            />
          </GridItem>
        );
      } else if (type === DataSetType.type_bool) {
        return (
          <GridItem xs={12} key={fieldKey}>
            <FormControlLabel
              control={
                <SwitchUI
                  checked={_customFields[fieldKey] || false}
                  onChange={
                    (event) => {
                      _customFields[fieldKey] = event.target.checked;
                      setData_customFields(_customFields);
                      process_UI_Layout(_customFields);
                      // console.log(
                      //   `data_customFieldList data   ${JSON.stringify(
                      //     _customFieldList
                      //   )}`
                      // );
                    }
                    // setData_hasDuration()
                  }
                  classes={{
                    switchBase: classes_switch.switchBase,
                    checked: classes_switch.switchChecked,
                    thumb: classes_switch.switchIcon,
                    track: classes_switch.switchBar,
                  }}
                />
              }
              classes={{
                label: classes.label,
              }}
              label={`${fieldName}${isMandatory ? "*" : ""}`}
            />
          </GridItem>
        );
      }
      else if (type === DataSetType.type_multiline_text) {
        return (
          <GridItem xs={12} key={fieldKey}>
            <TextField
              // success={requiredState === "success"}
              error={
                customInputStates === null ? false : customInputStates[fieldKey]
              }
              placeholder={`${fieldName}${isMandatory ? "*" : ""}`}
              id={_configItem.fieldKey} //{`${fieldName}_name`}
              multiline={true}
              rows={3}
              fullWidth={true}
              // formControlProps={{
              //   fullWidth: true,
              // }}
              inputProps={{
                value:
                  _customFields[fieldKey] ||
                  (type === DataSetType.type_multiline_text ? "" : 0),
                onChange: (event) => {
                  console.log("fieldKey: " + fieldKey);
                  console.log(
                    "event.target.value  type: " + event.target.value
                  );
                  _customFields[fieldKey] =
                    type === DataSetType.type_multiline_text
                      ? event.target.value
                      : parseFloat(event.target.value);
                  customInputStates[fieldKey] = false;
                  setData_customFields(_customFields);
                  process_UI_Layout(_customFields);
                  // console.log(
                  //   `data_customFieldList data  ${JSON.stringify(
                  //     data_customFields
                  //   )}`
                  // );
                },
                type:
                  type === DataSetType.type_multiline_text ? "text" : "number",
              }}
            />
          </GridItem>
        );}else if (type === DataSetType.type_time_picker) {
          // console.log('type_time_picker ' + _customFields[fieldKey]);
          // console.log('type_time_picker Convert ' + moment(_customFields[fieldKey]).format("HH:mm A"));
          var displayTime = moment(_customFields[fieldKey]).format("HH:mm A");
          return  <GridItem xs={12}>
          <br />
          <InputLabel className={classes.label}>{fieldName}</InputLabel>
          <br />
  
          <Datetime
            dateFormat={false}
            defaultValue={displayTime}
            inputProps={{value:displayTime, placeholder: "Time Picker Here" }}
              onChange={(event) => {
                console.log("event: ", event);
              // moment(item.start_timestamp).format("HH:mm");
              var data_startTime = new Date(event).getTime();
              _customFields[fieldKey] = data_startTime;
              setData_customFields({..._customFields});
  
              // console.log("event: ", +":"+new Date(data_startTime).getMinutes());
              // setData_startTime(event);
            }}
          />
        </GridItem>
        }
      // else if (type === DataSetType.type_MC){
      //   //MC Case
      //   return (
      //     <GridItem xs={12} key={fieldKey}>
      //       {`${fieldName}${isMandatory ? "*" : ""}`}
      //       <CustomDropdown
      //         buttonProps={{
      //           round: true,
      //           color: "info",
      //         }}
      //         buttonText={
      //           <span>
      //             {getStringToShow(
      //               fieldNameList,
      //               detailFieldList,
      //               _customFields[fieldKey]
      //             )}
      //           </span>
      //         }
      //         dropdownList={fieldNameList}
      //         itemIDList={fieldKeyList}
      //         onClick={(selectedID) => {
      //           if (selectedID === null) {
      //             _customFields[fieldKey] = "";
      //             setData_customFields(_customFieldList);
      //           } else {
      //             _customFields[fieldKey] = selectedID;
      //             setData_customFields(_customFields);
      //             process_UI_Layout(_customFields);
      //             // console.log(
      //             //   `data_customFieldList data   ${JSON.stringify(
      //             //     _customFields
      //             //   )}`
      //             // );
      //           }
      //         }}
      //       />
      //     </GridItem>
      //   );
      // }
    }
    return [];
  }

  // function getCustomizedTableList(list_targetCollection, list_result, onDone)
  // {
  //   const targetCollection = list_targetCollection[0];
  //   var newData = list_result;
  //   const body = {
  //     data_targetCollection: targetCollection
  //   };

  //   fetchAPI
  //     .do_fetch("post", "admin/list-all", body)
  //     .then(
  //       (res) => {
  //         console.log("success: ", res.data);
  //         // return res.data.data_list;
  //         newData[targetCollection] = res.data.data_list;

  //         var newCollectionList = list_targetCollection;
  //         newCollectionList.splice(0,1);
  //         if (newCollectionList.length === 0)
  //         {
  //           onDone(newData);
  //         }
  //         else
  //         {
  //           getCustomizedTableList(newCollectionList, newData, (res => {
  //             onDone(res);
  //           }));
  //         }
  //       },
  //       (error) => {
  //         console.log("failed: ", error);
  //         // showAlert(false, Loc.failed, Loc.data_fetch_failed);
  //         // return [];
  //         newData[targetCollection] = [];
  //         var newCollectionList = list_targetCollection;
  //         newCollectionList.splice(0,1);
  //         if (newCollectionList.length === 0)
  //         {
  //           onDone(newData);
  //         }
  //         else
  //         {
  //           getCustomizedTableList(newCollectionList, newData, (res => {
  //             onDone(res);
  //           }));
  //         }
  //       }
  //     );
  // }

  

  const showuploading = () => {
    if (isUploading)
    {
      return (
        <SweetAlert
          style={{ display: "block", marginTop: "-100px" }}
          closeOnClickOutside={false}
          title={Loc.loading}
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnText={Loc.confirm}
          confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
          btnSize="lg"
          showConfirm={false}
          showCancel={false}
        >
          {Loc.uploading}
        </SweetAlert>
      )
    }
    else
    {
      return null;
    }
  }

  // const getStringToShow = (
  //   fieldNameList,
  //   detailFieldList,
  //   selectedID,
  //   isMandatory
  // ) => {
  //   var strToShow = "";
  //   strToShow = fieldNameList[0];
  //   // console.log(`selectedID getStringToShow ${selectedID}` )
  //   detailFieldList.map((prop) => {
  //     if (prop.key === selectedID) {
  //       strToShow = prop.value;
  //     }
  //   });

  //   return strToShow;
  // };
  const getStringToShow = (
    _data_createFieldList,
    fieldList,
    fieldNameList,
    index
  ) => {
    var strToShow = fieldNameList[0];
    const createFieldListData = _data_createFieldList[index];
    fieldList.map((prop, key) => {
      if (prop.id === createFieldListData["type"]) {
        strToShow = prop.itemName;
      }
    });

    return strToShow;
  };

  function moveUpTableCardRow(index, _data_createFieldList) 
  {
    if (index !== 0)
    {
      // get item
      const targetSwapItem = _data_createFieldList[index - 1];

      // do swap
      _data_createFieldList[index - 1] = _data_createFieldList[index];
      _data_createFieldList[index] = targetSwapItem;
    
      // save and update
      setData_createFieldList(_data_createFieldList);

    }
  }

  function removeTableCardRow(index, _data_createFieldList) {
    const isPwType = _data_createFieldList[index].fieldType === DataSetType.type_account_password;
    // remove field
    _data_createFieldList.splice(index, 1);
    // remove related field
    if (isPwType)
    {
      // check if contain pw_confirm
      var indexOfRelatedField = -1;
      for (var i=0; i<_data_createFieldList.length; i++)
      {
        var d = _data_createFieldList[i];
        if (d.fieldType === DataSetType.type_account_password_confirm)
        {
          indexOfRelatedField = i; 
          break;
        }
      }

      if (indexOfRelatedField !== -1)
      {
        _data_createFieldList.splice(indexOfRelatedField, 1);
      }
    }}

  const classes = useStyles();
  const classes_alert = useStyles_alert();

  return (
    <div>
      {check_redirect()}
      {showuploading()}
      {alert_save}
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" text>
              <CardText color="rose">
                <h4 className={classes.cardTitle}>{Loc.basic_info}</h4>
              </CardText>
            </CardHeader>

            {_renderUILayout()}

            <CardFooter className={classes.justifyContentCenter}>
              <GridContainer
                spacing={2}
                direction="row"
                justify="center"
                alignItems="center"
              >
                <GridItem xs={6} align="center">
                  <Button onClick={backClicked}>
                    <ICON_BACK />
                    {Loc.back}
                  </Button>
                </GridItem>

                <GridItem xs={6} align="center">
                  <Button
                    color="rose"
                    onClick={doneClicked}
                    disabled={fetchingStatus === "fetching" ? true : false}
                  >
                    <ICON_DONE />
                    {isEdit ? Loc.save : Loc.create}
                  </Button>
                </GridItem>
              </GridContainer>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
