import React from "react";
import { Redirect } from "react-router-dom";

// react plugin for creating charts
import ChartistGraph from "react-chartist";
// react plugin for creating vector maps
import { VectorMap } from "react-jvectormap";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
// import ContentCopy from "@material-ui/icons/ContentCopy";
import Store from "@material-ui/icons/Store";
import MonetizationOn from "@material-ui/icons/MonetizationOn";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
// import InfoOutline from "@material-ui/icons/InfoOutline";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Refresh from "@material-ui/icons/Refresh";
import Edit from "@material-ui/icons/Edit";
import Place from "@material-ui/icons/Place";
import ArtTrack from "@material-ui/icons/ArtTrack";
import Language from "@material-ui/icons/Language";
import Chart from "@material-ui/icons/PieChart";
import DONE from "@material-ui/icons/Done";
import FAILED from "@material-ui/icons/Close";

import QuestionMark from "@material-ui/icons/Cached";



// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import RosterView from "components/BookingSystem/RosterView";

import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import T_Muted from "components/Typography/Muted.js";
import T_Success from "components/Typography/Success.js";
import T_Danger from "components/Typography/Danger.js";
import dashboardPermissionData from "dashboardPermissions.js";

import Loc from "localization";
import fetchAPI from "connectionHandler/FetchAPI.js";
import firebase from "connectionHandler/firebase";
import Config from "itchi_config";
import { SystemType } from "Util/SystemType.js";

// import DragDropUpload from "components/CustomUpload/DragDropUpload.js";

import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart,
} from "variables/charts";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import styles_tableBtn from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import styles_switch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import CardText from "components/Card/CardText";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle";
import styles_alert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";
var styles_card = {
  ...dashboardStyle,
  ...loginPageStyle,
  cardTitle: {
    marginTop: "0",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);
const useStyles_tableBtn = makeStyles(styles_tableBtn);
const useStyles_switch = makeStyles(styles_switch);
const useStyles_card = makeStyles(styles_card);
const useStyles_alert = makeStyles(styles_alert);

export default function Dashboard(props) {
  const classes_card = useStyles_card();
  const classes_tableBtn = useStyles_tableBtn();
  const classes_switch = useStyles_switch();
  const classes = useStyles();
  const classes_alert = useStyles_alert();

  const [data_todaySales, setData_todaySales] = React.useState(0);
  const [
    data_todayActiveCustomer,
    setData_todayActiveCustomer,
  ] = React.useState(Loc.loading);
  const [data_monthSales, setData_monthSales] = React.useState(Loc.loading);
  const [
    data_monthActiveCustomer,
    setData_monthActiveCustomer,
  ] = React.useState(Loc.loading);

  const [data_todaySales_store, setData_todaySales_store] = React.useState([]);
  const [data_adminInfo, setDate_adminInfo] = React.useState(null);
  const [isGotAdminInfo, setData_isGotAdminInfo] = React.useState(false);

  const [time_reloadTime, set_time_reloadTime] = React.useState(5);

  const [data_iaAutoFefresh, set_data_iaAutoFefresh] = React.useState(false);
  // promise(timer)
  const [promise_todaySales, set_promise_todaySales] = React.useState(null);
  const [
    promise_todayActiveCustomer,
    set_promise_todayActiveCustomer,
  ] = React.useState(null);
  const [promise_monthSales, set_promise_monthSales] = React.useState(null);
  const [
    promise_monthActiveCustomer,
    set_promise_monthActiveCustomer,
  ] = React.useState(null);
  const [
    promise_todaySales_stores,
    set_promise_todaySales_stores,
  ] = React.useState(null);

  const [interval_reload, set_interval_reload] = React.useState(null);

  const [server_status_api, set_server_status_api] = React.useState(null);
  

  // Redirect
  const [pathToRedirect, setRedirect] = React.useState("");
  const [stateType, setStateType] = React.useState("");
  const [locationState, setLocationState] = React.useState(null);

  // const [temp_fileArray, setTemp_fileArray] = React.useState([]);
  const [filesArray, setFilesArray] = React.useState([]);
  // const [testing, setTesting] = React.useState("");
  const [value, setValue] = React.useState("**Hello world!!!**");
  const [data_customButtonConfigList, setDataCustomButtonConfigList] = React.useState(null);
  //alert
  const [alert_info, setAlert_info] = React.useState(null);
  const [alert_confirm, setAlert_confirm] = React.useState(null);
  const [isLoading, set_isLoading] = React.useState(false);

  const hideAlert = () => {
    setAlert_info(null);
    setAlert_confirm(null);
  }
  React.useEffect(() => {
    load_server_status();
    if (data_customButtonConfigList == null) {
      
      get_custom_button_list();
    }

    // console.log("START ::::::::: " + JSON.stringify(fileArray))
    // if(temp_fileArray.length > 0)
    // {
    //   mergeFileArray(temp_fileArray);
    // }
    // console.log("START ::::::::: filesArray" + JSON.stringify(filesArray))
    // console.log("props BD == " + JSON.stringify(props))
    // if(!locationState || locationState == null){
    setLocationState(props.location.state);
    // }
    // var interval_reload = null;

    // var interval_todaySales = null;
    // var interval_todayActiveCustomer = null;
    // var interval_monthSales = null;
    // var interval_monthActiveCustomer = null;
    // var interval_todaySales_stores = null;

    handle_init();
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (data_iaAutoFefresh) {
        clearInterval(interval_reload);

        // clearInterval(interval_todaySales);
        // clearInterval(interval_todayActiveCustomer);
        // clearInterval(interval_monthSales);
        // clearInterval(interval_monthActiveCustomer);
        // clearInterval(interval_todaySales_stores);
      }

      set_promise_todaySales(null);
      set_promise_todayActiveCustomer(null);
      set_promise_monthSales(null);
      set_promise_monthActiveCustomer(null);
      set_promise_todaySales_stores(null);
      // promise_todaySales = null;
      // promise_todayActiveCustomer = null;
      // promise_monthSales = null;
      // promise_monthActiveCustomer = null;
      // promise_todaySales_stores = null
    };
  }, [data_iaAutoFefresh, isGotAdminInfo,data_customButtonConfigList]);

  function handle_init() {
    if (isGotAdminInfo) {
      if (data_adminInfo !== null) {
        // do_loadAllData();

        if (data_iaAutoFefresh) {
          var _interval_reload = setInterval(() => {
            // do_loadAllData();
          }, time_reloadTime * 1000);
          set_interval_reload(_interval_reload);

          // get today sales
          // interval_todaySales = setInterval(() => {
          //   promise_todaySales = get_todaySalesAmount();
          // }, (time_todaySales * 1000));

          // // get today active customer
          // interval_todayActiveCustomer = setInterval(() => {
          //   promise_todayActiveCustomer = get_todayActiveCustomer();
          // }, (time_reloadTime * 1000));

          // // get month sales
          // interval_monthSales = setInterval(() => {
          //   promise_monthSales = get_monthSalesAmount();
          // }, (time_reloadTime * 1000));

          // // get month active customer
          // interval_monthActiveCustomer = setInterval(() => {
          //   promise_monthActiveCustomer = get_monthActiveCustomer();
          // }, (time_reloadTime * 1000));

          // // get today store sales list
          // interval_todaySales_stores = setInterval(() => {
          //   promise_todaySales_stores = get_todaySalesAmount_stores();
          // }, (time_reloadTime * 1000));
        }
      } else {
        console.log("Admin info NULL!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
      }
    } else {
      get_adminInfo();

    }
  }

  function get_adminInfo() {
    // get admin info
    const body = {
      id: firebase.auth().currentUser.uid,
    };
    fetchAPI.do_fetch("post", "admin/get-adminInfo", body).then(
      (res) => {
        setDate_adminInfo(res.data);
        setData_isGotAdminInfo(true);
      },
      (error) => {
        firebase
          .auth()
          .signOut()
          .then(function () {
            console.log("Sign-out successful.");
            setData_isGotAdminInfo(true);
            // Sign-out successful.
          })
          .catch(function (error) {
            console.log("Sign-out fail, ", error);
            setData_isGotAdminInfo(true);
            // An error happened.
          });
      }
    );
  }


  function get_custom_button_list()
  {

    const body = {
      data_targetCollection: "CustomButtonConfig",
    }
    fetchAPI.do_fetch('post', 'admin/list-all', body)
    .then((res) => {
        console.log("CustomButtonConfig success: ", res.data.data_list);
        var newDataList = res.data.data_list;
        newDataList = newDataList.filter(d => (d.isDeleted == false || d.isDeleted == undefined));
        setDataCustomButtonConfigList(newDataList);
    }, error => {
      setDataCustomButtonConfigList([]);

        console.log("failed: ", error);

    })
  }

  function do_loadAllData() {
    // var _promise_todaySales = get_todaySalesAmount();
    var _promise_todayActiveCustomer = get_todayActiveCustomer();
    var _promise_monthSales = get_monthSalesAmount();
    var _promise_monthActiveCustomer = get_monthActiveCustomer();
    var _promise_todaySales_stores = get_todaySalesAmount_stores();

    // set_promise_todaySales(_promise_todaySales);
    set_promise_todayActiveCustomer(_promise_todayActiveCustomer);
    set_promise_monthSales(_promise_monthSales);
    set_promise_monthActiveCustomer(_promise_monthActiveCustomer);
    set_promise_todaySales_stores(_promise_todaySales_stores);
  }

  function get_todaySalesAmount() {
    const date_now = new Date();
    const date_start = new Date(
      date_now.getFullYear(),
      date_now.getMonth(),
      date_now.getDate(),
      0,
      0,
      0
    );
    const date_end = new Date(
      date_now.getFullYear(),
      date_now.getMonth(),
      date_now.getDate() + 1,
      0,
      0,
      0
    );

    const promise_todaySales = do_get_SalesAmount(date_start, date_end).then(
      (todaySales) => {
        if (todaySales !== null) {
          const todaySalesStr = "$ " + parseFloat(todaySales).toLocaleString();
          setData_todaySales(todaySalesStr);
        }
      }
    );
    return promise_todaySales;
  }

  function get_todaySalesAmount_stores() {
    const date_now = new Date();
    const date_start = new Date(
      date_now.getFullYear(),
      date_now.getMonth(),
      date_now.getDate(),
      0,
      0,
      0
    );
    const date_end = new Date(
      date_now.getFullYear(),
      date_now.getMonth(),
      date_now.getDate() + 1,
      0,
      0,
      0
    );

    const promise_todaySales = do_get_SalesAmount_stores(
      date_start,
      date_end
    ).then((todaySalesList) => {
      if (todaySalesList !== null) {
        const sortedSalesList = todaySalesList.sort((a, b) =>
          a.total < b.total ? 1 : -1
        );
        console.log("sortedSalesList: ", sortedSalesList);

        var todaySales = 0;
        sortedSalesList.map((i, k) => {
          todaySales += i.total;
        });

        setData_todaySales(todaySales);
        setData_todaySales_store(sortedSalesList);
      }
    });
    return promise_todaySales;
  }

  function get_todayActiveCustomer() {
    const date_now = new Date();
    const date_start = new Date(
      date_now.getFullYear(),
      date_now.getMonth(),
      date_now.getDate(),
      0,
      0,
      0
    );
    const date_end = new Date(
      date_now.getFullYear(),
      date_now.getMonth(),
      date_now.getDate() + 1,
      0,
      0,
      0
    );

    const promise_load = do_get_ActiveCustomer(date_start, date_end).then(
      (todayActiveCustomer) => {
        if (todayActiveCustomer !== null) {
          const todayActiveCustomerStr = todayActiveCustomer.toLocaleString();
          setData_todayActiveCustomer(todayActiveCustomerStr);
        }
      }
    );
    return promise_load;
  }

  function get_monthSalesAmount() {
    const date_now = new Date();
    const date_start = new Date(
      date_now.getFullYear(),
      date_now.getMonth(),
      1,
      0,
      0,
      0
    );
    const date_end = new Date(
      date_now.getFullYear(),
      date_now.getMonth() + 1,
      0,
      0,
      0,
      0
    );

    const promise_todaySales = do_get_SalesAmount(date_start, date_end).then(
      (todaySales) => {
        if (todaySales !== null) {
          const todaySalesStr = "$ " + parseFloat(todaySales).toLocaleString();
          setData_monthSales(todaySalesStr);
        }
      }
    );
    return promise_todaySales;
  }
  function get_monthActiveCustomer() {
    const date_now = new Date();
    const date_start = new Date(
      date_now.getFullYear(),
      date_now.getMonth(),
      1,
      0,
      0,
      0
    );
    const date_end = new Date(
      date_now.getFullYear(),
      date_now.getMonth() + 1,
      0,
      0,
      0,
      0
    );

    const promise_load = do_get_ActiveCustomer(date_start, date_end).then(
      (todayActiveCustomer) => {
        if (todayActiveCustomer !== null) {
          const todayActiveCustomerStr = todayActiveCustomer.toLocaleString();
          setData_monthActiveCustomer(todayActiveCustomerStr);
        }
      }
    );
    return promise_load;
  }

  function do_get_SalesAmount_stores(date_start, date_end) {
    return new Promise((onDone) => {
      const body = {
        date_start: date_start,
        date_end: date_end,
      };

      fetchAPI.do_fetch("post", "admin/report-sales-each-store", body).then(
        (res) => {
          console.log("sales list: ", res.data.itemList);
          onDone(res.data.itemList);
        },
        (error) => {
          console.log("failed: ", error);
          onDone(null);
        }
      );
    });
  }

  function do_get_SalesAmount(date_start, date_end) {
    return new Promise((onDone) => {
      const body = {
        date_start: date_start,
        date_end: date_end,
      };

      fetchAPI.do_fetch("post", "admin/report-totalSalesAmount", body).then(
        (res) => {
          console.log("sales: ", res.data.total);
          onDone(res.data.total);
        },
        (error) => {
          console.log("failed: ", error);
          onDone(null);
        }
      );
    });
  }

  function do_get_ActiveCustomer(date_start, date_end) {
    return new Promise((onDone) => {
      const body = {
        date_start: date_start,
        date_end: date_end,
      };

      fetchAPI.do_fetch("post", "admin/report-activeCustomerCount", body).then(
        (res) => {
          console.log("active customer: ", res.data.total);
          onDone(res.data.total);
        },
        (error) => {
          console.log("failed: ", error);
          onDone(null);
        }
      );
    });
  }

  function get_sales_store_tableList() {
    if (data_todaySales_store.length !== 0) {
      var newTableList = [];
      data_todaySales_store.map((i, k) => {
        const storeName = i.storeName === "" ? Loc.store_default : i.storeName;
        const totalSales = i.total;
        const rank = "#" + parseFloat(k + 1);
        const percentage_val =
          data_todaySales === Loc.loading
            ? 0
            : (parseFloat(totalSales) / parseFloat(data_todaySales)) * 100;
        const percentage_str =
          percentage_val.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) + " %";

        const item = [
          rank,
          storeName,
          "$ " +
            totalSales.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
          percentage_str,
        ];

        newTableList.push(item);
      });

      // sort rank
      // newTableList.sort((a,b) => parseFloat(a.total) > parseFloat(b.total));

      console.log("newTableList: ", newTableList);

      return newTableList;
    } else {
      return [];
    }
  }

  function show_autoRefreshState() {
    if (data_iaAutoFefresh) {
      return <T_Success>{Loc.audo_refresh + ": " + Loc.on}</T_Success>;
    } else {
      return <T_Danger>{Loc.audo_refresh + ": " + Loc.off}</T_Danger>;
    }
  }

  const check_redirect = () => {
    if (pathToRedirect != "") {
      const state = {
        data: null,
        config: locationState,
      };
      // console.log("check_redirectstate!!!!!!!!!" + JSON.stringify(state))
      var data_state = null;
      if (locationState !== null) {
        if (stateType === "type_2") {
          data_state = state;
        } else {
          data_state = locationState;
        }
      }
      if (pathToRedirect !== "/sales-pos-add") {
        return (
          <Redirect
            to={{
              pathname: "/admin" + pathToRedirect,
              state: data_state,
            }}
          />
        );
      } else {
        return (
          <Redirect
            to={{
              pathname: "/poscart" + pathToRedirect,
              state: data_state,
            }}
          />
        );
      }
      
    } else {
      return null;
    }
  };

  function getPermissions(targetType) {
    var permissions = [];

    if (data_adminInfo !== null) {
      dashboardPermissionData.map((p, k) => {
        if (p.type === targetType) {
          permissions.push(p);
        }
      });
    }

    return permissions;
  }

  function checkAllPermission() {
    if (
      data_adminInfo !== null &&
      data_adminInfo.permissions["all"] !== null &&
      data_adminInfo.permissions["all"] === true
    ) {
      return true;
    } else {
      return false;
    }
  }

  function render_dashboardSalesStatistics(permissions) {
    if (Config.targetFlags.includes(SystemType.ERP)) {
      if (data_adminInfo !== null) {
        var title = permissions[0].name;
        var color = permissions[0].color;
        var path = permissions[0].path;
        var isShow = false;
        if (
          checkAllPermission() ||
          data_adminInfo.dashboardPermissions[path] === true
        ) {
          isShow = true;
        }

        if (!isShow) {
          return null;
        } else {
          return (
            <GridContainer>
              <GridItem xs={12}>
                <Card>
                  <CardHeader color={color} text>
                    <CardText color={color}>
                      <h4>{title}</h4>
                    </CardText>
                  </CardHeader>
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={9} sm={9} md={9} lg={9}>
                        <Button
                          color="success"
                          round
                          onClick={() => do_loadAllData()}
                        >
                          <Refresh />
                          {Loc.refresh}
                        </Button>
                      </GridItem>
                      <GridItem xs={3} sm={3} md={3} lg={3}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={data_iaAutoFefresh}
                              onChange={(event) =>
                                set_data_iaAutoFefresh(event.target.checked)
                              }
                              // value={FALSE}
                              classes={{
                                switchBase: classes_switch.switchBase,
                                checked: classes_switch.switchChecked,
                                thumb: classes_switch.switchIcon,
                                track: classes_switch.switchBar,
                              }}
                            />
                          }
                          classes={{
                            label: classes.label,
                          }}
                          label={show_autoRefreshState()}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={3} md={3} lg={3}>
                        <Card>
                          <CardHeader color="info" stats icon>
                            <CardIcon color="info">
                              <MonetizationOn />
                            </CardIcon>
                            <p className={classes.cardCategory}>
                              {Loc.total_revenue}
                            </p>
                            <h3 className={classes.cardTitle}>
                              {"$ " +
                                parseFloat(data_todaySales).toLocaleString()}
                            </h3>
                          </CardHeader>
                          <CardFooter stats>
                            <div className={classes.stats}>
                              <DateRange />
                              {Loc.today}
                            </div>
                          </CardFooter>
                        </Card>
                      </GridItem>

                      <GridItem xs={12} sm={3} md={3} lg={3}>
                        <Card>
                          <CardHeader color="warning" stats icon>
                            <CardIcon color="warning">
                              <SupervisedUserCircleIcon />
                            </CardIcon>
                            <p className={classes.cardCategory}>
                              {Loc.active_customer}
                            </p>
                            <h3 className={classes.cardTitle}>
                              {data_todayActiveCustomer}
                            </h3>
                          </CardHeader>
                          <CardFooter stats>
                            <div className={classes.stats}>
                              <DateRange />
                              {Loc.today}
                            </div>
                          </CardFooter>
                        </Card>
                      </GridItem>

                      <GridItem xs={12} sm={3} md={3} lg={3}>
                        <Card>
                          <CardHeader color="info" stats icon>
                            <CardIcon color="info">
                              <MonetizationOn />
                            </CardIcon>
                            <p className={classes.cardCategory}>
                              {Loc.total_revenue}
                            </p>
                            <h3 className={classes.cardTitle}>
                              {data_monthSales}
                            </h3>
                          </CardHeader>
                          <CardFooter stats>
                            <div className={classes.stats}>
                              <DateRange />
                              {Loc.this_month}
                            </div>
                          </CardFooter>
                        </Card>
                      </GridItem>

                      <GridItem xs={12} sm={3} md={3} lg={3}>
                        <Card>
                          <CardHeader color="warning" stats icon>
                            <CardIcon color="warning">
                              <SupervisedUserCircleIcon />
                            </CardIcon>
                            <p className={classes.cardCategory}>
                              {Loc.active_customer}
                            </p>
                            <h3 className={classes.cardTitle}>
                              {data_monthActiveCustomer}
                            </h3>
                          </CardHeader>
                          <CardFooter stats>
                            <div className={classes.stats}>
                              <DateRange />
                              {Loc.this_month}
                            </div>
                          </CardFooter>
                        </Card>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={6}>
                        <Card>
                          <CardHeader color="success" icon>
                            <CardIcon color="success">
                              <Store />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>
                              {Loc.top_sales_by_store + " (" + Loc.today + ")"}
                            </h4>
                          </CardHeader>
                          <CardBody>
                            <GridContainer justify="space-between">
                              <GridItem xs={12}>
                                <Table
                                  tableData={get_sales_store_tableList()}
                                  customCellClasses={[
                                    classes_tableBtn.right,
                                    classes_tableBtn.right,
                                  ]}
                                  customClassesForCells={[2, 3]}
                                />
                              </GridItem>
                            </GridContainer>
                          </CardBody>
                        </Card>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          );
        }
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  function render_dashboardShortCut(permissions) {
    if (Config.targetFlags.includes(SystemType.ERP)) {
      if (data_adminInfo !== null) {
        var isShowShortcut = false;
        // console.log("permissions.length: " + permissions.length);
        var buttons = permissions.map((p, k) => {
          if (
            checkAllPermission() ||
            data_adminInfo.dashboardPermissions[p.path] === true
          ) {
            isShowShortcut = true;
            return (
              <GridItem sm={2} key={k}>
                <Button
                  color={p.color}
                  round
                  onClick={() => {
                    setStateType(p.stateType);
                    setRedirect(p.path);
                  }}
                >
                  <p.icon />
                  {p.name}
                </Button>
              </GridItem>
            );
          }
        });

        // var migration_customer = null;
        // if (checkAllPermission())
        // {
        //   migration_customer = (
        //     <GridItem sm = {2}>
        //       <Button color="danger" round onClick={() => {
        //           const body = {

        //           }
        //           fetchAPI.do_fetch('post', '/admin/old_server_migration_customer', body)
        //           .then((res) => {
        //             console.log("Migration API called");
        //           }, error => {
        //             console.log("Migration faild");
        //           })
        //         }}>
        //           {"Migration Customer"}
        //       </Button>
        //     </GridItem>
        //   );
        // }
      
        

        if (!isShowShortcut) {
          return null;
        } else {
          return (
            <GridContainer>
              <GridItem xs={12}>
                <Card>
                  <CardHeader color="primary" text>
                    <CardText color="primary">
                      <h4>{Loc.shortcut_menu}</h4>
                    </CardText>
                  </CardHeader>
                  <CardBody>
                    <GridContainer>
                      {buttons}
                      {/* {custom_buttons} */}
                      {/* {migration_customer} */}
                      {_renderBtnCleanLocalStorage()}
                    </GridContainer>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          );
        }
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  function _renderBtnCleanLocalStorage() {
    return (
      <GridItem sm={2} key={"CleanLocalStorage"}>
        <Button
          color="danger"
          round
          onClick={() => {
            localStorage.clear();
            window.location.reload();
          }}
        >
          <Refresh />
          {Loc.refresh_local_storage}
        </Button>
      </GridItem>
    );
  }

  // function mergeFileArray(_fileArray){

  //   setFilesArray([...filesArray,..._fileArray]);
  //   setTemp_fileArray([]);
  //   // console.log(`DidAcceptedFiles newArray: ${JSON.stringify(newArray)}`)
  // }
  function render_dragDrop(_fileArray) {
    // console.log(`render_dragDrop _fileArray: ${JSON.stringify(_fileArray)}`)
    // return (
    // <DragDropUpload
    //     fileArray={_fileArray}
    //     DidAcceptedFiles={(acceptedFiles) => {
    //       // console.log("DidAcceptedFiles");
    //       // console.log(`DidAcceptedFiles fileArray: ${JSON.stringify(_fileArray)}`)
    //       // console.log(`DidAcceptedFiles orgFileList: ${JSON.stringify(orgFileList)}`)
    //       setFilesArray(acceptedFiles);
    // }}/>
    // );
  }
  function handleEditorChange({ html, text }) {
    console.log('handleEditorChange', html, text);
  }

  function load_server_status()
  {
    set_server_status_api(null);
    const body = {}
    fetchAPI.do_fetch("post", "test_post", body).then(
      (res) => {
        // console.log("success: ", res);
        set_server_status_api(true);
      },
      (error) => {
        set_server_status_api(false);
        // console.log("failed: ", error);
      }
    );
  }

  function render_server_status()
  {
    var status_api = server_status_api == null ? <h4><T_Muted>Loading</T_Muted></h4> : (server_status_api ? <h4><T_Success>Good</T_Success></h4> : <h4><T_Danger>Failed</T_Danger></h4>);
    var status_api_icon = server_status_api == null ? <h4><T_Muted><QuestionMark /></T_Muted></h4> : (server_status_api ? <h4><T_Success><DONE/></T_Success></h4> : <h4><T_Danger><FAILED/></T_Danger></h4>);
      

    return (
      <GridContainer>
        <GridItem xs={6}>
          <Card>
            <CardHeader color="info" icon>
            <CardIcon color="info">
              <SupervisedUserCircleIcon />
            </CardIcon>
              <h4 className={classes_card.cardIconTitle}>{Loc.server_status}</h4>
            </CardHeader>
            <CardBody>

            <GridContainer>
              <GridItem xs={12}>
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: "5px"
                }}>
                  <h4>伺服器API狀態: &nbsp;</h4>
                  {status_api}
                  {status_api_icon}
                </div>
              </GridItem>

              
            </GridContainer>
              
            <Button
                  color={'primary'}
                  round
                onClick={() => {
                  load_server_status();
                  }}
                >
                  {Loc.refresh}
              </Button>
              <GridContainer>

 {/* <RosterView
          staffID={"1zfv0DgBMJMyLviekuk0aprYRzN2"}
          staffName={"雯雯"}
          durationList={["30","60"]}
          durationIdList={[30, 60]}
          
        /> */}

            </GridContainer>
              
            </CardBody>
          </Card>
        </GridItem>
        
      </GridContainer>
    );
  }

  const checkInPermission = (targetPath, adminInfo) => {
    var hasPermission = false;
    // console.log("adminInfo: ", adminInfo);
    if (adminInfo) {
      if (adminInfo.permissions["all"]) {
        hasPermission = true;
      } else {
        // console.log("checking targetPath: " + targetPath + " permissions :" + JSON.stringify(adminInfo.permissions));
        hasPermission = false;
        if (adminInfo.customButtonPermissions != undefined && adminInfo.customButtonPermissions != null)
        {
          hasPermission = adminInfo.customButtonPermissions[targetPath] === true ? true : false;
        }
      }
    }

    return hasPermission;
  };


  function render_custom_buttons() {
    var custom_buttons = []
    if(data_customButtonConfigList == null || data_customButtonConfigList.length == 0) { return custom_buttons;}
    var filter_buttons = data_customButtonConfigList.filter(data => checkInPermission(data.button_name, data_adminInfo));
    // console.log("filter_buttons: ", filter_buttons);
    custom_buttons = filter_buttons.map(data => {
      if (checkInPermission(data.button_name, data_adminInfo)) {
        return (
          <GridItem sm={2}>
            <Button color={data.button_color} round onClick={() => {
        set_isLoading(true);
              
             const body = {
               
            }
            fetchAPI.do_fetch('post', data.api_path, body)
            .then((res) => {
              console.log("Custom API called");
               set_isLoading(false);

              showAlert(true, "Success", data.api_path+" called");
            }, error => {
              console.log("Custom API call faild");
              set_isLoading(false);


              showAlert(false, "Failed", data.api_path+" call faild");
            })
          }}>
              {data.button_name}
          </Button> 
        </GridItem>
      )
    }
      
    });


    if(custom_buttons.length == 0) { return;}
    return (
      <GridContainer>
              <GridItem xs={12}>
                <Card>
                  <CardHeader color="primary" text>
                    <CardText color="primary">
                      <h4>{Loc.shortcut_menu}</h4>
                    </CardText>
                  </CardHeader>
                  <CardBody>
                    <GridContainer>

                      {custom_buttons}

                    </GridContainer>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
        
    );
  }

  
  function showAlert(issuccess, title, content){
    setAlert_info(
      <SweetAlert
        error={!issuccess}
        success={issuccess}
        style={{ display: "block", marginTop: "-100px" }}
        closeOnClickOutside={false}
        title={title}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnText={Loc.confirm}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        btnSize="lg"
      >
        {content}
      </SweetAlert>
    );
  }

  const showloading = () => {
    if (isLoading) {
      return (
        <SweetAlert
          style={{ display: "block", marginTop: "-100px" }}
          closeOnClickOutside={false}
          title={Loc.loading}
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnText={Loc.confirm}
          confirmBtnCssClass={
            classes_alert.button + " " + classes_alert.success
          }
          btnSize="lg"
          showConfirm={false}
          showCancel={false}
        >
          {Loc.please_wait}
        </SweetAlert>
      );
    } else {
      return null;
    }
  };

  return (
    <div>
      {alert_info}
      {showloading()}
      {render_server_status()}
      {render_custom_buttons()}

      {check_redirect()}
      {/* For ERP */}
      {render_dashboardShortCut(getPermissions("shortcut"))}
      {render_dashboardSalesStatistics(getPermissions("statistics_sales"))}

      {/* <Basic /> */}

      {/* <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart className={classes.cardHover}>
            <CardHeader color="info" className={classes.cardHeaderHover}>
              <ChartistGraph
                className="ct-chart-white-colors"
                data={dailySalesChart.data}
                type="Line"
                options={dailySalesChart.options}
                listener={dailySalesChart.animation}
              />
            </CardHeader>
            <CardBody>
              <div className={classes.cardHoverUnder}>
                <Tooltip
                  id="tooltip-top"
                  title={Loc.refresh}
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button simple color="info" justIcon>
                    <Refresh className={classes.underChartIcons} />
                  </Button>
                </Tooltip>
              </div>
              <h4 className={classes_card.cardTitle}>{Loc.daily_sales}</h4>
              <p className={classes.cardCategory}>
                <span className={classes.successText}>
                  <ArrowUpward className={classes.upArrowCardCategory} /> 55%
                </span>{" "}
                {Loc.increase_in_today_sales}
              </p>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> {Loc.updated + " " + "4" + " " + Loc.minutes + " " + Loc.ago}
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer> */}
    </div>
  );
}
