/*eslint-disable*/
import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import ProductCategoryDropdown from "views/CommonUseComponents/ProductCategoryDropdown.js";
import Dropdown from "views/CommonUseComponents/Dropdown.js";
// material ui icons
import MailOutline from "@material-ui/icons/MailOutline";
import Contacts from "@material-ui/icons/Contacts";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

import SwitchUI from "@material-ui/core/Switch";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import styles_alert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import ImageUpload from "components/CustomUpload/ImageUpload.js";

import ICON_BACK from "@material-ui/icons/ArrowBackIos";
import ICON_DONE from "@material-ui/icons/Done";

const useStyles = makeStyles(styles);
const useStyles_alert = makeStyles(styles_alert);

import SweetAlert from "react-bootstrap-sweetalert";

import Loc from "localization";
import fetchAPI from "connectionHandler/FetchAPI.js";

import styles_switch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import { ContactSupportOutlined } from "@material-ui/icons";
// import uuid from "react-uuid";

export default function Product_item_add(props) {
  const [isEdit, setIsEdit] = React.useState(false);

  const [data_id, setData_id] = React.useState("");
  const [data_uuid, setData_uuid] = React.useState("");
  const [data_imageUrl, setData_imageUrl] = React.useState("");
  const [data_name, setData_itemName] = React.useState("");

  const [data_price, setData_price] = React.useState(0.0);
  const [data_commission, setData_commission] = React.useState(0.0);
  const [data_categoryID, setData_categoryID] = React.useState("");
  const [data_categoryName, setData_categoryName] = React.useState("");

  const [data_remark, setData_remark] = React.useState("");

  const [pathToRedirect, setRedirect] = React.useState("");
  const [productNameState, setproductNameState] = React.useState("");
  const [fetchingStatus, setFetchingStatus] = React.useState("");

  const [isPreLoadedData, setIsPreLoadedData] = React.useState(false);
  const [data_category, setData_category] = React.useState(null);

  const [data_hasDuration, setData_hasDuration] = React.useState(false);
  const [data_duration, setData_Duration] = React.useState(0);
  // alert
  const [alert_save, setSaveAlert] = React.useState(null);
  const hideAlert = () => {
    setSaveAlert(null);
  };

  const useStyles_switch = makeStyles(styles_switch);
  const classes_switch = useStyles_switch();
  //for get confit list api
  const [data_page, set_page] = React.useState(0);
  const [data_pageCount, set_pageCount] = React.useState(50);
  const [isLoading, set_isLoading] = React.useState(false);
  const [data_isDurationOn, setData_isDurationOn] = React.useState(false);
  const [historyState,setHistoryState] = React.useState(null)

  React.useEffect(() => {
    var promise_preLoad = null;
    setHistoryState(props.history.location.state)
    if (!isPreLoadedData) {
      setIsPreLoadedData(true);
      promise_preLoad = do_preLoadData().then((isLoaded) => {
        if (isLoaded) {
        }
      });
    }

    getConfigList()

    // pre load data for edit
    if (props.location.state.data != null) {
      var dataToEdit = props.location.state.data;
      setIsEdit(true);
      setData_id(dataToEdit.id);
      // setData_uuid(dataToEdit.uuid);
      setData_imageUrl(dataToEdit.imageUrl);
      setData_itemName(dataToEdit.itemName);
      setData_categoryID(dataToEdit.categoryID);
      setData_categoryName(dataToEdit.categoryName);
      setData_price(dataToEdit.price);
      setData_commission(dataToEdit.commission);
      setData_remark(dataToEdit.remark);
      setData_Duration(dataToEdit.duration)
      setData_hasDuration(dataToEdit.hasDuration);
    }
    // Specify how to clean up after this effect:
    return function cleanup() {
      promise_preLoad = null;
    };
  }, []);
  //Payment 
  function do_preLoadData() {
    return new Promise((onDone) => {
      const body = {
        data_targetCollection: "Category",
      };
      fetchAPI.do_fetch("post", "admin/list-all", body).then(
        (res) => {
          console.log("success: ", res.data);
          setData_category(res.data.data_list);
          onDone(true);
        },
        (error) => {
          console.log("failed: ", error);
          showAlert(false, Loc.failed, "Load category faild");
          onDone(false);
        }
      );
    });
  }

  const getConfigList = () => {
    set_isLoading(true);
    const body = {
      "page":data_page,
      "pageCount":data_pageCount,
    }
    fetchAPI.do_fetch('post', 'admin/list-system-config', body)
    .then((res) => {
        set_isLoading(false);
        if(res.data && res.data.configList){
        var configs = res.data.configList

          setData_isDurationOn(configs["has-duration"])
        }
    }, error => {
        set_isLoading(false);
        console.log("failed: ", error);
        showAlert(false, Loc.failed, Loc.data_fetch_failed);
    })
  };

  const validCheck = () => {
    var result = true;
    if (data_name == "") {
      result = false;
    }
    return result;
  };

  const backClicked = () => {
    setRedirect("/admin/product-list");
  };

  const doneClicked = () => {
    setFetchingStatus("fetching");
    if (validCheck()) {
      const idToPass = isEdit ? data_id : null;
      const body = {
        id: idToPass,
        content: {
          itemName: data_name,
          categoryID: data_categoryID,
          categoryName: data_categoryName,
          price: data_price,
          commission: data_commission,
          remark: data_remark,
          imageUrl: data_imageUrl,
          hasDuration: data_hasDuration,
          duration: parseFloat(data_duration),
        },
      };

      console.log("body: ", body);

      fetchAPI.do_fetch("post", "admin/create-product", body).then(
        (res) => {
          console.log("success: ", res);
          setFetchingStatus("");
          showAlert(true, Loc.success, Loc.data_save_success);
          // setData_itemName("");
        },
        (error) => {
          console.log("failed: ", error);
          setFetchingStatus("");
          showAlert(false, Loc.failed, Loc.data_save_failed);
        }
      );
    } else {
      setproductNameState("error");
      setFetchingStatus("");
      showAlert(false, Loc.failed, Loc.data_save_failed);
    }
  };

  function showAlert(issuccess, title, content) {
    setSaveAlert(
      <SweetAlert
        error={!issuccess}
        success={issuccess}
        style={{ display: "block", marginTop: "-100px" }}
        closeOnClickOutside={false}
        title={title}
        onConfirm={() => {
          if (issuccess) {
            // hideAlert()
            setRedirect("/admin/product-list");
          } else {
            hideAlert();
          }
        }}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        cancelBtnCssClass={classes_alert.button + " " + classes_alert.info}
        onCancel={() => {
          if (issuccess) {
            setData_itemName("");
            setData_imageUrl("");
            setData_categoryID("");
            setData_price(0);
            setData_commission(0);
            setData_remark("");
            // window.location.reload(false);
            hideAlert();
          } else {
            hideAlert();
          }
        }}
        confirmBtnText={issuccess ? Loc.done : Loc.confirm}
        cancelBtnText={Loc.continue}
        btnSize="lg"
        showCancel={issuccess && !isEdit}
      >
        {content}
      </SweetAlert>
    );
  }

  const handleChange_gender = (event) => {
    setData_gender(event.target.value);
  };

  const check_redirect = () => {
    if (pathToRedirect != "") {
      return <Redirect to={{
        pathname: pathToRedirect,
        state: historyState.config,
      }} />;
    } else {
      return null;
    }
  };

  const imageDidUpload = (pathInStorage) => {
    if (pathInStorage != null) {
      setData_imageUrl(pathInStorage);
      console.log("imageDidUpload called, path: ", pathInStorage);
    }
  };

  const classes = useStyles();
  const classes_alert = useStyles_alert();

  function _renderDuration(){
    if(!data_isDurationOn) {return null}

    return (
      <>
      <GridItem xs={6}>
      <FormControlLabel
        control={
          <SwitchUI
            checked={data_hasDuration}
            onChange={(event) =>
              setData_hasDuration(event.target.checked)
            }
            // value={FALSE}
            classes={{
              switchBase: classes_switch.switchBase,
              checked: classes_switch.switchChecked,
              thumb: classes_switch.switchIcon,
              track: classes_switch.switchBar,
            }}
          />
        }
        classes={{
          label: classes.label,
        }}
        label={Loc.has_duration}
      />
    </GridItem>

    {durationInput()}
    </>
    );

  }

  const durationInput = () =>{
    if(data_hasDuration) {
        return(
          <GridItem xs={6}>
                      <CustomInput
                        labelText={Loc.duration_min}
                        id="product_minutes"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: data_duration,
                          onChange: (event) => {
                            setData_Duration(event.target.value);
                          },
                          type: "number",
                        }}
                      />
                    </GridItem>
        )
    }
    return null
  }

  return (
    <div>
      {check_redirect()}
      {alert_save}
      <GridContainer>
        <GridItem xs={6}>
          <Card>
            <CardHeader color="rose" text>
              <CardText color="rose">
                <h4 className={classes.cardTitle}>{Loc.basic_info}</h4>
              </CardText>
            </CardHeader>
            <CardBody>
              <form>
                <GridContainer>
                  <GridItem xs={12} align="center">
                    <ImageUpload
                      imageDidUpload={(res) => imageDidUpload(res)}
                      imageUrl={data_imageUrl}
                      targetFolder="Product"
                    />
                  </GridItem>

                  <GridItem xs={12} align="center">
                    <Dropdown
                      currentID={data_categoryID}
                      listData={data_category === null ? [] : data_category}
                      defaultSelectionString={Loc.category_default}
                      didSelectItem={(selectedItem) => {
                        if (selectedItem === null) {
                          setData_categoryName("");
                          setData_categoryID("");
                        } else {
                          const selectedID = selectedItem.id;
                          const categoryName = selectedItem.itemName;
                          setData_categoryName(categoryName);
                          setData_categoryID(selectedID);
                        }
                      }}
                    />
                  </GridItem>

                  <GridItem xs={12}>
                    <CustomInput
                      // success={requiredState === "success"}
                      error={productNameState === "error"}
                      labelText={Loc.product_name + "*"}
                      id="product_name"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: data_name,
                        onChange: (event) => {
                          setproductNameState("success");
                          setData_itemName(event.target.value);
                        },
                        type: "text",
                      }}
                    />
                  </GridItem>

                  <GridItem xs={6}>
                    <CustomInput
                      labelText={Loc.product_commission}
                      id="product_commission"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: data_commission,
                        onChange: (event) => {
                          setData_commission(event.target.value);
                        },
                        type: "number",
                      }}
                    />
                  </GridItem>

                  <GridItem xs={6}>
                    <CustomInput
                      labelText={Loc.product_price}
                      id="product_price"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: data_price,
                        onChange: (event) => {
                          setData_price(event.target.value);
                        },
                        type: "number",
                      }}
                    />
                  </GridItem>

                {_renderDuration()}

                  <GridItem xs={12}>
                    <CustomInput
                      labelText={Loc.remark}
                      id="remark"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: data_remark,
                        onChange: (event) => {
                          setData_remark(event.target.value);
                        },
                        type: "text",
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </form>
            </CardBody>
            <CardFooter className={classes.justifyContentCenter}>
              <GridContainer
                spacing={2}
                direction="row"
                justify="center"
                alignItems="center"
              >
                <GridItem xs={6} align="center">
                  <Button onClick={backClicked}>
                    <ICON_BACK />
                    {Loc.back}
                  </Button>
                </GridItem>

                <GridItem xs={6} align="center">
                  <Button
                    color="rose"
                    onClick={doneClicked}
                    disabled={fetchingStatus === "fetching" ? true : false}
                  >
                    <ICON_DONE />
                    {isEdit ? Loc.save : Loc.create}
                  </Button>
                </GridItem>
              </GridContainer>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
