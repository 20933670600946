import React from "react";
import cx from "classnames";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar_admin from "components/Sidebar/Sidebar_admin.js";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

// import routes, {sideMenuRoutes} from "routes.js";

import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";
import styles_alert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import Loc from "localization";
import firebase from 'connectionHandler/firebase';
import fetchAPI from "connectionHandler/FetchAPI.js";
import SweetAlert from "react-bootstrap-sweetalert";
import  * as RoutesHelper from 'Helper/RoutesHelper'
import Config from "itchi_config";


var ps;

const useStyles = makeStyles(styles);
const useStyles_alert = makeStyles(styles_alert);

export default function Dashboard(props) {
  const { ...rest } = props;
  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [miniActive, setMiniActive] = React.useState(false);
  const [image, setImage] = React.useState(require("assets/img/sidebar-2.jpg"));
  // const [image, setImage] = React.useState(require(Config.sidebarImageBackground).default);
  const [color, setColor] = React.useState("blue");
  const [bgColor, setBgColor] = React.useState("black");
  // const [hasImage, setHasImage] = React.useState(true);
  const [fixedClasses, setFixedClasses] = React.useState("dropdown");
  const [logo, setLogo] = React.useState(require("assets/img/itchidash.svg"));
  // const [logo, setLogo] =  React.useState(require(Config.webLogo));
  // auth
  const [isSignedIn, setSignedIn] = React.useState(false);
  const [isCheckedSignIn, set_isCheckedSignIn] = React.useState(false);
  const [data_adminInfo, setDate_adminInfo] = React.useState(null);

  const [data_SetDataManagementList,setData_SetDataManagementList] = React.useState(null);
  const [data_SetDataManagementTypeList,setData_SetDataManagementTypeList] = React.useState(null);
  const [data_CustomSettingConfigList,setData_CustomSettingConfigList] = React.useState(null);
  const [data_preloadData, setData_preloadData] = React.useState(null);
  const [isPreLoadedData, setIsPreLoadedData] = React.useState(false);

  const [isLoading, set_isLoading] = React.useState(false);
  const [data_page, set_page] = React.useState(0);
  const [data_pageCount, set_pageCount] = React.useState(50);
  const [isERP, set_isERP] = React.useState(false)
  //alert
  const [alert_info, setAlert_info] = React.useState(null);
  const [alert_confirm, setAlert_confirm] = React.useState(null);
  const hideAlert = () => {
    setAlert_info(null);
    setAlert_confirm(null);
  }

  const collectionsToLoad = [
    "Product",
    "Product-Coupon",
    "Product-Package",
    "Store",
    "Payment",
    "Staff",
    "Category"
  ];

  // styles
  const classes = useStyles();
  const classes_alert = useStyles_alert();

  const mainPanelClasses =
    classes.mainPanel +
    " " +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1
    });
  // ref for main panel div
  const mainPanel = React.createRef();

  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  React.useEffect(() => {
    // console.log("props.location.state.adminInfo: ", props.location.state.adminInfo);
    set_isCheckedSignIn(false);
    const subscription_auth = add_authListener();
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      subscription_auth();
      if (navigator.platform.indexOf("Win") > -1 && ps) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
      // console.log("cleanup called in Admin");
    };
  }, []);

  const setSidebarRef= (element)=>{
    if (navigator.platform.indexOf("Win") > -1 && element) {
      element.scrollTop = 0
      ps = new PerfectScrollbar(element, {
        wheelSpeed: 0.05,
        suppressScrollX: true,
        suppressScrollY: false
      });
    }
  }

  function get_adminInfo()
  {
    // get admin info
    const body = {
      "id":firebase.auth().currentUser.uid
    }
    fetchAPI.do_fetch('post', 'admin/get-adminInfo', body)
      .then((res) => {
      console.log("Adminjs get_adminInfo res.data: ", JSON.stringify(res.data));
      setDate_adminInfo(res.data);
      set_isCheckedSignIn(true);
      
      get_SetDataManagementConfig();
      
    }, error => {
      firebase.auth().signOut().then(function() {
        console.log("Sign-out successful.");
        setSignedIn(false);
        set_isCheckedSignIn(true);
        // Sign-out successful.
      }).catch(function(error) {
        console.log("Sign-out fail, ", error);
        setSignedIn(false);
        set_isCheckedSignIn(true);
        // An error happened.
      });
    })
  }

  function get_SetDataManagementConfig()
  {
    set_isLoading(true);
    const body = {
      getFullList:true
    }
    fetchAPI.do_fetch('post', 'admin/list-set-data-management-config', body)
    .then((res) => {
        console.log("success: ", res.data);
        // sort with tableOrder
        var data_tableList = res.data.data_list;
        data_tableList.sort((a, b) => (parseInt(a.tableOrder) > parseInt(b.tableOrder)) ? 1 : -1)
        // set the custom table
        setData_SetDataManagementList(data_tableList);
        get_CustomSettingConfig();
    }, error => {
        console.log("failed: ", error);
        showAlert(false, Loc.failed, Loc.data_fetch_failed);
    })
  }

  function get_SetDataManagementTypeConfig()
  {
    set_isLoading(true);
    const body = {
      getFullList:true
    }
    fetchAPI.do_fetch('post', 'admin/list-set-data-management-type-config', body)
    .then((res) => {
      
        console.log("success: ", res.data);
        setData_SetDataManagementTypeList(res.data.data_list);
        
        const routesList = RoutesHelper.getMergedRoutesList(data_SetDataManagementList,res.data.data_list,data_CustomSettingConfigList);
        var isERP = false
        for(let i = 0;i<routesList.length;i++){
          var routeData = routesList[i]
          if(routeData.flag == "erp"){
            isERP = true
            break
          }
        }
        set_isERP(isERP)
        // console.log("get routes for checking : "+JSON.stringify(routes));
        if (isERP){
          const jsonStr = localStorage.getItem('preloadDataList');
        
        if (jsonStr == null){ 
          getListOfCollection(collectionsToLoad, {}).then(
            (returnList) => {
              // console.log(`Return list  ${JSON.stringify(returnList)}`)
              setData_preloadData(returnList);
              setPreloadDataToLocal(JSON.stringify(returnList));
            }
          );
          set_isLoading(false);
        }else{
        console.log("checkCollectionVersionList: ");
          
          checkCollectionVersionList().then(updateCollectionNameList=>{
            if (updateCollectionNameList.length > 0){
              updatePreloadData(updateCollectionNameList,{}).then(()=>{
                // console.log("updatePreloadDataupdatePreloadData Done " )
                const newJsonStr = localStorage.getItem('preloadDataList');
                var newPreloadDataList = JSON.parse(newJsonStr); 
                setData_preloadData(newPreloadDataList);
                
              })
              syncCollectionVersionList(true)
            }else{
              //No need to update
              const currentJsonStr = localStorage.getItem('preloadDataList');
              var currentLocalPreloadList = JSON.parse(currentJsonStr); 
                setData_preloadData(currentLocalPreloadList);
            }
            syncCollectionVersionList(false) // just for checking
            set_isLoading(false);
          })

        }
        }else{
          setData_preloadData(null);
          set_isLoading(false);
        }
        

        
        
    }, error => {
        console.log("failed: ", error);
        set_isLoading(false);
        showAlert(false, Loc.failed, Loc.data_fetch_failed);
    })
  }

  function get_CustomSettingConfig()
  {
    set_isLoading(true);
    const body = {
      data_targetCollection: "CustomSettingConfig",
    }
    fetchAPI.do_fetch('post', 'admin/list-all', body)
    .then((res) => {
        console.log("get_CustomSettingConfig success: ", res.data.data_list);
        var newDataList = res.data.data_list;
        newDataList = newDataList.filter(d => (d.isDeleted == false || d.isDeleted == undefined));
        setData_CustomSettingConfigList(newDataList);
        get_SetDataManagementTypeConfig();
    }, error => {
        console.log("failed: ", error);
        showAlert(false, Loc.failed, Loc.data_fetch_failed);
    })
  }
  function getListOfCollection(collectionsList, onHoldList)
  {
    var newList = collectionsList;
    var newOnHoldList = onHoldList;
    
    // console.log(`do_loadListOfCollection =====>`);

    const targetCollection = newList[0];
    return new Promise((onDone) => {
      // set_isLoading(true);
      const body = {
        data_targetCollection: targetCollection,
      };

      fetchAPI.do_fetch("post", "admin/list-all", body).then(
        (res) => {
          newOnHoldList[targetCollection] = res.data.data_list;
          newList.splice(0, 1);

          if (newList.length > 0) {
            getListOfCollection(newList, newOnHoldList).then(
              (finalOnHoldList) => {
                onDone(finalOnHoldList);
                
              }
            );
          } else {
            onDone(newOnHoldList);
          }
          //   onDone(res.data.data_list);
        },
        (error) => {
          console.log("failed: ", error);
          set_isLoading(false);
          onDone(null);
        }
      );
    });
  }

   function syncCollectionVersionList(needUpdate)
  {
    var body = {
      "data_targetCollection":"CollectionVersion",
    }
    // console.log(`do_loadListOfCollection =====>`);
    // return new Promise((onDone) => {
      fetchAPI.do_fetch("post", "admin/list-all", body).then(
        (res) => {
          //save to local list
            // localStorage.clear()
            // console.log("serverCollectionVersionList",JSON.stringify(res.data.data_list))
              // localStorage.setItem("currentCollectionVersion",JSON.stringify(res.data.data_list))
            var value = localStorage.getItem("currentCollectionVersion")
            // console.log(`currentCollectionVersion ===== value  ${value}`);
            if(value === null || needUpdate){
              console.log(`currentCollectionVersion ===== set list to current list`);
              localStorage.setItem("currentCollectionVersion",JSON.stringify(res.data.data_list))
            }else{
              console.log(`currentCollectionVersion = ${value}`);
            }
            // set_isLoading(false);
        },
        (error) => {
          console.log("failed: ", error);
          set_isLoading(false);
          // onDone(null);
        }
      );
    // });
  }
  // async function getCollectionVersionList (callback){
    
  //   return JSON.parse(localStorage.getItem("currentCollectionVersion")
  // }

  function checkCollectionVersionList(){
    //check version 
    var _jsonStr = localStorage.getItem('currentCollectionVersion')
    var currentVersionList = JSON.parse(_jsonStr);

    var updateCollectionNameList = []

    var body = {
      "data_targetCollection":"CollectionVersion",
    }
    return new Promise((onDone) => {
      fetchAPI.do_fetch("post", "admin/list-all", body).then(
        (res) => {
          //save to local list
            const latestVersionList = res.data.data_list;

            currentVersionList.map((currentData,index) =>{
              const latestData = latestVersionList[index]
              
              // console.log("latestData:  "+JSON.stringify(latestData))

              if (!dateCompare(JSON.stringify(latestData.last_update),JSON.stringify (currentData.last_update))){ //is not equal
                //not match then update the preload data
                // console.log("currentData:  "+JSON.stringify(currentData.last_update))

                updateCollectionNameList.push(currentData.collectionName)
                console.log("need update:  "+JSON.stringify(currentData.collectionName))
              }
            })
            
            onDone(updateCollectionNameList)
         
        },
        (error) => {
          console.log("failed: ", error);
          set_isLoading(false);
          onDone(updateCollectionNameList)
        }
      );
    })
    
  }

  function dateCompare(d1, d2){
    const date1 = d1//new Date(d1);
    const date2 = d2//new Date(d2);
    var isEqual = false
    if(date1 == date2){
      isEqual = true
      // console.log(`Both dates are equal ${date1}  ${date2}` )
    }else{
      isEqual = false
      // console.log(`Not match wor ${date1}  ${date2}` )
    }
    return isEqual
}

  function updatePreloadData(updateCollectionNameList,onHoldList){
    var newList = updateCollectionNameList;
    // var newOnHoldList = onHoldList
    
    const targetItem = newList[0];
    
    var body = {
      "data_targetCollection":targetItem,
    }
    return new Promise((onDone) => {
      fetchAPI.do_fetch("post", "admin/list-all", body).then(
        (res) => {
            // console.log("updatePreloadDataupdatePreloadData  "+targetItem)
            // newOnHoldList[targetItem] = res.data.data_list;
            const dataList = res.data.data_list; 
            const jsonStr = localStorage.getItem('preloadDataList');
            var newPreloadDataList = JSON.parse(jsonStr);//[obj:obj]
            
            newPreloadDataList[targetItem] = dataList;
            
            // console.log("newPreloadDataList  "+JSON.stringify(newPreloadDataList))

            localStorage.setItem("preloadDataList",JSON.stringify(newPreloadDataList));

            newList.splice(0, 1);

          if (newList.length > 0){
            console.log("newList  "+JSON.stringify(targetItem))
            updatePreloadData(newList).then((finalList)=>{
              onDone(finalList)
            })
          }else{
            onDone(null)
          }
             
        },
        (error) => {
          console.log("failed: ", error);
          set_isLoading(false);
          onDone(null)
        }
      );
    })
    
    // var newList = collectionsList;
    // var newOnHoldList = onHoldList;
    
    // // console.log(`do_loadListOfCollection =====>`);

    // const targetCollection = newList[0];
    // return new Promise((onDone) => {
    //   // set_isLoading(true);
    //   const body = {
    //     data_targetCollection: targetCollection,
    //   };

    //   fetchAPI.do_fetch("post", "admin/list-all", body).then(
    //     (res) => {
    //       newOnHoldList[targetCollection] = res.data.data_list;
    //       newList.splice(0, 1);

    //       if (newList.length > 0) {
    //         getListOfCollection(newList, newOnHoldList).then(
    //           (finalOnHoldList) => {
    //             onDone(finalOnHoldList);
                
    //           }
    //         );
    //       } else {
    //         onDone(newOnHoldList);
    //       }
    //       //   onDone(res.data.data_list);
    //     },
    //     (error) => {
    //       console.log("failed: ", error);
    //       set_isLoading(false);
    //       onDone(null);
    //     }
    //   );
    // });

  }

   function setPreloadDataToLocal(preloadDataList){
    console.log("setPreloadDataToLocal start-------")
    localStorage.setItem(
        'preloadDataList',
        preloadDataList
      );

      syncCollectionVersionList()
      // console.log("setPreloadDataToLocal   :"+ preloadDataList)
    
  }

  function showAlert(issuccess, title, content){
    setAlert_info(
      <SweetAlert
        error={!issuccess}
        success={issuccess}
        style={{ display: "block", marginTop: "-100px" }}
        closeOnClickOutside={false}
        title={title}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnText={Loc.confirm}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        btnSize="lg"
      >
        {content}
      </SweetAlert>
    );
  }
  // functions for changeing the states from components
  const handleImageClick = image => {
    setImage(image);
  };
  const handleColorClick = color => {
    setColor(color);
  };
  const handleBgColorClick = bgColor => {
    switch (bgColor) {
      case "white":
        setLogo(require("assets/img/logo.svg"));
        break;
      default:
        setLogo(require("assets/img/logo-white.svg"));
        break;
    }
    setBgColor(bgColor);
  };
  const handleFixedClick = () => {
    if (fixedClasses === "dropdown") {
      setFixedClasses("dropdown show");
    } else {
      setFixedClasses("dropdown");
    }
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };
  const getActiveRoute = routes => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      // else if (prop.layout === "/admin-auth")
      // {
      //   return (
      //     <Route
      //       path={prop.layout + prop.path}
      //       component={prop.component}
      //       key={key}
      //     />
      //   );
      // }
      else {
        return null;
      }
    });
  };
  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  
  function add_authListener()
  {
    // console.log("add_authListener called in Admin");
    return firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        // User is signed in.
        console.log("Checking, signed in");
        setSignedIn(true);
        get_adminInfo();
        
      } else {
        // User is signed out.
        console.log("Checking, NOT signed in");
        setSignedIn(false);
        set_isCheckedSignIn(true);
      }
    });
  }

  function check_isSignedIn()
  {
    if (isCheckedSignIn)
    {
      if (isSignedIn)
      {
        return null;
      }
      else
      {
        return (<Redirect to="/admin-auth" />);
      }      
    }
    else
    {
      return null;
    }
  }

  function check_RedirectToDashboard()
  {
    if (isCheckedSignIn && isSignedIn)
    {
      var _preloadData = { ...data_preloadData, adminInfo: data_adminInfo, isERP: isERP }

      return (<Redirect from="/admin" to= {{pathname:"/admin/dashboard",state:_preloadData}}/>);
    }
    else
    {
      return null;
    }
  }

  function render_sidebarAdmin(routes)
  {
    if (data_adminInfo !== null)
    {
      return (
        <Sidebar_admin
          adminInfo={data_adminInfo}
          routes={routes}
          preloadData={data_preloadData}
          logoText={Loc.app_name}
          logo={logo}
          image={image}
          handleDrawerToggle={handleDrawerToggle}
          open={mobileOpen}
          color={color}
          bgColor={bgColor}
          miniActive={miniActive}
          {...rest}
        />
      )
    }
    else
    {
      return null;
    }
  }

  function render_content()
  {
    if (!isERP){
      if (isCheckedSignIn && isSignedIn && data_SetDataManagementList !== null && data_SetDataManagementTypeList !== null && data_CustomSettingConfigList !== null)
      {
        const routes = RoutesHelper.getMergedRoutesList(data_SetDataManagementList,data_SetDataManagementTypeList,data_CustomSettingConfigList);

        return (
          <div >
            {render_sidebarAdmin(routes)}

            <div className={mainPanelClasses} ref={mainPanel}>
              <AdminNavbar
                sidebarMinimize={sidebarMinimize.bind(this)}
                miniActive={miniActive}
                brandText={getActiveRoute(routes)}
                handleDrawerToggle={handleDrawerToggle}
                {...rest}
              />
              <div className={classes.content}>
                <div className={classes.container}>
                  <Switch>
                    {getRoutes(routes)}
                    {check_RedirectToDashboard()}
                    {/* <Redirect from="/admin" to={{pathname:"/admin/dashboard", state:data_adminInfo}} /> */}
                  </Switch>
                </div>
              </div>
              {getRoute() ? <Footer fluid /> : null}
              
            </div>
          
          </div>
        );
      }
      else
      {

        return (
          <SweetAlert
            style={{ display: "block", marginTop: "-100px" }}
            closeOnClickOutside={false}
            title={Loc.loading}
            onConfirm={() => {}}
            onCancel={() => {}}
            confirmBtnText={Loc.confirm}
            confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
            btnSize="lg"
            showConfirm={false}
            showCancel={false}
          >
            {Loc.please_wait}
          </SweetAlert>
        );
      }

    }else{
      if (isCheckedSignIn && isSignedIn && data_SetDataManagementList !== null && data_SetDataManagementTypeList !== null && data_preloadData !== null && data_CustomSettingConfigList !== null)
      {
        const routes = RoutesHelper.getMergedRoutesList(data_SetDataManagementList,data_SetDataManagementTypeList,data_CustomSettingConfigList);
        if (navigator.platform.indexOf("Win") > -1  ) {
          // mainPanel.current.scrollTo(0,0)
        }else{
          window.scrollTo(0, 0);
        }
        
        // console.log(">>>>>>>>>>>>>>>>>>>>>>test: " + routes);
        return (
          <div >
            {render_sidebarAdmin(routes)}
            <div className={mainPanelClasses} ref={mainPanel}>
              <AdminNavbar
                sidebarMinimize={sidebarMinimize.bind(this)}
                miniActive={miniActive}
                brandText={getActiveRoute(routes)}
                handleDrawerToggle={handleDrawerToggle}
                {...rest}
              />
              {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
              {/* {getRoute() ? (
                <div className={classes.content}>
                  <div className={classes.container}>
                    <Switch>
                      {getRoutes(routes)}
                      <Redirect from="/admin" to="/admin/dashboard" />
                    </Switch>
                  </div>
                </div>
              ) : (
                <div className={classes.map}>
                  <Switch>
                    {getRoutes(routes)}
                    <Redirect from="/admin" to="/admin/dashboard" />
                  </Switch>
                </div>
              )} */}
              <div className={classes.content}>
                <div className={classes.container}>
                  <Switch>
                    {getRoutes(routes)}
                    {check_RedirectToDashboard()}
                    {/* <Redirect from="/admin" to={{pathname:"/admin/dashboard", state:data_adminInfo}} /> */}
                  </Switch>
                </div>
              </div>
              {getRoute() ? <Footer fluid /> : null}
              {/* <FixedPlugin
                handleImageClick={handleImageClick}
                handleColorClick={handleColorClick}
                handleBgColorClick={handleBgColorClick}
                color={color}
                bgColor={bgColor}
                bgImage={image}
                handleFixedClick={handleFixedClick}
                fixedClasses={fixedClasses}
                sidebarMinimize={sidebarMinimize.bind(this)}
                miniActive={miniActive}
              /> */}
            </div>
          
          </div>
        );
        
      }
      else
      {
        // console.log(">>>>>>>>>>>>>>>>>>In ELSE routes");
        // if (data_SetDataManagementList !== null && data_SetDataManagementTypeList !== null && data_routes === null)
        // {
        //   var _routes =  RoutesHelper.getMergedRoutesList(data_SetDataManagementList,data_SetDataManagementTypeList);
        //   if (!_routes || _routes === []) //when no custom data set
        //   {
        //     _routes = routes;
        //   }
        //   setData_routes(_routes);
        // }
        return (
          <SweetAlert
            style={{ display: "block", marginTop: "-100px" }}
            closeOnClickOutside={false}
            title={Loc.loading}
            onConfirm={() => {}}
            onCancel={() => {}}
            confirmBtnText={Loc.confirm}
            confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
            btnSize="lg"
            showConfirm={false}
            showCancel={false}
          >
            {Loc.please_wait}
          </SweetAlert>
        );
      }
      
    }
  }
  
  return (
    <div className={classes.wrapper} ref={setSidebarRef}>
      {alert_info}
      {alert_confirm}
      {check_isSignedIn()}
      {render_content()}
    </div>
  );

}
