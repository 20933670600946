// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
var firebase = require("firebase/app");

// Add the Firebase products that you want to use
require("firebase/auth");
require("firebase/firestore");
require("firebase/storage");

const firebaseConfig = 
{
    apiKey: "AIzaSyDCD2BBRjZsFLYYwgdAVBvg7Vx45J0Ql-4",
    authDomain: "emapapp-363c5.firebaseapp.com",
    projectId: "emapapp-363c5",
    storageBucket: "emapapp-363c5.appspot.com",
    messagingSenderId: "296648915766",
    appId: "1:296648915766:web:7afddb83b7a8374cced197",
    measurementId: "G-R8Y72G9GWM"
};


// Initialize Firebase
firebase.initializeApp(firebaseConfig);


export default firebase;