/*eslint-disable*/
import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import ProductCategoryDropdown from "views/CommonUseComponents/ProductCategoryDropdown.js";
import Dropdown from "views/CommonUseComponents/Dropdown.js";
// material ui icons
import MailOutline from "@material-ui/icons/MailOutline";
import Contacts from "@material-ui/icons/Contacts";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

import SwitchUI from "@material-ui/core/Switch";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import NavPills from "components/NavPills/NavPills.js";
import Danger from "components/Typography/Danger.js";
import SearchBar from "views/CommonUseComponents/SearchBar.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import PagingTable from "views/CommonUseComponents/PagingTable.js";
import Table from "components/Table/Table.js";
import Success from "components/Typography/Success.js";

// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import styles_alert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styles_tableBtn from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import ImageUpload from "components/CustomUpload/ImageUpload.js";

import ICON_BACK from "@material-ui/icons/ArrowBackIos";
import ICON_DONE from "@material-ui/icons/Done";
import ICON_QUIT from "@material-ui/icons/ExitToApp";
import ICON_OUTPUT from "@material-ui/icons/Print";
import ICON_SELECT from "@material-ui/icons/CheckCircleOutline";
import ICON_SELECTED from "@material-ui/icons/CheckCircle";

const useStyles = makeStyles(styles);
const useStyles_alert = makeStyles(styles_alert);
const useStyles_tableBtn = makeStyles(styles_tableBtn);

import SweetAlert from "react-bootstrap-sweetalert";

import Loc from "localization";
import fetchAPI from "connectionHandler/FetchAPI.js";

import styles_switch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import BarcodeReader from "react-barcode-reader";
// import uuid from "react-uuid";

export default function Sales_pos(props) {
  const [isEdit, setIsEdit] = React.useState(false);

  const [data_id, setData_id] = React.useState("");
  const [data_uuid, setData_uuid] = React.useState("");
  const [data_imageUrl, setData_imageUrl] = React.useState("");
  const [data_name, setData_itemName] = React.useState("");

  const [data_price, setData_price] = React.useState(0.0);
  const [data_commission, setData_commission] = React.useState(0.0);
  const [data_categoryID, setData_categoryID] = React.useState("");
  const [data_categoryName, setData_categoryName] = React.useState("");
  const [data_paymentID, setData_paymentID] = React.useState("");
  const [data_paymentName, setData_paymentName] = React.useState("");

  const [data_remark, setData_remark] = React.useState("");

  const [pathToRedirect, setRedirect] = React.useState("");
  const [productNameState, setproductNameState] = React.useState("");
  const [fetchingStatus, setFetchingStatus] = React.useState("");

  const [isPreLoadedData, setIsPreLoadedData] = React.useState(false);
  const [data_category, setData_category] = React.useState(null);

  const [data_hasDuration, setData_hasDuration] = React.useState(false);
  const [data_duration, setData_Duration] = React.useState(0);
  // alert
  const [alert_save, setSaveAlert] = React.useState(null);
  const hideAlert = () => {
    setSaveAlert(null);
  };

  const useStyles_switch = makeStyles(styles_switch);
  const classes_switch = useStyles_switch();
  const classes_tableBtn = useStyles_tableBtn();
  //for get confit list api
  const [data_page, set_page] = React.useState(0);
  const [data_pageCount, set_pageCount] = React.useState(50);
  const [isLoading, set_isLoading] = React.useState(false);
  const [data_isDurationOn, setData_isDurationOn] = React.useState(false);
  const [historyState, setHistoryState] = React.useState(null);
  const [isEnterFullscreen, setIsEnterFullscreen] = React.useState(false);
  const [preloadDataList, set_preloadDataList] = React.useState(null);
  const [data_isLoaded, set_isLoaded] = React.useState(true);

  const [data_dataList, setData_dataList] = React.useState(null);
  const [data_dataListCount, setData_dataListCount] = React.useState(0);

  const [data_cartList, setData_cartList] = React.useState([]);
  const [data_totalAmount, setData_totalAmount] = React.useState(0);
  const [data_totalDiscount, setData_totalDiscount] = React.useState(0);
  const [data_receive, setData_receive] = React.useState(0);
  const [data_receiveable_org, setData_receiveable_org] = React.useState(0);

  const [data_receive_net, setData_receive_net] = React.useState(0.0);

  React.useEffect(() => {
    var promise_preLoad = null;
    setHistoryState(props.history.location.state);
    // if (!isPreLoadedData) {
    //   setIsPreLoadedData(true);
    //   promise_preLoad = do_preLoadData().then((isLoaded) => {
    //     if (isLoaded) {
    //     }
    //   });
    // }
    // {showInputQuantityAlert(true,"Input QTY","")}
    // getConfigList();
    if (preloadDataList == null) {
      getListOfCollection(["CustomSetting","data_payment_method", "data_category"], {}).then(
        (resultList) => {
          // console.log("getListOfCollection ", JSON.stringify(resultList));
          set_preloadDataList(resultList);
          // const payment_method_list = resultList["data_payment_method"];
          // setData_paymentID(payment_method_list[0].id);
        }
      );
    } else {
      if (data_paymentID == "") {
        const payment_method_list = preloadDataList["data_payment_method"];
        setData_paymentID(payment_method_list[0].id);
      }
    }

    // Specify how to clean up after this effect:
    return function cleanup() {
      promise_preLoad = null;
    };
  }, [isEnterFullscreen, preloadDataList, data_totalAmount]);
  //Payment

  function do_loadDataList() {
    // console.log(JSON.stringify(props.location.state.collectionName));

    // if (localizationState.collectionName !== null) {
    set_isLoading(true);
    const body = {
      collectionName: "data_product",
      page: data_page,
      pageCount: data_pageCount,
      showDeletedData: false,
      orderBy: "createDate",
      orders: "desc",
    };
    fetchAPI.do_fetch("post", "admin/list-custom", body).then(
      (res) => {
        set_isLoading(false);
        console.log("success: ", res.data);
        setData_dataList(res.data.data_list);
        setData_dataListCount(res.data.count);
      },
      (error) => {
        console.log("failed: ", error);
        set_isLoading(false);
        showAlert(false, Loc.failed, Loc.data_fetch_failed);
      }
    );
    // }
  }

  function getListOfCollection(collectionsList, onHoldList) {
    var newList = collectionsList;
    var newOnHoldList = onHoldList;

    // console.log(`do_loadListOfCollection =====>`);

    const targetCollection = newList[0];
    return new Promise((onDone) => {
      // set_isLoading(true);
      const body = {
        data_targetCollection: targetCollection,
      };

      fetchAPI.do_fetch("post", "admin/list-all", body).then(
        (res) => {
          const dataList = res.data.data_list;
          newOnHoldList[targetCollection] = dataList.filter(
            (v) => v.isDeleted != true
          );
          newList.splice(0, 1);

          if (newList.length > 0) {
            getListOfCollection(newList, newOnHoldList).then(
              (finalOnHoldList) => {
                onDone(finalOnHoldList);
              }
            );
          } else {
            onDone(newOnHoldList);
          }
          //   onDone(res.data.data_list);
        },
        (error) => {
          console.log("failed: ", error);
          set_isLoading(false);
          onDone(null);
        }
      );
    });
  }

  function do_submit(needReceipt = false) {
    set_isLoading(true);
    const body = {
      id: null,
      content: {
        order_detail: data_cartList,
        total_amount: data_receive,
        total_receivable: data_receive_net,
        payment_method_detail: {
          targetCollection: "data_payment_method",
          targetID: data_paymentID,
          display_name: getStringToShow(
            Loc.payment_default,
            preloadDataList == null
              ? []
              : preloadDataList["data_payment_method"],
            "data_payment_method",
            data_paymentID
          ),
        },
      },
    };
    fetchAPI.do_fetch("post", "admin/createCartOrder", body).then(
      (res) => {
        set_isLoading(false);
        console.log("success: ", JSON.stringify(res.data));
        const products = res.data.data_list;
        const needOpenCashdrawer = res.data.needOpenCashdrawer;
        //Check if the qty is < 0 || < 10(remind)
        var out_of_stock_items = [];
        var less_stock_items = [];
        for (var i = 0; i < products.length; i++) {
          var data = products[i];
          if (data.qty < 0) {
            out_of_stock_items.push(data);
          }
          var origin_product = data_cartList.find((d) => d.product_id == data.id);
          if (data.qty >= 0 && data.qty <= origin_product.warning_qty) {
            less_stock_items.push(data);
          }
        }
        const custom_settings = preloadDataList["CustomSetting"];
        const targetSetting = custom_settings.find((d => d.targetID == "product_stock_alert"));
        // console.log("custom_settings: ", JSON.stringify(custom_settings));
        const isShowStockAlert = targetSetting.isEnable;
        if (isShowStockAlert && (out_of_stock_items.length > 0 || less_stock_items.length > 0)) {
          showStockAlert(out_of_stock_items, less_stock_items);
        } else {
          showAlert(true, Loc.success, Loc.data_save_success);
        }
        setData_cartList([]);
        setData_totalAmount(0);
        setData_totalDiscount(0);
        setData_receive(0);
        setData_receiveable_org(0);
        setData_receive_net(0);
        if (needReceipt) {
          fetchAPI.do_fetch_local("post", "/get_printer", {})
           .then((result) => {
             const printer = result.data.printer; 
             fetchAPI.do_fetch("post", "admin/print_receipt", {...body,printer:printer}).then(
               (result) => {
                 const printer_buff = result.data.printer_buff;
                 
                 const print_job_body = {
                   printer_buff:printer_buff
                 };

                 fetchAPI.do_fetch_local("post", "/printJob", print_job_body)
                 .then((result) => { 
                   console.log("printer: " + JSON.stringify(result));

                 });

               },
               (error) => {
                 
               }
             );


           });
        }

        if (needOpenCashdrawer) {
          fetchAPI.do_fetch_local("post", "/openCashDrawer", {})
            .then((result) => { 
            
            console.log("OpenCashdrawer");

          });
       }
      },
      (error) => {
        set_isLoading(false);
        console.log("failed: ", error);
        showAlert(false, Loc.failed, Loc.data_fetch_failed);
      }
    );
  }

  const backClicked = () => {
    // localStorage.setItem("isFullScreen", "false");
    localStorage.setItem("goCartView", "false");
    setRedirect("/admin");
  };

  const doneClicked = () => {
    setFetchingStatus("fetching");
    if (validCheck()) {
      showAlertWithCustomHandle(
        true,
        "",
        Loc.confirm,
        Loc.cancel,
        () => {
          setFetchingStatus("");
          showAlertWithCustomHandle(
            true,
            "",
            Loc.create,
            Loc.create + "(連收據)",
            () => {
              // setFetchingStatus("");

              do_submit();
              hideAlert();
            },
            () => {
              // setFetchingStatus("");
              do_submit(true);
              hideAlert();
            }
          );
        },
        () => {
          setFetchingStatus("");
          hideAlert();
          // do_submit(true);
        }
      );
    } else {
      setFetchingStatus("");
      showAlert(false, Loc.failed, Loc.data_fetch_failed);
    }
  };

  function validCheck() {
    var isValid = true;
    if (data_cartList.length == 0 || data_paymentID == "") {
      //Not select any payment method/Cart have nothing
      isValid = false;
    }
    return isValid;
  }

  function showAlert(issuccess, title, content) {
    setSaveAlert(
      <SweetAlert
        error={!issuccess}
        success={issuccess}
        style={{ display: "block", marginTop: "-100px" }}
        closeOnClickOutside={false}
        title={title}
        onConfirm={() => {
          if (issuccess) {
            // hideAlert()
            setRedirect("/admin/product-list");
          } else {
            hideAlert();
          }
        }}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        cancelBtnCssClass={classes_alert.button + " " + classes_alert.info}
        onCancel={() => {
          if (issuccess) {
            setData_itemName("");
            setData_imageUrl("");
            setData_categoryID("");
            setData_price(0);
            setData_commission(0);
            setData_remark("");
            // window.location.reload(false);
            hideAlert();
          } else {
            hideAlert();
          }
        }}
        confirmBtnText={issuccess ? Loc.done : Loc.confirm}
        cancelBtnText={Loc.continue}
        btnSize="lg"
        showCancel={issuccess && !isEdit}
      >
        {content}
      </SweetAlert>
    );
  }

  function showStockAlert(oosList, lessStockList) {
    const oosMsg = oosList.map((v) => {
      return v.product_name;
    });

    const lessStockMsg = lessStockList.map((v) => {
      return v.product_name;
    });
    var showOOS = oosList.length > 0;
    var showLessStock = lessStockList.length > 0;
    setSaveAlert(
      <SweetAlert
        error={false}
        success={true}
        style={{ display: "block", marginTop: "-100px" }}
        closeOnClickOutside={false}
        title={""}
        onConfirm={() => {
            hideAlert();
        }}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        cancelBtnCssClass={classes_alert.button + " " + classes_alert.info}
        onCancel={() => {
          // if (issuccess) {
            setData_itemName("");
            setData_imageUrl("");
            setData_categoryID("");
            setData_price(0);
            setData_commission(0);
            setData_remark("");
            hideAlert();
          // } else {
          //   hideAlert();
          // }
        }}
        confirmBtnText={Loc.confirm}
        cancelBtnText={Loc.continue}
        btnSize="lg"
        showCancel={false}
      >
        {showOOS && <GridItem xs={12}>{Loc.pos_hints_negative+": "+oosMsg}</GridItem>}
        {showLessStock && <GridItem xs={12}>{Loc.pos_hints_oos+": "+lessStockMsg}</GridItem>}
      </SweetAlert>
    );
  }

  function showAlertWithCustomHandle(
    issuccess,
    title,

    leftBtnText,
    rightBtnText,
    callback_left,
    callback_right
  ) {
    setSaveAlert(
      <SweetAlert
        error={!issuccess}
        success={issuccess}
        style={{ display: "block", marginTop: "-100px" }}
        closeOnClickOutside={false}
        title={title}
        onConfirm={callback_left}
        confirmBtnCssClass={
          classes_alert.button +
          " " +
          (leftBtnText == Loc.cancel
            ? classes_alert.danger
            : classes_alert.success)
        }
        cancelBtnCssClass={
          classes_alert.button +
          " " +
          (rightBtnText == Loc.cancel
            ? classes_alert.danger
            : classes_alert.info)
        }
        onCancel={callback_right}
        confirmBtnText={leftBtnText}
        cancelBtnText={rightBtnText}
        btnSize="lg"
        showCancel={true}
      >
        <GridItem xs={12}>
          {Loc.payment_name +
            ":" +
            getStringToShow(
              Loc.payment_default,
              preloadDataList == null
                ? []
                : preloadDataList["data_payment_method"],
              "data_payment_method",
              data_paymentID
            )}
        </GridItem>
        <GridItem xs={12}>{Loc.sales_amount_receive + +data_receive}</GridItem>
        <GridItem xs={12}>
          {Loc.sales_amount_net_receive + +data_receive_net}
        </GridItem>
      </SweetAlert>
    );
  }

  //When select the product cell ->pop
  function showInputQuantityAlert(issuccess, title, content, productData) {
    setSaveAlert(
      <SweetAlert
        input
        inputType="number"
        style={{ display: "block", marginTop: "-100px" }}
        title={title}
        onConfirm={(e) => {
          // inputConfirmAlertNext(e);
          console.log("e: ", JSON.stringify(e));
          //Add the product to cart list->
          var inupt_qty = e;
          if (inupt_qty <= 0) {
            inupt_qty = 1; //force correct to 1
          }
          add_to_cart(productData, e);

          hideAlert();
        }}
        //   onCancel={() => hideAlert()}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        // cancelBtnCssClass={classes.button + " " + classes.danger}
      ></SweetAlert>
    );
  }

  const check_redirect = () => {
    if (pathToRedirect != "") {
      return (
        <Redirect
          to={{
            pathname: pathToRedirect,
            state: null,
          }}
        />
      );
    } else {
      return null;
    }
  };

  const classes = useStyles();
  const classes_alert = useStyles_alert();

  function process_productTableList(targetData) {
    if (targetData === null) {
      return [];
    }
    const m = targetData.filter((v) => v.isDeleted !== true);

    const newData = m.map((prop, key) => {
      const code = prop.code;
      const itemName = prop.product_name;
      const price = prop.price === "" ? "/" : prop.price;

      // const isSelf = (customer_id === data_customerID);

      const data_tabContent = [
        code,
        itemName,
        price,
        // we've added some custom button actions
        <div className="actions-right">
          {/* use this button to add a edit kind of action */}
          <Button
            color="success"
            simple
            className={classes_tableBtn.actionButton}
            onClick={() => {
              showInputQuantityAlert(false, "Input Quantity", "", prop);
            }}
          >
            <ICON_SELECT className={classes_tableBtn.icon} />
            {/* {isSelf ? <ICON_SELECTED className={classes_tableBtn.icon} /> : <ICON_SELECT className={classes_tableBtn.icon} />} */}
          </Button>
        </div>,
      ];

      // if (isSelf)
      // {
      //   return {
      //     color: "success",
      //     data: data_tabContent
      //   }
      // }
      // else
      // {
      return data_tabContent;
      // }
    });
    // console.log("newData: " + newData.length);
    // setCustomerData(newData);
    return newData;
  }

  function add_to_cart(productData, qty) {
    var { product_name, price } = productData;
    const currentCartList = data_cartList;

    var newCartList = [...currentCartList];
    var exist_index = newCartList.findIndex((v) => v.id === productData.id);
    if (exist_index == -1) {
      var cartData = {
        id: productData.id,
        name: product_name,
        price: price,
        qty: qty,
        amount: qty * price,
        category: productData.category,
        warning_qty: productData.warning_qty,
      };
      newCartList.push(cartData);
    } else {
      var existData = { ...newCartList[exist_index] };
      existData.qty = qty;
      existData.amount = qty * price;
      newCartList[exist_index] = existData;
    }

    console.log(
      "added to cart, set to cart list: " + JSON.stringify(newCartList)
    );
    get_cart_amount(newCartList);
    setData_cartList(newCartList);
    // process_cartTableList(newCartList);
  }

  function remove_from_cart(target_index) {
    const currentCartList = data_cartList;
    var newCartList = [...currentCartList];
    newCartList.splice(target_index, 1);
    get_cart_amount(newCartList);
    setData_cartList(newCartList);
  }

  function get_cart_amount(targetCartList) {
    var amount = 0;
    targetCartList.map((v) => {
      amount += v.amount;
    });

    setData_totalAmount(amount);

    setData_receive(amount);
  }
  function process_cartTableList(targetData) {
    if (targetData === null) {
      return [];
    }
    const m = targetData.filter((v) => v.isDeleted !== true);

    const newData = m.map((prop, key) => {
      // const code = prop.code;
      const itemName = prop.name;
      const price = prop.price === "" ? "/" : prop.price;
      const qty = prop.qty === "" ? "/" : prop.qty;
      const amount = prop.amount;
      // const isSelf = (customer_id === data_customerID);

      const data_tabContent = [
        // code,
        key + 1,
        itemName,
        price,
        qty,
        amount,
        // we've added some custom button actions
        <div className="actions-right">
          {/* use this button to add a edit kind of action */}
          <Button
            color="danger"
            simple
            className={classes_tableBtn.actionButton}
            onClick={(e) => {
              remove_from_cart(key);
            }}
          >
            <Close className={classes_tableBtn.icon} />
          </Button>
        </div>,
      ];

      // if (isSelf)
      // {
      //   return {
      //     color: "success",
      //     data: data_tabContent
      //   }
      // }
      // else
      // {
      return data_tabContent;
      // }
    });
    // console.log("newData: " + newData.length);
    // setCustomerData(newData);
    return newData;
  }

  const div_list_empty = () => {
    const theDiv = (
      <GridItem xs={12} align="center">
        <Danger>{Loc.list_empty}</Danger>
      </GridItem>
    );
    return theDiv;
  };

  function _renderSearchBar() {
    const searchFieldList = [
      {
        itemName: Loc.code_old,
        key: ["hashCode"],
        id: "hashCode",
        type: "text",
        isPreloadField: false,
      },
      {
        itemName: Loc.product_name,
        key: ["product_name"],
        id: "product_name",
        type: "text",
        isPreloadField: false,
      },
    ];

    return (
      <SearchBar
        id="searchBar"
        searchFieldList={searchFieldList}
        // searchFieldNameList={searchFieldNameList}
        // searchIDList={searchIDList}
        apiPath={"admin/list-custom"}
        loadDataList={() => {
          set_isLoading(true);
          do_loadDataList();
        }}
        searchResultDataList={(dataList) => {
          if (dataList.length > 0) {
            const targetData = dataList[0];
            showInputQuantityAlert(false, "Input Quantity", "", targetData);
          }
          setData_dataList(dataList);
        }}
        searchResultDataCount={(count) => {
          //   setData_dataListCount(count);
        }}
        // didSelectedID={(id) => {
        //   setData_searchID(id);
        // }}
        showDelete={false}
        collectionName={"data_product"}

        // locationState={locationState}
      />
    );
  }
  const getStringToShow = (defaultStr, fieldList, fieldName, id) => {
    // console.log(`getStringToShow ${fieldName} ===>${id}`);
    var strToShow = defaultStr;

    if (fieldList.length > 0) {
      const fieldNameList = getDropDownNameList(fieldName);
      strToShow = fieldNameList[0];
      fieldList.map((prop, key) => {
        if (prop.id === id) {
          strToShow = prop.payment_method_name;
        }
      });
    }
    return strToShow;
  };

  const getDropDownIDList = (fieldName) => {
    const fieldList = preloadDataList == null ? [] : preloadDataList[fieldName];
    const fieldIDList = [];
    if (fieldList.length > 0) {
      fieldList.map((item) => {
        fieldIDList.push(item.id);
      });
    }

    return fieldIDList;
  };

  const getDropDownNameList = (fieldName) => {
    const fieldList = preloadDataList == null ? [] : preloadDataList[fieldName];
    const fieldNameList = [];
    if (fieldList.length > 0) {
      fieldList.map((item) => {
        fieldNameList.push(item.payment_method_name);
      });
    }
    return fieldNameList;
  };

  return (
    <div>
      {check_redirect()}
      {alert_save}

      {isEnterFullscreen ? (
        <>
          {" "}
          <GridContainer>
            <GridItem xs={12} align="right">
              <Button onClick={backClicked}>
                <ICON_QUIT />
              </Button>
              {""}
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={8}>
              <Card>
                {/* <CardHeader color="rose" text>
              <CardText color="rose">
                <h4 className={classes.cardTitle}>{Loc.basic_info}</h4>
              </CardText>
            </CardHeader> */}
                <CardBody>
                  <form>
                    <GridItem xs={12} align="center">
                      <Card>
                        <CardHeader color="primary" text>
                          <CardText color="primary">
                            <h4 className={classes.cardTitle}>
                              {Loc.sales_item_list}
                            </h4>
                          </CardText>
                        </CardHeader>
                        <CardBody>
                          {/* {data_itemsInPackage.length == 0 ? (
                      div_list_empty()
                    ) : ( */}
                          <Table
                            tableHead={[
                              "#",
                              Loc.product,
                              Loc.quantity_short,
                              Loc.payments_total_amount,
                              Loc.sales_item_subTotal,
                              Loc.remove,
                            ]}
                            tableData={process_cartTableList(data_cartList)}
                            customCellClasses={[
                              classes.center,
                              classes.center,
                              classes.center,
                              classes.center,
                              classes.right,
                            ]}
                            customClassesForCells={[0, 5, 5]}
                            customHeadCellClasses={[
                              classes.center,
                              classes.center,
                              classes.center,
                              classes.right,
                              classes.right,
                            ]}
                            customHeadClassesForCells={[0, 5, 5]}
                          />
                          {/* )} */}
                        </CardBody>
                      </Card>
                    </GridItem>

                    <GridContainer>
                      <GridItem xs={6} align="center">
                        <CustomDropdown
                          buttonProps={{
                            round: true,
                            color: "info",
                          }}
                          buttonText={
                            <span>
                              {getStringToShow(
                                Loc.payment_default,
                                preloadDataList == null
                                  ? []
                                  : preloadDataList["data_payment_method"],
                                "data_payment_method",
                                data_paymentID
                              )}
                            </span>
                          }
                          dropdownList={getDropDownNameList(
                            "data_payment_method"
                          )}
                          itemIDList={getDropDownIDList("data_payment_method")}
                          onClick={(selectedID) => {
                            setData_paymentID(selectedID);
                            // setData_paymentName(
                            //   getStringToShow(
                            //     Loc.payment_default,
                            //     data_dataListArray == null
                            //       ? []
                            //       : data_dataListArray["Payment"],
                            //     "Payment",
                            //     selectedID
                            //   )
                            // );
                          }}
                        />
                      </GridItem>
                      <GridItem xs={6}>
                        <GridContainer>
                          <GridItem xs={6}>{Loc.sales_amount_total}</GridItem>
                          <GridItem xs={6} align="right">
                            {parseFloat(data_totalAmount).toFixed(2)}
                          </GridItem>

                          <GridItem xs={6}>
                            {Loc.sales_amount_discount}
                          </GridItem>
                          <GridItem xs={6} align="right">
                            {/* {data_totalDiscount != 0 ? ( */}
                            <Danger>
                              {parseFloat(-data_totalDiscount).toFixed(2)}
                            </Danger>
                            {/* ) : (
                          "/"
                        )} */}
                          </GridItem>
                        </GridContainer>
                        <hr />
                        <GridContainer>
                          <GridItem xs={6}>
                            <Success>
                              <h4>{Loc.sales_amount_receive}</h4>
                            </Success>
                          </GridItem>
                          <GridItem xs={6} align="right">
                            <Success>
                              <h4>{parseFloat(data_receive).toFixed(2)}</h4>
                            </Success>
                          </GridItem>
                        </GridContainer>
                        <GridItem xs={12}>
                          <CustomInput
                            labelText={Loc.sales_amount_net_receive}
                            id="sales_amount_net_receive"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              value: data_receive_net,
                              onChange: (event) => {
                                setData_receive_net(
                                  parseFloat(event.target.value)
                                );
                              },
                              type: "number",
                            }}
                          />
                        </GridItem>
                      </GridItem>
                      {/* <GridItem xs={12} align="right">
                        <Button
                          onClick={() => {
                            const body = {
                              id: null,
                              content: {
                                order_detail: data_cartList,
                                total_amount: data_receive,
                                total_receivable: data_receive_net,
                                payment_method_detail: {
                                  targetCollection: "data_payment_method",
                                  targetID: data_paymentID,
                                  display_name: getStringToShow(
                                    Loc.payment_default,
                                    preloadDataList == null
                                      ? []
                                      : preloadDataList["data_payment_method"],
                                    "data_payment_method",
                                    data_paymentID
                                  ),
                                },
                              },
                            };
                            fetchAPI
                              .do_fetch("post", "/print_barcode", body)
                              .then(() => {});
                          }}
                        >
                          <ICON_OUTPUT />
                          {"Recipe"}
                        </Button>
                      </GridItem> */}
                    </GridContainer>

                    {/* </GridItem> */}
                    {/* <GridItem xs={12}>
                  <CustomInput
                    labelText={Loc.remark}
                    id="remark"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: data_remark,
                      onChange: (event) => {
                        setData_remark(event.target.value);
                      },
                      type: "text",
                    }}
                  />
                </GridItem> */}
                    {/* </GridContainer> */}
                  </form>
                </CardBody>
                {/* <CardFooter className={classes.justifyContentCenter}>
              <GridContainer
                spacing={2}
                direction="row"
                justify="center"
                alignItems="center"
              >
                <GridItem xs={6} align="center">
                  <Button onClick={backClicked}>
                    <ICON_BACK />
                    {Loc.back}
                  </Button>
                </GridItem>
                <GridItem xs={6} align="center">
                  <Button
                    color="rose"
                    onClick={doneClicked}
                    disabled={fetchingStatus === "fetching" ? true : false}
                  >
                    <ICON_DONE />
                    {isEdit ? Loc.save : Loc.create}
                  </Button>
                </GridItem>
              </GridContainer>
            </CardFooter> */}
              </Card>
            </GridItem>

            <GridItem xs={4}>
              <Card>
                <CardBody>
                  <CardHeader color="rose" text>
                    <CardText color="rose">
                      <h4 className={classes.cardTitle}>{Loc.search}</h4>
                    </CardText>
                  </CardHeader>
                  <NavPills
                    color="info"
                    tabs={[
                      {
                        tabButton: Loc.product,
                        tabContent: (
                          // div_list_empty()
                          //   data_dataListArray == null ||
                          //   data_dataListArray["Product"] == null ||
                          //   process_itemTableList(data_dataListArray["Product"])
                          //     .length == 0 ? (
                          //     div_list_empty()
                          //   ) : (
                          <>
                            {_renderSearchBar()}
                            <PagingTable
                              tableHead={[
                                "#",
                                Loc.product_name,
                                Loc.product_price,
                                Loc.select,
                              ]}
                              // tableData={process_customerTableList(data_dataList)}
                              tableData={process_productTableList(
                                data_dataList
                              )}
                              customCellClasses={[
                                classes_tableBtn.center,
                                classes_tableBtn.center,
                                classes_tableBtn.right,
                              ]}
                              customClassesForCells={[0, 0, 5]}
                              customHeadCellClasses={[
                                classes_tableBtn.center,
                                classes_tableBtn.center,
                                classes_tableBtn.right,
                              ]}
                              customHeadClassesForCells={[0, 0, 5]}
                              gotoPageClicked={(targetPage) => {
                                //   set_page(targetPage);
                                //   set_isLoaded(false);
                              }}
                              changedPageCount={(newPageCount) => {
                                //   set_page(0);
                                //   set_pageCount(newPageCount);
                                //   set_isLoaded(false);
                              }}
                              totalDataCount={0}
                              page={data_page}
                              pageCount={data_pageCount}
                            />
                          </>
                        ),
                        //   ),
                      },
                    ]}
                  />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
          <GridItem xs={12}>
            <Card>
              {/* <CardHeader color="rose" text>
              <CardText color="rose">
                <h4 className={classes.cardTitle}>{Loc.basic_info}</h4>
              </CardText>
            </CardHeader> */}
              <CardBody>
                <form></form>
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <GridContainer
                  spacing={2}
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <GridItem xs={6} align="center">
                    <Button
                      color="rose"
                      onClick={doneClicked}
                      disabled={fetchingStatus === "fetching" ? true : false}
                    >
                      <ICON_DONE />
                      {isEdit ? Loc.save : Loc.create}
                    </Button>
                  </GridItem>
                </GridContainer>
              </CardFooter>
            </Card>
          </GridItem>
          {/* <BarcodeReader onError={handleError} onScan={handleScan} /> */}
        </>
      ) : (
        <>
          <GridContainer>
            <GridItem xs={12} align="right">
              <Button onClick={backClicked}>
                <ICON_QUIT />
              </Button>
              {""}
            </GridItem>
          </GridContainer>
          <GridContainer>
            <Card>
              <CardBody>
                <GridItem align="center">
                  <Button
                    color="rose"
                    round
                    onClick={() => {
                      // localStorage.setItem("isFullScreen", "true");
                      setIsEnterFullscreen(true);

                      setRedirect("/poscart/sales-pos-new");
                    }}
                  >
                    {/* <ICON_BACK /> */}
                    {"Enter Cart Mode"}
                  </Button>
                </GridItem>
              </CardBody>
            </Card>
          </GridContainer>
        </>
      )}
    </div>
  );
}
