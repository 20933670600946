import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Edit";
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import PagingTable from "views/CommonUseComponents/PagingTable.js";
import SearchBar from "views/CommonUseComponents/SearchBar.js";
import Table from "components/Table/Table.js";
import { dataTable } from "variables/general.js";
import moment from "moment";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import SweetAlert from "react-bootstrap-sweetalert";

import Loc from "localization";
import fetchAPI from "connectionHandler/FetchAPI.js";

import styles_alert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styles_tableBtn from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

import ICON_ADD from "@material-ui/icons/Add";
import ICON_DOWNLOAD from "@material-ui/icons/ArrowDownward";
import Recover from "@material-ui/icons/Replay";
import { saveAs } from "file-saver";
const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};

const useStyles = makeStyles(styles);
const useStyles_alert = makeStyles(styles_alert);
const useStyles_tableBtn = makeStyles(styles_tableBtn);

export default function Data_set(props) {
  // alert
  const [alert_info, setAlert_info] = React.useState(null);
  const [alert_confirm, setAlert_confirm] = React.useState(null);
  const [alert_upload, setAlert_upload] = React.useState(null);
  const hideAlert = () => {
    setAlert_info(null);
    setAlert_confirm(null);
    setAlert_upload(null);
  };
  const [pathToRedirect, setRedirect] = React.useState("");
  const [itemToEdit, setItemToEdit] = React.useState(null);

  const [data_page, set_page] = React.useState(0);
  const [data_pageCount, set_pageCount] = React.useState(50);
  const [data_isLoaded, set_isLoaded] = React.useState(false);
  const [isLoading, set_isLoading] = React.useState(false);
  const [data_dataList, setData_dataList] = React.useState(null);
  const [data_dataListCount, setData_dataListCount] = React.useState(0);
  const [locationState, setLocationState] = React.useState(null);

  const [showDeletedData, setShowDeletedData] = React.useState(false);
  const [showExtendedTable, setShowExtendedTable] = React.useState(false);

  const [data_startDate, setData_startDate] = React.useState(new Date());
  const [data_endDate, setData_endDate] = React.useState(new Date());

  React.useEffect(() => {
    setLocationState(props.location.state);
    if (data_dataList != undefined && data_dataList != null) {
      console.log("Custom Button Config "+JSON.stringify(data_dataList));
      processDataWithActions();
    }

    if (!data_isLoaded && data_pageCount >= 0 && data_page >= 0) {
      set_isLoaded(true);
      do_loadDataList();
    }
    // Specify how to clean up after this effect:
    return function cleanup() {};
  }, [

    data_pageCount,
    data_page,
    data_dataList,
    locationState,
    showDeletedData,
  ]);
  var xls = require("exceljs");

  async function operation() {
    var workbook = new xls.Workbook();

    workbook.created = new Date();

    workbook.modified = new Date();

    const worksheet = workbook.addWorksheet("sheet");

    //calcualte part

    // const headerEndColumn = String.fromCharCode(65+(getExcelColumns().length - 1))
    // //merge cell
    // worksheet.mergeCells("A1:"+headerEndColumn+"1");
    // worksheet.mergeCells("A2:"+headerEndColumn+"2");

    worksheet.columns = getExcelColumns();

    const tableHeaderList = tableHeader.map((item) => {
      if (item !== Loc.actions) {
        return item;
      }
    });
    //Fixed Items
    const rows = [tableHeaderList];
    // //columns
    const titleRows = worksheet.getRow(1);

    // // // report_itemName
    titleRows.values = tableHeaderList;
    titleRows.alignment = { vertical: "middle", horizontal: "center" };

    // console.log("getExcelData()   "+JSON.stringify(getExcelData()))
    // worksheet.addRows(rows);
    //add data part
    worksheet.addRows(getExcelData());
    //export excel
    const buffer = await workbook.xlsx.writeBuffer();
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const fileExtension = ".xlsx";

    const blob = new Blob([buffer], { type: fileType });

    const fileName = `${pageName}_${moment(data_startDate).format(
      "YYYY-MM-DD-hh-mm-ss"
    )}~${moment(data_endDate).format("YYYY-MM-DD-hh-mm-ss")}`;
    saveAs(blob, fileName + fileExtension);
  }

  function getExcelColumns() {
    var columns = [];
    tableHeader.map((item) => {
      if (item != Loc.actions)
        columns.push({ header: "", key: item, width: 20 });
    });

    return columns;
  }

  function getExcelData() {
    const newData = data_dataList.map((prop, key) => {
      const item_id = prop.id;
      // const itemName = prop.itemName;
      // const remark = (prop.remark === "" || prop.remark == null) ? "/" : prop.remark;
      const api_path = prop.api_path;
      const button_name = prop.button_name;
      // var strLocalizationKey = localizationKey; //Loc.getString(localizationKey) ? Loc.getString(localizationKey):localizationKey
      return [key + 1, button_name, api_path];
    });

    return newData;
  }

  function do_loadDataList() {
    set_isLoading(true);
    const body = {
      data_targetCollection: "CustomButtonConfig",
      page: data_page,
      pageCount: data_pageCount,
      showDeletedData: showDeletedData,
    };
    fetchAPI.do_fetch("post", "admin/list-custom-button-config", body).then(
      (res) => {
        set_isLoading(false);
        console.log("ist-custom-button-config success: ", res.data.data_list);
        setData_dataList(res.data.data_list);
        setData_dataListCount(res.data.data_list.count);
      },
      (error) => {
        console.log("failed: ", error);
        set_isLoading(false);
        showAlert(false, Loc.failed, Loc.data_fetch_failed);
      }
    );
  }

  function do_deleteData(targetID) {
    const body = {
      idList: [targetID],
      targetCollection: "CustomButtonConfig",
      targetID: targetID,
    };
    const path = showDeletedData
      ? "admin/recover-deleted"
      : "admin/del-custom-button-config";
    fetchAPI.do_fetch("post", path, body).then(
      (res) => {
        console.log("success: ", res.data);
        do_loadDataList();
        showAlert(
          true,
          Loc.success,
          showDeletedData ? Loc.data_recover_success : Loc.data_delete_success
        );
      },
      (error) => {
        console.log("failed: ", error);
        showAlert(
          false,
          Loc.failed,
          showDeletedData ? Loc.data_recover_failed : Loc.data_delete_failed
        );
      }
    );
  }

  function processDataWithActions() {
    console.log("custom setting " + JSON.stringify(data_dataList));
    if (data_dataList == undefined || data_dataList == null || data_dataList.length == 0) {
      setData([]);

    } else {
      
    const newData = data_dataList
      .map((prop, key) => {
        const item_id = prop.id;
        const button_name = prop.button_name;
        const api_path = prop.api_path;
        return [
          key + 1,
          <span>
            {/* <img src={prop.imageUrl} alt="..." /> */}
            {button_name}
          </span>,
          api_path,
          // we've added some custom button actions
          <div className="actions-right">
            {/* use this button to add a edit kind of action */}
            <Button
              justIcon
              round
              simple
              onClick={() => {
                var state = {
                  data: prop,
                  config: locationState,
                };
                setItemToEdit(state);
                setRedirect("/admin/custom-button-config-new");
              }}
              color="warning"
              className="edit"
            >
              <Dvr />
            </Button>{" "}
            {/* use this button to remove the data row */}
            <Button
              justIcon
              round
              simple
              onClick={() => {
                showConfirmDeleteAlert(item_id);
              }}
              color="danger"
              className="remove"
            >
              {showDeletedData ? <Recover /> : <Close />}
            </Button>{" "}
          </div>,
        ];
      });

      setData(newData);
    }
      
  }

  function showConfirmDeleteAlert(targetID) {
    setAlert_confirm(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        closeOnClickOutside={false}
        title={
          showDeletedData ? Loc.confirm_recover_title : Loc.confirm_delete_title
        }
        onConfirm={() => {
          hideAlert();
          do_deleteData(targetID);
        }}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        cancelBtnCssClass={classes_alert.button + " " + classes_alert.danger}
        confirmBtnText={
          showDeletedData
            ? Loc.confirm_recover_confirm
            : Loc.confirm_delete_confirm
        }
        cancelBtnText={Loc.cancel}
        showCancel
      >
        {Loc.are_you_sure}
      </SweetAlert>
    );
  }

  function showAlert(issuccess, title, content) {
    setAlert_info(
      <SweetAlert
        error={!issuccess}
        success={issuccess}
        style={{ display: "block", marginTop: "-100px" }}
        closeOnClickOutside={false}
        title={title}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnText={Loc.confirm}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        btnSize="lg"
      >
        {content}
      </SweetAlert>
    );
  }

  const check_redirect = () => {
    if (pathToRedirect != "") {
      if (itemToEdit != null) {
        return (
          <Redirect
            to={{
              pathname: pathToRedirect,
              state: itemToEdit,
            }}
          />
        );
      } else {
        return <Redirect to={pathToRedirect} />;
      }
    } else {
      return null;
    }
  };
  const showloading = () => {
    if (isLoading) {
      return (
        <SweetAlert
          style={{ display: "block", marginTop: "-100px" }}
          closeOnClickOutside={false}
          title={Loc.loading}
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnText={Loc.confirm}
          confirmBtnCssClass={
            classes_alert.button + " " + classes_alert.success
          }
          btnSize="lg"
          showConfirm={false}
          showCancel={false}
        >
          {Loc.please_wait}
        </SweetAlert>
      );
    } else {
      return null;
    }
  };

  const _renderSearchBar = () => {
    const searchFieldList = [
      {
        itemName: Loc.custom_button_name,
        id: "buttonName",
        key: ["button_name"],
        type: "text",
        isPreloadField: false,
      },
      {
        itemName: "API Path",
        id: "api_path",
        key: ["api_path"],
        type: "text",
        isPreloadField: false,
      },
      // { itemName: "", id: "divider", key: [""], type: "",isPreloadField:false },
      // { itemName: Loc.search_by_date, id: "date", key: ["createDate"], type: "date",isPreloadField:false },
    ];

    const searchFieldNameList = [];
    const searchIDList = [];

    searchFieldList.map((item) => {
      if (item.id == "divider") {
        searchFieldNameList.push({ divider: true });
        searchIDList.push("");
      } else {
        searchFieldNameList.push(item.itemName);
        searchIDList.push(item.id);
      }
    });

    if (locationState && locationState !== null) {
      return (
        <SearchBar
          searchFieldList={searchFieldList}
          apiPath={"admin/list-custom-button-config"}
          loadDataList={() => do_loadDataList()}
          searchResultDataList={(dataList) => {
            setData_dataList(dataList);
          }}
          searchResultDataCount={(count) => {
            setData_dataListCount(count);
          }}
          showDeletedData={showDeletedData}
          locationState={locationState}
          didSelectedShowDeletedData={(_showDeletedData) => {
            setShowDeletedData(_showDeletedData);
            set_isLoaded(false);
          }}
          showExtendedTable={(bool) => {
            setShowExtendedTable(bool);
          }}
          searchPeriod={(from, to) => {
            setData_startDate(from);
            setData_endDate(to);
          }}
        />
      );
    }
  };
  const tableHeader = ["#", Loc.custom_button_name, "API Path", Loc.actions];
  function _renderPagingTable() {
    return (
      <PagingTable
        tableHead={tableHeader}
        tableData={data}
        // customCellClasses={[classes.center, classes.right, classes.right]}
        customCellClasses={[
          classes_tableBtn.left,
          classes_tableBtn.center,
          classes_tableBtn.center,
          classes_tableBtn.right,
        ]}
        customClassesForCells={[0, 1, 2, 3]}
        customHeadCellClasses={[
          classes_tableBtn.left,
          classes_tableBtn.center,
          classes_tableBtn.center,
          classes_tableBtn.right,
        ]}
        customHeadClassesForCells={[0, 1, 2, 3]}
        gotoPageClicked={(targetPage) => {
          set_page(targetPage);
          set_isLoaded(false);
        }}
        changedPageCount={(newPageCount) => {
          set_page(0);
          set_pageCount(newPageCount);
          set_isLoaded(false);
        }}
        totalDataCount={data_dataListCount}
        page={data_page}
        pageCount={data_pageCount}
      />
    );
  }

  const onFileChange = (event) => {

    const jsonfile = event.target.files[0];
    if (jsonfile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = JSON.parse(e.target.result);
        // console.log(text);
        const body = {
          target_collection: "CustomSettingConfig",
          data_collection: data
        };
        fetchAPI
          .do_fetch("post", "admin/import_collection", body)
          .then(
            (res) => {
              console.log("success: ", res);
              hideAlert()
            },
            (error) => {
              console.log("failed: ", error);
            }
          );
      };
      reader.readAsText(jsonfile);
    }
  };

  function handle_show_backup()
  {
    setAlert_upload(
      <>
        <SweetAlert
          style={{ display: "block", marginTop: "-100px" }}
          title={"Backup"}
          onConfirm={() => hideAlert()}
          confirmBtnCssClass={
            classes_alert.button + " " + classes_alert.success
          }
          cancelBtnCssClass={classes_alert.button + " " + classes_alert.danger}
          confirmBtnText={Loc.back}
        >
          <small>Upload json</small>
          <br />
          <input type="file" onChange={onFileChange} />
          <hr />
          <Button round color="primary" onClick={() => {
            const body = {
              target_collection: "CustomButtonConfig"
            };
            fetchAPI
              .do_fetch("post", "admin/export_collection", body)
              .then(
                (res) => {
                  console.log("success: ", res);
                  // write res json into file
                  const element = document.createElement("a");
                  const file = new Blob([JSON.stringify(res.data)], {
                    type: "text/plain"
                  });
                  element.href = URL.createObjectURL(file);
                  element.download = "CustomButtonConfig.txt";
                  document.body.appendChild(element);
                  // download
                  element.click();
                },
                (error) => {
                  console.log("failed: ", error);
                  
                }
              );
          }}>
                <ICON_DOWNLOAD/>
                Export Json
              </Button>
        </SweetAlert>
      </>
    );
  }

  function _renderExtendedTable() {
    return (
      <Table
        tableHead={tableHeader}
        tableData={data}
        // customCellClasses={[classes.center, classes.right, classes.right]}
        customCellClasses={[
          classes_tableBtn.left,
          classes_tableBtn.center,
          classes_tableBtn.center,
          classes_tableBtn.right,
        ]}
        customClassesForCells={[0, 1, 2, 3]}
        customHeadCellClasses={[
          classes_tableBtn.left,
          classes_tableBtn.center,
          classes_tableBtn.center,
          classes_tableBtn.right,
        ]}
        customHeadClassesForCells={[0, 1, 2, 3]}
      />
    );
  }
  const pageName = Loc.custom_button_config;
  const [data, setData] = React.useState([]);
  const classes = useStyles();
  const classes_alert = useStyles_alert();
  const classes_tableBtn = useStyles_tableBtn();
  return (
    <div>
      {check_redirect()}
      {showloading()}
      {alert_info}
      {alert_confirm}
      {alert_upload}
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{pageName}</h4>
            </CardHeader>
            <CardBody>
              <Button
                color="success"
                round
                onClick={() => {
                  var state = {
                    data: null,
                    config: locationState,
                  };
                  setItemToEdit(state);
                  setRedirect("/admin/custom-button-config-new");
                }}
              >
                <ICON_ADD />
                {Loc.custom_button_config_add}
              </Button>

              <Button round color="primary" onClick={handle_show_backup}>
                <ICON_DOWNLOAD/>
                Backup
              </Button>

              {/* <Button color="success" round onClick={() => {
          operation()
          }}>
            {Loc.download_excel}
        </Button> */}
              {_renderSearchBar()}
              {showExtendedTable
                ? _renderExtendedTable()
                : _renderPagingTable()}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
