import LocalizedStrings from 'react-localization';
import Config from 'itchi_config';

let strings = new LocalizedStrings({
    en:{
        itchi_tech:"",
        app_name:Config.app_name,	
dashboard:"Dashboard",
customer_management:"Customer Management",
sales_management:"Sales Management",
inventory_management:"Inventory Management",
product_management:"Product Management",
expenditure_management:"Expenditure Management",
reports:"Reports",
admin_management:"Admin Management",
admin_level_management:"Admin Level Management",
action_log:"Action Log",
user_login:"User Login",
user_name:"User name",
password:"Password",
old_password: "Old Password",
new_password: "New Password",
new_password_confirm: "Confirm Password",
change_password_success: "Change Password Success",
change_password_fail: "Change Password Fail",
login:"Login",
profile:"Profile",
logout:"Logout",
change_password:"Change Password",
confirm:"Confirm",
cancel:"Cancel",
done:"Done",
continue:"Continue",
create:"Create",
edit:"Edit",
delete:"Delete",
save:"Save",
actions:"Actions",
back:"Back",
loginFail_title:"Login Failed",
loginFail_content:"Invalid email or password",
success:"Success",
failed:"Failed",
data_save_success:"Data add success!",
data_save_failed:"Data save failed!",
data_delete_success:"Data delete success!",
data_delete_failed:"Data delete failed!",
data_fetch_failed:"Data loading failed!",
setting:"Setting",
setting_fields:"Fields Setting",
basic_info:"Basic Information",
advanced_info:"Advanced Information",
customer_new:"New Customer",
customer_list:"Customer List",
customer_info:"Customer Information",
customer_id:"Customer ID",	
customer_name:"Customer Name",	
customer_phone:"Phone",
customer_email:"Email",
gender:"Gender",
product_new:"New Product",
product_list:"Product List",
product_info:"Product Information",
male:"Male",
female:"Female",
secret:"Secret",
confirm_delete_title:"Confirm Delete",
confirm_delete_confirm:"Yes, delete it!",
are_you_sure:"Are you sure to do this?",
previous_page:"Previous",
next_page:"Next",
page:"Page",
rows:"Rows",
search:"Search",
records:"Records",
choose_picture:"Choose Picture",
choose_picture_change:"Change",
product_info:"Product Information",
product_name:"Product Name",
product_cost:"Cost ($)",
product_price:"Price ($)",
product_id:"ID",
product_commission:"Commission ($)",
remark:"Remark",
product_coupon_list:"Coupon List",
product_coupon_management:"Coupon Management",
product_coupon_new:"New Coupon",
coupon_info:"Coupon Information",
coupon_name:"Coupon Name",
coupon_id:"ID",
coupon_price:"Price ($)",
coupon_commission:"Commission ($)",
coupon_exchange_item:"Exchange Product",
no_exchange_item:"No Exchange Product",
package_list:"Package List",
package_management:"Package Management",
package_new:"New Package",
package_info:"Package Information",
package_name:"Package Name",
package_content:"Package Content",
package_original_total:"Original Price ($)",
package_price:"Price ($)",
package_commission:"Commission ($)",
add:"Add",
remove:"Remove",
quantity:"Quantity",
quantity_short:"Qty.",
max_quantity_short:"Max. Qty.",	
quantity_short_free:"Free Qty.",
total:"Total",
product:"Product",
coupon:"Coupon",
package:"Package",
unit_price:"$/Each",
list_empty:"List Empty",
info_error:"Information Error",
system_setting:"System Setting",
product_category:"Category",
product_category_list:"Category List",
product_category_management:"Category Management",
product_category_new:"New Category",
category_default:"Default Category",
category_name:"Category Name",
store_management:"Store Management",
store_new:"Add Store",
store_name:"Store Name",
store_address:"Store Address",
payment_management:"Payment Management",
payment_new:"Add New Methood",
payment_name:"Payment Methood",
extra_charge_percentage:"Extra Charge (%)",
extra_charge_static:"Extra Charge ($)",
deleted:"Deleted",	
inventory_management:"Inventory Management",
inventory_records:"Inventory Records",
inventory_in_add:"Add Inventory Transaction",
inventory_in_date:"Transaction Date",
inventory_in_list:"Inventory List",
expenditure_management:"Expenditure Management",
expenditure_records:"Expenditure Records",
expenditure_add:"Add New Expenditure",
expenditure_date:"Expenditure Date",
expenditure_name:"Expenditure Title",
expenditure_amount:"Amount ($)",
expenditure_type:"Expenditure Type",
select_date_time:"Select Date & Time",	
receivable_management:"Receivable Management",
receivable_list:"Receivable Records",
receivable_add:"Add Record",		
receivable_name:"Adjustment Desctiption",	
receivable_amount:"Amount ($)",	
receivable_date:"Date",	
receivable_debtor:"Debtor",
repayment_list:"Repayment Records",
repayment_add:"Add New Repayment",
repayment_amount:"Repayment Amount ($)",
repayment_date:"Repayment Date",
financial_management:"Financial Management",	
store_default:"Default Store",	
inventory_in_description:"Description",	
inventory_balance:"On-Hand Balance",	
inventory_last_in_date:"Last Transaction Date",
inventory:"Inventory",
type:"Type",	
type_product:"Product",
type_coupon:"Coupon",
type_package:"Package",
receivable:"Receivable",
repayment:"Repayment",
errorMsg_date_empty:"Date is empty",
errorMsg_empty:"Field is empty",
errorMsg_value_negative:"Invalid negative value",	
select:"Select",	
sales_management:"Sales Management",	
sales_records:"Sales Records",	
sales_add:"Add New Sales",
sales_info:"Sales Information",
sales_date:"Sales Date",
sales_customer:"Sales Customer",
sales_item_list:"Sale List",
sales_amount_total:"Total ($)",
sales_amount_discount:"Discount ($)",
sales_amount_receive:"Receive ($)",
sales_amount_net_receive:"Net Receive ($)",
sales_amount_change:"Change ($)",
payment_default:"Default Payment",	
price_short:"$",	
sales_item_subTotal:"Sub Total ($)",	
list_coupon_empty:"No coupons found",	
list_couponToUse_empty:"Not using coupon",	
default_customer:"Walk-in Customer",	
confirm_receive:"Confirm receive",	
confirm_update:"Confirm Update?",	
coupon_on_hand:"Coupon on-hand",	
coupon_balance_qty:"Balance",	
coupon_balance_use:"Use Coupon",	
coupon_max_qty:"Max. Qty",	
purchases_expenditure:"Purchase expenditure",	
other_expenditure:"Other Expenditure",
commission_expenditure:"Commission Expenditure",
staff_management:"Staff Management",
staff_list:"Staff List",
staff_new:"Create New Staff",
personnel_management:"Personnel Management",	
staff_name:"Staff Name",
staff_IDCard:"Staff ID Card",
staff_bank_account:"Staff Bank Account",
staff_phone:"Staff Phone",
staff_email:"Staff Email",
staff_default:"No Staff",	
errorMsg_package_content_error:"Coupons and other products cannot be added at the same time.",	
receivable_balance:"Receivable Balance",	
commission_balance:"Commission Balance",
commission_record:"Commission Record",
commission_management:"Commission Management",
commission_add:"Commission Adjustment",
commisson_amount:"Amount ($)",	
date:"Date",	
sales_commission:"Sales Commission",
redeem_commission:"Redeem Commission",
adjust_commission:"Adjust Commission",	
unknown:"Unknown",
commission_name:"Commission Description",	
pay_commission:"Pay Commission",	
sales_amount_receivable:"Receivable ($)",	
sales_receivable:"Sales Receivable",	
edit_sales_used_coupon_select_customer:"Selecting other customer is not allowed in sales record with other coupon used.",	
error:"Error",	
loading:"Loading",
please_wait:"Please Wait...",
category:"Category",	
last_update:"Last Update",
today:"Today",
yesterday:"Yesterday",
total_revenue:"Total Revenue",
active_customer:"Active Customer",
daily_sales:"Daily Sales",
increase_in_today_sales:"Increase in today sales",
decrease_in_today_sales:"Decrease in today sales",
updated:"Updated ",
ago:"Ago",
minutes :"Minutes ",
top_sales_by_store:"Top Sales by Store",
refresh:"Refresh",	
this_month:"This Month",	
sales_list_commission:"Commission ($)",
sales_list_storeSales:"Net Sales ($)",
sales_list_total_sales:"Sales ($)",
sales_list_total_receive:"Receive ($)",
sales_list_total_net_receive:"Net Receive ($)",
report_management:"Report Management",
report_operating:"Operating Report",
select_period:"Select Period",
this_year:"This Year",
select_custom_period:"Select Custom Period",	
this_week:"This Week",
from_date:"From Date",
to_date:"To Date",
errorMsg_load_report_faild:"Load report faild",	
report_itemName:"Item",
report_percentage:"%",
report_amount:"Amount ($)",
report_subtotal_sales:"Sales subtotal",
report_subtotal_expenditure:"Expenditure subtotal",
report_total:"Total",
report_sales_product:"Sales of products",
report_sales_coupon:"Sales of coupons",
report_sales_package:"Sales of pacakges",
report_sales_coupon_use:"Sales of coupon redeem",
report_exp_purchases:"Purchases expenditure",
report_exp_commission:"Commision expenditure",
report_exp_other:"Other expenditure",	
report_gross_margin:"Gross Margin",
report_sales:"Sales",
report_exp_operating:"Operating Expenses",
report_operating_income:"Operating Income",
report_net_income:"Net Income",
report_net_loss:"Net Loss",
report_subtotal:"Subtotal",	
report_cost_of_good_sold:"Cost of Goods Sold",	
download_pdf:"Download PDF",	
admin_management:"Admin Management",
create_new_admin:"Create New Admin",
edit_profile:"Edit Profile",
edit_admin_permission:"Edit Admin Permission",
admin_name:"Admin Name",	
admin_default_password:"Gender",
admin_email:"Admin Login Email",	
admin_default_password:"Default Password",
admin_default_password_confirm:"Confirm Default Password",
reset_password:"Reset Password",
admin_level:"Admin Level",	
admin_permission:"Admin Permission",
admin_level_name:"Admin Level name",	
add_admin_level:"Add Admin Level",	
select_permission_page:"Select page permissions",
select_permission_dashboard:"Select Dashboard permissions",				
errorMsg_incorrectPassword:"Confirm Password Incorrect",
select_admin_level:"Select Admin Level",		
register_faild_title:"Register Faild",
reigster_faild_content:"Register from Firebase Auth faild",
set_default_password:"Set Default Password",	
action_area_admin:"Admin",
action_area_adminLevel:"Admin Level",
action_area_category:"Category",
action_area_commission:"Commission",
action_area_commissionBalance:"Commission Balance",
action_area_couponBalance:"Coupon Balance",
action_area_customer:"Customer",
action_area_expenditure:"Expenditure",
action_area_inventoryBalance:"Inventory Balance",
action_area_inventoryIn:"Inventory-In",
action_area_payment:"Payment",
action_area_product:"Product",
action_area_productCoupon:"Product Coupon",
action_area_productPackage:"Product Pakcage",
action_area_receivable:"Receivable",
action_area_receivableBalance:"Receivable Balance",
action_area_sales:"Sales",
action_area_staff:"Staff",
action_area_store:"Store",
actionLog_list:"Action Log",	
action_type_create:"Create",
action_type_edit:"Edit",
action_type_delete:"Delete",
action_type_unknown:"Unknown Action",	
action_area_unknown:"Unknown Area",	
action_date:"Date",
action_adminName:"Admin",
action_actionArea:"Area",
action_relatedID:"Related ID",
action_actionType:"Action",
hints_staff_phone_usage:"Using for staff attendance login",
staff_login:"Staff Login",
staff_state:"Staff State",
state_free:"Free",
state_busy:"Busy",
state_off:"Off Duty",
admin_login:"Admin Login",
staff_login:"Staff Login",
login_dashboard:"Login Dashboard",	
verify_code:"Verify Code",
get_verify_code:"Get Code",
errorMsg_phone_empty:"Phone number is required.",
getCodeFaild_title:"Get code Failed",
staffLoginFaild_title:"Phone / Code invalid",
errorMsg_code_empty:"Verify Code is empty",	
errorMsg_phone_invalid:"Invalid phone number",	
staff_attendance:"Staff Attendance",	
staff_info:"Staff Info",	
select_store:"Select Store",
on_duty:"On Duty",
off_duty:"Off Duty",
duty_state_free:"Free",
duty_state_busy:"Busy",
duty_state_break:"Break",
duty_state_off:"Off Duty",	
current_state:"Current State",	
staff_onDuty_list:"Duty list",
refresh:"Refresh",	
no_staff_on_duty:"There are currently no staff on duty",	
audo_refresh:"Auto Refresh",
on:"On",
off:"Off",
shortcut_menu:"Shortcut",
dashboard_statistics:"Statistics",
has_duration:"Duration",
duration_min:"Minutes",
system_config:"System Config",
system_config_switch_title:"Select system config",
search_text:"Search text",
search_by_date:"Search by date",
From:"From",
To:"To",
data_set_management:"Data set management",
string:"String",
number:"Number",
bool:"Bool",
multi_selection:"Multi-Selection",
data_set_management_new:"New Data set management",
data_set_management_type_new:"New Data set management type",
data_set_management_type:"Data set management type",
data_set_management_type_name:"Data set type name",
data_set_management_type_name_key:"Data set type name key",	
icon_name:"Icon Name",
icon:"Icon",
short_term:"Short term",
path:"Path",
show_in_table_view:"Show in table view",
add_new:"Add new",
mandatory:"Mandatory",
account_phone:"Account phone",
account_email:"Account email",
account_password:"Account password",
account_password_confirm:"Account password confirm",	
data_set_type_duplicated:"Data set type duplicated",
data_set_type_password_missing:"Data set type [Password] missing",
is_unique:"Is Unique",
isReadOnly:"Read Only",
statistics_sales:"Sales Statistics",	
receivable_repayment:"Receivable Repayment",	
version:"Version",	
exchange:"Exchange",
no_code:"No-Code",
code_old:"CODE",	
store_code:"Store Code",	
alert_sales_amount_warning:"The sales amount is negative, continue?",
upload_image_single:"Upload Image (Single)",
upload_image_multi:"Upload Image (Multi)",
uploading:"Uploading...",	
please_select_customer:"Please select customer",
multi_search:"Multi-Search",
select_permission_boss:"Select boss permission",
check_commission_value:"Check commission value",
confidential:"Confidential",
confirm_recover_title:"Confirm recover",
confirm_recover_confirm:"Yes, recover it!",
recovered:"Recovered",
data_recover_success:"Data recover success!",
data_recover_failed:"Data recover failed!",
recover:"Recover",
discount_allowed_sales:"Discount Allowed",
discount_allowed_prepaid:"Discount Allowed Prepaid",
multi_search_staff:"Search Staff + Date",
multi_search_store:"Search Store + Date",
download_excel:"Download Excel",
select_from_dataset_single: "Select From Other (Single)",
select_from_other_dataset_permission: "Permission Content",
select_from_dataset_multi:"Select From Other (Multi)",	
multiline_text:"Multi-Line Text",
dragdrop_upload: "Drag Drop Upload",
md_editor: "MD Editor",
isExchangeMark:"[EXC]",	
sales_coupon_used_qty:"Total used",	
actual_sale:"Actual Sale",	
check_customer:"Check Customer",
multi_search_payment:"Search Payment + Date",	
payments:"Payment(s)",
payments_total_receive:"Total Receive ($)",
select_category:"Select Category",
select_payment:"Select Payment",
select_staff:"Select Staff",
select_product:"Select Product",
expenditure_total_sales_amount:"Amount ($)",
refresh_local_storage:"Manual refresh",
payments_total_amount:"Total",
payments_total_receive_net:"Total Receive Net ($)",
payments_total_repayment_amount:"Total Repayment Amount ($)",
no_arrears:"No arrears",
multi_search_staff_product_service:"Search Staff + Product/Service/Package +Date",
multi_search_store_product_service:"Search Store + Product/Service/Package +Date",
multi_search_staff_store:"Search Store + Staff + Date",
type_service:"Service",	
purchase_date:"Purchase date",
show_coupon_zero:"Show zero coupon",
owned_store:"Owned Store",
search_hints:"Use search bar to load data",
sales_product_qty:"Total product",
input_excel_data_handle:"Input Excel Data",
download_excel_data:"Download Template",
upload_excel_data:"Upload Excel",
or:"OR",
show_excel_import:"Show Excel Import",
show_excel_export:"Show Excel Export ",
upload_order:"Upload Order",
multi_search_store_exp:"Search Store + Expenditure",
select_exp:"Select Expenditure",
delete_selected_data:"Delete selected data",
select_all_data:"Select all data",
custom_setting_list:"Custom Setting",
custom_setting_add:"Add Custom Setting",
custom_setting_config_list:"Custom Setting Config",
custom_setting_config_add:"Add Custom Setting Config",
type_list:"List",
drag_drop_hints:"Drag and drop some files here, or click to select files",
files_list:"Files List",	
type_firebase_geopoint: "Firebase GeoPoint",
input_qty:"input_qty",
pos_hints_oos:"pos_hints_oos",
pos_hints_negative:"pos_hints_negative",
server_status: "Server Status",
custom_button_config: "custom_button_config",
custom_button_name: "custom_button_name",
select_permission_button:"select_permission_button",
role_add: "Add new Role",
role_list: "Roles",
role_name: "Role Name",
account_permission_role: "Role",
staff_id:"staff_id",
        status: "status",
        roster_start_time:"roster_start_time",

},
    // zh_Hans:{
        
    // },
    zh_Hant:{
        itchi_tech:"",
        app_name:Config.app_name,
dashboard:"主頁",
customer_management:"客戶管理",
sales_management:"銷售管理",
inventory_management:"庫存管理",
product_management:"產品管理",
expenditure_management:"支出管理",
reports:"報告",
admin_management:"管理員管理",
admin_level_management:"管理員級別管理",
action_log:"動作日誌",
user_login:"用戶登入",
user_name:"用戶名",
password:"密碼",
old_password: "舊密碼",
new_password: "新密碼",
new_password_confirm: "確認密碼",
change_password_success: "修改密碼成功",
change_password_fail: "修改密碼失敗",
login:"登入",
profile:"個人資料",
logout:"登出",
change_password:"修改密碼",
confirm:"確認",
cancel:"取消",
done:"完成",
continue:"繼續",
create:"創建",
edit:"編輯",
delete:"刪除",
save:"保存",
actions:"操作",
back:"返回",
loginFail_title:"登入失敗",
loginFail_content:"電子郵件或密碼無效",
success:"成功",
failed:"失敗",
data_save_success:"資料保存成功！",
data_save_failed:"資料保存失敗！",
data_delete_success:"資料刪除成功！",
data_delete_failed:"資料刪除失敗！",
data_fetch_failed:"資料讀取失敗！",
setting:"設定",
setting_fields:"資料設定",
basic_info:"基本資料",
advanced_info:"進階資料",
customer_new:"添加新客戶",
customer_list:"客戶列表",
customer_info:"客戶資料",
customer_id:"客戶編號",
customer_name:"客戶名稱",
customer_phone:"電話",
customer_email:"電郵",
gender:"性別",
product_new:"創建產品",
product_list:"產品列表",
product_info:"產品資料",
male:"男",
female:"女",
secret:"保密",
confirm_delete_title:"確認刪除",
confirm_delete_confirm:"是的刪除！",
are_you_sure:"是否確認執行？",
previous_page:"上一頁",
next_page:"下一頁",
page:"頁",
rows:"行",
search:"搜索",
records:"紀錄",
choose_picture:"選擇圖片",
choose_picture_change:"更改",
product_info:"產品資料",
product_name:"產品名稱",
product_cost:"成本($)",
product_price:"售價($)",
product_id:"編號",
product_commission:"佣金($)",
remark:"備註",
product_coupon_list:"優惠券列表",
product_coupon_management:"優惠券管理",
product_coupon_new:"創建優惠券",
coupon_info:"優惠券資料",
coupon_name:"優惠券名稱",
coupon_id:"編號",
coupon_price:"售價($)",
coupon_commission:"佣金($)",
coupon_exchange_item:"兌換產品",
no_exchange_item:"沒有兌換產品",
package_list:"套裝列表",
package_management:"套裝管理",
package_new:"創建套裝",
package_info:"套裝資料",
package_name:"套裝名稱",
package_content:"套裝內容",
package_original_total:"原價($)",
package_price:"售價($)",
package_commission:"佣金($)",
add:"添加",
remove:"移除",
quantity:"數量",
quantity_short:"Qty.",
max_quantity_short:"Max. Qty.",
quantity_short_free:"Free Qty.",
total:"合計",
product:"產品",
coupon:"優惠券",
package:"套裝",
unit_price:"$/件",
list_empty:"列表為空",
info_error:"資訊錯誤",
system_setting:"系統設定",
product_category:"類別",
product_category_list:"分類列表",
product_category_management:"分類管理",
product_category_new:"創建分類",
category_default:"默認分類",
category_name:"分類名稱",
store_management:"分店管理",
store_new:"新增分店",
store_name:"分店名稱",
store_address:"分店地址",
payment_management:"付款方式管理",
payment_new:"新增付款方式",
payment_name:"付款方式",
extra_charge_percentage:"額外收費 (%)",
extra_charge_static:"額外收費 ($)",
deleted:"已刪除",
inventory_management:"庫存管理",
inventory_records:"庫存紀錄",
inventory_in_add:"新增入庫",
inventory_in_date:"入庫日期",
inventory_in_list:"入庫列表",
expenditure_management:"支出管理",
expenditure_records:"支出紀錄",
expenditure_add:"新增支出",
expenditure_date:"支出日期",
expenditure_name:"項目名稱",
expenditure_amount:"項目金額 ($)",
expenditure_type:"支出類別",
select_date_time:"選擇日期時間",
receivable_management:"應收賬款管理",
receivable_list:"欠款紀錄",
receivable_add:"新增紀錄",
receivable_name:"調整說明",
receivable_amount:"金額 ($)",
receivable_date:"日期",
receivable_debtor:"欠款人",
repayment_list:"還款紀錄",
repayment_add:"新增還款",
repayment_amount:"還款金額 ($)",
repayment_date:"還款日期",
financial_management:"財務管理",
store_default:"默認分店",
inventory_in_description:"入庫說明",
inventory_balance:"庫存餘額",
inventory_last_in_date:"最後入庫日期",
inventory:"庫存",
type:"類型",
type_product:"產品",
type_coupon:"兌換券",
type_package:"套裝",
receivable:"欠款",
repayment:"還款",
errorMsg_date_empty:"日期不能為空",
errorMsg_empty:"不能為空",
errorMsg_value_negative:"值不能為負",
select:"選擇",
sales_management:"銷售管理",
sales_records:"銷售紀錄",
sales_add:"新增銷售紀錄",
sales_info:"銷售信息",
sales_date:"銷售日期",
sales_customer:"銷售客戶",
sales_item_list:"銷售列表",
sales_amount_total:"總額 ($)",
sales_amount_discount:"折扣 ($)",
sales_amount_receive:"應收 ($)",
sales_amount_net_receive:"實收 ($)",
sales_amount_change:"找贖 ($)",
payment_default:"默認付款方式",
price_short:"$",
sales_item_subTotal:"小計 ($)",
list_coupon_empty:"暫時沒有優惠券",
list_couponToUse_empty:"沒有使用優惠券",
default_customer:"街客",
confirm_receive:"確認收款",
confirm_update:"確認更新？",
coupon_on_hand:"持有優惠券",
coupon_balance_qty:"餘額",
coupon_balance_use:"使用優惠券",	
coupon_max_qty:"最大使用次數",
purchases_expenditure:"入貨支出",
other_expenditure:"其他支出",
commission_expenditure:"佣金支出",
staff_management:"員工管理",
staff_list:"員工列表",
staff_new:"創建員工",
personnel_management:"人員管理",
staff_name:"員工名稱",
staff_IDCard:"員工身分證",
staff_bank_account:"員工銀行帳戶",
staff_phone:"員工電話",
staff_email:"員工電郵",
staff_default:"沒有員工",
errorMsg_package_content_error:"不可同時加入優惠券和其他產品。",
receivable_balance:"欠款餘額",
commission_balance:"佣金餘額",
commission_record:"佣金紀錄",
commission_management:"佣金管理",
commission_add:"佣金調整",
commisson_amount:"金額 ($)",
date:"日期",
sales_commission:"銷售佣金",
redeem_commission:"兌換佣金",
adjust_commission:"調整佣金",
unknown:"沒有數據",
commission_name:"佣金說明",
pay_commission:"支付佣金",
sales_amount_receivable:"欠款 ($)",
sales_receivable:"銷售欠款",
edit_sales_used_coupon_select_customer:"在已使用優惠券的銷售記錄中，不允許選擇/更改當前客戶為其他客戶。",
error:"錯誤",
loading:"讀取中",
please_wait:"請稍候...",
category:"分類",
last_update:"最後更新",
today:"今天",
yesterday:"昨天",
total_revenue:"總收入",
active_customer:"活躍客戶",
total_revenue:"收入",
active_customer:"活躍客戶",
daily_sales:"每日銷售",
increase_in_today_sales:"今日销量增加",
decrease_in_today_sales:"今日销量下降",
updated:"更新於",
ago:"之前",
minutes :"分鐘",
top_sales_by_store:"最佳銷售店",
refresh:"刷新",
this_month:"本月",
sales_list_commission:"佣金($)",
sales_list_storeSales:"店收入($)",
sales_list_total_sales:"總銷售($)",
sales_list_total_receive:"應收($)",
sales_list_total_net_receive:"實收($)",
report_management:"報表管理",
report_operating:"營運收支報表",
select_period:"選擇查詢週期",
this_year:"本年",
select_custom_period:"選擇指定週期",
this_week:"本週",
from_date:"開始日期",
to_date:"結束日期",
errorMsg_load_report_faild:"讀取報表失敗",
report_itemName:"項目",
report_percentage:"%",
report_amount:"金額 ($)",
report_subtotal_sales:"銷售小計",
report_subtotal_expenditure:"支出小計",
report_total:"總額",
report_sales_product:"產品銷售收入",
report_sales_coupon:"優惠券銷售收入",
report_sales_package:"產品套裝銷售收入",
report_sales_coupon_use:"優惠券兌換收入",
report_exp_purchases:"入貨支出",
report_exp_commission:"佣金支出",
report_exp_other:"其他支出",
report_gross_margin:"毛利率",
report_sales:"營業額",
report_exp_operating:"營業費用",
report_operating_income:"營業收入",
report_net_income:"淨收入",
report_net_loss:"淨虧損",
report_subtotal:"小計",
report_cost_of_good_sold:"銷貨成本",
download_pdf:"下載PDF",
admin_management:"管理員列表",
create_new_admin:"建立管理員",
edit_profile:"編輯個人檔案",
edit_admin_permission:"編輯管理員權限",
admin_name:"管理員名稱",
admin_default_password:"性別",
admin_email:"管理員登入電郵",
admin_default_password:"默認密碼",
admin_default_password_confirm:"確認默認密碼",
reset_password:"重置密碼",
admin_level:"管理員級別",
admin_permission:"管理員權限",
admin_level_name:"級別名稱",
add_admin_level:"新增管理員級別",
select_permission_page:"勾選頁面權限",
select_permission_dashboard:"勾選主頁權限",
errorMsg_incorrectPassword:"確認密碼錯誤",
select_admin_level:"選擇管理員級別",
register_faild_title:"註冊失敗",
reigster_faild_content:"Firebase Auth註冊失敗",
set_default_password:"設置默認密碼",
action_area_admin:"管理員",
action_area_adminLevel:"管理員級別",
action_area_category:"分類",
action_area_commission:"佣金",
action_area_commissionBalance:"佣金餘額",
action_area_couponBalance:"優惠券餘額",
action_area_customer:"客戶",
action_area_expenditure:"支出",
action_area_inventoryBalance:"庫存",
action_area_inventoryIn:"入貨",
action_area_payment:"付款方式",
action_area_product:"產品",
action_area_productCoupon:"優惠券",
action_area_productPackage:"套裝",
action_area_receivable:"應收款",
action_area_receivableBalance:"應收款餘額",
action_area_sales:"銷售",
action_area_staff:"員工",
action_area_store:"分店",
actionLog_list:"日誌",
action_type_create:"創建",
action_type_edit:"編輯",
action_type_delete:"刪除",
action_type_unknown:"未知動作",
action_area_unknown:"未知區域",
action_date:"日期時間",
action_adminName:"管理員",
action_actionArea:"區域",
action_relatedID:"相關ID",
action_actionType:"動作",
hints_staff_phone_usage:"用於員工考勤登入",	
staff_login:"員工登入",
staff_state:"員工狀態",
state_free:"有空",
state_busy:"工作中",
state_off:"已下班",
admin_login:"管理員登入",
staff_login:"員工登入",
login_dashboard:"登入後台",
verify_code:"驗證碼",
get_verify_code:"獲取驗證碼",
errorMsg_phone_empty:"電話號碼不能為空",
getCodeFaild_title:"獲取失敗",
staffLoginFaild_title:"電話號碼/驗證碼無效",
errorMsg_code_empty:"驗證碼為空",
errorMsg_phone_invalid:"電話號碼無效",
staff_attendance:"員工考勤",
staff_info:"員工資料",
select_store:"選擇分店",
on_duty:"上班",
off_duty:"下班",
duty_state_free:"有空",
duty_state_busy:"忙碌",
duty_state_break:"休息",
duty_state_off:"已下班",
current_state:"當前狀態",
staff_onDuty_list:"值班清單",
refresh:"刷新",
no_staff_on_duty:"當前沒有員工值班",
audo_refresh:"自動刷新",
on:"開啟",
off:"關閉",
shortcut_menu:"快捷菜單",
dashboard_statistics:"統計",
has_duration:"時數限制",
duration_min:"分鐘",
system_config:"系統配置",
system_config_switch_title:"勾選系統配置",
search_text:"搜尋內容",
search_by_date:"以日期搜索",
From:"由",
To:"至",
data_set_management:"數據組管理",
string:"文字",
number:"數值",
bool:"是非值",
multi_selection:"多項選擇",
data_set_management_new:"創建數據組",
data_set_management_type_new:"創建類別",
data_set_management_type:"類別管理",
data_set_management_type_name:"類別名稱",
data_set_management_type_name_key:"類別名稱key",
icon_name:"圖標名稱",
icon:"圖標",
short_term:"縮寫",
path:"路徑",
show_in_table_view:"顯示在列表",
add_new:"新增",
mandatory:"必填",
account_phone:"帳戶電話",
account_email:"帳戶電郵",
account_password:"帳戶密碼",
account_password_confirm:"確認帳戶密碼",
data_set_type_duplicated:"數據類別重複",
data_set_type_password_missing:"缺失數據類別「密碼」",
is_unique:"獨有",
isReadOnly:"只讀",
statistics_sales:"銷售統計",
receivable_repayment:"收客欠款",
version:"版本",
exchange:"代扣",
no_code:"沒代碼",
code_old:"編號",
store_code:"分店編號",
alert_sales_amount_warning:"應收額為負數，是否繼續？",
upload_image_single:"上傳圖片（單張）",
upload_image_multi:"上傳圖片（多張）",
uploading:"上傳中...",
please_select_customer:"請選擇客人",
multi_search:"雙重搜索",
select_permission_boss:"勾選老闆權限",
check_commission_value:"可見前台佣金",
confidential:"機密",
confirm_recover_title:"確認復原",
confirm_recover_confirm:"是的復原！",
recovered:"已復原",
data_recover_success:"資料復原成功！",
data_recover_failed:"資料復原失敗！",
recover:"復原",
discount_allowed_sales:"銷售折扣",
discount_allowed_prepaid:"預支折扣",
multi_search_staff:"員工+日期搜索",
multi_search_store:"店鋪+日期搜索",
download_excel:"下載Excel",
select_from_dataset_single:"從其他選擇(單選）",
select_from_other_dataset_permission: "內容權限",
select_from_dataset_multi:"從其他選擇(多選）",
multiline_text:"多行文字",
dragdrop_upload: "拖放上傳",
md_editor: "MD編輯器",
isExchangeMark:"[扣]",
sales_coupon_used_qty:"總節數",
actual_sale:"售價",
check_customer:"客人查詢",
multi_search_payment:"付款方式+日期搜索",
payments:"付款統計",
payments_total_receive:"總應收($)",
select_category:"選擇類別",
select_payment:"選擇付款方式",
select_staff:"選擇員工",
select_product:"選擇產品",
expenditure_total_sales_amount:"總項目金額 ($)",
refresh_local_storage:"手動更新",
payments_total_amount:"總數",
payments_total_receive_net:"總實收($)",
payments_total_repayment_amount:"總還款金額($)",
no_arrears:"沒有欠款",
multi_search_staff_product_service:"員工+產品/服務/套票+日期搜索",
multi_search_store_product_service:"店鋪+產品/服務/套票+日期搜索",
multi_search_staff_store:"店鋪+員工+日期搜索",
type_service:"服務",
purchase_date:"購買日期",
show_coupon_zero:"顯示次數0優惠券",
owned_store:"所屬分部",
search_hints:"請使用搜尋功能以獲取資料",
sales_product_qty:"總數量",
input_excel_data_handle:"批量處理",
download_excel_data:"下載範本",
upload_excel_data:"上載Excel",
or:"或",
show_excel_import:"顯示匯入Excel",
show_excel_export:"顯示下載Excel",
upload_order:"上傳訂單",
multi_search_store_exp:"店鋪+支出+日期搜索",
select_exp:"選擇支出",
delete_selected_data:"刪除已選擇資料",
select_all_data:"選擇全部資料",
custom_setting_list:"自訂設定",
custom_setting_add:"創建自訂設定",
custom_setting_config_list:"自訂設定管理",
custom_setting_config_add:"創建自訂設定管理",
type_list:"列表",
drag_drop_hints:"將一些文件拖放到此處，或單擊以選擇文件",
files_list:"文件列表",
type_firebase_geopoint: "Firebase 座標",
input_qty:"請輸入貨品數量",
pos_hints_oos:"以下貨品庫存不足",
pos_hints_negative:"以下貨品庫存異常",
server_status: "伺服器狀態",
push_notification: "推送通知",
push_notification_new_topic: "話題通知",
push_notification_new_specific: "個人通知",
push_notification_list: "推送通知歷史紀錄",
push_notification_title: "推送通知標題",
push_notification_message: "推送通知內容",
push_notification_topic: "推送通知主題",
push_notification_deviceType: "目標類別",
push_notification_send: "推送",
push_notification_deviceType_ios: "IOS",
push_notification_deviceType_android: "Android",
push_notification_deviceType_web: "網頁版",
push_notification_deviceType_all: "全部",
push_notification_form_title: "推送通知內容",
push_notification_success: "推送通知發送成功",
push_notification_fail: "推送通知發送失敗",
push_notification_registrationToken: "個人註冊令牌",
push_notification_notificationData: "推送數據",
push_notification_date: "日期",
push_notification_type: "推送通知類型",
resend_notification: "重新發送通知",
confirm_resend_notification: "確定要重新發送通知嗎？",
custom_button_config:"自訂按鈕設定",
custom_button_config_add: "創建自訂按鈕設定",
custom_button_name: "自訂按鈕名稱",
select_permission_button:"勾選自訂按鈕權限",
role_add: "新增權限",
role_list: "權限列表",
role_name: "權限名稱",
        account_permission_role: "權限",
        staff_id:"員工編號",
        status: "狀態",
        roster_start_time:"開始時間",
account_permission_role: "權限",
}
});

// set default language
strings.setLanguage(Config.language);

export default strings