import React, { useState } from "react";
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import styles_checkbox from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Table from "components/Table/Table.js";

import { makeStyles } from "@material-ui/core/styles";
import Check from "@material-ui/icons/Check";
import Button from "components/CustomButtons/Button.js";


import Loc from "localization";
import fetchAPI from "connectionHandler/FetchAPI.js";

const useStyles = makeStyles(styles);
const useStyles_checkbox = makeStyles(styles_checkbox);

const PermissionTable = ({ onPagesChange, initValue, hiddenPath }) => {
  const [pages, setPages] = useState([]);
  const classes = useStyles();

  React.useEffect(() => {
    updatePages(initValue)

  }, [initValue]);

  const updatePages = (updatedPages) => {
    setPages(updatedPages);
    if (onPagesChange) {
      onPagesChange(updatedPages);
    }
  };

  const handleToggleAllChange = (id, isChecked) => {
    const updatedPages = pages.map(page => {
      if (page.id === id) {
        return { ...page, create: isChecked, read: isChecked, update: isChecked, delete: isChecked };
      }
      return page;
    });
    updatePages(updatedPages);
  };

  const tableHeader = ['#', 'Page Name', 'All', 'Create', 'Read', 'Update', 'Delete'];

  const renderCheckbox = (id, permission, isChecked, onChange = null) => (
    <FormControlLabel
      control={
        <Checkbox
          tabIndex={-1}
          onClick={() => (onChange ? onChange(id, !isChecked) : handleToggleChange(id, permission))}
          checked={isChecked}
          checkedIcon={<Check className={classes.checkedIcon} />}
          icon={<Check className={classes.uncheckedIcon} />}
          classes={{
            checked: classes.checked,
            root: classes.checkRoot,
          }}
        />
      }
      classes={{
        label: classes.label,
        root: classes.labelRoot,
      }}
    />
  );

  const handleToggleChange = (id, permission) => {
    const updatedPages = pages.map(page => {
      if (page.id === id) {
        return { ...page, [permission]: !page[permission] };
      }
      return page;
    });
    updatePages(updatedPages);
  };

  const toggleAllCheckboxes = () => {
    const allChecked = pages.every(page => page.create && page.read && page.update && page.delete && page.id != hiddenPath);
    const updatedPages = pages.map(page => {
        console.log("checking: " + page.path + " " + hiddenPath);
        if (page.path != hiddenPath)
        {
            return {
              ...page,
              create: !allChecked,
              read: !allChecked,
              update: !allChecked,
              delete: !allChecked
            };
        }
        else
        {
            return {
                ...page,
                create: page.create,
                read: page.read,
                update: page.update,
                delete: page.delete
            };
        }
    });
    updatePages(updatedPages);
  };

  var data = [];

  pages.map((page) => {
    if (page.path != hiddenPath)
    {
        const allChecked = page.create && page.read && page.update && page.delete;
        const item =  [
            data.length + 1,
            page.name,
            renderCheckbox(page.id, 'all', allChecked, handleToggleAllChange),
            renderCheckbox(page.id, 'create', page.create),
            renderCheckbox(page.id, 'read', page.read),
            renderCheckbox(page.id, 'update', page.update),
            renderCheckbox(page.id, 'delete', page.delete),
            ];

        data.push(item);
    }
  });

  const areAllCheckboxesChecked = () => {
    return pages.every(page => page.create && page.read && page.update && page.delete);
  };

  return (
    <>
        <Button onClick={toggleAllCheckboxes} color="primary" variant="contained">
            {areAllCheckboxesChecked() ? "Toggle All OFF" : "Toggle All ON"}
        </Button>
        <Table
        tableHead={tableHeader}
        tableData={data}
        customCellClasses={[
            classes.left,
            classes.center,
            classes.center,
            classes.center,
            classes.center,
            classes.center,
            classes.right,
        ]}
        customClassesForCells={[0, 1, 2, 3, 4, 5, 6]}
        customHeadCellClasses={[
            classes.left,
            classes.center,
            classes.center,
            classes.center,
            classes.center,
            classes.center,
            classes.right,
        ]}
        customHeadClassesForCells={[0, 1, 2, 3, 4, 5, 6]}
        />
    </>
  );
};

export default PermissionTable;