/*eslint-disable*/
import React from "react";
// react components used to create a calendar with events on it
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import { Switch, Route, Redirect } from "react-router-dom";

// dependency plugin for react-big-calendar
import moment from "moment";
// react component used to create alerts
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";

// core components
import Heading from "components/Heading/Heading.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import BookingDetail from "views/BookingSystem/BookingDetail";
import Backdrop from "@material-ui/core/Backdrop";

import ICON_BACK from "@material-ui/icons/ArrowBack";

import styles from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";
import styles_alert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import { events as calendarEvents } from "variables/general.js";
import fetchAPI from "connectionHandler/FetchAPI.js";
import Loc from "localization";
import RosterView from "components/BookingSystem/RosterView";

const localizer = momentLocalizer(moment);
const useStyles_alert = makeStyles(styles_alert);

const useStyles = makeStyles(styles);

export default function BookingCalendar(props) {
  const classes = useStyles();
  const [events, setEvents] = React.useState(calendarEvents);
  const [alert, setAlert] = React.useState(null);
  const [isLoading, set_isLoading] = React.useState(false);
  const [isLoaded, set_isLoaded] = React.useState(false);
  const [data_dataList, setData_dataList] = React.useState([]);
  const [data_dataListCount, setData_dataListCount] = React.useState(0);
  const [data_eventList, setData_eventList] = React.useState([]);
  const [data_displayType, setData_displayType] = React.useState("all");
  const [data_startDate, setData_startDate] = React.useState(0);
  const [data_endDate, setData_endDate] = React.useState(0);
  const [isFirst, setIsFirst] = React.useState(true);
  const [alert_save, setSaveAlert] = React.useState(null);
  const [pathToRedirect, setRedirect] = React.useState("");
  const [itemToEdit, setItemToEdit] = React.useState(null);
  const [data_lookupObject, setData_lookupObject] = React.useState(null);
  const classes_alert = useStyles_alert();
  const { lookupObj } = props.location.state.data;
  
  React.useEffect(() => {
    if (isFirst) {
      //Init
      console.log("props: BCV: ", JSON.stringify(props));
      setIsFirst(false);
      setData_startDate(moment().startOf("month").toDate().getTime());
      setData_endDate(moment().endOf("month").toDate().getTime());
      setData_lookupObject(lookupObj);
      // do_loadDataList();
    } else {
      if (!isLoaded) {
        set_isLoaded(true);
        do_loadDataList();
      }
    }
  }, [isLoaded, isLoading, data_displayType, data_eventList, data_dataList,data_lookupObject]);
  //Sample
  // const events = [
  //   {
  //     title: "All Day Event",
  //     allDay: true,
  //     start: new Date(y, m, 1),
  //     end: new Date(y, m, 1),
  //     color: "default"
  //   }]
  function do_loadDataList() {
    set_isLoading(true);
    if(data_lookupObject == null) return;
    var orderBy = data_lookupObject["orderBy"];//"date_timestamp";

    var body = {
      collectionName: data_lookupObject["targetBookingCollectionName"],//"data_booking",
      isCalendar: true,
      showDeletedData: false,
      orderBy: orderBy,
      orders: "desc",
      fromDate: data_startDate,
      toDate: data_endDate,
    };

    fetchAPI.do_fetch("post", "admin/list-booking", body).then(
      (res) => {
        set_isLoading(false);
        console.log("success: ", res.data);
        if (res.data == undefined || res.data == null) {
          setData_dataList([]);
        } else {
          var data_list = res.data.data_list;

          //   = dateStrList.map((date) => {

          //   return {date: date, count: countByDate[date] || 0};
          // });
          // console.log("dateList: ", JSON.stringify(dateObj));
          handle_events(data_list);
          setData_dataList(res.data.data_list);
          setData_dataListCount(res.data.count);
        }
      },
      (error) => {
        console.log("failed: ", error);
        set_isLoading(false);
        showAlert(false, Loc.failed, Loc.data_fetch_failed);
      }
    );
  }

  function handle_events(data_list) {
    // console.log("handle_events: ", JSON.stringify(data_list));
    var dateStrList = [];
    var statusList = [];
    var eventList = [];
    // const data_list = [...data_dataList];
    // if(data_dataList.length == 0) return;
    data_list.map((item, index) => {
      var dateByString = moment(item.date_timestamp).format("YYYY-MM-DD");
      var status = item.roster.status;
      // console.log("dateByString: ", dateByString);
      var isDateExist = dateStrList.includes(dateByString);
      if (!isDateExist) {
        dateStrList.push(dateByString);
      }
      var isStatusExist = statusList.includes(status);
      if (!isStatusExist) {
        statusList.push(status);
      }
    });

    if (data_displayType == "status") {
      var countByDate = data_list.reduce((obj, d) => {
        // var id = d.id;
        var dateByString = moment(d.date_timestamp).format("YYYY-MM-DD");
        var status = d.roster.status;
        // console.log("countByDate: status ", status);
        if (!obj[dateByString]) {
          obj[dateByString] = {};
        }
        if (!obj[dateByString][status]) {
          obj[dateByString][status] = 0;
        }
        obj[dateByString][status]++;
        return obj;
      }, {});
      // console.log("countByDate: ", JSON.stringify(countByDate));

      var dateObj = {};
      dateStrList.map((date) => {
        var array = [];
        for (var i = 0; i < statusList.length; i++) {
          var status = statusList[i];
          var count = (countByDate[date] && countByDate[date][status]) || 0;
          var obj = { status: status, count: count };
          array.push(obj);
        }
        return (dateObj[date] = array);
      });

      dateStrList.map((date, index) => {
        //["2020-01-01"]

        var targetList = dateObj[date];
        console.log("targetList: ", JSON.stringify(targetList));
        targetList.map((item, index) => {
          var event = {
            title: item.status + ": " + item.count + " 人",
            allDay: true,
            start: new Date(date),
            end: new Date(date),
            color: item.status == "booked" ? "success" : "info",
          };
          if (item.count > 0) eventList.push(event);
        });
      });
    } else {
      var countByDate = data_list.reduce(function (obj, item) {
        var dateByString = moment(item.date_timestamp).format("YYYY-MM-DD");
        if (!obj[dateByString]) {
          obj[dateByString] = 0;
        }
        obj[dateByString]++;
        return obj;
      }, {});

      var dataList = dateStrList.map(function (date) {
        return { date: date, count: countByDate[date] || 0 };
      });

      dataList.map((item, index) => {
        var event = {
          title: "總預約數: " + item.count,
          allDay: true,
          start: new Date(item.date),
          end: new Date(item.date),
          color: "info",
        };
        if (item.count > 0) {
          eventList.push(event);
        }
      });
    }

    setData_eventList([...eventList]);
  }

  const selectedEvent = (event) => {
    // window.alert(event.title);
    // should be go booking list and should be search by date
    var date = event;
    var fromDate = moment(date).startOf("day").toDate().getTime();
    var toDate = moment(date).endOf("day").toDate().getTime();
    const searchData = {
      startDate: fromDate,
      endDate: toDate,
      // searchStr: paymentName,
      // searchID: paymentID,
      id: "date_booking",
      key: ["createDate"],
      keyToShow: "itemName",
      type: "date",
      isPreloadField: false,
      needFilterType: false,
    };

    // console.log("searchData  in report:::::  "+JSON.stringify(searchData))
    localStorage.setItem("searchData", JSON.stringify(searchData));
    setRedirect(lookupObj["redirectPath"]);
  };
  const addNewEventAlert = (slotInfo) => {
    // var targetDate = moment(slotInfo.start).to.getTime();
    var targetDate = localizer.format(slotInfo.start, "YYYY-MM-DD");
    // console.log("targetDate: ", targetDate);
    setAlert(
      
      <Modal
        open={true}
        onClose={() => {
          hideAlert();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <GridContainer justify="center">
          <GridItem xs={12}>
            {/* <BookingDetail
              preloadCollectionList={["Staff", "data_roster"]}
              bookingDate={new Date(targetDate)}
              showButton={true}
              onPressSubmit={() => {
                hideAlert();
                set_isLoaded(false);
              }}
            /> */}
            <BookingDetail
            preloadCollectionList={["Staff", "data_roster"]}
            dataToEdit={null}
            isEditMode={false}
            isReadOnly={false}
            isComponent={false}
            lookupObj={lookupObj}
            targetMatchID={null}
            bookingDate={new Date()}
            showButton={true}
            backPathname={"props.location.pathname"}
            // onChangeDate={(date) => { 
            //   console.log("onChangeDate custom adddd " + date);
            //   var key = fieldKey+"_selectedDate";
            //   _customFields[key] = date;
            //   setData_customFields(_customFields);
            // }}
            // onPressChip={(data,previousRosterId,previousRosterDate) => {
            //   console.log("onPressChip " + JSON.stringify(data));
            //   if (data !== undefined && data !== null) {
            //     _customFields["roster"] = data;
            //     _customFields["previousRosterId"] = previousRosterId;
            //     _customFields["previousRosterDate"] = previousRosterDate;
            //     // _customFields[look_up_key] = 
            //     // _customFields[look_up_val_key] = 
            //     setData_customFields(_customFields);

            //   } 
            // }}
            onPressSubmit={() => {
              hideAlert();
              set_isLoaded(false);
            }}
          />
          </GridItem>
        </GridContainer>
      </Modal>
    );
  };
  const addNewEvent = (e, slotInfo) => {
    var newEvents = events;
    newEvents.push({
      title: e,
      start: slotInfo.start,
      end: slotInfo.end,
    });
    setAlert(null);
    setEvents(newEvents);
  };
  const hideAlert = () => {
    setAlert(null);
    setSaveAlert(null);
  };

  function showAlert(issuccess, title, content) {
    setSaveAlert(
      <SweetAlert
        error={!issuccess}
        success={issuccess}
        style={{ display: "block", marginTop: "-100px" }}
        closeOnClickOutside={false}
        title={title}
        onConfirm={() => {
          if (issuccess) {
            // hideAlert()
            setRedirect("/admin");
          } else {
            hideAlert();
          }
        }}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        cancelBtnCssClass={classes_alert.button + " " + classes_alert.info}
        onCancel={() => {
          if (issuccess) {
            setData_rosterList([]);
            setData_date(new Date());

            // window.location.reload(false);
            hideAlert();
          } else {
            hideAlert();
          }
        }}
        confirmBtnText={issuccess ? Loc.done : Loc.confirm}
        cancelBtnText={Loc.continue}
        btnSize="lg"
        showCancel={issuccess && !isEdit}
      >
        {content}
      </SweetAlert>
    );
  }
  const eventColors = (event) => {
    var backgroundColor = "event-";
    event.color
      ? (backgroundColor = backgroundColor + event.color)
      : (backgroundColor = backgroundColor + "default");
    return {
      className: backgroundColor,
    };
  };

  const check_redirect = () => {
    if (pathToRedirect != "") {
      if (itemToEdit != null) {
        return (
          <Redirect
            to={{
              pathname: pathToRedirect,
              state: itemToEdit,
            }}
          />
        );
      } else {
        return <Redirect to={pathToRedirect} />;
      }
    } else {
      return null;
    }
  };

  const showloading = () => {
    if (isLoading) {
      return (
        <SweetAlert
          style={{ display: "block", marginTop: "-100px" }}
          closeOnClickOutside={false}
          title={Loc.loading}
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnText={Loc.confirm}
          confirmBtnCssClass={
            classes_alert.button + " " + classes_alert.success
          }
          btnSize="lg"
          showConfirm={false}
          showCancel={false}
        >
          {Loc.please_wait}
        </SweetAlert>
      );
    } else {
      return null;
    }
  };
  return (
    <div>
      {check_redirect()}
      {showloading()}
      {alert}
      {alert_save}
      <GridContainer>
        <GridItem xs={12}>
          <Button
            color="danger"
            round
            onClick={() => {
              setRedirect(lookupObj["redirectPath"]);
            }}
          >
            <ICON_BACK />
          </Button>
        </GridItem>
        <GridItem xs={12}>
          <Button
            color="success"
            round
            onClick={() => {
              setData_displayType(data_displayType == "all" ? "status" : "all");
              handle_events(data_dataList);
            }}
          >
            {/* <ICON_ADD /> */}
            {"Change Display Type: " + data_displayType}
          </Button>
        </GridItem>

        <GridItem xs={12} sm={12} md={10}>
          <Card>
            <CardBody calendar>
              <BigCalendar
                selectable
                localizer={localizer}
                events={data_eventList}
                defaultView="month"
                scrollToTime={new Date(1970, 1, 1, 6)}
                defaultDate={new Date()}
                onSelectEvent={(event) => selectedEvent(event)}
                onSelectSlot={(slotInfo) => addNewEventAlert(slotInfo)}
                eventPropGetter={eventColors}
                onNavigate={(date) => {
                  console.log("Move " + date);
                  var fromDate = moment(date)
                    .startOf("month")
                    .toDate()
                    .getTime();
                  var toDate = moment(date).endOf("month").toDate().getTime();
                  setData_startDate(fromDate);
                  setData_endDate(toDate);
                  set_isLoaded(false);
                }}
              />
            </CardBody>
          </Card>
        </GridItem>
       
      </GridContainer>
    </div>
  );
}
