/*eslint-disable*/
import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";
import ProductCategoryDropdown from "views/CommonUseComponents/ProductCategoryDropdown.js";
import Datetime from "react-datetime";
import FormControl from "@material-ui/core/FormControl";
import moment from "moment";
import Dropdown from "views/CommonUseComponents/Dropdown.js";

// material ui icons
import MailOutline from "@material-ui/icons/MailOutline";
import Contacts from "@material-ui/icons/Contacts";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import NavPills from "components/NavPills/NavPills.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import Danger from "components/Typography/Danger.js";
import SearchBar from "views/CommonUseComponents/SearchBar.js";
import PagingTable from "views/CommonUseComponents/PagingTable.js";
// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import styles_alert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styles_tableBtn from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

import ImageUpload from "components/CustomUpload/ImageUpload.js";

import ICON_BACK from "@material-ui/icons/ArrowBackIos";
import ICON_DONE from "@material-ui/icons/Done";
import ICON_ADD from "@material-ui/icons/Add";
import ICON_SELECT from "@material-ui/icons/CheckCircleOutline";
const useStyles = makeStyles(styles);
const useStyles_alert = makeStyles(styles_alert);
const useStyles_tableBtn = makeStyles(styles_tableBtn);

import SweetAlert from "react-bootstrap-sweetalert";

import Loc from "localization";
import fetchAPI from "connectionHandler/FetchAPI.js";

export default function Inventory_in_add(props) {
  const data_targetCollection = "Inventory-In";
  // type validation
  const [isEdit, setIsEdit] = React.useState(false);

  const [data_isPreLoadData, setData_preLoadData] = React.useState(false);

  const [data_id, setData_id] = React.useState("");
  const [data_expenditureID, setData_expenditureID] = React.useState("");
  const [data_name, setData_itemName] = React.useState("");
  const [data_description, setData_description] = React.useState("");
  const [data_remark, setData_remark] = React.useState("");
  const [data_amount, setData_amount] = React.useState(0);
  const [data_itemsInPackage, setData_itemsInPackage] = React.useState([]);
  const [data_OrgItemsInPackage, setData_OrgItemsInPackage] = React.useState(
    []
  );

  const [data_date, setData_date] = React.useState(new Date());
  const [dateState, setdateState] = React.useState("");
  const [data_storeID, setData_storeID] = React.useState("");
  const [data_storeName, setData_storeName] = React.useState("");
  const [data_dataList, setData_dataList] = React.useState(null);
  const [data_recordList, setData_recordList] = React.useState([]);

  const [data_page, set_page] = React.useState(0);
  const [data_pageCount, set_pageCount] = React.useState(50);

  const [
    data_itemsInPackageTableList,
    setData_itemsInPackageTableList,
  ] = React.useState([]);

  const [pathToRedirect, setRedirect] = React.useState("");

  //   const collectionsToLoad = ["Product", "Store"];
  const [data_dataListArray, setData_dataListArray] = React.useState(null);
  const [isPreLoadedData, setIsPreLoadedData] = React.useState(false);
  const [isLoadedEditData, setIsLoadedEditData] = React.useState(false);

  //   const [productNameState, setproductNameState] = React.useState("success");
  const [isLoading, set_isLoading] = React.useState(false);
  const [fetchingStatus, setFetchingStatus] = React.useState("");
  const [historyState, setHistoryState] = React.useState(null);
  // alert
  const [alert_save, setSaveAlert] = React.useState(null);
  const [alert_confirm, setConfirmAlert] = React.useState(null);
  const hideAlert = () => {
    setSaveAlert(null);
    setConfirmAlert(null);
  };

  const div_list_empty = () => {
    const theDiv = (
      <GridItem xs={12} align="center">
        <Danger>{Loc.list_empty}</Danger>
      </GridItem>
    );
    return theDiv;
  };

  React.useEffect(() => {
    setHistoryState(props.history.location.state);

    var promise_preLoad = null;

    if (!isPreLoadedData) {
      setIsPreLoadedData(true);

      promise_preLoad = getListOfCollection(["data_category"], {}).then(
        (returnList) => {
          console.log("returnList: ", returnList);
          setData_dataListArray(returnList);
        }
      );
    }

    // pre load data for edit
    if (
      data_dataListArray !== null &&
      props.location.state.data !== null &&
      !isLoadedEditData
    ) {
      var dataToEdit = props.location.state.data;
      setIsEdit(true);
      setData_id(dataToEdit.id);
      setData_expenditureID(dataToEdit.expenditureID);
      setData_itemName(dataToEdit.itemName);
      setData_description(dataToEdit.description);
      setData_remark(dataToEdit.remark);
      setData_itemsInPackage(dataToEdit.itemsInPackage);
      // setData_recordList(dataToEdit.products);
      // setData_order_id(dataToEdit.order_id);
      setData_date(dataToEdit.date);
      // setData_storeID(dataToEdit.storeID);
      setData_amount(dataToEdit.amount);
      const orgData = dataToEdit.products.map((item, key) => {
        console.log("Inventory item:" + JSON.stringify(item));
          const copyItem = {
              "product_id":item.product_id,
              "qty":item.qty,
              "product_name":item.product_name,
          }
          return copyItem;
      })
      // setData_OrgItemsInPackage(orgData);
      setData_recordList(orgData);
      process_itemsInPackageTableList(orgData);
    }

    // Specify how to clean up after this effect:
    return function cleanup() {
      promise_preLoad = null;
    };
  }, [data_dataListArray]);

  function getListOfCollection(collectionsList, onHoldList) {
    var newList = collectionsList;
    var newOnHoldList = onHoldList;

    // console.log(`do_loadListOfCollection =====>`);

    const targetCollection = newList[0];
    return new Promise((onDone) => {
      // set_isLoading(true);
      const body = {
        data_targetCollection: targetCollection,
      };

      fetchAPI.do_fetch("post", "admin/list-all", body).then(
        (res) => {
          const dataList = res.data.data_list;
          newOnHoldList[targetCollection] = dataList.filter(
            (v) => v.isDeleted != true
          );
          newList.splice(0, 1);

          if (newList.length > 0) {
            getListOfCollection(newList, newOnHoldList).then(
              (finalOnHoldList) => {
                onDone(finalOnHoldList);
              }
            );
          } else {
            onDone(newOnHoldList);
          }
          //   onDone(res.data.data_list);
        },
        (error) => {
          console.log("failed: ", error);
          set_isLoading(false);
          onDone(null);
        }
      );
    });
  }

  function do_loadDataList() {
    // console.log(JSON.stringify(props.location.state.collectionName));

    // if (localizationState.collectionName !== null) {
    set_isLoading(true);
    const body = {
      collectionName: "data_product",
      page: data_page,
      pageCount: data_pageCount,
      showDeletedData: false,
      orderBy: "createDate",
      orders: "desc",
    };
    fetchAPI.do_fetch("post", "admin/list-custom", body).then(
      (res) => {
        set_isLoading(false);
        console.log("success: ", res.data);
        setData_dataList(res.data.data_list);
        // setData_dataListCount(res.data.count);
      },
      (error) => {
        console.log("failed: ", error);
        set_isLoading(false);
        showAlert(false, Loc.failed, Loc.data_fetch_failed);
      }
    );
    // }
  }

  function process_itemTableList(targetData) {
    const newData = targetData
      .filter((v) => v.isDeleted != true)
      .map((prop, key) => {
        const product_id = prop.id;
        const itemName = prop.itemName;
        const price = prop.price;

        return {
          no: key + 1,
          id: key,
          product_id: product_id,
          itemName: (
            <span>
              {/* <img src={prop.imageUrl} alt="..." /> */}
              {itemName}
            </span>
          ),
          product_id: product_id,
          price: "$ " + price,
          actions: (
            // we've added some custom button actions
            <div className="actions-right">
              {/* use this button to add a edit kind of action */}
              <Button
                color="success"
                simple
                className={classes_tableBtn.actionButton}
                onClick={() => {
                  do_addItemIntoPackage(prop, "item");
                }}
              >
                <ICON_ADD className={classes_tableBtn.icon} />
              </Button>
            </div>
          ),
        };
      });

    return newData;
    //   setData_itemTableList(newData);
  }

  function do_addItemIntoPackage(targetItem, targetType) {
    var newItemList = data_itemsInPackage;
    const targetID = targetItem.id;
    const itemName = targetItem.itemName;

    // items in package logic
    var itemIndexKey = -1;
    newItemList.map((item, key) => {
      if (item.id === targetID) {
        itemIndexKey = key;
      }
    });
    if (itemIndexKey == -1) {
      const newItem = {
        id: targetID,
        qty: 1.0,
        type: targetType,
        cost: 0,
        itemName: itemName,
      };
      newItemList.push(newItem);
    } else {
      var newQty = parseFloat(newItemList[itemIndexKey].qty);
      newQty += 1.0;
      newItemList[itemIndexKey].qty = newQty;
    }
    setData_itemsInPackage(newItemList);
    process_itemsInPackageTableList(newItemList);
  }

  const validCheck = () => {
    var result = true;
    if (dateState === "error") {
      result = false;
    }

    if (data_recordList.length === 0) {
      result = false;
    }
    return result;
  };

  const backClicked = () => {
    setRedirect("/admin/inventory-in-list");
  };

  const doneClicked = () => {
    setFetchingStatus("fetching");
    if (validCheck()) {
      const idToPass = isEdit ? data_id : null;
      const body = {
        id: idToPass,
        content: {
          remark: data_remark,
          description:data_description,
          products: data_recordList,
          amount: data_amount,
        },
      };

      console.log("doneClicked: ", JSON.stringify(data_recordList));

      fetchAPI.do_fetch("post", "admin/create_stock_record", body).then(
        (res) => {
          setFetchingStatus("");
          showAlert(true, Loc.success, Loc.data_save_success);

        },
        (error) => {
          console.log("failed: ", error);
          setFetchingStatus("");
          onDone(false);
        }
      );
    } else {
      // setproductNameState("error");
      setFetchingStatus("");
      showAlert(false, Loc.failed, Loc.data_save_failed);
    }
  };


  function showAlert(issuccess, title, content) {
    setSaveAlert(
      <SweetAlert
        error={!issuccess}
        success={issuccess}
        style={{ display: "block", marginTop: "-100px" }}
        closeOnClickOutside={false}
        title={title}
        onConfirm={() => {
          if (issuccess) {
            // hideAlert()
            setRedirect("/admin/inventory-in-list");
          } else {
            hideAlert();
          }
        }}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        cancelBtnCssClass={classes_alert.button + " " + classes_alert.info}
        onCancel={() => {
          if (issuccess) {
            setData_itemName("");
            setData_remark("");
            setData_itemsInPackage([]);
            process_itemsInPackageTableList([]);
            setData_description("");
            setData_recordList("");
            setData_amount(0);
            setData_date(new Date());
            // window.location.reload(false);
            hideAlert();
          } else {
            hideAlert();
          }
        }}
        confirmBtnText={issuccess ? Loc.done : Loc.confirm}
        cancelBtnText={Loc.continue}
        btnSize="lg"
        showCancel={issuccess && !isEdit}
      >
        {content}
      </SweetAlert>
    );
  }
  function showConfirmRemoveFromPackageAlert(itemKey, itemsInPackage) {
    setConfirmAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        closeOnClickOutside={false}
        title={Loc.confirm_delete_title}
        onConfirm={() => {
          var newItemsInPackage = itemsInPackage;
          newItemsInPackage.splice(itemKey, 1);
          setData_itemsInPackage(newItemsInPackage);
          process_itemsInPackageTableList(newItemsInPackage);
          hideAlert();
        }}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        cancelBtnCssClass={classes_alert.button + " " + classes_alert.info}
        onCancel={() => {
          hideAlert();
        }}
        confirmBtnText={Loc.confirm}
        cancelBtnText={Loc.cancel}
        btnSize="lg"
        showCancel
      >
        {Loc.are_you_sure}
      </SweetAlert>
    );
  }

  const check_redirect = () => {
    if (pathToRedirect != "") {
      return (
        <Redirect
          to={{
            pathname: pathToRedirect,
            state: historyState.config,
          }}
        />
      );
    } else {
      return null;
    }
  };

  function process_itemsInPackageTableList(rawData_itemsInPackage) {
    const list = rawData_itemsInPackage.map((prop, key_itemInPackage) => {
      const itemID = prop.id;
      var itemName = prop.product_name;
      const qty = prop.qty;
      var isDeleted = false;

      return [
        isDeleted ? <Danger>{itemName}</Danger> : itemName,
        <CustomInput
          labelText={Loc.quantity_short}
          id="quantity_short"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            value: rawData_itemsInPackage[key_itemInPackage].qty,
            onChange: (event) => {
              var newItemsInPackage = rawData_itemsInPackage;
              var newQty = parseFloat(event.target.value);

              if (newQty <= 0) {
                // showConfirmRemoveFromPackageAlert(key_itemInPackage);
                newQty = 1;
              } else {
                newItemsInPackage[key_itemInPackage].qty = newQty;
              }
              setData_itemsInPackage(newItemsInPackage);
              process_itemsInPackageTableList(newItemsInPackage);
            },
            type: "number",
          }}
        />,
        <Button
          color="danger"
          simple
          className={classes_tableBtn.actionButton}
          onClick={(e) => {
            showConfirmRemoveFromPackageAlert(
              key_itemInPackage,
              rawData_itemsInPackage
            );
          }}
        >
          <Close className={classes_tableBtn.icon} />
        </Button>,
      ];
    });

    setData_itemsInPackageTableList(list);
  }

  function process_productTableList(targetData) {
    if (targetData === null) {
      return [];
    }
    const m = targetData.filter((v) => v.isDeleted !== true);

    const newData = m.map((prop, key) => {
      const code = prop.code;
      const itemName = prop.product_name;
      const price = prop.price === "" ? "/" : prop.price;

      // const isSelf = (customer_id === data_customerID);

      const data_tabContent = [
        code,
        itemName,
        price,
        // we've added some custom button actions
        <div className="actions-right">
          {/* use this button to add a edit kind of action */}
          <Button
            color="success"
            simple
            className={classes_tableBtn.actionButton}
            onClick={() => {
              //   showInputQuantityAlert(false, "Input Quantity", "", prop);
              add_to_record_list(prop, 1);
            }}
          >
            <ICON_SELECT className={classes_tableBtn.icon} />
            {/* {isSelf ? <ICON_SELECTED className={classes_tableBtn.icon} /> : <ICON_SELECT className={classes_tableBtn.icon} />} */}
          </Button>
        </div>,
      ];

      // if (isSelf)
      // {
      //   return {
      //     color: "success",
      //     data: data_tabContent
      //   }
      // }
      // else
      // {
      return data_tabContent;
      // }
    });
    // console.log("newData: " + newData.length);
    // setCustomerData(newData);
    return newData;
  }

  function add_to_record_list(productData, qty) {
    var { product_name, price } = productData;
    const currentList = data_recordList;

    var newList = [...currentList];

    console.log("add_to_record_list " + JSON.stringify(newList)+" "+productData.id);
    var exist_index = newList.findIndex((v) => v.product_id === productData.id);
    if (exist_index == -1) {
      var newData = {
        product_id: productData.id,
        product_name: product_name,
        product_category: productData.category,
        qty: qty,
      };
      newList.push(newData);
    } else {
      var existData = { ...newList[exist_index] };
      existData.qty = 0;
      //   existData.amount = qty * price;
      newList[exist_index] = existData;
    }

    // get_cart_amount(newList);
    setData_recordList(newList);
    process_itemsInPackageTableList(newList);
    // process_cartTableList(newCartList);
  }

  function _renderSearchBar() {
    const searchFieldList = [
      {
        itemName: Loc.code_old,
        key: ["hashCode"],
        id: "hashCode",
        type: "text",
        isPreloadField: false,
      },
      {
        itemName: Loc.product_name,
        key: ["product_name"],
        id: "product_name",
        type: "text",
        isPreloadField: false,
      },
    ];

    return (
      <SearchBar
        id="searchBar"
        searchFieldList={searchFieldList}
        // searchFieldNameList={searchFieldNameList}
        // searchIDList={searchIDList}
        apiPath={"admin/list-custom"}
        loadDataList={() => {
          set_isLoading(true);
          do_loadDataList();
        }}
        searchResultDataList={(dataList) => {
          if (dataList.length > 0) {
            const targetData = dataList[0];
            showInputQuantityAlert(false, Loc.input_qty, "", targetData);
          }
          setData_dataList(dataList);
        }}
        searchResultDataCount={(count) => {
          //   setData_dataListCount(count);
        }}
        // didSelectedID={(id) => {
        //   setData_searchID(id);
        // }}
        showDelete={false}
        collectionName={"data_product"}

        // locationState={locationState}
      />
    );
  }

  const classes = useStyles();
  const classes_alert = useStyles_alert();
  const classes_tableBtn = useStyles_tableBtn();

  return (
    <div>
      {check_redirect()}
      {alert_save}
      {alert_confirm}
      <GridContainer>
        <GridItem xs={5}>
          <Card>
            <CardHeader color="rose" text>
              <CardText color="rose">
                <h4 className={classes.cardTitle}>{Loc.inventory_in_add}</h4>
              </CardText>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12}>
                  <FormControl fullWidth>
                    <Datetime
                      timeFormat={false}
                      defaultValue={data_date}
                      value={
                        data_date == null
                          ? moment(new Date())
                          : moment(data_date)
                      }
                      inputProps={{
                        placeholder: Loc.inventory_in_date,
                      }}
                      onChange={(m) => {
                        if (moment.isMoment(m)) {
                          setdateState("success");
                          setData_date(m.toDate());
                        } else {
                          setdateState("error");
                        }
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12}>
                  <CustomInput
                    // success={requiredState === "success"}
                    // error={productNameState === "error"}
                    labelText={Loc.inventory_in_description}
                    id="inventory_in_description"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: data_description,
                      onChange: (event) => {
                        // setproductNameState("success");
                        setData_description(event.target.value);
                      },
                      type: "text",
                    }}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <CustomInput
                    // success={requiredState === "success"}
                    // error={productNameState === "error"}
                    labelText={Loc.sales_amount_total}
                    id="inventory_in_amount"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: data_amount,
                      onChange: (event) => {
                        // setproductNameState("success");
                        setData_amount(Number(event.target.value));
                      },
                      type: "number",
                    }}
                  />
                </GridItem>

                <GridItem xs={12}>
                  <CustomInput
                    labelText={Loc.remark}
                    id="remark"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: data_remark,
                      onChange: (event) => {
                        setData_remark(event.target.value);
                      },
                      type: "text",
                    }}
                  />
                </GridItem>

                <GridItem xs={12}>
                  <Card>
                    <CardHeader color="info" text>
                      <CardText color="info">
                        <h5 className={classes.cardTitle}>
                          {Loc.inventory_in_list}
                        </h5>
                      </CardText>
                    </CardHeader>
                    <CardBody>
                      {data_recordList.length == 0 ? (
                        div_list_empty()
                      ) : (
                        <Table
                          tableHead={[
                            // "#",
                            Loc.product,
                            Loc.quantity_short,
                            Loc.remove,
                          ]}
                          tableData={data_itemsInPackageTableList}
                          customCellClasses={[
                            classes.center,
                            classes.right,
                            classes.right,
                          ]}
                          customClassesForCells={[0, 4, 5]}
                          customHeadCellClasses={[
                            classes.center,
                            classes.right,
                            classes.right,
                          ]}
                          customHeadClassesForCells={[0, 4, 5]}
                        />
                      )}
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter className={classes.justifyContentCenter}>
              <GridContainer
                spacing={2}
                direction="row"
                justify="center"
                alignItems="center"
              >
                <GridItem xs={6} align="center">
                  <Button onClick={backClicked}>
                    <ICON_BACK />
                    {Loc.back}
                  </Button>
                </GridItem>

                <GridItem xs={6} align="center">
                  <Button
                    color="rose"
                    onClick={doneClicked}
                    disabled={fetchingStatus === "fetching" ? true : false}
                  >
                    <ICON_DONE />
                    {isEdit ? Loc.save : Loc.create}
                  </Button>
                </GridItem>
              </GridContainer>
            </CardFooter>
          </Card>
        </GridItem>

        <GridItem xs={7}>
          <Card>
            <CardBody>
              <CardHeader color="rose" text>
                <CardText color="rose">
                  <h4 className={classes.cardTitle}>{Loc.search}</h4>
                </CardText>
              </CardHeader>
              <NavPills
                color="info"
                tabs={[
                  {
                    tabButton: Loc.product,
                    tabContent: (
                      // div_list_empty()
                      //   data_dataListArray == null ||
                      //   data_dataListArray["Product"] == null ||
                      //   process_itemTableList(data_dataListArray["Product"])
                      //     .length == 0 ? (
                      //     div_list_empty()
                      //   ) : (
                      <>
                        {_renderSearchBar()}
                        <PagingTable
                          tableHead={[
                            "#",
                            Loc.product_name,
                            Loc.product_price,
                            Loc.select,
                          ]}
                          // tableData={process_customerTableList(data_dataList)}
                          tableData={process_productTableList(data_dataList)}
                          customCellClasses={[
                            classes_tableBtn.center,
                            classes_tableBtn.right,
                            classes_tableBtn.right,
                          ]}
                          customClassesForCells={[0, 0, 5]}
                          customHeadCellClasses={[
                            classes_tableBtn.center,
                            classes_tableBtn.right,
                            classes_tableBtn.right,
                          ]}
                          customHeadClassesForCells={[0, 0, 5]}
                          gotoPageClicked={(targetPage) => {
                            //   set_page(targetPage);
                            //   set_isLoaded(false);
                          }}
                          changedPageCount={(newPageCount) => {
                            //   set_page(0);
                            //   set_pageCount(newPageCount);
                            //   set_isLoaded(false);
                          }}
                          totalDataCount={0}
                          page={data_page}
                          pageCount={data_pageCount}
                        />
                      </>
                    ),
                    //   ),
                  },
                ]}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
