/*eslint-disable*/
import React, { useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";

// material ui icons
import MailOutline from "@material-ui/icons/MailOutline";
import Contacts from "@material-ui/icons/Contacts";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js"; 
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Table from "components/Table/Table.js";
import PermissionTable from "./PermissionTable";

import {routes} from "routes.js";

import dashboardPermissionData from "dashboardPermissions.js";
import bossPermissionData from "bossPermissions.js";
// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import styles_alert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styles_checkbox from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";

import PictureUpload from "components/CustomUpload/PictureUpload.js";

import ICON_BACK from "@material-ui/icons/ArrowBackIos";
import ICON_DONE from "@material-ui/icons/Done";
import * as RoutesHelper from "Helper/RoutesHelper";

const useStyles = makeStyles(styles);
const useStyles_alert = makeStyles(styles_alert);
const useStyles_checkbox = makeStyles(styles_checkbox);

import SweetAlert from "react-bootstrap-sweetalert";

import Loc from "localization";
import fetchAPI from "connectionHandler/FetchAPI.js";
import Config from "itchi_config";
import { SystemType } from "Util/SystemType.js";

export default function Admin_permission_add(props) {
  const [isEdit, setIsEdit] = React.useState(false);

  const [data_id, setData_id] = React.useState("");
  const [data_adminLevelName, setData_adminLevelName] = React.useState("");
  const [adminLevelNameState, setAdminLevelNameState] = React.useState("");

  const [permissionTablePages, setPermissionTablePages] = useState([]);
  const [permissionTablePagesInitValue, setPermissionTablePagesInitValue] = useState([]);


  const handlePermissionTablePagesChange = (pages) => {
    setPermissionTablePages(pages);
  };

  const [pathToRedirect, setRedirect] = React.useState("");
  const [fetchingStatus, setFetchingStatus] = React.useState("");
  const [isLoading, set_isLoading] = React.useState(false);
  const [historyState,setHistoryState] = React.useState(null)

  const classes = useStyles();
  const classes_alert = useStyles_alert();
  const classes_checkbox = useStyles_checkbox();

  // alert
  const [alert_save, setSaveAlert] = React.useState(null);
  const hideAlert = () => {
    setSaveAlert(null);
  };

  React.useEffect(() => {
    setHistoryState(props.history.location.state)

    var initValue = [];
    if (props.location.state.data != null) {
      var dataToEdit = props.location.state.data;
      setIsEdit(true);
      setData_id(dataToEdit.id);
      setData_adminLevelName(dataToEdit.itemName);
      initValue = dataToEdit.permissions;
    }

    get_SetDataManagementConfig(initValue)
      .then((samplePages) => {
        if (samplePages) {
          setPermissionTablePagesInitValue(samplePages);
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });

    // Specify how to clean up after this effect:
    return function cleanup() {};
  }, []);

  

  const validCheck = () => {
    var result = true;
    if (data_adminLevelName == "") {
      result = false;
    }
    return result;
  };

  const backClicked = () => {
    setRedirect("/admin/role-list");
  };

  const doneClicked = () => {
    setFetchingStatus("fetching");
    if (validCheck()) {
      const idToPass = isEdit ? data_id : null;
      console.log("idToPass: ", idToPass);
      // console.log("bossPermissions ==>" + JSON.stringify(data_bossPermissionList))
      const body = {
        id: idToPass,
        collectionName: "Role",
        content: {
          itemName: data_adminLevelName,
          permissions: permissionTablePages,
        },
      };
      fetchAPI.do_fetch("post", "admin/create-custom", body).then(
        (res) => {
          console.log("success: ", res);
          setFetchingStatus("");
          showAlert(true, Loc.success, Loc.data_save_success);
        },
        (error) => {
          console.log("failed: ", error);
          setFetchingStatus("");
          showAlert(false, Loc.failed, Loc.data_save_failed);
        }
      );
    } else {
      setAdminLevelNameState("error");
      setFetchingStatus("");
      showAlert(false, Loc.failed, Loc.data_save_failed);
    }
  };
  function get_CustomSettingConfig(Callback)
  {
    set_isLoading(true);
    const body = {
      data_targetCollection: "CustomSettingConfig",
    }
    fetchAPI.do_fetch('post', 'admin/list-all', body)
    .then((res) => {
        console.log("get_CustomSettingConfig success: ", res.data.data_list);
        var newDataList = res.data.data_list;
        newDataList = newDataList.filter(d => (d.isDeleted == false || d.isDeleted == undefined));
        setData_CustomSettingConfigList(newDataList);
        console.log("setData_CustomSettingConfigList: ", newDataList);

        Callback(newDataList);
    }, error => {
        console.log("failed: ", error);
        showAlert(false, Loc.failed, Loc.data_fetch_failed);
    })
  }

  function showAlert(issuccess, title, content) {
    setSaveAlert(
      <SweetAlert
        error={!issuccess}
        success={issuccess}
        style={{ display: "block", marginTop: "-100px" }}
        title={title}
        closeOnClickOutside={false}
        onConfirm={() => {
          if (issuccess) {
            // hideAlert()
            setRedirect("/admin/role-list");
          } else {
            hideAlert();
          }
        }}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        cancelBtnCssClass={classes_alert.button + " " + classes_alert.info}
        onCancel={() => {
          if (issuccess) {
            setData_adminLevelName("");
            setPermissionTablePages([]);
            setAdminLevelNameState("");
            hideAlert();
          } else {
            hideAlert();
          }
        }}
        confirmBtnText={issuccess ? Loc.done : Loc.confirm}
        cancelBtnText={Loc.continue}
        btnSize="lg"
        showCancel={issuccess && !isEdit}
      >
        {content}
      </SweetAlert>
    );
  }

  const check_redirect = () => {
    if (pathToRedirect != "") {
      return <Redirect to={{
        pathname: pathToRedirect,
        state: historyState.config,
      }} />;
    } else {
      return null;
    }
  };

  const showloading = () => {
    if (isLoading) {
      return (
        <SweetAlert
          style={{ display: "block", marginTop: "-100px" }}
          closeOnClickOutside={false}
          title={Loc.loading}
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnText={Loc.confirm}
          confirmBtnCssClass={
            classes_alert.button + " " + classes_alert.success
          }
          btnSize="lg"
          showConfirm={false}
          showCancel={false}
        >
          {Loc.please_wait}
        </SweetAlert>
      );
    } else {
      return null;
    }
  };

  const get_SetDataManagementConfig = (_initValue) => {
    set_isLoading(true);
    return new Promise(async (resolve, reject) => {
      try {
        const body = {
          getFullList: true,
        };
        const res = await fetchAPI.do_fetch(
          'post',
          'admin/list-set-data-management-config',
          body
        );

        // get original hard-code path
        var newDataList = [routes[0]]
        for(var i=1; i<routes.length; i++)
        {
          newDataList.push(routes[i]);
        }
        const newroutes = RoutesHelper.filterWithFlag(newDataList);
        const originalRoutes = getRoutes(newroutes, [], "");
    
        var customSetDataList = res.data.data_list;

        // process with "/"
        customSetDataList.forEach(d => {
          d.pathName = "/" + d.pathName
        });

        customSetDataList = [...originalRoutes, ...customSetDataList];

        var samplePages = generateSamplePages(customSetDataList, "/dashboard");

  
        // Update samplePages with values from _permissions if they exist
        samplePages = updateSamplePagesWithPermissions(samplePages, _initValue);
  
        resolve(samplePages);
        set_isLoading(false);

      } catch (error) {
        reject(error);
        set_isLoading(false);

      }
    });
  };

  const getRoutes = (routes, returnArray, ignorePath) => {
    var newReturnArray = returnArray;
    routes.map((prop, key) => {
      if (prop.collapse) {
        newReturnArray = getRoutes(prop.views, newReturnArray, ignorePath);
      }
      else if (
        prop.layout === "/admin" &&
        prop.path !== ignorePath &&
        prop.isShowInSide
      ) {
        const item = {
          pathName: prop.path,
          localizationKey: prop.name,
        };
        newReturnArray.push(item);
      }
    });

    return newReturnArray;
  };

  function generateSamplePages(data_list, forceTruePath) {
    const samplePages = [];

    data_list.forEach((item, index) => {
      samplePages.push({
        id: index + 1,
        path: item.pathName,
        name: item.localizationKey,
        create: (item.pathName == forceTruePath) ? true : false,
        read: (item.pathName == forceTruePath) ? true : false,
        update: (item.pathName == forceTruePath) ? true : false,
        delete: (item.pathName == forceTruePath) ? true : false,
      });
    });

    return samplePages;
  }

  const updateSamplePagesWithPermissions = (samplePages, _permissions) => {
    for (let i = 0; i < samplePages.length; i++) {
      for (let j = 0; j < _permissions.length; j++) {
        if (samplePages[i].path === _permissions[j].path) {
          samplePages[i].create = _permissions[j].create;
          samplePages[i].read = _permissions[j].read;
          samplePages[i].update = _permissions[j].update;
          samplePages[i].delete = _permissions[j].delete;
          break;
        }
      }
    }
    return samplePages;
  };


  return (
    <div>
      {check_redirect()}
      {showloading()}
      {alert_save}
      <GridContainer>
        <GridItem xs={6}>
          <Card>
            <CardHeader color="rose" text>
              <CardText color="rose">
                <h4 className={classes.cardTitle}>{Loc.basic_info}</h4>
              </CardText>
            </CardHeader>
            <CardBody>
              <form>
                <GridContainer>
                  {/* Admin level name */}
                  <GridItem xs={12}>
                    <CustomInput
                      // success={requiredState === "success"}
                      error={adminLevelNameState === "error"}
                      labelText={Loc.role_name + "*"}
                      id="role_name"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: data_adminLevelName,
                        onChange: (event) => {
                          setAdminLevelNameState("success");
                          setData_adminLevelName(event.target.value);
                        },
                        type: "text",
                      }}
                    />
                  </GridItem>

                  <GridItem xs={12}>
                    <PermissionTable 
                      onPagesChange={handlePermissionTablePagesChange} 
                      initValue={permissionTablePagesInitValue}
                      hiddenPath="/dashboard"
                    />
                  </GridItem>

                </GridContainer>
              </form>
            </CardBody>
            <CardFooter className={classes.justifyContentCenter}>
              <GridContainer
                spacing={2}
                direction="row"
                justify="center"
                alignItems="center"
              >
                <GridItem xs={6} align="center">
                  <Button onClick={backClicked}>
                    <ICON_BACK />
                    {Loc.back}
                  </Button>
                </GridItem>

                <GridItem xs={6} align="center">
                  <Button
                    color="rose"
                    onClick={doneClicked}
                    disabled={fetchingStatus === "fetching" ? true : false}
                  >
                    <ICON_DONE />
                    {isEdit ? Loc.save : Loc.create}
                  </Button>
                </GridItem>
              </GridContainer>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
