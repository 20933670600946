import React from "react";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import CardBody from "components/Card/CardBody.js";

import Loc from "localization";

import ICON_SEARCH from "@material-ui/icons/Search";
import InputLabel from "@material-ui/core/InputLabel";

import Dropdown from "views/CommonUseComponents/Dropdown.js";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";

import fetchAPI from "connectionHandler/FetchAPI.js";
//alert
import { makeStyles } from "@material-ui/core/styles";
import SweetAlert from "react-bootstrap-sweetalert";
import styles_alert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import FormControl from "@material-ui/core/FormControl";

import Datetime from "react-datetime";
import moment from "moment";
import DataSetType from "Data_set_type";


import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import styles_switch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Close from "@material-ui/icons/Close";
import { FormatColorReset } from "@material-ui/icons";
import BarcodeReader from 'react-barcode-reader'
import SwitchUI from "@material-ui/core/Switch";


const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};

export default function _renderSearchBar(props) {
  // alert
  const useStyles = makeStyles(styles);
  const useStyles_alert = makeStyles(styles_alert);
  const useStyles_switch = makeStyles(styles_switch);

  const classes = useStyles();
  const classes_alert = useStyles_alert();
  const classes_switch = useStyles_switch();

  const [alert_info, setAlert_info] = React.useState(null);
  const [alert_confirm, setConfirmAlert] = React.useState(null);
  const hideAlert = () => {
    setAlert_info(null);
    setConfirmAlert(null);
  };

  const [data_strSearchKey, set_strSearchKey] = React.useState("");
  const [data_strSecondSearchKey, set_strSecondSearchKey] = React.useState("");

  const [data_searchID, setData_searchID] = React.useState("");
  const [data_searchSelectFromOther_show, setData_data_searchSelectFromOther_show] = React.useState("");

  const [data_searchKeyArray, setData_searchKeyArray] = React.useState([]);
  const [data_searchType, setData_searchType] = React.useState("");
  const [isLoading, set_isLoading] = React.useState(false);
  const [data_page, set_page] = React.useState(0);
  const [data_pageCount, set_pageCount] = React.useState(50);
  const [data_dataList, setData_dataList] = React.useState(null);
  const [data_dataListCount, setData_dataListCount] = React.useState(0);
  const [data_dataListArray, setData_dataListArray] = React.useState(null); //preload data

  const [data_fromDate, setData_FromDate] = React.useState(
    new moment().startOf("day").toDate()
  );
  const [data_toDate, setData_ToDate] = React.useState(
    new moment().endOf("day").toDate()
  );

  
  const [data_searchBool, setData_data_searchBool] = React.useState(false);

  const [
    data_searchFieldNameList,
    setData_searchFieldNameList,
  ] = React.useState([]);
  const [data_searchIDList, setData_searchIDList] = React.useState([]);
  const [locationState, setLocationState] = React.useState(null);
  const [showDeletedData, setShowDeletedData] = React.useState(false);

  // const [data_storeID, setData_storeID] = React.useState("");
  // const [data_storeName, setData_storeName] = React.useState([]);
  // const [data_staffID, setData_staffID] = React.useState("");
  // const [data_staffName, setData_staffName] = React.useState("");
  const [data_dropdownID, setData_dropdownID] = React.useState("");
  const [data_dropdownName, setData_dropdownName] = React.useState("");
  const [data_secondDropdownID, setData_secondDropdownID] = React.useState("");
  const [data_secondDropdownName, setData_secondDropdownName] = React.useState(
    ""
  );
  const [data_customID, setData_customID] = React.useState("");
  const [data_customName, setData_customName] = React.useState("");

  const [preloadFieldList, set_preloadFieldList] = React.useState([]);
  const [keyToShowList, set_keyToShowList] = React.useState([]);
  const [scanData, setScanData] = React.useState("");


  var showDelete = true;
  if (props.showDelete) {
    showDelete = props.showDelete;
  }

  const [
    isProductPackageDropdown,
    setIsProductPackageDropdown,
  ] = React.useState(false);

  const handleScan = (data) => {
    setScanData(data);
    console.log("handleScan ", data);
    //Focus to search input field
    set_strSearchKey(data.toString());
    searchKeywords([data.toString()],["hashCode"],"text",false);
    //set the search code, if exist, pop alert(click that product)

  }

  const handleError = (err) => { console.error(err) };

  // var isPreloadField = props.isPreloadField ? props.isPreloadField:true
  React.useEffect(() => {
    const searchFieldNameList = [];
    const searchIDList = [];
    const preloadFieldList = [];
    const keyToShowList = [];
    // if (locationState == null) {
    setLocationState(props.locationState);
    const data_preloadDataList = props.preloadDataList;
    // } else {
    props.searchFieldList.map((item) => {
      if (item.id == "divider") {
        searchFieldNameList.push({ divider: true });
        searchIDList.push("");
        preloadFieldList.push(false);
      } else {
        searchFieldNameList.push(item.itemName);
        searchIDList.push(item.id);

        var preloadCollectionName = item.preloadCollectionName
          ? item.preloadCollectionName
          : [];

        var preloadFieldData = {
          id: item.id,
          isPreloadField: item.isPreloadField,
          isCustomField:item.isCustomField ? item.isCustomField:false,
          preloadCollectionName: preloadCollectionName,
          preloadDefaultName: item.preloadDefaultName
            ? item.preloadDefaultName
            : [],
        };

        preloadFieldList.push(preloadFieldData);

        if (item.keyToShow) {
          keyToShowList.push({
            id: item.id,
            key: item.keyToShow,
          });
        }
      }
    });
    set_preloadFieldList(preloadFieldList);
    setData_dataListArray(props.locationState);
    // console.log("search bar + "+ JSON.stringify(props.locationState))
    setData_searchFieldNameList(searchFieldNameList);
    setData_searchIDList(searchIDList);

    set_keyToShowList(keyToShowList);
    if (props.searchPeriod) {
      props.searchPeriod(data_fromDate, data_toDate);
    }

    //Pass from other page
    var searchDataStr = localStorage.getItem("searchData");
    var searchData = JSON.parse(searchDataStr);
    // console.log(`searchData: ${JSON.stringify(searchData)}, setData_FromDate: ${new Date(searchData.startDate)} ,setData_ToDate ${new Date(searchData.endDate)} `);

    if (searchData && searchData !== null) {
      setData_FromDate(new Date(searchData.startDate));
      setData_ToDate(new Date(searchData.endDate));
      setData_searchID(searchData.id);
      setData_searchKeyArray(searchData.key);
      setData_searchType(searchData.type);
      setData_dropdownID(searchData.searchID);
      setData_dropdownName(
        getPreloadStringToShow(
          Loc.staff_default,
          data_dataListArray == null
            ? []
            : data_dataListArray[searchData.preloadCollectionName],
          searchData.preloadCollectionName,
          searchData.searchID
        )
      );
      set_strSearchKey(searchData.searchID);
      //  setTimeout(() => {
      const data = {
        fromDate: new Date(searchData.startDate),
        toDate: new Date(searchData.endDate),
      };
      var searchContent = [];
      if (searchData.secondSearchID) {
        console.log("searchData.secondSearchID ");
        setData_secondDropdownID(searchData.secondSearchID);
        setData_secondDropdownName(searchData.secondSearchStr);
        set_strSecondSearchKey(searchData.secondSearchID);
        searchContent = [data, searchData.searchID, searchData.secondSearchID];
      } else {
        console.log("searchData normal case ");
        searchContent = [data, searchData.searchID, ""];
      }
      var needFilterType = false;
      if (searchData.needFilterType) {
        needFilterType = searchData.needFilterType;
      }

      searchKeywords(
        searchContent,
        searchData.key,
        searchData.type,
        needFilterType
      );
      // }, 100);
    } else {
      setData_searchID(searchIDList[0]);
      setData_searchKeyArray(props.searchFieldList[0].key);
      setData_searchType(props.searchFieldList[0].type);
    }

    // checkIsPreloadField()
    // Specify how to clean up after this effect:
    return function cleanup() {};
  }, []);
  function searchKeywords(searchID, searchKey, searchType, needFilterType) {
    console.log("searchKeywords local param: "+searchID + " , "+searchKey + " , "+searchType + " , "+needFilterType);
    console.log(
      `searchKeywords   ${data_strSearchKey} ${data_searchType} ${searchID} `
    );
    var showExtendedTable = true;
    if (data_searchType == "date") 
    {
      if (data_fromDate || data_toDate) 
      {
        getDataListWithSearchKeyword();
      } 
      else 
      {
        showExtendedTable = false;
        props.loadDataList();
      }
      // if (data_searchType === 'multi'){
      //   if (data_strSearchKey.trim().length > 0 || data_fromDate || data_toDate ) {
      //     getDataListWithSearchKeyword();
      //   } else {
      //     props.loadDataList();
      //   }
      // }else{
        

      // }
    }
    // else if (data_searchType == "bool")
    // {

    // }
    else 
    {
      if (checkIsCustomField())
      {
        // console.log("checkIsCustomField");
        getDataListWithSearchKeyword(searchID,
          searchKey,
          searchType,
          needFilterType);
      } 
      else if (searchID && searchID !== null) 
      {
        getDataListWithSearchKeyword(
          searchID,
          searchKey,
          searchType,
          needFilterType
        );
      } 
      else if (
        data_strSearchKey.trim().length > 0 ||
        data_strSecondSearchKey.trim().length > 0
      ){
        console.log("search!");
        getDataListWithSearchKeyword();
      } 
      else 
      {
        showExtendedTable = false;
        console.log("origin!");
        props.loadDataList();
      }
    }


    if (props.showExtendedTable) {
      props.showExtendedTable(showExtendedTable);
    }
  }

  function showAlert(issuccess, title, content) {
    setAlert_info(
      <SweetAlert
        error={!issuccess}
        success={issuccess}
        style={{ display: "block", marginTop: "-100px" }}
        closeOnClickOutside={false}
        title={title}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnText={Loc.confirm}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        btnSize="lg"
      >
        {content}
      </SweetAlert>
    );
  }

  function getSearchContent() {
    var searchInput = "";
    if (data_searchID === "code") {
      searchInput = data_strSearchKey.toUpperCase();
    } else {
      searchInput = data_strSearchKey;
    }

    console.log("getSearchContent  " + searchInput);
    if (data_searchType == "multi") {
      const data = {
        fromDate: data_fromDate.getTime(),
        toDate: data_toDate.getTime(),
      };

      if (data_strSecondSearchKey !== "" && data_searchID != "date_store_exp") {
        
        return [data, searchInput, data_strSecondSearchKey];
      } else {
        return [data, searchInput, ""];
      }
    } 
    else if (data_searchType === "date") 
    {
      console.log("data_fromDate: " + data_fromDate);
      console.log("data_toDate: " + data_toDate);
      const data = {
        fromDate: data_fromDate.getTime(),
        toDate: data_toDate.getTime(),
      };
      return [data];
    }
    else if (data_searchType === "bool") 
    {
      return [data_searchBool];
    }
    else 
    {
      return [searchInput];
    }
  }

  //search key api
  const getSelectMenuItem = (
    fieldName,
    _fieldList,
    isProductPackageDropdown,
    isExpenditureDropdown = false
  ) => {
    var fieldList = [];
    var menuItems = [];
    if (_fieldList && _fieldList !== null) {
      fieldList = _fieldList[fieldName];
    } else {
      fieldList =
        data_dataListArray == null ? [] : data_dataListArray[fieldName];

    }

    if (isExpenditureDropdown){
      fieldList = _fieldList[Loc.expenditure_type];
      // console.log("isExpenditureDropdown fieldList:"+JSON.stringify(fieldList[Loc.expenditure_type]))

    }

    if (!isProductPackageDropdown) {
      if (fieldList && fieldList.length > 0) {
        fieldList.map((item, key) => {
          // console.log("isProductPackageDropdown  " + isProductPackageDropdown);

          menuItems.push(
            <MenuItem
              key={key}
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value={item.id}
            >
              {item.itemName}
            </MenuItem>
          );
        });
      }

    } 
    else{
      var newProductList = [];
      var newServiceList = [];
      var newPackageList = [];

      var productList = data_dataListArray["Product"];
      var packageList = data_dataListArray["Product-Package"];

      productList.map((item) => {
        if (!item.isOld) {
          var type = getCategoryType(item.id);
          if (type == "cat_product") {
            newProductList.push(item);
          } else if (type == "cat_service") {
            newServiceList.push(item);
          }
        }
      });

      packageList.map((item) => {
        if (!item.isOld) {
          newPackageList.push(item);
        }
      });

      menuItems.push(
        <MenuItem
          disabled
          classes={{
            root: classes.selectMenuItem,
          }}
        >
          {Loc.type_product}
        </MenuItem>
      );

      newProductList.map((item, key) => {
        menuItems.push(
          <MenuItem
            key={key}
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected,
            }}
            value={item.id}
          >
            {item.itemName}
          </MenuItem>
        );
      });

      menuItems.push(
        <MenuItem
          disabled
          classes={{
            root: classes.selectMenuItem,
          }}
        >
          {Loc.type_service}
        </MenuItem>
      );

      newServiceList.map((item, key) => {
        menuItems.push(
          <MenuItem
            key={key}
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected,
            }}
            value={item.id}
          >
            {item.itemName}
          </MenuItem>
        );
      });

      menuItems.push(
        <MenuItem
          disabled
          classes={{
            root: classes.selectMenuItem,
          }}
        >
          {Loc.type_package}
        </MenuItem>
      );
      newPackageList.map((item, key) => {
        menuItems.push(
          <MenuItem
            key={key}
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected,
            }}
            value={item.id}
          >
            {item.itemName}
          </MenuItem>
        );
      });
    }

    return menuItems;
  };

  function getCategoryType(productID) {
    if (data_dataListArray) {
      const categoryList = data_dataListArray["Category"];
      const productList = data_dataListArray["Product"];
      var type = "";
      if (productList && categoryList) {
        productList.forEach((productData) => {
          if (productData.id == productID) {
            const catID = productData.categoryID;
            // console.log("catID: " + catID);
            categoryList.forEach((categoryData) => {
              if (categoryData.id == catID) {
                // console.log("categoryData.uuid: " + categoryData.uuid);
                type = categoryData.uuid;
              }
            });
          }
        });
      }
    }
    // console.log("type: " + type);
    return type;
  }

  const getDropDownNameList = (fieldName, _fieldList) => {
    var fieldList = [];
    if (_fieldList && _fieldList !== null) {
      fieldList = _fieldList;
      // console.log(`"getDropDownNameList ${fieldName} "`+JSON.stringify(_fieldList))
    } else {
      fieldList =
        data_dataListArray == null ? [] : data_dataListArray[fieldName];
    }
    const fieldNameList = [];
    if (fieldList.length > 0) {
      fieldList.map((item) => {
        var itemKey = "";
        if (getKeyToShow() != "") {
          itemKey = getKeyToShow();
          // console.log("getKeyToShow = > "+itemKey)
          fieldNameList.push(item.itemKey);
        } else {
          fieldNameList.push(item.itemName);
        }
      });
    }
    return fieldNameList;
  };

  // function do_loadAllList() { //for local filter
  //   return new Promise((onDone) => {
  //     const body = {
  //       data_targetCollection: props.collectionName,
  //     };
  //     fetchAPI.do_fetch("post", "admin/list-all", body).then(
  //       (res) => {
          
  //         onDone(res.data.data_list);
  //       },
  //       (error) => {
  //         console.log("failed: ", error);
  //         onDone(null);
  //       }
  //     );
  //   });
  // }

  function getDataListWithSearchKeyword(
    searchID,
    searchKey,
    searchType,
    needFilterType = false
  ) {
    set_isLoading(true);

    console.log("check searchID: " + searchID);
    console.log("check searchKey: " + searchKey);
    console.log("check searchType: " + searchType);
    console.log("check needFilterType: " + needFilterType);


    if (searchID) {
      if (searchID.length > 2) {
        console.log(`is multi la! ${searchID}}`);
      }
    }

    var body = {
      page: data_page,
      pageCount: data_pageCount,
      searchKeyword: searchID == null ? getSearchContent() : searchID,
      searchKey: searchKey == null ? data_searchKeyArray : searchKey,
      searchType: searchType == null ? data_searchType : searchType,
      showDeletedData: props.showDeletedData,
      isProductPackageDropdown: isProductPackageDropdown,
    };

    console.log(`checking: ${JSON.stringify(body)}`)

    if (props.collectionName) {
      body = { ...body, collectionName: props.collectionName };
    }
    if (props.permissionContent) {
      body = { ...body, permissionContent: props.permissionContent, permissionTargetDetail: props.permissionTargetDetail };
    }

    console.log(`props.apiPath  ${props.apiPath}  body ${JSON.stringify(body)}`);
    // console.log("checkIsCustomField()  "+checkIsCustomField());
      fetchAPI.do_fetch("post", props.apiPath, body).then(
        (res) => {
          set_isLoading(false);
          console.log("success: ", res.data);
          if (res.data == null || res.data.data_list == null || res.data.data_list.length === 0) {
            setData_dataList([]);
            setData_dataListCount(0);
            props.searchResultDataList([]);
            props.searchResultDataCount(0);
          } else {
            if (isProductPackageDropdown) {
              //is multi and need local filter
              var resData = res.data.data_list;
              var newDataList = [];
              //get the sale list and filter
              var searchContent = getSearchContent();
              console.log("searchContent[2]: " + searchContent[2]);
  
              resData.map((item) => {
                var _itemsInPackage = item.itemsInPackage;
                var _couponUsageList = item.couponUsageList;
                _itemsInPackage.map((package_item) => {
                  if (package_item.id == searchContent[2]) {
                    newDataList.push(item);
                  }
                });
  
                _couponUsageList.map((coupon_item) => {
                  if (coupon_item.exchange_itemID == searchContent[2]) {
                    newDataList.push(item);
                  }
                });
              });
  
              props.searchResultDataList(newDataList);
              props.searchResultDataCount(newDataList.length);
            } else if (needFilterType) {
              var resData = res.data.data_list;
              var newDataList = [];
  
              resData.map((item) => {
                if (item.type == "-") {
                  newDataList.push(item);
                }
              });
  
              props.searchResultDataList(newDataList);
              props.searchResultDataCount(newDataList.length);
            } else if (data_searchID == "date_store_exp"){
              var resData = res.data.data_list;
              var newDataList = [];
  
              resData.map((item) => {
                if (item.type == data_strSecondSearchKey) {
                  newDataList.push(item);
                }
              });
  
              props.searchResultDataList(newDataList);
              props.searchResultDataCount(newDataList.length);
            }
             else {
              props.searchResultDataList(res.data.data_list);
              props.searchResultDataCount(res.data.count);
            }
  
            if (localStorage.removeItem("searchData") !== null) {
              localStorage.removeItem("searchData");
            }
          }
        },
        (error) => {
          set_isLoading(false);
          console.log("failed: ", error);
          showAlert(false, Loc.failed, Loc.data_fetch_failed);
        }
      );
    
    
  }

  const getStringToShow = () => {
    // console.log(`getStringToShow  ${strToShow}`)
    var strToShow = data_searchFieldNameList[0];
    props.searchFieldList.map((prop, key) => {
      if (prop.id === data_searchID) {
        strToShow = prop.itemName;
      }
    });
    // console.log(`getStringToShow  ${strToShow}`);
    return strToShow;
  };

  const getPreloadStringToShow = (defaultStr, fieldList, fieldName, id) => {
    console.log(`getStringToShow ${defaultStr} ===>${id}`);
    var strToShow = defaultStr;
    if (id == "") {
    } else {
      if (fieldList.length > 0) {
        const fieldNameList = getDropDownNameList(fieldName);
        strToShow = fieldNameList[0];
        fieldList.map((prop, key) => {
          if (prop.id === id) {
            strToShow = prop.itemName;
          }
        });
      }
    }
    return strToShow;
  };

  const getCustomStringToShow = (defaultStr, fieldList, fieldName, id) => {
    var strToShow = defaultStr;

    if (fieldList.length > 0) {
      const fieldNameList = getDropDownNameList(fieldName, fieldList);
      strToShow = fieldNameList[0];
      fieldList.map((prop, key) => {
        if (prop.id === id) {
          strToShow = prop.itemName;
        }
      });
    }
    return strToShow;
  };

  const _renderInputView = (
    collectionName,
    isMultiDropdown,
    collectionNameList,
    preloadDataList
  ) => {
    // console.log("_renderInputView");

    if (checkIsPreloadField()) {
      // console.log("_renderInputView  _renderDropdownList")
      // console.log("checkIsPreloadField  "+JSON.stringify(preloadDataList))

      return _renderDropdownList(
        collectionName,
        isMultiDropdown,
        collectionNameList,
        preloadDataList
      );
    } else {
      if (data_searchType == "date") 
      {
        return (
          <FormControl>
            <GridItem xs={12}>
              <GridContainer>
                <GridItem xs={6}>
                  <Datetime
                    inputProps={{ placeholder: Loc.From }}
                    timeFormat={false}
                    defaultValue={data_fromDate}
                    value={
                      data_fromDate == null ? new Date() : moment(data_fromDate)
                    }
                    onChange={(m) => {
                      if (moment.isMoment(m)) {
                        // setFromDateState("success");
                        setData_FromDate(m.startOf("day").toDate());
                      } else {
                        // setFromDateState("error");
                      }
                    }}
                  />
                </GridItem>
                <GridItem xs={6}>
                  <Datetime
                    inputProps={{ placeholder: Loc.To }}
                    timeFormat={false}
                    defaultValue={data_toDate}
                    value={
                      data_toDate == null ? new Date() : moment(data_toDate)
                    }
                    onChange={(m) => {
                      if (moment.isMoment(m)) {
                        // setToDateState("success");
                        setData_ToDate(m.endOf("day").toDate());
                      } else {
                        // setToDateState("error");
                      }
                    }}
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
          </FormControl>
        );
      } 
      else if (data_searchType == "bool")
      {
        return (
          <GridItem xs={12} key={"search_key"}>
            <CustomDropdown
              buttonProps={{
                round: false,
                color: "primary",
              }}
              buttonText={
                <span>
                  {data_searchBool ? "True" : "False"}
                </span>
              }
              dropdownList={["True", "False"]}
              itemIDList={["true", "false"]}
              onClick={(selectedID) => {
                if (selectedID === null) {
                  setData_data_searchBool(false);
                } else {
                  setData_data_searchBool(selectedID == "true" ? true : false)
                }
              }}
            />
          </GridItem>
        );
      }
      else if (data_searchType == DataSetType.type_select_from_other_dataset_single)
      {
        var customSearchField = null;
        props.searchFieldList.map((prop, key) => {
          if (prop.id === data_searchID) {
            customSearchField = prop;
          }
        });
        
        const data_list_for_dropDown = props.preloadDataList[customSearchField.otherTableCollectionName];

        var data_dropDown = [];
        var data_dropDown_id = [];
        var data_dataPair = {};
        data_list_for_dropDown.map(item => {
          data_dropDown.push(item[customSearchField.otherTableShowFieldName]);
          data_dropDown_id.push(item.id);
          data_dataPair[item.id] = item[customSearchField.otherTableShowFieldName];
        })

        if (data_strSearchKey == "")
        {
          set_strSearchKey(data_dropDown_id[0]);
        }

        return(
          <GridItem xs={12} key={"search_key"}>
            <CustomDropdown
              buttonProps={{
                round: false,
                color: "primary",
              }}
              buttonText={
                <span>
                  {data_strSearchKey ? data_dataPair[data_strSearchKey] : data_dropDown[0]}
                </span>
              }
              dropdownList={data_dropDown}
              itemIDList={data_dropDown_id}
              onClick={(selectedID) => {
                set_strSearchKey(selectedID);
              }}
            />
          </GridItem>
        );
      }
      else {
        return (
          <CustomInput
            // success={requiredState === "success"}
            // error={}
            labelText={Loc.search_text}
            id="search_key"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: data_strSearchKey,
              onChange: (event) => {
                if (
                  data_searchID === "customerCode" ||
                  data_searchID === "code"
                ) {
                  // console.log("data_strSearchKey  "+event.target.value.toUpperCase())
                  set_strSearchKey(event.target.value.toUpperCase());
                } else {
                  set_strSearchKey(event.target.value);
                }
              },
              type: "text",
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                searchKeywords();
              }
            }}
          />
        );
      }
    }
  };

  function _renderDropdownList(
    collectionName,
    isMultiDropdown,
    collectionNameList,
    preloadDataList
  ) {
    var preloadCollectionName = collectionName;
    var data_dataListArray = preloadDataList;
    var isProductPackageDropdown = collectionNameList
      ? collectionNameList.length > 2
      : false;

    console.log(
      "_renderDropdownList data_searchType multi  " +
        (data_searchType === "multi")
    );
    //Local filter

    // else if(collectionName == "Product"){

    // }

    if (props.customPreloadList && getStringToShow() == Loc.expenditure_type) {
      const { customPreloadList } = props;
      return (
        <FormControl
          fullWidth
          className={
            classes.selectFormControl + " " + classes.formControlMargins
          }
        >
          <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
            {Loc.select_category}
          </InputLabel>
          <Select
            MenuProps={{
              className: classes.selectMenu,
            }}
            classes={{
              select: classes.select,
            }}
            value={data_customID}
            onChange={(event) => {
              const selectedID = event.target.value;
              setData_customName(
                getCustomStringToShow(
                  "",
                  customPreloadList[getStringToShow()],
                  getStringToShow(),
                  selectedID
                )
              );
              setData_customID(selectedID);
              set_strSearchKey(selectedID);
              console.log("selectedID  " + selectedID);
              // console.log("setData_storeName  " + data_storeName);
            }}
            inputProps={{
              name: data_customName,
              id: data_customID,
            }}
          >
            {getSelectMenuItem(getStringToShow(), customPreloadList)}
          </Select>
        </FormControl>
      );

      // }else if (getStringToShow() == Loc.product_category){
    } else {
      if (data_searchType === "multi") {
        if (isMultiDropdown) {
          var preloadDefaultNameList = getPreloadDefaultName(isMultiDropdown);
          // console.log("Multi search "+preloadDefaultNameList[1])
          return (
            <>
              <GridContainer>
                <GridItem xs={12} sm={12} md={9}>
                  <FormControl
                    fullWidth
                    className={
                      classes.selectFormControl +
                      " " +
                      classes.formControlMargins
                    }
                  >
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.selectLabel}
                    >
                      {preloadDefaultNameList[0]}
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={data_dropdownID}
                      onChange={(event) => {
                        const selectedID = event.target.value;

                        // console.log("Multi search "+selectedID+ " "+ getPreloadDefaultName())
                        setData_dropdownID(selectedID);

                        setData_dropdownName(
                          getPreloadStringToShow(
                            preloadDefaultNameList[0],
                            data_dataListArray == null
                              ? []
                              : data_dataListArray[collectionNameList[0]],
                            collectionNameList[0],
                            selectedID
                          )
                        );

                        set_strSearchKey(selectedID);
                      }}
                      inputProps={{
                        name: data_dropdownName,
                        id: data_dropdownID,
                      }}
                    >
                      {getSelectMenuItem(collectionNameList[0])}
                      {/* {} */}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <Button
                    justIcon
                    round
                    simple
                    onClick={() => {
                      set_strSearchKey("");
                      setData_dropdownID("");
                      setData_dropdownName("");
                    }}
                    color="primary"
                    className="edit"
                  >
                    <Close />
                  </Button>
                </GridItem>
              </GridContainer>

              <GridContainer>
                {collectionNameList[1] == "Expenditure" ? (
                  <GridItem xs={12} sm={12} md={9}>
                    <FormControl
                      fullWidth
                      className={
                        classes.selectFormControl +
                        " " +
                        classes.formControlMargins
                      }
                    >
                      <InputLabel
                        htmlFor="simple-select"
                        className={classes.selectLabel}
                      >
                        {preloadDefaultNameList[1]}
                      </InputLabel>
                      <Select
                        MenuProps={{
                          className: classes.selectMenu,
                        }}
                        classes={{
                          select: classes.select,
                        }}
                        value={data_secondDropdownID}
                        onChange={(event) => {
                          const selectedID = event.target.value;

                          // console.log("Multi search "+selectedID+ " "+ getPreloadDefaultName())
                          setData_secondDropdownID(selectedID);

                          setData_secondDropdownName(
                            getPreloadStringToShow(
                              preloadDefaultNameList[1],
                              props.customPreloadList,
                              collectionNameList[1],
                              selectedID
                            )
                          );

                          set_strSecondSearchKey(selectedID);
                        }}
                        inputProps={{
                          name: data_secondDropdownName,
                          id: data_secondDropdownID,
                        }}
                      >
                        {getSelectMenuItem(
                          preloadDefaultNameList[1],
                          props.customPreloadList,
                          isProductPackageDropdown,
                          true
                        )}
                        {/* {} */}
                      </Select>
                    </FormControl>
                  </GridItem>
                ) : (
                  <GridItem xs={12} sm={12} md={9}>
                    <FormControl
                      fullWidth
                      className={
                        classes.selectFormControl +
                        " " +
                        classes.formControlMargins
                      }
                    >
                      <InputLabel
                        htmlFor="simple-select"
                        className={classes.selectLabel}
                      >
                        {preloadDefaultNameList[1]}
                      </InputLabel>
                      <Select
                        MenuProps={{
                          className: classes.selectMenu,
                        }}
                        classes={{
                          select: classes.select,
                        }}
                        value={data_secondDropdownID}
                        onChange={(event) => {
                          const selectedID = event.target.value;

                          // console.log("Multi search "+selectedID+ " "+ getPreloadDefaultName())
                          setData_secondDropdownID(selectedID);

                          setData_secondDropdownName(
                            getPreloadStringToShow(
                              preloadDefaultNameList[1],
                              data_dataListArray == null
                                ? []
                                : data_dataListArray[collectionNameList[1]],
                              collectionNameList[1],
                              selectedID
                            )
                          );

                          set_strSecondSearchKey(selectedID);
                        }}
                        inputProps={{
                          name: data_secondDropdownName,
                          id: data_secondDropdownID,
                        }}
                      >
                        {getSelectMenuItem(
                          collectionNameList[1],
                          null,
                          isProductPackageDropdown
                        )}
                        {/* {} */}
                      </Select>
                    </FormControl>
                  </GridItem>
                )}

                <GridItem xs={12} sm={12} md={3}>
                  <Button
                    justIcon
                    round
                    simple
                    onClick={() => {
                      setData_secondDropdownID("");
                      setData_secondDropdownName("");
                      set_strSecondSearchKey("");
                    }}
                    color="primary"
                    className="edit"
                  >
                    <Close />
                  </Button>
                </GridItem>
              </GridContainer>

              <GridItem xs={12}>
                <GridContainer>
                  <GridItem xs={6}>
                    <Datetime
                      inputProps={{ placeholder: Loc.From }}
                      timeFormat={false}
                      defaultValue={data_fromDate}
                      value={
                        data_fromDate == null
                          ? new Date()
                          : moment(data_fromDate)
                      }
                      onChange={(m) => {
                        if (moment.isMoment(m)) {
                          // setFromDateState("success");
                          setData_FromDate(m.startOf("day").toDate());
                        } else {
                          // setFromDateState("error");
                        }
                      }}
                    />
                  </GridItem>
                  <GridItem xs={6}>
                    <Datetime
                      inputProps={{ placeholder: Loc.To }}
                      timeFormat={false}
                      defaultValue={data_toDate}
                      value={
                        data_toDate == null ? new Date() : moment(data_toDate)
                      }
                      onChange={(m) => {
                        if (moment.isMoment(m)) {
                          // setToDateState("success");
                          setData_ToDate(m.endOf("day").toDate());
                        } else {
                          // setToDateState("error");
                        }
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
            </>
          );
        } else {
          return (
            <FormControl
              fullWidth
              className={
                classes.selectFormControl + " " + classes.formControlMargins
              }
            >
              <InputLabel
                htmlFor="simple-select"
                className={classes.selectLabel}
              >
                {getPreloadDefaultName()}
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={data_dropdownID}
                onChange={(event) => {
                  const selectedID = event.target.value;

                  // console.log("Multi search "+selectedID+ " "+ getPreloadDefaultName())
                  setData_dropdownID(selectedID);

                  setData_dropdownName(
                    getPreloadStringToShow(
                      getPreloadDefaultName(),
                      data_dataListArray == null
                        ? []
                        : data_dataListArray[preloadCollectionName],
                      preloadCollectionName,
                      selectedID
                    )
                  );

                  set_strSearchKey(selectedID);
                }}
                inputProps={{
                  name: data_dropdownName,
                  id: data_dropdownID,
                }}
              >
                {getSelectMenuItem(preloadCollectionName)}
                {/* {} */}
              </Select>
              <GridItem xs={12}>
                <GridContainer>
                  <GridItem xs={6}>
                    <Datetime
                      inputProps={{ placeholder: Loc.From }}
                      timeFormat={false}
                      defaultValue={data_fromDate}
                      value={
                        data_fromDate == null
                          ? new Date()
                          : moment(data_fromDate)
                      }
                      onChange={(m) => {
                        if (moment.isMoment(m)) {
                          // setFromDateState("success");
                          setData_FromDate(m.startOf("day").toDate());
                        } else {
                          // setFromDateState("error");
                        }
                      }}
                    />
                  </GridItem>
                  <GridItem xs={6}>
                    <Datetime
                      inputProps={{ placeholder: Loc.To }}
                      timeFormat={false}
                      defaultValue={data_toDate}
                      value={
                        data_toDate == null ? new Date() : moment(data_toDate)
                      }
                      onChange={(m) => {
                        if (moment.isMoment(m)) {
                          // setToDateState("success");
                          setData_ToDate(m.endOf("day").toDate());
                        } else {
                          // setToDateState("error");
                        }
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
            </FormControl>
          );
        }
      } else {
        // console.log("data_dataListArray[preloadCollectionName]  "+JSON.stringify(data_dataListArray))
        return (
          <FormControl
            fullWidth
            className={
              classes.selectFormControl + " " + classes.formControlMargins
            }
          >
            <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
              {getPreloadDefaultName()}
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu,
              }}
              classes={{
                select: classes.select,
              }}
              value={data_dropdownID}
              onChange={(event) => {
                const selectedID = event.target.value;
                setData_dropdownName(
                  getPreloadStringToShow(
                    Loc.store_default,
                    data_dataListArray == null
                      ? []
                      : data_dataListArray[preloadCollectionName],
                    preloadCollectionName,
                    selectedID
                  )
                );
                setData_dropdownID(selectedID);
                //XX-balance case

                if (
                  !data_searchKeyArray[0].includes("ID") ||
                  (data_searchKeyArray.length > 1 &&
                    !data_searchKeyArray[1].includes("ID"))
                ) {
                  set_strSearchKey(
                    getPreloadStringToShow(
                      Loc.store_default,
                      data_dataListArray == null
                        ? []
                        : data_dataListArray[preloadCollectionName],
                      preloadCollectionName,
                      selectedID
                    )
                  );
                } else {
                  // Search key -> with ID
                  set_strSearchKey(selectedID);
                }
                // console.log("selectedID  " + selectedID);
                // console.log("setData_storeName  " + data_storeName);
              }}
              inputProps={{
                name: data_dropdownName,
                id: data_dropdownID,
              }}
            >
              {getSelectMenuItem(preloadCollectionName)}
            </Select>
          </FormControl>
        );
      }
    }
  }

  function _renderShowDeletedSwitch() {
    // console.log(`_renderShowDeletedSwitch  ${locationState.adminInfo}`)
    if (props.locationState && props.locationState.adminInfo) {
      const adminName = props.locationState.adminInfo.itemName;
      // console.log(`Admin Name  ${adminName}`)
      if (adminName == "Super Admin") {
        return (
          <div align="right">
            <FormControlLabel
              control={
                <Switch
                  checked={showDeletedData || false}
                  onChange={(event) => {
                    setShowDeletedData(!showDeletedData);
                    props.didSelectedShowDeletedData(!showDeletedData);
                  }}
                  classes={{
                    switchBase: classes_switch.switchBase,
                    checked: classes_switch.switchChecked,
                    thumb: classes_switch.switchIcon,
                    track: classes_switch.switchBar,
                  }}
                />
              }
              classes={{
                label: classes.label,
              }}
              label={"Show deleted data"}
            />
          </div>
        );
      }
    }

    return null;
  }

  function checkIsPreloadField() {
    var result = false;
    if (preloadFieldList.length > 0) {
      for (let i = 0; i < preloadFieldList.length; i++) {
        const item = preloadFieldList[i];
        if (item.id == data_searchID) {
          result = item.isPreloadField;
          break;
        }
      }
    }
    // console.log("checkIsPreloadField  "+data_searchID+" "+JSON.stringify(preloadFieldList) )
    return result;
  }

  function checkIsCustomField() {
    var result = false;
    if (preloadFieldList.length > 0) {
      for (let i = 0; i < preloadFieldList.length; i++) {
        const item = preloadFieldList[i];
        if (item.id == data_searchID) {
          result = item.isCustomField;
          break;
        }
      }
    }
    // console.log("checkIsPreloadField  "+data_searchID+" "+JSON.stringify(preloadFieldList) )
    return result;
  }

  function getPreloadCollectionNameList() {
    var preloadCollectionNameList = [];
    if (preloadFieldList.length > 0) {
      for (let i = 0; i < preloadFieldList.length; i++) {
        const item = preloadFieldList[i];
        if (item.id == data_searchID) {
          preloadCollectionNameList = item.preloadCollectionName;
          break;
        }
      }
    }
    // console.log("checkIsPreloadField  "+data_searchID+" "+JSON.stringify(preloadFieldList) )
    return preloadCollectionNameList;
  }

  function getPreloadDefaultName(isMultiDropdown) {
    var preloadDefaultName = "";
    if (preloadFieldList.length > 0) {
      for (let i = 0; i < preloadFieldList.length; i++) {
        const item = preloadFieldList[i];
        if (item.id == data_searchID) {
          preloadDefaultName = item.preloadDefaultName;
          break;
        }
      }
    }
    // console.log("checkIsPreloadField  "+data_searchID+" "+JSON.stringify(preloadFieldList) )
    if (isMultiDropdown) {
      return preloadDefaultName;
    } else {
      return preloadDefaultName[0];
    }
  }

  function getKeyToShow() {
    var key = "";
    if (keyToShowList.length > 0) {
      for (let i = 0; i < keyToShowList.length; i++) {
        const item = keyToShowList[i];
        if (item.id == data_searchID) {
          key = item.key;
          break;
        }
      }
    }
    // console.log("checkIsPreloadField  "+data_searchID+" "+JSON.stringify(preloadFieldList) )
    return key;
  }

  function _renderMultiInputView() {
    var preloadCollectionName = getPreloadCollectionNameList();
    // console.log("getPreloadCollectionNameList  "+JSON.stringify(preloadCollectionName))
    var isMultiDropdown = preloadCollectionName.length > 1;

    if (isMultiDropdown) {
      return _renderInputView(
        "",
        isMultiDropdown,
        preloadCollectionName,
        data_dataListArray
      );
    } else if (
      props.customPreloadList &&
      getStringToShow() == Loc.expenditure_type
    ) {
      return _renderInputView("", false);
    } else {
      var multiInputView = preloadCollectionName.map((item) => {
        console.log("multiInputView  " + item);
        return _renderInputView(
          item,
          false,
          preloadCollectionName,
          data_dataListArray
        );
      });
    }

    return multiInputView;
  }

  const showloading = () => {
    if (isLoading) {
      return (
        <SweetAlert
          style={{ display: "block", marginTop: "-100px" }}
          closeOnClickOutside={false}
          title={Loc.loading}
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnText={Loc.confirm}
          confirmBtnCssClass={
            classes_alert.button + " " + classes_alert.success
          }
          btnSize="lg"
          showConfirm={false}
          showCancel={false}
        >
          {Loc.please_wait}
        </SweetAlert>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      {showloading()}
      <CardBody xs={12} md={6}>
        <GridContainer>
          {/* {//dropdown + textfield} */}
          <GridItem xs={12} md={6}>
            <GridContainer>
              <GridItem xs={3}>
                <div>
                  <CustomDropdown
                    buttonProps={{
                      round: true,
                      color: "info",
                    }}
                    buttonText={<span>{getStringToShow()}</span>}
                    dropdownList={data_searchFieldNameList}
                    itemIDList={data_searchIDList}
                    onClick={(selectedID) => {
                      if (selectedID === null) {
                        setData_searchID("");
                      } else {
                        setData_searchID(selectedID);
                        // props.didSelectedID(selectedID);
                      }
                      var isProductPackageDropdown = false;

                      props.searchFieldList.map((item) => {
                        if (item.id === selectedID) {
                          setData_searchKeyArray(item.key);
                          setData_searchType(item.type);
                          console.log(
                            "searchFieldList Length:" + JSON.stringify(item)
                          );
                          var collectionNameList = item.preloadCollectionName;
                          if (collectionNameList) {
                            collectionNameList.map((collectionName) => {
                              if (collectionName == "Product-Package") {
                                isProductPackageDropdown = true;
                              }
                            });
                          }
                        }
                      });
                      setIsProductPackageDropdown(isProductPackageDropdown);

                      if (props.searchPeriod) {
                        // if(data_searchType == 'date'){
                        props.searchPeriod(data_fromDate, data_toDate);
                        // }
                      }

                      set_strSearchKey("");
                      setData_dropdownID("");
                      setData_dropdownName("");

                      setData_secondDropdownID("");
                      setData_secondDropdownName("");
                      set_strSecondSearchKey("");

                      setData_customID("");
                      setData_customName("");
                      checkIsPreloadField();
                    }}
                  />
                </div>
              </GridItem>
              <GridItem xs={1}></GridItem>
              <GridItem xs={8}>
                {" "}
                {checkIsPreloadField()
                  ? _renderMultiInputView()
                  : _renderInputView()}
              </GridItem>
            </GridContainer>
          </GridItem>
          <BarcodeReader onError={handleError} onScan={handleScan} />

          <GridItem xs={12} md={2}>
            <div>
              <Button
                color="dribbble"
                round
                onClick={() => {
                  searchKeywords();
                }}
              >
                <ICON_SEARCH />
                {Loc.search}
              </Button>
            </div>
          </GridItem>
          <GridItem xs={12} md={4}>
            {showDelete ? _renderShowDeletedSwitch() : null}
          </GridItem>
        </GridContainer>
      </CardBody>
    </>
  );
}
