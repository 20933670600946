/*eslint-disable*/
import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// @material-ui/core components
// .
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import SwitchUI from "@material-ui/core/Switch";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";

// material ui icons
import MailOutline from "@material-ui/icons/MailOutline";
import Contacts from "@material-ui/icons/Contacts";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomTextField from "components/CustomTextField/CustomTextField";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import IconButton from "@material-ui/core/IconButton";
import RosterView from "components/BookingSystem/RosterView";
import BookingDetail from "views/BookingSystem/BookingDetail";
import NavPills from "components/NavPills/NavPills.js";
import Danger from "components/Typography/Danger.js";
import PagingTable from "views/CommonUseComponents/PagingTable.js";
import SearchBar from "views/CommonUseComponents/SearchBar.js";
// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import styles_alert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styles_switch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import styles_tableBtn from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

import ICON_BACK from "@material-ui/icons/ArrowBackIos";
import ICON_DONE from "@material-ui/icons/Done";
import ICON_ADD from "@material-ui/icons/Add";
import ICON_DELETE from "@material-ui/icons/Clear";
import ICON_UP from "@material-ui/icons/ExpandLess";
import ICON_OUTPUT from "@material-ui/icons/Print";

import Datetime from "react-datetime";
import moment from "moment";
import firebase from "connectionHandler/firebase";
import Config from "itchi_config";
import { SystemType } from "Util/SystemType.js";

const useStyles = makeStyles(styles);
const useStyles_alert = makeStyles(styles_alert);
const useStyles_tableBtn = makeStyles(styles_tableBtn);

import SweetAlert from "react-bootstrap-sweetalert";

import Loc from "localization";
import fetchAPI from "connectionHandler/FetchAPI.js";
import DataSetType from "Data_set_type";
import ImageUpload from "components/CustomUpload/ImageUpload.js";
import { OperationCanceledException } from "typescript";

import DragDropUpload from "components/CustomUpload/DragDropUpload.js";
import MDApp from "../../components/mdedit/markdown";
const { forwardRef, useRef, useImperativeHandle } = React;
import { v4 as uuidv4 } from "uuid";

// ======================================================
//
//   Task: add a darg drop type
//   Reference: line 582 type_string
//
// ======================================================

export default function Custom_add(props) {
  const [filesArray, setFilesArray] = React.useState([]);

  const [isEdit, setIsEdit] = React.useState(false);
  const [isChangePassword, setIsChangePassword] = React.useState(false);

  const [data_id, setData_id] = React.useState("");
  const [data_name, setData_itemName] = React.useState("");
  const [data_remark, setData_remark] = React.useState("");
  const [data_customFields, setData_customFields] = React.useState([]);
  const [isBookingSystem, setIsBookingSystem] = React.useState(false);
  const [isRoster, setIsRoster] = React.useState(false);
  const [data_lookupObj, setData_lookupObj] = React.useState(null);
  const [data_UILayout, setData_UILayout] = React.useState([]);
  const [pathToRedirect, setRedirect] = React.useState("");
  const [data_mdText, setMDText] = React.useState("");

  const [customInputStates, setCustomInputStates] = React.useState(null);
  const [productNameState, setcategoryNameState] = React.useState("");
  const [data_otherTableData, setData_otherTableData] = React.useState([]);

  const [fetchingStatus, setFetchingStatus] = React.useState("");
  const [localizationState, setLocalizationState] = React.useState(null);
  const [historyState, setHistoryState] = React.useState(null);

  const [isUploading, set_isUploading] = React.useState(false);
  const [isReadOnlyMode, set_isReadOnlyMode] = React.useState(false);
  const [signupLater, set_signupLater] = React.useState(false);
  // alert
  const [alert_save, setSaveAlert] = React.useState(null);
  const hideAlert = () => {
    setSaveAlert(null);
  };
  const div_list_empty = () => {
    const theDiv = (
      <GridItem xs={12} align="center">
        <Danger>{Loc.list_empty}</Danger>
      </GridItem>
    );
    return theDiv;
  };
  const useStyles_switch = makeStyles(styles_switch);
  const classes_switch = useStyles_switch();

  React.useEffect(() => {
    // pre load data for edit
    // console.log("testing get state: ", props);
    // console.log("Custom_setting_add::::::  "+JSON.stringify(props.location.state))

    if (props.location.state != null) {
      var data_state = props.location.state;
      // auto fill only for new create
      // console.log(
      //   "props.location.state:  " + JSON.stringify(props)
      // );
      // console.log(
      //   "props.location.state:  " + JSON.stringify(props.location.state)
      // );
      console.log(
        "props.location.state.data:  " +
          JSON.stringify(props.location.state.data)
      );

      if (
        props.location.state.data != null &&
        props.location.state.data.isNewData
      ) {
        data_state = auto_fill_permission_content(data_state);
      }
      setLocalizationState(data_state);
    } else {
      setLocalizationState(props.location.state);
    }

    setHistoryState(props.history.location.state);
    if (localizationState) {
      initCustomInputStates();
      if (localizationState !== null && localizationState.data !== null) {
        if (localizationState.data.isEdit) {
          setIsEdit(!localizationState.data.isNewData);
          setData_id(localizationState.data.id);
          setData_customFields(localizationState.data);
          set_isReadOnlyMode(localizationState.data.isReadOnly);
          set_signupLater(localizationState.data.signupLater);
          setIsBookingSystem(localizationState.data.isBookingSystem);
  
          setData_lookupObj(localizationState.data.lookupObj);
          
        } else {
          initCustomFields();
        }
        // console.log("Custom add: signupLater: " + JSON.stringify(localizationState.data));
      } else {
        // console.log("initCustomFields called: localizationState.data is null");
        initCustomFields();
      }

      // process_UI_Layout(data_customFields);
      handle_selectFromOtherTable().then((result) => {});
    }
    // Specify how to clean up after this effect:
    return function cleanup() {};
  }, [localizationState, historyState, isReadOnlyMode, isBookingSystem]);

  React.useEffect(() => {
    process_UI_Layout(data_customFields);
  }, [data_customFields]);

  //update ui
  function process_UI_Layout(_data_customFields) {
    const new_layout = _renderUILayout(_data_customFields);
    setData_UILayout(new_layout);
  }

  function handle_selectFromOtherTable() {
    console.log("handle_selectFromOtherTable called");
    return new Promise((onDone) => {
      const targetDataToLoad = [];
      localizationState.config.createFieldList.map((configItem) => {
        if (
          configItem.fieldType === DataSetType.type_select_from_other_dataset ||
          configItem.fieldType ===
            DataSetType.type_select_from_other_dataset_single ||
          // TODO: only for super admin
          configItem.fieldType ===
            DataSetType.type_select_from_other_dataset_permission
        ) {
          targetDataToLoad.push(configItem.otherTableCollectionName);
        }

        if (configItem.fieldType === DataSetType.type_account_permission_role) {
          targetDataToLoad.push("Role");
        }
          console.log("targetDataToLoad "+JSON.stringify(targetDataToLoad))
      });
      if (targetDataToLoad.length > 0) {
        // console.log("targetDataToLoad: " + JSON.stringify(localizationState));
        getCustomizedTableList(targetDataToLoad, []).then((preloadedData) => {
          onDone(preloadedData);
          setData_otherTableData(preloadedData);
        });
      }
    });
  }

  const validCheck = (isDone) => {
    console.log("uid: " + data_customFields["uid"]);
    var result = true;
    const _customInputStates = customInputStates;
    var key_pw = "";
    var key_pw_confirm = "";
    var val_account_phone = "";
    var val_account_email = "";
    var val_pw = "";
    var val_pw_confirm = "";

    var list_unique_to_check = [];

    const configList = localizationState.config.createFieldList;
    if (configList !== null) {
      var has_pw = false;
      var has_pw_confirm = false;
      var has_phone = false;

      // check has field and get value
      configList.map((item) => {
        if (item.mandatory) {
          if (
            data_customFields[item.fieldKey] === null ||
            data_customFields[item.fieldKey] === ""
          ) {
            _customInputStates[item.fieldKey] = true;
            result = false;
          }
        } else {
          _customInputStates[item.fieldKey] = false;
        }

        if (item.isUnique) {
          var key = item.fieldKey;
          var val = data_customFields[key];
          // console.log(`item.fieldKey : ${key}  ${data_customFields[key]} `)
          var data = {};
          data[item.fieldKey] = val;
          list_unique_to_check.push(data);
        }

        if (item.fieldType === DataSetType.type_account_email) {
          val_account_email = data_customFields[item.fieldKey];
        }

        if (item.fieldType === DataSetType.type_account_password) {
          has_pw = true;
          key_pw = item.fieldKey;
          val_pw = data_customFields[item.fieldKey];
        }
        if (item.fieldType === DataSetType.type_account_password_confirm) {
          has_pw_confirm = true;
          key_pw_confirm = item.fieldKey;
          val_pw_confirm = data_customFields[item.fieldKey];
        }
        if (item.fieldType === DataSetType.type_account_phone) {
          has_phone = true;
          val_account_phone = data_customFields[item.fieldKey];
          var key_phone = item.fieldKey;

          if (val_account_phone === "") {
            _customInputStates[key_phone] = true;
            result = false;
          }
        }
      });

      // check pw & pw_confirm are not empty
      if (result && has_pw && has_pw_confirm) {
        if ((!isEdit || isChangePassword) && !signupLater) {
          console.log(
            "check pw & pw_confirm are not empty signupLater " + signupLater
          );
          if (
            val_pw === "" ||
            val_pw_confirm === "" ||
            val_pw !== val_pw_confirm
          ) {
            _customInputStates[key_pw] = true;
            _customInputStates[key_pw_confirm] = true;
            result = false;
          } else {
            _customInputStates[key_pw] = false;
            _customInputStates[key_pw_confirm] = false;
          }
        }
      }

      setCustomInputStates(_customInputStates);
    }
    // return result;
    if (!result) {
      console.log("result is false");
      isDone(result);
    } else if (has_pw || has_phone) {
      // Need to check
      if (signupLater) {
        //Can sign up later, if this case is true ,do nothing cause no valid email or pw
        if (val_account_email == null || val_account_email == "") {
          //SKIP CREATE FlOW
          isDone(result);
        } else {
          //The email is not empty, the password should not empty
          if (val_pw == null || val_pw == "") {
            if (isChangePassword) {
              isDone(false);
            } else {
              isDone(result);
            }
          } else {
            handle_firebaseUserRegister(
              data_customFields["uid"] ? data_customFields["uid"] : null,
              (!isEdit || isChangePassword) &&
                val_account_email !== null &&
                val_account_email !== ""
                ? val_account_email
                : null,
              (!isEdit || isChangePassword) && val_pw !== null && val_pw !== ""
                ? val_pw
                : null,
              val_account_phone !== null && val_account_phone !== ""
                ? val_account_phone
                : null,
              (uid) => {
                if (uid !== null) {
                  var data = data_customFields;
                  data["uid"] = uid;
                  // remove pw
                  if (key_pw !== null && key_pw_confirm !== null) {
                    delete data[key_pw];
                    delete data[key_pw_confirm];
                  }
                  setData_customFields(data);

                  isDone(result);
                } else {
                  isDone(false);
                }
              }
            );
          }
        }
      } else {
        handle_firebaseUserRegister(
          data_customFields["uid"] ? data_customFields["uid"] : null,
          (!isEdit || isChangePassword) &&
            val_account_email !== null &&
            val_account_email !== ""
            ? val_account_email
            : null,
          (!isEdit || isChangePassword) && val_pw !== null && val_pw !== ""
            ? val_pw
            : null,
          val_account_phone !== null && val_account_phone !== ""
            ? val_account_phone
            : null,
          (uid) => {
            if (uid !== null) {
              var data = data_customFields;
              data["uid"] = uid;
              // remove pw
              if (key_pw !== null && key_pw_confirm !== null) {
                delete data[key_pw];
                delete data[key_pw_confirm];
              }
              setData_customFields(data);

              isDone(result);
            } else {
              isDone(false);
            }
          }
        );
      }
    } else {
      if (list_unique_to_check.length > 0) {
        handle_checkUniqueField(list_unique_to_check, (checkResult) => {
          if (checkResult !== null) {
            isDone(checkResult);
          } else {
            isDone(false);
          }
        });
      } else {
        isDone(result);
      }
    }
  };

  function handle_checkUniqueField(_uniqueFieldList, callback) {
    const body = {
      collectionName: localizationState.config.collectionName,
      content: {
        uniqueFieldList: _uniqueFieldList,
      },
    };

    // console.log(`body ${JSON.stringify(body)}`)

    fetchAPI.do_fetch("post", "admin/custom-unique-checking", body).then(
      (res) => {
        console.log("success: ", res);
        callback(res.data);
      },
      (error) => {
        console.log("failed: ", error);
        callback(null);
      }
    );
  }

  const handle_firebaseUserRegister = (
    data_uid,
    data_account_email,
    data_account_password,
    data_account_phone = null,
    callback
  ) => {
    const body = {
      id: data_uid,
      content: {
        email: data_account_email,
        password: data_account_password,
        phone: data_account_phone,
      },
    };

    console.log(`body ${JSON.stringify(body)}`);

    fetchAPI.do_fetch("post", "admin/register-user", body).then(
      (res) => {
        console.log("success: ", res);
        callback(res.data.uid);
      },
      (error) => {
        console.log("failed: ", error);
        callback(null);
      }
    );
  };

  const backClicked = () => {
    setRedirect(`${props.location.pathname.replace("-new", "")}`);
  };

  const doneClicked = () => {
    setFetchingStatus("fetching");
    validCheck((isValid) => {
      if (isValid) {
        const idToPass = isEdit ? data_id : null;
        console.log("idToPass: ", idToPass);
        console.log(`doneClicked data_customFields: ${JSON.stringify(data_customFields)}`);

        var readonly_fields = [];
        const configList = props.location.state.config.createFieldList;
        configList.map((field) => {
          if (field.isReadOnly) {
            readonly_fields.push(field.fieldKey);
          }
        });
        //dont submit the field that is read only
        var obj = {};
        obj = { ...obj, ...data_customFields };
        Object.keys(obj).forEach((key) => {
          if (readonly_fields.includes(key)) {
            delete obj[key];
          }
        });
        console.log(
          `Custom add submit obj data_lookupObj: ${JSON.stringify(
            data_lookupObj
          )}`
        );
        console.log(
          `Custom add submit obj isBookingSystem: ${JSON.stringify(
            data_customFields
          )}`
        );
        console.log("localizationState.config.collectionName   " + localizationState.config.collectionName);
        var body = {
          collectionName: localizationState.config.collectionName,
          id: idToPass,
          // content: obj,
        };

        if (isBookingSystem) {
          obj["isBookingSystem"] = true;
          obj["targetRosterCollectionName"] =
            data_lookupObj["targetRosterCollectionName"];
          obj["targetBooking"] = data_lookupObj["targetBooking"];
          obj["rosterLookup"] = data_lookupObj["rosterLookup"];
          obj["lookupID"] = data_customFields[data_lookupObj["rosterLookup"]];
          var dateKey = data_lookupObj["targetBooking"] + "_selectedDate";
          obj["date_timestamp"] = data_customFields["date_timestamp"];
          obj[dateKey] = data_customFields[dateKey];
          obj["orderBy"] = dateKey;
          // console.log(
          //   "isBookingSystem dateKey: " +
          //     dateKey +
          //     " " +
          //     data_customFields[dateKey] +
          //     " " +
          //     obj["date_timestamp"]
          // );
          if (isEdit) {
            body["previousRosterId"] = data_customFields["previousRosterId"];
            body["previousRosterDate"] =
              data_customFields["previousRosterDate"];
          }
        }

        body["content"] = obj;

        console.log("Result " + JSON.stringify(body));
        fetchAPI.do_fetch("post", "admin/create-custom", body).then(
          (res) => {
            console.log("success: ", res);
            setFetchingStatus("");
            showAlert(true, Loc.success, Loc.data_save_success);
            setData_itemName("");
          },
          (error) => {
            console.log("failed: ", error);
            setFetchingStatus("");
            showAlert(false, Loc.failed, Loc.data_save_failed);
          }
        );
      } else {
        // checkInvalidInput();
        setFetchingStatus("");
        showAlert(false, Loc.failed, Loc.data_save_failed);
      }
    });
  };

  function GetReadOnlyFieldList() {
    var target_list = [];
  }

  // function checkInvalidInput(){
  //   const _customInputStates = customInputStates;

  //   const configList = props.location.state.config.createFieldList;

  //   configList.map(item =>{
  //     if(item.mandatory && (data_customFields[item.fieldKey] === "" || data_customFields[item.fieldKey] === null)){ //is mandatory but the value is empty
  //       console.log(`checkInvalidInput   ${JSON.stringify(data_customFields)}`)
  //       _customInputStates[item.fieldKey] = true;
  //     }

  //   })
  //   console.log(`checkInvalidInput   ${JSON.stringify(_customInputStates)}`)
  //    setCustomInputStates(_customInputStates)

  // }

  function showAlert(issuccess, title, content) {
    setSaveAlert(
      <SweetAlert
        error={!issuccess}
        success={issuccess}
        style={{ display: "block", marginTop: "-100px" }}
        closeOnClickOutside={false}
        title={title}
        onConfirm={() => {
          if (issuccess) {
            setRedirect(`${props.location.pathname.replace("-new", "")}`);
          } else {
            hideAlert();
          }
        }}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        cancelBtnCssClass={classes_alert.button + " " + classes_alert.info}
        onCancel={() => {
          if (issuccess) {
            setData_itemName("");
            setData_remark("");

            initCustomFields();
            // window.location.reload(false);
            hideAlert();
          } else {
            hideAlert();
          }
        }}
        confirmBtnText={issuccess ? Loc.done : Loc.confirm}
        cancelBtnText={Loc.continue}
        btnSize="lg"
        showCancel={issuccess && !isEdit}
      >
        {content}
      </SweetAlert>
    );
  }

  const check_redirect = () => {
    if (pathToRedirect != "") {
      return (
        <Redirect
          to={{
            pathname: pathToRedirect,
            state: historyState.config,
          }}
        />
      );
    } else {
      return null;
    }
  };

  function auto_fill_permission_content(data_state) {
    if (
      data_state.config.adminInfo.permission_content != undefined &&
      data_state.config.adminInfo.permission_content != null
    ) {
      const configList = data_state.config.createFieldList;
      configList.map((configItem, index) => {
        if (
          configItem.fieldType ==
          DataSetType.type_select_from_other_dataset_permission
        ) {
          const selectedTargetData = {
            targetID: data_state.config.adminInfo.permission_content.targetID,
            targetCollection: configItem.otherTableCollectionName,
          };

          data_state.data[configItem.fieldKey] = selectedTargetData;
          // console.log(`data_customFields: ${JSON.stringify(data_state.data)}`)
        }
      });
    }

    return data_state;
  }

  const _renderUILayout = () => {
    if (!localizationState || !localizationState.config.createFieldList) {
      return null;
    }

    const configList = localizationState.config.createFieldList;
    var customFields = data_customFields;
    if (data_customFields.length === {}) {
      return null;
    }
    // console.log("configList " + JSON.stringify(configList));
    const ui_gridItems = configList.map((configItem, index) => {
      if (configItem.fieldType == DataSetType.type_account_password && isEdit) {
        return (
          <>
            <GridItem xs={12} key={configItem.fieldKey}>
              <FormControlLabel
                control={
                  <SwitchUI
                    checked={isChangePassword || false}
                    onChange={
                      (event) => {
                        setIsChangePassword(event.target.checked);
                      }
                      // setData_hasDuration()
                    }
                    classes={{
                      switchBase: classes_switch.switchBase,
                      checked: classes_switch.switchChecked,
                      thumb: classes_switch.switchIcon,
                      track: classes_switch.switchBar,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                }}
                label={Loc.set_default_password}
              />
            </GridItem>
            {_renderUIComponentByType(customFields, configItem, index)}
          </>
        );
      } else {
        return _renderUIComponentByType(customFields, configItem, index);
      }
    });

    return (
      <CardBody>
        <form>
          <GridContainer>{ui_gridItems}</GridContainer>
        </form>
      </CardBody>
    );
  };

  function initCustomFields() {
    const configList = localizationState.config.createFieldList;
    var customFields = {};
    const targetDataToLoad = [];

    if (!isEdit) {
      if (HaveOtherDatasetCase()) {
        console.log("need preload collection");

        handle_selectFromOtherTable().then((result_list) => {
          console.log("result_list: " + JSON.stringify(result_list));
          configList.map((item) => {
            //set default value

            var value = null;
            if (item.fieldType === "bool") {
              value = false;
            } else if (item.fieldType === "number") {
              value = 0;
            } else if (item.fieldType === "date") {
              value = new Date();
            } else if (item.fieldType === "MC") {
              value = item.detailFieldList[0].key;
            } else if (item.fieldType === "dragdrop_upload") {
              value = [];
            } else if (
              item.fieldType ===
              DataSetType.type_select_from_other_dataset_single
            ) {
              var target_list = result_list[item.otherTableCollectionName];
              var data = {
                targetID: target_list[0].id,
                targetCollection: item.otherTableCollectionName,
              };
              value = data;
            } else if (
              item.fieldType === DataSetType.type_account_permission_role
            ) {
              var target_list = result_list["Role"];
              var data = {
                targetID: target_list[0].id,
                targetCollection: Role,
              };
              value = data;
            } else {
              value = item.mandatory ? value : "";
            }

            // if (item.fieldType != DataSetType.type_select_from_other_dataset_permission)
            // {
            customFields[item.fieldKey] = value;
            // }
            // customFieldList.push(customItem);
          });
          console.log("initCustomFields " + JSON.stringify(customFields));
          setData_customFields(customFields);
          process_UI_Layout(customFields);
        });
      } else {
        configList.map((item) => {
          //set default value

          var value = null;
          if (item.fieldType === "bool") {
            value = false;
          } else if (item.fieldType === "number") {
            value = 0;
          } else if (item.fieldType === "date") {
            value = new Date();
          } else if (item.fieldType === "MC") {
            value = item.detailFieldList[0].key;
          } else if (item.fieldType === "dragdrop_upload") {
            value = [];
          } else {
            value = item.mandatory ? value : "";
          }

          customFields[item.fieldKey] = value;
          // customFieldList.push(customItem);
        });
        console.log("initCustomFields 2 " + JSON.stringify(customFields));
        setData_customFields(customFields);
      }
    }
  }

  function initCustomInputStates() {
    const configList = localizationState.config.createFieldList;
    var customInputStates = {};
    configList.map((item) => {
      //set default value
      customInputStates[item.fieldKey] = false;
    });
    
    console.log("initCustomInputStates  " + JSON.stringify(customInputStates));

    setCustomInputStates(customInputStates);
  }

  function HaveOtherDatasetCase() {
    const configList = localizationState.config.createFieldList;

    var isExist = configList.find(
      (item) => item.fieldType === "select_from_other_dataset_single"
    );
    console.log("haveOtherDatasetCase " + JSON.stringify(isExist));
    return isExist !== undefined && isExist !== null;
  }

  function process_customerTableList(targetData) {
    if (targetData === null) {
      return [];
    }
    const m = targetData.filter((v) => v.isDeleted !== true);

    const newData = m
      .map((prop, key) => {
        const customer_id = prop.id;
        const code = prop.code;
        const itemName = prop.itemName;
        const phone = prop.phone === "" ? "/" : prop.phone;

        const isSelf = (customer_id === "");

        const data_tabContent =  [
          // no: key + 1,
          // id: key,
          // targetData: targetData,
          code,
          itemName,
          //   (
          //   <span>
          //     {/* <img src={prop.imageUrl} alt="..." /> */}
          //     {itemName}
          //   </span>
          //   ),
          // customer_id: customer_id,
          phone,

          // we've added some custom button actions
          <div className="actions-right">
            {/* use this button to add a edit kind of action */}
            <Button
              color="success"
              simple
              className={classes_tableBtn.actionButton}
              onClick={() => {
              
                do_selectCustomer(prop, []);

              }}
            >
              {/* {isSelf ? <ICON_SELECTED className={classes_tableBtn.icon} /> : <ICON_SELECT className={classes_tableBtn.icon} />} */}
            </Button>
          </div>,
        ];

        if (isSelf)
        {
          return {
            color: "success",
            data: data_tabContent
          }
        }
        else
        {
          return data_tabContent
        }

      });
    // console.log("newData: " + newData.length);
    // setCustomerData(newData);
    return newData;
  }

  const childRef = useRef();
  const mdRef = useRef();
  const mdRefs = useRef([]);
  const classes_tableBtn = useStyles_tableBtn();
  // need create dict first when
  function _renderUIComponentByType(_customFields, _configItem, _index) {
    if (_configItem !== null) {
      const fieldName = _configItem.fieldName;
      const fieldKey = _configItem.fieldKey;
      const type = _configItem.fieldType;
      const isMandatory = _configItem.mandatory;
      const isReadOnly = _configItem.isReadOnly;
      // const data = _customFieldList[index];
      const detailFieldList = _configItem.detailFieldList;

      var fieldNameList = []; //display name
      var fieldKeyList = [];

      detailFieldList.map((item) => {
        fieldNameList.push(item.value);
        fieldKeyList.push(item.key);
      });
      // console.log("_customFields" + JSON.stringify(_customFields));
      // console.log("_configItem" + JSON.stringify(_configItem));
      // console.log("isReadOnly" + JSON.stringify(isReadOnly));
      // const type = "string";
      if (
        type === DataSetType.type_string ||
        type === DataSetType.type_number ||type === DataSetType.type_timestamp
      ) {
        return (
          <GridContainer key={_index}>
            <GridItem
              xs={fieldKey == "hashCode" && isReadOnlyMode ? 4 : 12}
              key={fieldKey}
            >
              <CustomInput
                // success={requiredState === "success"}
                error={
                  customInputStates === null
                    ? false
                    : customInputStates[fieldKey]
                }
                labelText={`${fieldName}${isMandatory ? "*" : ""}${
                  isReadOnly ? "(" + Loc.isReadOnly + ")" : ""
                }`}
                id={_configItem.fieldKey} //{`${fieldName}_name`}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value:
                    _customFields[fieldKey] ||
                    (isReadOnly
                      ? null
                      : type === DataSetType.type_string
                      ? ""
                      : 0),
                  onChange: (event) => {
                    console.log(
                      "event.target.value  type: " + event.target.value
                    );
                    _customFields[fieldKey] =
                      type === DataSetType.type_string
                        ? event.target.value
                        : parseFloat(event.target.value);
                    customInputStates[fieldKey] = false;
                    setData_customFields(_customFields);
                    process_UI_Layout(_customFields);
                    // console.log(
                    //   `data_customFieldList data  ${JSON.stringify(
                    //     data_customFields
                    //   )}`
                    // );
                  },
                  type: type === DataSetType.type_string ? "text" : "number",
                  disabled: isReadOnly || isReadOnlyMode,
                }}
              />
            </GridItem>
            {fieldKey == "hashCode" &&
              isReadOnlyMode &&
              Config.targetFlags.includes(SystemType.POS) && (
                <GridItem xs={6}>
                  <Button
                    onClick={() => {
                      showInputQuantityAlert(
                        _customFields["hashCode"],
                        _customFields["product_name"],
                        _customFields["price"]
                      );
                    }}
                  >
                    <ICON_OUTPUT />
                    {"Barcode"}
                  </Button>
                </GridItem>
              )}
          </GridContainer>
        );
      } else if (type === DataSetType.type_multiline_text) {
        return (
          <GridItem xs={12} key={fieldKey}>
            <TextField
              // success={requiredState === "success"}
              error={
                customInputStates === null ? false : customInputStates[fieldKey]
              }
              labelText={`${fieldName}${isMandatory ? "*" : ""}`}
              id={_configItem.fieldKey} //{`${fieldName}_name`}
              multiline={true}
              rows={3}
              fullWidth={true}
              // formControlProps={{
              //   fullWidth: true,
              // }}
              inputProps={{
                value:
                  _customFields[fieldKey] ||
                  (type === DataSetType.type_multiline_text ? "" : 0),
                onChange: (event) => {
                  console.log(
                    "event.target.value  type: " + event.target.value
                  );
                  _customFields[fieldKey] =
                    type === DataSetType.type_multiline_text
                      ? event.target.value
                      : parseFloat(event.target.value);
                  customInputStates[fieldKey] = false;
                  setData_customFields(_customFields);
                  process_UI_Layout(_customFields);
                  // console.log(
                  //   `data_customFieldList data  ${JSON.stringify(
                  //     data_customFields
                  //   )}`
                  // );
                },
                type:
                  type === DataSetType.type_multiline_text ? "text" : "number",
                disabled: isReadOnly || isReadOnlyMode,
              }}
            />
          </GridItem>
        );
        // ===============================================================
        //
        //        This is the function you will be editing
        //
        // ===============================================================
      } else if (type === DataSetType.type_md_editor) {
        console.log("Render MD:  " + _customFields[fieldKey]);

        return (
          <GridItem xs={12} key={fieldKey}>
            {fieldName}
            <MDApp
              ref={(el) => {
                mdRefs.current[_index] = el;
              }}
              value={_customFields[fieldKey]}
              DidAcceptedInput={(input) => {
                _customFields[fieldKey] = input;
                mdRefs.current[_index].update_text(input);
                // setData_customFields({..._customFields});
                // process_UI_Layout({..._customFields});
                console.log("DidAcceptedInput:  " + input);
              }}
            />
          </GridItem>
        );
      } else if (type === DataSetType.type_dragdrop_upload) {
        return (
          <GridItem xs={12} key={fieldKey}>
            <br></br>
            <DragDropUpload
              ref={childRef}
              fileArray={filesArray}
              targetFolder={"AdminConsole/" + fieldKey}
              DidAcceptedFiles={(acceptedFiles) => {
                // console.log("DidAcceptedFiles");
                // console.log(`DidAcceptedFiles fileArray: ${JSON.stringify(_fileArray)}`)
                // console.log(`DidAcceptedFiles orgFileList: ${JSON.stringify(orgFileList)}`)

                setFilesArray(acceptedFiles);
              }}
              DidAcceptedImage={(acceptedImage) => {
                console.log("DidAcceptedImage: ", acceptedImage);
                const listKey = _configItem.targetListKey;
                let temp = [];
                if (_customFields[listKey]) {
                  temp = [..._customFields[listKey]].filter(
                    (data) => data != ""
                  );
                }
                let mergedArray = [...temp, ...acceptedImage];
                temp != [""]
                  ? (mergedArray = [...temp, ...acceptedImage])
                  : (mergedArray = acceptedImage);
                _customFields[listKey] = mergedArray;
                // console.log("_customFields[listKey]: ", _customFields[listKey]);

                customInputStates[fieldKey] = false;
                setData_customFields(_customFields);
                process_UI_Layout(_customFields);
              }}
            />
            <Button
              color="success"
              round
              onClick={() => childRef.current.submit_image(filesArray, [])}
              disabled={isReadOnlyMode}
            >
              Submit
            </Button>
          </GridItem>
        );
      } else if (type === DataSetType.type_date) {
        return (
          <GridItem xs={12} key={fieldKey}>
            <Datetime
              inputProps={{
                placeholder: `${Loc.date}${isMandatory ? "*" : ""}`,
                disabled: isReadOnly || isReadOnlyMode,
              }}
              timeFormat={true}
              defaultValue={_customFields[fieldKey]}
              value={
                _customFields[fieldKey] === null
                  ? new Date()
                  : moment(_customFields[fieldKey])
              }
              onChange={(m) => {
                if (moment.isMoment(m)) {
                  _customFields[fieldKey] = m.toDate();
                  // console.log(
                  //   `data_customFieldList data   ${JSON.stringify(
                  //     _customFields
                  //   )}`
                  // );
                  setData_customFields(_customFields);
                  process_UI_Layout(_customFields);
                } else {
                  // setFromDateState("error");
                }
              }}
            />
          </GridItem>
        );
      } else if (type === DataSetType.type_bool) {
        return (
          <GridItem xs={12} key={fieldKey}>
            <FormControlLabel
              control={
                <SwitchUI
                  checked={_customFields[fieldKey] || false}
                  onChange={
                    (event) => {
                      _customFields[fieldKey] = event.target.checked;
                      setData_customFields(_customFields);
                      process_UI_Layout(_customFields);
                      // console.log(
                      //   `data_customFieldList data   ${JSON.stringify(
                      //     _customFieldList
                      //   )}`
                      // );
                    }
                    // setData_hasDuration()
                  }
                  classes={{
                    switchBase: classes_switch.switchBase,
                    checked: classes_switch.switchChecked,
                    thumb: classes_switch.switchIcon,
                    track: classes_switch.switchBar,
                  }}
                />
              }
              classes={{
                label: classes.label,
              }}
              label={`${fieldName}${isMandatory ? "*" : ""}`}
              disabled={isReadOnly || isReadOnlyMode}
            />
          </GridItem>
        );
      } else if (type === DataSetType.type_MC) {
        //MC Case
        return (
          <GridItem xs={12} key={fieldKey}>
            {`${fieldName}${isMandatory ? "*" : ""}`}
            <CustomDropdown
              buttonProps={{
                round: true,
                color: "info",
                disabled: isReadOnly || isReadOnlyMode,
              }}
              buttonText={
                <span>
                  {getStringToShow(
                    fieldNameList,
                    detailFieldList,
                    _customFields[fieldKey] + ""
                  )}
                </span>
              }
              dropdownList={fieldNameList}
              itemIDList={fieldKeyList}
              onClick={(selectedID) => {
                if (selectedID === null) {
                  _customFields[fieldKey] = "";
                  setData_customFields(_customFields);
                } else {
                  _customFields[fieldKey] = selectedID;
                  setData_customFields(_customFields);
                  process_UI_Layout(_customFields);
                  // console.log(
                  //   `data_customFieldList data   ${JSON.stringify(
                  //     _customFields
                  //   )}`
                  // );
                }
              }}
              disabled={isReadOnly || isReadOnlyMode}
            />
          </GridItem>
        );
      } else if (type === DataSetType.type_account_phone) {
        return (
          <GridItem xs={12} key={fieldKey}>
            <CustomInput
              // success={requiredState === "success"}
              error={
                customInputStates === null ? false : customInputStates[fieldKey]
              }
              labelText={`${fieldName}${isMandatory ? "*" : ""}`}
              id={_configItem.fieldKey} //{`${fieldName}_name`}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: _customFields[fieldKey] || "",
                onChange: (event) => {
                  _customFields[fieldKey] = event.target.value;
                  customInputStates[fieldKey] = false;
                  setData_customFields(_customFields);
                  process_UI_Layout(_customFields);
                },
                type: "tel",
                disabled: isReadOnly || isReadOnlyMode,
              }}
            />
          </GridItem>
        );
      } else if (type === DataSetType.type_account_email) {
        return (
          <GridItem xs={12} key={fieldKey}>
            <CustomInput
              // success={requiredState === "success"}
              error={
                customInputStates === null ? false : customInputStates[fieldKey]
              }
              labelText={`${fieldName}${isMandatory ? "*" : ""}`}
              id={_configItem.fieldKey} //{`${fieldName}_name`}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: _customFields[fieldKey] || "",
                onChange: (event) => {
                  _customFields[fieldKey] = event.target.value;
                  customInputStates[fieldKey] = false;
                  setData_customFields(_customFields);
                  process_UI_Layout(_customFields);
                },
                type: "email",
                disabled: isReadOnly || isReadOnlyMode,
              }}
            />
          </GridItem>
        );
      } else if (
        type === DataSetType.type_account_password ||
        type === DataSetType.type_account_password_confirm
      ) {
        return isEdit && !isChangePassword ? null : (
          <GridItem xs={12} key={fieldKey}>
            <CustomInput
              // success={requiredState === "success"}
              error={
                customInputStates === null ? false : customInputStates[fieldKey]
              }
              labelText={`${fieldName}${isMandatory ? "*" : ""}`}
              id={_configItem.fieldKey} //{`${fieldName}_name`}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: _customFields[fieldKey] || "",
                onChange: (event) => {
                  _customFields[fieldKey] = event.target.value;
                  customInputStates[fieldKey] = false;
                  setData_customFields(_customFields);
                  process_UI_Layout(_customFields);
                },
                type: "password",
                disabled: isReadOnly || isReadOnlyMode,
              }}
            />
          </GridItem>
        );
        // }
      } else if (type === DataSetType.type_upload_image_single) {
        return (
          <GridItem xs={12} align="center" key={fieldKey}>
            <ImageUpload
              imageDidSelect={() => {
                set_isUploading(true);
              }}
              imageDidUpload={(pathInStorage) => {
                if (pathInStorage != null) {
                  _customFields[fieldKey] = pathInStorage;
                  customInputStates[fieldKey] = false;
                  setData_customFields(_customFields);
                  console.log("imageDidUpload called, path: ", pathInStorage);
                  set_isUploading(false);
                }
              }}
              imageUrl={_customFields[fieldKey] || ""}
              targetFolder={fieldKey}
              disabled={isReadOnly || isReadOnlyMode}
            />
          </GridItem>
        );
      } else if (type === DataSetType.type_select_from_other_dataset_single) {
        return (
          <>
          <GridItem xs={12} key={fieldKey}>
            <Card>
              <CardBody>
                <GridContainer>
                  <InputLabel
                    htmlFor="simple-select"
                    className={classes.selectLabel}
                  >
                    {fieldName}
                  </InputLabel>

                  {_render_cardBody_selectFromOther_single(
                    fieldName,
                    fieldKey,
                    _configItem.otherTableCollectionName,
                    _configItem.otherTableShowFieldName,
                    _customFields,
                    isReadOnly || isReadOnlyMode
                  )}
                </GridContainer>
              </CardBody>
            </Card>
            </GridItem>

          </>
        );
      } else if (type === DataSetType.type_account_permission_role) {
        return (
          <GridItem xs={12} key={fieldKey}>
            <Card>
              <CardBody>
                <GridContainer>
                  <InputLabel
                    htmlFor="simple-select"
                    className={classes.selectLabel}
                  >
                    {fieldName}
                  </InputLabel>

                  {_render_cardBody_selectRole(
                    fieldName,
                    fieldKey,
                    "Role",
                    "itemName",
                    _customFields,
                    isReadOnly || isReadOnlyMode
                  )}
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        );
      }

      // TODO: show only for super admin
      else if (
        type === DataSetType.type_select_from_other_dataset_permission &&
        props.location.state.config.adminInfo.permission_content == undefined &&
        props.location.state.config.adminInfo.permission_content == null
      ) {
        return (
          <GridItem xs={12} key={fieldKey}>
            <Card>
              <CardBody>
                <GridContainer>
                  <InputLabel
                    htmlFor="simple-select"
                    className={classes.selectLabel}
                  >
                    {fieldName}
                  </InputLabel>

                  {_render_cardBody_permission(
                    fieldName,
                    fieldKey,
                    _configItem.otherTableCollectionName,
                    _configItem.otherTableShowFieldName,
                    _customFields,
                    isReadOnly || isReadOnlyMode
                  )}
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        );
      } else if (type === DataSetType.type_select_from_other_dataset) {
        return (
          <GridItem xs={12} key={fieldKey}>
            <Card>
              <CardBody>
                <GridContainer>
                  <InputLabel
                    htmlFor="simple-select"
                    className={classes.selectLabel}
                  >
                    {fieldName}
                  </InputLabel>

                  {_render_cardBody_selectFromOther(
                    fieldName,
                    fieldKey,
                    _configItem.otherTableCollectionName,
                    _configItem.otherTableShowFieldName,
                    _customFields,
                    isReadOnly || isReadOnlyMode
                  )}
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        );
      } else if (type === DataSetType.type_list) {
        return (
          <GridItem xs={12} key={fieldKey}>
            <Card>
              <CardBody>
                <GridContainer>
                  <InputLabel
                    htmlFor="simple-select"
                    className={classes.selectLabel}
                  >
                    {fieldName}
                  </InputLabel>
                  {/* {_customFields[fieldKey] == "" &&  
                  (
                  <GridItem xs={12}>    

                    <Button
                    // aria-label="add"
                    color="success"
                  round
                    onClick={() => {
                      _customFields[fieldKey] = [""];
                      customInputStates[fieldKey] = false;
                      setData_customFields(_customFields);
                      process_UI_Layout(_customFields);


                      console.log("new_customFields:  "+JSON.stringify(_customFields));
                    }}
                  >
                    <ICON_ADD />
                  </Button>
          </GridItem>

                  )} */}
                  {_render_cardBody_list(fieldName, fieldKey, _customFields)}
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        );
      } else if (type === DataSetType.type_list_object) {
        // if (fieldKey.includes("meta_")) { return [] }

        return (
          <GridItem xs={12} key={fieldKey}>
            <Card>
              <CardBody>
                <GridContainer>
                  <InputLabel
                    htmlFor="simple-select"
                    className={classes.selectLabel}
                  >
                    {fieldName}
                  </InputLabel>
                  {_render_cardBody_object_list(
                    fieldName,
                    fieldKey,
                    _customFields,
                    detailFieldList
                  )}
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        );
      } else if (type === DataSetType.type_roster) {
        return (
          <RosterView
            staffID={"1zfv0DgBMJMyLviekuk0aprYRzN2"}
            staffName={"雯雯"}
            isEditMode={isEdit}
            isReadOnly={isReadOnlyMode}
            dataToEdit={_customFields}
            durationList={["30", "60"]}
            durationIdList={[30, 60]}
            rosterListCallback={(rosterList, date) => {
              console.log("rosterListCallback date:  " + date);
              _customFields["rosterList"] = rosterList;
              _customFields[fieldKey + "_selectedDate"] = date;
              _customFields["date_timestamp"] = date;

              //     customInputStates[fieldKey] = false;
              setData_customFields(_customFields);
              console.log("rosterList: " + JSON.stringify(rosterList));
            }}
          />
        );
      } else if (type === DataSetType.type_booking_system) {
        console.log(
          "type_booking_system _configItem " + JSON.stringify(_configItem)
        );
        var roster_look_up_key = _configItem["roster_look_up_key"];
        var target_look_up_key = _configItem["target_look_up_key"];
        var target_roster = _configItem["target_roster"];
        var target_id = _customFields[target_look_up_key];
        // console.log("type_booking_system _configItem roster_look_up_key " + roster_look_up_key + " target_look_up_key " + target_look_up_key + " target_roster " + target_roster);
        console.log("Booking System " + JSON.stringify(_customFields));
        var lookupObj = {
          roster_look_up_key: roster_look_up_key,
          target_look_up_key: target_look_up_key,
          target_roster: target_roster,
          target_booking: fieldKey,
          orderBy: fieldKey + "_selectedDate",
        };
        console.log("lookupObj " + JSON.stringify(lookupObj));

        return (
          <BookingDetail
            preloadCollectionList={["Staff", "data_roster"]}
            dataToEdit={_customFields}
            isEditMode={isEdit}
            isReadOnly={isReadOnlyMode}
            isComponent={true}
            lookupObj={lookupObj}
            targetMatchID={target_id}
            bookingDate={new Date()}
            showButton={false}
            backPathname={props.location.pathname}
            onChangeDate={(date) => {
              console.log("onChangeDate custom adddd " + date);
              var key = fieldKey + "_selectedDate";
              _customFields[key] = date;
              setData_customFields(_customFields);
            }}
            onPressChip={(data, previousRosterId, previousRosterDate) => {
              console.log("onPressChip " + JSON.stringify(data));
              if (data !== undefined && data !== null) {
                _customFields["roster"] = data;
                _customFields["previousRosterId"] = previousRosterId;
                _customFields["previousRosterDate"] = previousRosterDate;
                // _customFields[look_up_key] =
                // _customFields[look_up_val_key] =
                setData_customFields(_customFields);
              }
            }}
            // onPressSubmit={() => {
            //   // hideAlert();
            //   // set_isLoaded(false);
            // }}
          />
        );
      } else if (type === DataSetType.type_time_picker) {
        // console.log('type_time_picker ' + _customFields[fieldKey]);
        // console.log('type_time_picker Convert ' + moment(_customFields[fieldKey]).format("HH:mm A"));
        var displayTime = moment(_customFields[fieldKey]).format("HH:mm A");
        return  <GridItem xs={12}>
        <br />
        <InputLabel className={classes.label}>{fieldName}</InputLabel>
        <br />

        <Datetime
          dateFormat={false}
          defaultValue={displayTime}
          inputProps={{value:displayTime, placeholder: "Time Picker Here" }}
            onChange={(event) => {
              console.log("event: ", event);
            // moment(item.start_timestamp).format("HH:mm");
            var data_startTime = new Date(event).getTime();
            _customFields[fieldKey] = data_startTime;
            setData_customFields({..._customFields});

            // console.log("event: ", +":"+new Date(data_startTime).getMinutes());
            // setData_startTime(event);
          }}
        />
      </GridItem>
      }
    }
    return [];
  }

  function cardBody_list_addNew(currentList) {
    var newList = currentList;
    if (!newList || newList === null) {
      newList = [];
    }
    newList.push("");
    return newList;
  }

  function _render_cardBody_list(fieldName, fieldKey, _customFields) {
    var newList = _customFields[fieldKey];
    if (!newList || newList === null) {
      newList = cardBody_list_addNew(newList);
    }
    const body_selects = newList.map((item, key) => {
      const isLast = key + 1 === newList.length;
      const isOnlyOne = newList.length === 1;
      const currentValue = item && item.targetID ? item.targetID : "";
      return (
        <>
          <GridContainer>
            <Card>
              <GridItem xs={8} key={key}>
                <CustomInput
                  // success={requiredState === "success"}
                  // error={productNameState === "error"}
                  labelText={"Field Value" + "*"}
                  id="field_value"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: newList[key],
                    onChange: (event) => {
                      newList[key] = event.target.value;

                      var new_customFields = _customFields;
                      new_customFields[fieldKey] = newList;
                      setData_customFields(new_customFields);
                      process_UI_Layout(new_customFields);
                    },
                    type: "text",
                    disabled: isReadOnlyMode,
                  }}
                />
              </GridItem>

              <>
                <GridItem xs={0.5}>
                  <IconButton
                    aria-label="up"
                    color="default"
                    onClick={() => {
                      if (key !== 0) {
                        const targetItem = newList[key - 1];
                        newList[key - 1] = newList[key];
                        newList[key] = targetItem;
                      }

                      var new_customFields = _customFields;
                      new_customFields[fieldKey] = newList;
                      setData_customFields(new_customFields);
                      process_UI_Layout(new_customFields);
                    }}
                    disabled={isReadOnlyMode}
                  >
                    <ICON_UP />
                  </IconButton>
                </GridItem>

                <GridItem xs={0.5}>
                  <IconButton
                    aria-label="delete"
                    color="default"
                    onClick={() => {
                      if (!isOnlyOne) {
                        newList.splice(key, 1);
                      } else {
                        newList = [""];
                      }

                      var new_customFields = _customFields;
                      new_customFields[fieldKey] = newList;
                      setData_customFields(new_customFields);
                      process_UI_Layout(new_customFields);
                    }}
                    disabled={isReadOnlyMode}
                  >
                    <ICON_DELETE />
                  </IconButton>
                </GridItem>
              </>
            </Card>

            <GridItem xs={12}>
              {!isLast ? null : (
                <Button
                  // aria-label="add"
                  color="success"
                  round
                  onClick={() => {
                    newList = cardBody_list_addNew(newList);
                    var new_customFields = _customFields;
                    new_customFields[fieldKey] = newList;
                    setData_customFields(new_customFields);
                    process_UI_Layout(new_customFields);
                  }}
                  disabled={isReadOnlyMode}
                >
                  <ICON_ADD />
                </Button>
              )}
            </GridItem>
          </GridContainer>
        </>
      );
    });

    return body_selects;
  }

  function cardBody_object_list_addNew(currentList, _detailFieldList) {
    var newList = currentList;
    if (!newList || newList === null) {
      newList = [];
    }
    // console.log("cardBody_object_list_addNew typeList "+JSON.stringify(typeList));

    var data = {};
    _detailFieldList.map((item, index) => {
      const type = item.value; //value:string;
      if (type == "number") {
        data[item.key] = 0;
      } else {
        data[item.key] = "";
      }
    });
    data["uuid"] = uuidv4();
    // console.log("cardBody_object_list_addNew "+data);
    newList.push(data);
    return newList;
  }

  function cardBody_object_list_remove(index, currentList, _detailFieldList) {
    var newList = currentList;

    if (index > -1) {
      newList.splice(index, 1);
    }

    return newList;
  }

  function _render_cardBody_object_list(
    fieldName,
    fieldKey,
    _customFields,
    _detailFieldList
  ) {
    var newList = _customFields[fieldKey];
    if (!newList || newList === null) {
      // console.log("newList "+JSON.stringify(newList));
      newList = cardBody_object_list_addNew(newList, _detailFieldList);
    }
    var body_selects;
    if (newList.length > 0) {
      
    body_selects = newList.map((item, key) => {
      const isLast = key + 1 === newList.length;
      const isOnlyOne = newList.length === 1;
      const currentValue = item && item.targetID ? item.targetID : "";

      var list_data = newList[key];
      // dataSetCell.push(); // UUID
      const dataSetCell = _detailFieldList.map((item, index) => {
        const _type = item.value;

        // console.log("_render_cardBody_object_list  _type "+_type)
        if (_type == "bool") {
          return (
            <>
              <GridItem xs={4} key={index}>
                <FormControlLabel
                  control={
                    <SwitchUI
                      checked={list_data[item.key] == true ? true : false}
                      onChange={(event) => {
                        list_data[item.key] = event.target.checked;
                        var new_customFields = _customFields;
                        new_customFields[fieldKey] = newList;
                        customInputStates[fieldKey] = false;
                        setData_customFields(new_customFields);
                        process_UI_Layout(new_customFields);
                      }}
                      classes={{
                        switchBase: classes_switch.switchBase,
                        checked: classes_switch.switchChecked,
                        thumb: classes_switch.switchIcon,
                        track: classes_switch.switchBar,
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                  }}
                  label={item.key + "*"}
                />
              </GridItem>
            </>
          );
        } else {
          return (
            <>
              <GridItem xs={4} key={index}>
                <CustomInput
                  // success={requiredState === "success"}
                  // error={productNameState === "error"}
                  labelText={item.key + "*"}
                  id={item.key + "_value"}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: list_data[item.key],
                    onChange: (event) => {
                      // console.log("_render_cardBody_object_list " + JSON.stringify(list_data));
                      list_data[item.key] = event.target.value;

                      var new_customFields = _customFields;
                      new_customFields[fieldKey] = newList;
                      customInputStates[fieldKey] = false;
                      setData_customFields(new_customFields);
                      process_UI_Layout(new_customFields);
                    },
                    type: _type == "number" ? "number" : "text",
                    disabled: isReadOnlyMode,
                  }}
                />
              </GridItem>
            </>
          );
        }
      });

      return (
        <>
          <GridContainer>
            <Card>
              <CardBody>
                <>
                <GridItem xs={4} key={key}>
                <CustomInput
                  // success={requiredState === "success"}
                  // error={productNameState === "error"}
                  labelText={"UUID" + "*"}
                  id={"UUID"+ key + "_value"}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: list_data["uuid"],
                    onChange: (event) => {
                   
                    },
                    type: "text",
                    disabled: true,
                  }}
                />
              </GridItem>
                  {dataSetCell}
                  <Button
                    // aria-label="add"
                    color="danger"
                    round
                    onClick={() => {
                      console.log("key  "+key);
                      newList = cardBody_object_list_remove(
                        key,
                        newList,
                        _detailFieldList
                      );
                      // console.log(
                      //   "newList after cardBody_object_list_remove " +
                      //     JSON.stringify(newList)
                      // );
                      var new_customFields = _customFields;
                      new_customFields[fieldKey] = newList;
                      setData_customFields(new_customFields);
                      process_UI_Layout(new_customFields);
                    }}
                    disabled={isReadOnlyMode}
                  >
                    <ICON_DELETE />
                  </Button>
                </>
              </CardBody>
            </Card>
            <GridItem xs={12}>
              {!isLast ? null : (
                <Button
                  // aria-label="add"
                  color="success"
                  round
                  onClick={() => {
                    newList = cardBody_object_list_addNew(
                      newList,
                      _detailFieldList
                    );
                    var new_customFields = _customFields;
                    new_customFields[fieldKey] = newList;
                    setData_customFields(new_customFields);
                    process_UI_Layout(new_customFields);
                  }}
                  disabled={isReadOnlyMode}
                >
                  <ICON_ADD />
                </Button>
              )}
            </GridItem>
          </GridContainer>
        </>
      );
    });
    } else {
      body_selects = (<>
        <Button
                  // aria-label="add"
                  color="success"
                  round
                  onClick={() => {
                    newList = cardBody_object_list_addNew(
                      newList,
                      _detailFieldList
                    );
                    var new_customFields = _customFields;
                    new_customFields[fieldKey] = newList;
                    setData_customFields(new_customFields);
                    process_UI_Layout(new_customFields);
                  }}
                  disabled={isReadOnlyMode}
                >
                  <ICON_ADD />
                </Button>
      </>);
  }

    return body_selects;
  }

  function cardBody_selectFromOther_addNew(currentList, targetCollection) {
    var newList = currentList;
    if (!newList || newList === null) {
      newList = [];
    }
    const newData = {
      isRelatedField: true,
      targetID: "",
      targetCollection: "",
    };
    newList.push(newData);
    return newList;
  }

  function _render_cardBody_selectFromOther_single(
    fieldName,
    fieldKey,
    targetCollection,
    showFieldName,
    _customFields,
    isDisabled
  ) {

    var obj_fromOther_single = _customFields[fieldKey];
    if (!obj_fromOther_single || obj_fromOther_single === null) {
      obj_fromOther_single = {
        targetID: "",
        targetCollection: "",
      };
    }
    // console.log("obj_fromOther_single.targetID " + _customFields[fieldKey]);
    var body_selects = (
      <GridContainer>
        <GridItem xs={12}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <Select
              MenuProps={{
                className: classes.selectMenu,
              }}
              classes={{
                select: classes.select,
              }}
              value={obj_fromOther_single.targetID}
              onChange={(event) => {
                // console.log("_render_cardBody_selectFromOther_single onChange triggered");
                const selectedID = event.target.value;
                const selectedTargetData = {
                  targetID: selectedID,
                  targetCollection: targetCollection,
                };

                var new_customFields = _customFields;
                new_customFields[fieldKey] = selectedTargetData;
                new_customFields[fieldKey + "_val"] = selectedID;

                customInputStates[fieldKey] = false;
                setData_customFields(new_customFields);
                process_UI_Layout(new_customFields);
              }}
              disabled={isDisabled}
              inputProps={{
                name: fieldName,
                id: fieldKey,
              }}
            >
              {_render_targetTableDataMenuList(targetCollection, showFieldName)}
            </Select>
          </FormControl>
        </GridItem>
        
      </GridContainer>
    );

    return body_selects;
  }

  function _renderSearchBar() {

    const searchFieldList = [
      { itemName: Loc.code_old, key:["code"], id: "code", type: "text",isPreloadField:false },
      { itemName: Loc.customer_name, key: ["itemName"], id: "itemName", type: "text",isPreloadField:false },
      { itemName: Loc.customer_phone, key: ["phone"], id: "phone", type: "text",isPreloadField:false },
    ];

    // const searchFieldNameList = [];
    // const searchIDList = [];

    // searchFieldList.map((item) => {
    //   searchFieldNameList.push(item.itemName);
    //   searchIDList.push(item.id);
    // });

    return (
      <SearchBar
        searchFieldList={searchFieldList}
        // searchFieldNameList={searchFieldNameList}
        // searchIDList={searchIDList}
        apiPath={"admin/list-customer"}
        loadDataList={() => {
          // set_isLoading(true);
          getCustomerList();
        }}
        searchResultDataList={(dataList) => {
          const m = dataList.filter((v) => v.isDeleted !== true);
          console.log(`m ${JSON.stringify(m)}`)
          if (m.length === 1)
          {
            const prop = Array.from(m)[0];
            
            
            do_selectCustomer(prop, []);
            
          }
          setData_dataList(dataList);
        }}
        searchResultDataCount={(count) => {
          setData_dataListCount(count);
        }}
        // didSelectedID={(id) => {
        //   setData_searchID(id);
        // }}
        showDelete={false}
        // locationState={locationState}
      />
    );
  }

  function _render_cardBody_selectRole(
    fieldName,
    fieldKey,
    targetCollection,
    showFieldName,
    _customFields,
    isDisabled
  ) {
    var roleID = "";
    // var obj_fromOther_single = _customFields[fieldKey];
    if (
      _customFields[fieldKey] != undefined ||
      _customFields[fieldKey] === null
    ) {
      roleID = _customFields[fieldKey];
    }
    // console.log("obj_fromOther_single.targetID " + _customFields[fieldKey]);
    var body_selects = (
      <GridContainer>
        <GridItem xs={12}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <Select
              MenuProps={{
                className: classes.selectMenu,
              }}
              classes={{
                select: classes.select,
              }}
              value={roleID}
              onChange={(event) => {
                // console.log("_render_cardBody_selectFromOther_single onChange triggered");
                const selectedID = event.target.value;
                // const selectedTargetData = {
                //   targetID: selectedID,
                //   targetCollection: targetCollection,
                // };

                var new_customFields = _customFields;
                new_customFields[fieldKey] = selectedID;
                customInputStates[fieldKey] = false;
                setData_customFields(new_customFields);
                process_UI_Layout(new_customFields);
              }}
              disabled={isDisabled}
              inputProps={{
                name: fieldName,
                id: fieldKey,
              }}
            >
              {_render_targetTableDataMenuList(targetCollection, showFieldName)}
            </Select>
          </FormControl>
        </GridItem>
      </GridContainer>
    );

    return body_selects;
  }

  function _render_cardBody_permission(
    fieldName,
    fieldKey,
    targetCollection,
    showFieldName,
    _customFields,
    isDisabled
  ) {
    if (
      _customFields[fieldKey] == undefined ||
      _customFields[fieldKey] === null
    ) {
      _customFields[fieldKey] = {
        targetID: "",
        targetCollection: "",
      };
    }
    // console.log("isReadOnlyMode: " + isReadOnlyMode);
    var body_selects = (
      <GridContainer>
        <GridItem xs={12}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <Select
              MenuProps={{
                className: classes.selectMenu,
              }}
              classes={{
                select: classes.select,
              }}
              value={
                _customFields[fieldKey] != null
                  ? _customFields[fieldKey].targetID
                  : "-"
              }
              onChange={(event) => {
                console.log("_render_cardBody_permission onChange triggered");

                const selectedID = event.target.value;
                const selectedTargetData = {
                  targetID: selectedID,
                  targetCollection: targetCollection,
                };

                var new_customFields = _customFields;
                new_customFields[fieldKey] = selectedTargetData;

                console.log(
                  `new_customFields: ${JSON.stringify(new_customFields)}`
                );
                customInputStates[fieldKey] = false;
                setData_customFields(new_customFields);
                process_UI_Layout(new_customFields);
              }}
              disabled={isDisabled}
              inputProps={{
                name: fieldName,
                id: fieldKey,
              }}
            >
              {_render_targetTableDataMenuList(targetCollection, showFieldName)}
            </Select>
          </FormControl>
        </GridItem>
      </GridContainer>
    );

    return body_selects;
  }

  function _render_cardBody_selectFromOther(
    fieldName,
    fieldKey,
    targetCollection,
    showFieldName,
    _customFields,
    isDisabled
  ) {
    var newList = _customFields[fieldKey];
    if (!newList || newList === null) {
      newList = cardBody_selectFromOther_addNew(newList, targetCollection);
    }
    const body_selects = newList.map((item, key) => {
      const isLast = key + 1 === newList.length;
      const isOnlyOne = newList.length === 1;
      const currentValue = item && item.targetID ? item.targetID : "";
      return (
        <GridContainer>
          <GridItem xs={8} key={key}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={currentValue}
                onChange={(event) => {
                  const selectedID = event.target.value;
                  const selectedTargetData = {
                    isRelatedField: true,
                    targetID: selectedID,
                    targetCollection: targetCollection,
                  };

                  newList[key] = selectedTargetData;

                  var new_customFields = _customFields;
                  new_customFields[fieldKey] = newList;
                  customInputStates[fieldKey] = false;
                  setData_customFields(new_customFields);
                  process_UI_Layout(new_customFields);
                }}
                disabled={isDisabled}
                inputProps={{
                  name: fieldName,
                  id: fieldKey,
                }}
              >
                {_render_targetTableDataMenuList(
                  targetCollection,
                  showFieldName
                )}
              </Select>
            </FormControl>
          </GridItem>

          <GridItem xs={0.5}>
            <IconButton
              aria-label="up"
              color="default"
              onClick={() => {
                if (key !== 0) {
                  const targetItem = newList[key - 1];
                  newList[key - 1] = newList[key];
                  newList[key] = targetItem;
                }

                var new_customFields = _customFields;
                new_customFields[fieldKey] = newList;
                setData_customFields(new_customFields);
                process_UI_Layout(new_customFields);
              }}
            >
              <ICON_UP />
            </IconButton>
          </GridItem>

          <GridItem xs={0.5}>
            <IconButton
              aria-label="delete"
              color="default"
              onClick={() => {
                if (!isOnlyOne) {
                  newList.splice(key, 1);
                } else {
                  newList[key].targetID = "";
                  newList[key].targetCollection = "";
                }

                var new_customFields = _customFields;
                new_customFields[fieldKey] = newList;
                setData_customFields(new_customFields);
                process_UI_Layout(new_customFields);
              }}
            >
              <ICON_DELETE />
            </IconButton>
          </GridItem>

          <GridItem xs={0.5}>
            {!isLast ? null : (
              <IconButton
                aria-label="add"
                color="default"
                onClick={() => {
                  newList = cardBody_selectFromOther_addNew(
                    newList,
                    targetCollection
                  );
                  var new_customFields = _customFields;
                  new_customFields[fieldKey] = newList;
                  setData_customFields(new_customFields);
                  process_UI_Layout(new_customFields);
                }}
              >
                <ICON_ADD />
              </IconButton>
            )}
          </GridItem>
        </GridContainer>
      );
    });

    return body_selects;
  }

  function _render_targetTableDataMenuList(targetCollection, showFieldKey) {
    if (
      data_otherTableData[targetCollection] &&
      data_otherTableData[targetCollection] !== null
    ) {
      const menuItems = [];
      data_otherTableData[targetCollection].map((item, key) => {
        menuItems.push(
          <MenuItem
            key={key}
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected,
            }}
            value={item.id}
          >
            {item[showFieldKey]}
          </MenuItem>
        );
      });

      return menuItems;
    } else {
      return null;
    }
  }

  function getCustomizedTableList(list_targetCollection, list_result) {
    var targetCollectionList = list_targetCollection;
    var newData = list_result;
    const targetCollection = targetCollectionList[0];
    return new Promise((onDone) => {
      const body = {
        data_targetCollection: targetCollection,
      };

      fetchAPI.do_fetch("post", "admin/list-all", body).then(
        (res) => {
          console.log("success: ", res.data);
          // return res.data.data_list;
          newData[targetCollection] = res.data.data_list.filter(
            (v) => v.isDeleted != true
          );

          targetCollectionList.splice(0, 1);
          if (targetCollectionList.length === 0) {
            // console.log("get custom tavle list "+JSON.stringify(newData));
            onDone(newData);
          } else {
            getCustomizedTableList(targetCollectionList, newData).then(
              (finalOnHoldList) => {
                onDone(finalOnHoldList);
              }
            );
          }
        },
        (error) => {
          console.log("failed: ", error);

          onDone(null);
        }
      );
    });
  }

  const showuploading = () => {
    if (isUploading) {
      return (
        <SweetAlert
          style={{ display: "block", marginTop: "-100px" }}
          closeOnClickOutside={false}
          title={Loc.loading}
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnText={Loc.confirm}
          confirmBtnCssClass={
            classes_alert.button + " " + classes_alert.success
          }
          btnSize="lg"
          showConfirm={false}
          showCancel={false}
        >
          {Loc.uploading}
        </SweetAlert>
      );
    } else {
      return null;
    }
  };

  const getStringToShow = (
    fieldNameList,
    detailFieldList,
    selectedID,
    isMandatory
  ) => {
    var strToShow = "";
    strToShow = fieldNameList[0];
    // console.log(`selectedID getStringToShow ${selectedID}` )
    detailFieldList.map((prop) => {
      if (prop.key === selectedID) {
        strToShow = prop.value;
      }
    });

    return strToShow;
  };

  function print_barcode(qty, hashcode, product_name, product_price) {
    const body = {
      hashCode: hashcode,
      product_name: product_name,
      product_price: product_price,
    };

    fetchAPI
      .do_fetch_local("post", "/get_printer", {}, "30001")
      .then((result) => {
        const printer = result.data.printer;
        fetchAPI
          .do_fetch("post", "admin/print_barcode", {
            ...body,
            printer: printer,
          })
          .then(
            (result) => {
              const printer_buff = result.data.printer_buff;

              const print_job_body = {
                printer_buff: printer_buff,
                isBarcode: true,
              };

              for (var i = 0; i < qty; i++) {
                fetchAPI
                  .do_fetch_local("post", "/printJob", print_job_body, "30001")
                  .then((result) => {
                    console.log("printer: " + JSON.stringify(result));
                  });
              }
              // fetchAPI
              //   .do_fetch_local(
              //     "post",
              //     "/printJob",
              //     print_job_body
              //   )
              //   .then((result) => {
              //     console.log(
              //       "printer: " + JSON.stringify(result)
              //     );
              //   });
            },
            (error) => {}
          );
      });
  }

  function showInputQuantityAlert(hashCode, product_name, product_price) {
    setSaveAlert(
      <SweetAlert
        input
        inputType="number"
        style={{ display: "block", marginTop: "-100px" }}
        title={"Print Qty"}
        onConfirm={(e) => {
          // inputConfirmAlertNext(e);
          console.log("e: ", JSON.stringify(e));
          //Add the product to cart list->
          var inupt_qty = e;
          if (inupt_qty <= 0) {
            inupt_qty = 1; //force correct to 1
          }
          print_barcode(inupt_qty, hashCode, product_name, product_price);

          hideAlert();
        }}
        //   onCancel={() => hideAlert()}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        // cancelBtnCssClass={classes.button + " " + classes.danger}
      ></SweetAlert>
    );
  }

  const classes = useStyles();
  const classes_alert = useStyles_alert();

  function handle_viewWidth() {
    if (
      localizationState != null &&
      localizationState.config != null &&
      localizationState.config.viewWidth != null
    ) {
      return localizationState.config.viewWidth;
    } else {
      return 12;
    }
  }

  return (
    <div>
      {check_redirect()}
      {showuploading()}
      {alert_save}
      <GridContainer>
        <GridItem xs={handle_viewWidth()}>
          <Card>
            <CardHeader color="rose" text>
              <CardText color="rose">
                <h4 className={classes.cardTitle}>{Loc.basic_info}</h4>
              </CardText>
            </CardHeader>

            {_renderUILayout()}

            <CardFooter className={classes.justifyContentCenter}>
              <GridContainer
                spacing={2}
                direction="row"
                justify="center"
                alignItems="center"
              >
                <GridItem xs={6} align="center">
                  <Button onClick={backClicked}>
                    <ICON_BACK />
                    {Loc.back}
                  </Button>
                </GridItem>

                {!isReadOnlyMode && (
                  <GridItem xs={6} align="center">
                    <Button
                      color="rose"
                      onClick={doneClicked}
                      disabled={fetchingStatus === "fetching" ? true : false}
                    >
                      <ICON_DONE />
                      {isEdit ? Loc.save : Loc.create}
                    </Button>
                  </GridItem>
                )}
              </GridContainer>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
