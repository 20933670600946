/*eslint-disable*/
import React from "react";
import { Route, Redirect } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";

// material ui icons
import MailOutline from "@material-ui/icons/MailOutline";
import Contacts from "@material-ui/icons/Contacts";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Dropdown from "views/CommonUseComponents/Dropdown.js";
import Switch from "@material-ui/core/Switch";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import styles_alert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styles_switch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";

import PictureUpload from "components/CustomUpload/PictureUpload.js";

import ICON_BACK from "@material-ui/icons/ArrowBackIos";
import ICON_DONE from "@material-ui/icons/Done";

import * as RoutesHelper from "Helper/RoutesHelper";

const useStyles = makeStyles(styles);
const useStyles_alert = makeStyles(styles_alert);
const useStyles_switch = makeStyles(styles_switch);

import SweetAlert from "react-bootstrap-sweetalert";

import Loc from "localization";
import fetchAPI from "connectionHandler/FetchAPI.js";
import firebase from "connectionHandler/firebase";
import Config from "itchi_config";
import { SystemType } from "Util/SystemType.js";

export default function Admin_add(props) {
  const [isEdit, setIsEdit] = React.useState(false);
  const [isChangePassword, setIsChangePassword] = React.useState(false);

  const [data_id, setData_id] = React.useState("");
  const [data_name, setData_itemName] = React.useState("");
  const [data_email, setData_email] = React.useState("");
  const [data_password, setData_password] = React.useState("");
  const [data_confirmPassword, setData_confirmPassword] = React.useState("");

  const [data_adminLevel, setData_adminLevel] = React.useState("");
  const [data_adminLevelName, setData_adminLevelName] = React.useState("");
  const [data_adminLevelList, setData_adminLevelList] = React.useState(null);
  const [data_storeID, setData_storeID] = React.useState("");
  const [data_storeName, setData_storeName] = React.useState("");
  const [data_permission_content, setData_permission_content] = React.useState(
    ""
  );

  const [pathToRedirect, setRedirect] = React.useState("");

  const [adminNameState, setAdminNameState] = React.useState("");
  const [adminEmailState, setAdminEmailState] = React.useState("");
  const [adminPasswordState, setAdminPasswordState] = React.useState("");
  const [
    adminConfirmPasswrodState,
    setAdminConfirmPasswordState,
  ] = React.useState("");
  const [data_dataListArray, setData_dataListArray] = React.useState(null);

  const [isPreLoadedData, setIsPreLoadedData] = React.useState(false);
  const [fetchingStatus, setFetchingStatus] = React.useState("");
  const [historyState, setHistoryState] = React.useState(null);
  const [isERP, setIsERP] = React.useState(false);
  const [isPOS, setIsPOS] = React.useState(false);
  const [hasPermissionContent, set_hasPermissionContent] = React.useState(false);
  

  const classes = useStyles();
  const classes_alert = useStyles_alert();
  const classes_switch = useStyles_switch();

  // alert
  const [alert_save, setSaveAlert] = React.useState(null);
  const hideAlert = () => {
    setSaveAlert(null);
  };

  React.useEffect(() => {
    var promise_preLoad = null;
    setHistoryState(props.history.location.state);
    if (!isPreLoadedData) {
      setIsPreLoadedData(true);
      promise_preLoad = do_preLoadData().then((isLoaded) => {
        if (isLoaded) {
        }
      });
    }
    var preloadListStr = localStorage.getItem("preloadDataList");
    var preloadList = JSON.parse(preloadListStr);
    console.log("preloadList  " + preloadListStr);
    if (data_dataListArray === null) {
      // const stateConfig = locationState.config;
      // if(preloadList["isERP"]){
      var isERP = Config.targetFlags.includes(SystemType.ERP); //preloadList != null
      var isPOS = Config.targetFlags.includes(SystemType.POS);
      setIsPOS(isPOS);
      setIsERP(isERP);
      // }
      if (isERP) {
        if (preloadList["Store"].length > 0) {
          setData_storeID(preloadList["Store"][0].id);
          setData_storeName(preloadList["Store"][0].itemName);
        }
      }

      setData_dataListArray(preloadList);
    }

    // pre load data for edit
    if (props.location.state.data != null) {
      var dataToEdit = props.location.state.data;
      setIsEdit(true);
      setData_id(dataToEdit.id);
      setData_itemName(dataToEdit.itemName);
      setData_email(dataToEdit.email);
      setData_adminLevel(dataToEdit.admin_level);
      setData_adminLevelName(dataToEdit.admin_level_name);
      var permission_content = dataToEdit.permission_content;
      console.log("permission_content: " + JSON.stringify(permission_content));
      if (permission_content != undefined)
      {
        setData_permission_content(permission_content.targetID);
      }
      else
      {
        setData_permission_content("");
      }
      if (isERP) {
        setData_storeName(dataToEdit.storeName);
        setData_storeID(dataToEdit.storeID);
      }
    }
    else
    {
      if (props.location.state.config.adminInfo.permission_content != null)
      {
        set_hasPermissionContent(true);
        setData_permission_content(props.location.state.config.adminInfo.permission_content.targetID)
      }
      else
      {
        setData_permission_content(null);
      }
    }
    // Specify how to clean up after this effect:
    return function cleanup() {
      promise_preLoad = null;
    };
  }, [data_dataListArray, isERP]);

  const validCheck = () => {
    var result = true;
    if (data_name === "") {
      setAdminNameState("error");
      showAlert(false, Loc.failed, Loc.data_save_failed);
      result = false;
    } else if (data_email === "") {
      setAdminEmailState("error");
      showAlert(false, Loc.failed, Loc.data_save_failed);
      result = false;
    }
    if (!isEdit || isChangePassword) {
      if (data_password === "") {
        setAdminPasswordState("error");
        showAlert(false, Loc.failed, Loc.data_save_failed);
        result = false;
      } else if (data_confirmPassword === "") {
        setAdminConfirmPasswordState("error");
        showAlert(false, Loc.failed, Loc.data_save_failed);
        result = false;
      } else if (data_password !== data_confirmPassword) {
        // setAdminPasswordState("error");
        // setAdminConfirmPasswordState("error");
        showAlert(false, Loc.failed, Loc.errorMsg_incorrectPassword);
        result = false;
      }
    }
    return result;
  };

  function do_preLoadData() {
    return new Promise((onDone) => {
      const body = {
        data_targetCollection: "AdminLevel",
      };
      fetchAPI.do_fetch("post", "admin/list-all", body).then(
        (res) => {
          console.log("success: ", res.data);
          setData_adminLevelList(res.data.data_list);
          onDone(true);
        },
        (error) => {
          console.log("failed: ", error);
          showAlert(false, Loc.failed, "Load admin level faild");
          onDone(false);
        }
      );
    });
  }

  const getStringToShow = (defaultStr, fieldList, fieldName, id) => {
    console.log(`getStringToShow ${fieldName} ===>${id}`);
    var strToShow = defaultStr;

    if (fieldList.length > 0) {
      const fieldNameList = getDropDownNameList(fieldName);
      strToShow = fieldNameList[0];
      fieldList.map((prop, key) => {
        if (prop.id === id) {
          strToShow = prop.itemName;
        }
      });
    }
    return strToShow;
  };

  const getDropDownNameList = (fieldName) => {
    const fieldList =
      data_dataListArray == null ? [] : data_dataListArray[fieldName];
    const fieldNameList = [];
    if (fieldList.length > 0) {
      fieldList.map((item) => {
        fieldNameList.push(item.itemName);
      });
    }
    return fieldNameList;
  };

  const getSelectMenuItem = (fieldName) => {
    const fieldList =
      data_dataListArray == null ? [] : data_dataListArray[fieldName];
    const menuItems = [];

    // console.log(`getSelectMenuItem   `+ JSON.stringify(data_dataListArray))

    if (fieldList && fieldList.length > 0) {
      fieldList.map((item, key) => {
        menuItems.push(
          <MenuItem
            key={key}
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected,
            }}
            value={item.id}
          >
            {item.itemName}
          </MenuItem>
        );
      });
    }

    return menuItems;
  };

  const backClicked = () => {
    setRedirect("/admin/admin-list");
  };

  const doneClicked = () => {
    setFetchingStatus("fetching");
    if (validCheck()) {
      const idToPass = isEdit ? data_id : null;
      console.log("idToPass: ", idToPass);
      var body = {
        id: idToPass,
        content: {
          itemName: data_name,
          email: data_email,
          password: data_password,
          admin_level: data_adminLevel,
          admin_level_name: data_adminLevelName,
          permission_content: {targetID:data_permission_content},
        },
      };
      if (isERP) {
        body.content.storeName = data_storeName;
        body.content.storeID = data_storeID;
      }

      fetchAPI.do_fetch("post", "admin/create-admin", body).then(
        (res) => {
          console.log("success: ", res);
          setFetchingStatus("");
          showAlert(true, Loc.success, Loc.data_save_success);
        },
        (error) => {
          console.log("failed: ", error);
          setFetchingStatus("");
          showAlert(false, Loc.failed, Loc.data_save_failed);
        }
      );
    } else {
      setFetchingStatus("");
    }
  };

  function showAlert(issuccess, title, content) {
    setSaveAlert(
      <SweetAlert
        error={!issuccess}
        success={issuccess}
        style={{ display: "block", marginTop: "-100px" }}
        title={title}
        closeOnClickOutside={false}
        onConfirm={() => {
          if (issuccess) {
            // hideAlert()
            setRedirect("/admin/admin-list");
          } else {
            hideAlert();
          }
        }}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        cancelBtnCssClass={classes_alert.button + " " + classes_alert.info}
        onCancel={() => {
          if (issuccess) {
            setData_itemName("");
            setData_email("");
            setData_adminLevel("");
            setData_adminLevelName("");
            setData_password("");
            setData_confirmPassword("");
            setData_storeID("");
            setData_storeName("");
            hideAlert();
          } else {
            hideAlert();
          }
        }}
        confirmBtnText={issuccess ? Loc.done : Loc.confirm}
        cancelBtnText={Loc.continue}
        btnSize="lg"
        showCancel={issuccess && !isEdit}
      >
        {content}
      </SweetAlert>
    );
  }

  const handleChange_gender = (event) => {
    setData_gender(event.target.value);
  };

  const check_redirect = () => {
    if (pathToRedirect != "") {
      return (
        <Redirect
          to={{
            pathname: pathToRedirect,
            state: historyState.config,
          }}
        />
      );
    } else {
      return null;
    }
  };

  const showHide_changePasswordPart = () => {
    if (isEdit) {
      return (
        <GridItem xs={12}>
          <GridContainer>
            <GridItem xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={isChangePassword}
                    onChange={(event) =>
                      setIsChangePassword(event.target.checked)
                    }
                    // value="checkedA"
                    classes={{
                      switchBase: classes_switch.switchBase,
                      checked: classes_switch.switchChecked,
                      thumb: classes_switch.switchIcon,
                      track: classes_switch.switchBar,
                    }}
                  />
                }
                classes={{
                  label: classes_switch.label,
                }}
                label={Loc.set_default_password}
              />
            </GridItem>

            {!isChangePassword ? null : (
              <GridItem xs={12}>
                <GridContainer>
                  <GridItem xs={12}>
                    <CustomInput
                      error={adminPasswordState === "error"}
                      labelText={Loc.admin_default_password + "*"}
                      id="admin_default_password"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: data_password,
                        onChange: (event) => {
                          setAdminPasswordState("success");
                          setData_password(event.target.value);
                        },
                        type: "password",
                      }}
                    />
                  </GridItem>

                  <GridItem xs={12}>
                    <CustomInput
                      error={adminConfirmPasswrodState === "error"}
                      labelText={Loc.admin_default_password_confirm + "*"}
                      id="admin_default_password_confirm"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: data_confirmPassword,
                        onChange: (event) => {
                          setAdminConfirmPasswordState("success");
                          setData_confirmPassword(event.target.value);
                        },
                        type: "password",
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
            )}
          </GridContainer>
        </GridItem>
      );
    } else {
      return (
        <GridItem xs={12}>
          <GridContainer>
            <GridItem xs={12}>
              <CustomInput
                error={adminPasswordState === "error"}
                labelText={Loc.admin_default_password + "*"}
                id="admin_default_password"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: data_password,
                  onChange: (event) => {
                    setAdminPasswordState("success");
                    setData_password(event.target.value);
                  },
                  type: "password",
                }}
              />
            </GridItem>

            <GridItem xs={12}>
              <CustomInput
                error={adminConfirmPasswrodState === "error"}
                labelText={Loc.admin_default_password_confirm + "*"}
                id="admin_default_password_confirm"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: data_confirmPassword,
                  onChange: (event) => {
                    setAdminConfirmPasswordState("success");
                    setData_confirmPassword(event.target.value);
                  },
                  type: "password",
                }}
              />
            </GridItem>
          </GridContainer>
        </GridItem>
      );
    }
  };

  const render_erp_selectStore = () => {
    if (isERP) {
      console.log("isERP!!!!!!!!!!");
      return (
        <GridItem xs={12}>
          <FormControl
            fullWidth
            className={
              classes.selectFormControl + " " + classes.formControlMargins
            }
          >
            <Select
              MenuProps={{
                className: classes.selectMenu,
              }}
              classes={{
                select: classes.select,
              }}
              value={data_storeID}
              onChange={(event) => {
                const selectedID = event.target.value;
                setData_storeName(
                  getStringToShow(
                    Loc.store_default,
                    data_dataListArray == null
                      ? []
                      : data_dataListArray["Store"],
                    "Store",
                    selectedID
                  )
                );
                setData_storeID(selectedID);
                console.log("selectedID  " + selectedID);
                console.log("setData_storeName  " + data_storeName);
              }}
              inputProps={{
                name: data_storeName,
                id: data_storeID,
              }}
            >
              {getSelectMenuItem("Store")}
            </Select>
          </FormControl>
        </GridItem>
      );
    } else {
      return null;
    }
  };

  return (
    <div>
      {check_redirect()}
      {alert_save}
      <GridContainer>
        <GridItem xs={7} sm={7} md={7}>
          <Card>
            <CardHeader color="rose" text>
              <CardText color="rose">
                <h4 className={classes.cardTitle}>{Loc.basic_info}</h4>
              </CardText>
            </CardHeader>
            <CardBody>
              <form>
                <GridContainer>
                  <GridItem xs={12}>
                    <small>{Loc.select_admin_level}</small>
                    <Dropdown
                      currentID={data_adminLevel}
                      listData={
                        data_adminLevelList === null ? [] : data_adminLevelList
                      }
                      defaultSelectionString={Loc.select_admin_level}
                      didSelectItem={(selectedItem) => {
                        if (selectedItem === null) {
                          setData_adminLevel("");
                          setData_adminLevelName("");
                        } else {
                          const selectedID = selectedItem.id;
                          const adminLeveName = selectedItem.itemName;
                          setData_adminLevel(selectedID);
                          setData_adminLevelName(adminLeveName);
                        }
                        console.log("selectedItem: ", selectedItem);
                      }}
                    />
                  </GridItem>
                  {render_erp_selectStore()}

                  <GridItem xs={12}>
                    <CustomInput
                      // success={requiredState === "success"}
                      error={adminNameState === "error"}
                      labelText={Loc.admin_name + "*"}
                      id="admin_name"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: data_name,
                        onChange: (event) => {
                          setAdminNameState("success");
                          setData_itemName(event.target.value);
                        },
                        type: "text",
                      }}
                    />
                  </GridItem>

                  <GridItem xs={12}>
                    <CustomInput
                      error={adminEmailState === "error"}
                      labelText={Loc.admin_email + "*"}
                      id="admin_email"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: data_email,
                        onChange: (event) => {
                          setAdminEmailState("success");
                          setData_email(event.target.value);
                        },
                        type: "email",
                      }}
                    />
                  </GridItem>

                  {showHide_changePasswordPart()}
                  <GridItem xs={12}>
                    {hasPermissionContent ? null : (
                    <CustomInput
                      // success={requiredState === "success"}

                      labelText={`Permission content`}
                      id={"pc"} //{`${fieldName}_name`}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: data_permission_content,
                        onChange: (event) => {
                          setData_permission_content(event.target.value);
                        },
                        type: "text",
                        // disabled:{hasPermissionContent}
                      }}
                    />
                    )}
                  </GridItem>
                </GridContainer>
              </form>
            </CardBody>
            <CardFooter className={classes.justifyContentCenter}>
              <GridContainer
                spacing={2}
                direction="row"
                justify="center"
                alignItems="center"
              >
                <GridItem xs={6} align="center">
                  <Button onClick={backClicked}>
                    <ICON_BACK />
                    {Loc.back}
                  </Button>
                </GridItem>

                <GridItem xs={6} align="center">
                  <Button
                    color="rose"
                    onClick={doneClicked}
                    disabled={fetchingStatus === "fetching" ? true : false}
                  >
                    <ICON_DONE />
                    {isEdit ? Loc.save : Loc.create}
                  </Button>
                </GridItem>
              </GridContainer>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
