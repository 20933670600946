/*eslint-disable*/
import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";

import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import InputLabel from "@material-ui/core/InputLabel";
import Datetime from "react-datetime";
import FormControl from "@material-ui/core/FormControl";
import moment from "moment";
import StoreDropdown from "views/CommonUseComponents/StoreDropdown.js";
import Dropdown from "views/CommonUseComponents/Dropdown.js";
import TagsInput from "react-tagsinput";
import SearchBar from "views/CommonUseComponents/SearchBar.js";
import DataSetType from "Data_set_type";

// material ui icons
import MailOutline from "@material-ui/icons/MailOutline";
import Contacts from "@material-ui/icons/Contacts";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import SwitchUI from "@material-ui/core/Switch";
import ICON_ADD from "@material-ui/icons/Add";
import Dvr from "@material-ui/icons/Edit";
import CalendarToday from "@material-ui/icons/CalendarToday";
import Refresh from "@material-ui/icons/Refresh";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import PagingTable from "views/CommonUseComponents/PagingTable.js";

import NavPills from "components/NavPills/NavPills.js";
import Table from "components/Table/Table.js";

// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import styles_alert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styles_switch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import ImageUpload from "components/CustomUpload/ImageUpload.js";
import styles_tableBtn from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

import ICON_BACK from "@material-ui/icons/ArrowBackIos";
import ICON_DONE from "@material-ui/icons/Done";
import Visibility from "@material-ui/icons/Visibility";

const useStyles = makeStyles(styles);
const useStyles_alert = makeStyles(styles_alert);
const useStyles_switch = makeStyles(styles_switch);
const useStyles_tableBtn = makeStyles(styles_tableBtn);

import SweetAlert from "react-bootstrap-sweetalert";

import Loc from "localization";
import fetchAPI from "connectionHandler/FetchAPI.js";

import Chip from "@material-ui/core/Chip";
import FaceIcon from "@material-ui/icons/Face";

export default function BookingListView(props) {
  // type validation
  const [isEdit, setIsEdit] = React.useState(false);

  const [data_id, setData_id] = React.useState("");
  const [data_name, setData_itemName] = React.useState("");
  const [alert_info, setAlert_info] = React.useState(null);
  const [alert_confirm, setAlert_confirm] = React.useState(null);

  const [data_date, setData_date] = React.useState(null);
  const [data_staffID, setData_staffID] = React.useState("");
  const [data_storeID, setData_storeID] = React.useState("");
  const [data_storeName, setData_storeName] = React.useState("");
  const [data_staffName, setData_staffName] = React.useState("");
  const [data_rosterList, setData_rosterList] = React.useState([]);
  const [data_page, set_page] = React.useState(0);
  const [data_pageCount, set_pageCount] = React.useState(50);
  const [showDeletedData, setShowDeletedData] = React.useState(false);
  const [showExtendedTable, setShowExtendedTable] = React.useState(false);
  const [currentIndex, setCurrentIndex] = React.useState(-1);
  const [data_dataList, setData_dataList] = React.useState(null);
  const [data_dataListCount, setData_dataListCount] = React.useState(0);
  const [itemToEdit, setItemToEdit] = React.useState(null);
  const [tabIndex, setTabIndex] = React.useState(0);

  const [data_dataListArray, setData_dataListArray] = React.useState(null);
  const [data_isPreLoadData, setData_preLoadData] = React.useState(false);
  const [isLoading, set_isLoading] = React.useState(false);
  const [isLoaded, set_isLoaded] = React.useState(false);
  const [pathToRedirect, setRedirect] = React.useState("");

  const [dateState, setdateState] = React.useState("");

  const [fetchingStatus, setFetchingStatus] = React.useState("");
  const [data_adminInfo, setData_adminInfo] = React.useState(null);
  const [data_startDate, setData_startDate] = React.useState(new Date());
  const [data_endDate, setData_endDate] = React.useState(new Date());
  // const [isPreLoadedData, setIsPreLoadedData] = React.useState(false);
  // const [data_store, setData_store] = React.useState(null);
  // const [data_staff, setData_staff] = React.useState(null);
  const [locationState, setLocationState] = React.useState(null);
  const [historyState, setHistoryState] = React.useState(null);
  const classes_tableBtn = useStyles_tableBtn();
  const [data_adminRole, setData_adminRole] = React.useState([]);
  const [data_lookupObj, setData_lookupObj] = React.useState(null);
  // alert
  const [alert_save, setSaveAlert] = React.useState(null);
  const hideAlert = () => {
    setSaveAlert(null);
    setAlert_info(null);
    setAlert_confirm(null);
  };

  const { targetBookingCollectionName, createFieldList, onPressCreateNewBooking, onPressEditBooking, onPressDeleteBooking,onPressCalendar,needRefresh,preloadData } = props;

  React.useEffect(() => {
    // var preloadListStr = localStorage.getItem("preloadDataList");
    // var preloadList = JSON.parse(preloadListStr);

    // console.log("Booking List View " + JSON.stringify(createFieldList));
    // if (props.location.state.adminInfo.permissions != null)
    //   {
    //     setData_adminRole(props.location.state.adminInfo.permissions);
    //   }
    

    if (isLoaded == false) {
      set_isLoaded(true);
      do_loadDataList();
    }
    
  
    // Specify how to clean up after this effect:
    return function cleanup() { };
  }, [data_dataListArray, locationState, onPressCreateNewBooking,isLoaded, onPressEditBooking,onPressDeleteBooking,onPressCalendar, data_lookupObj, createFieldList]);

  React.useEffect(() => { 

    if (needRefresh == true) {
      set_isLoaded(true);
      do_loadDataList();
    }

  }, [needRefresh]);


  function deleteBookingRecord(id, staffID, date_timestamp, data) {
    const body = {
      id: id,
      targetCollection: targetBookingCollectionName,
      content: {
        targetCollection: targetBookingCollectionName,
        staffID: staffID,
        roster: data,
        date_timestamp: date_timestamp,
      },
    };
    fetchAPI.do_fetch("post", "admin/delete-booking", body).then(
      (res) => {
        console.log("success: ", res.data);
        do_loadDataList();
        showAlert(
          true,
          Loc.success,
          showDeletedData ? Loc.data_recover_success : Loc.data_delete_success
        );
      },
      (error) => {
        console.log("failed: ", error);
        showAlert(
          false,
          Loc.failed,
          showDeletedData ? Loc.data_recover_failed : Loc.data_delete_failed
        );
      }
    );
  }

  function showAlert(issuccess, title, content) {
    setSaveAlert(
      <SweetAlert
        error={!issuccess}
        success={issuccess}
        style={{ display: "block", marginTop: "-100px" }}
        closeOnClickOutside={false}
        title={title}
        onConfirm={() => {
          if (issuccess) {
            // hideAlert()
            setRedirect("/admin");
          } else {
            hideAlert();
          }
        }}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        cancelBtnCssClass={classes_alert.button + " " + classes_alert.info}
        onCancel={() => {
          if (issuccess) {
            setData_date(null);
            setData_rosterList([]);

            // window.location.reload(false);
            hideAlert();
          } else {
            hideAlert();
          }
        }}
        confirmBtnText={issuccess ? Loc.done : Loc.confirm}
        cancelBtnText={Loc.continue}
        btnSize="lg"
        showCancel={issuccess && !isEdit}
      >
        {content}
      </SweetAlert>
    );
  }

  const check_redirect = () => {
    if (pathToRedirect != "") {
      if (itemToEdit != null) {
        return (
          <Redirect
            to={{
              pathname: pathToRedirect,
              state: itemToEdit,
            }}
          />
        );
      } else {
        return <Redirect to={pathToRedirect} />;
      }
    } else {
      return null;
    }
  };

  const getStringToShow = (defaultStr, fieldList, fieldName, id) => {
    console.log(`getStringToShow ${fieldName} ===>${id}`);
    var strToShow = defaultStr;

    if (fieldList.length > 0) {
      const fieldNameList = getDropDownNameList(fieldName);
      strToShow = fieldNameList[0];
      fieldList.map((prop, key) => {
        if (prop.id === id) {
          strToShow = prop.itemName;
        }
      });
    }
    return strToShow;
  };

  const getDropDownNameList = (fieldName) => {
    const fieldList =
      data_dataListArray == null ? [] : data_dataListArray[fieldName];
    const fieldNameList = [];
    if (fieldList.length > 0) {
      fieldList.map((item) => {
        fieldNameList.push(item.itemName);
      });
    }
    return fieldNameList;
  };

  function do_loadDataList() {
    set_isLoading(true);
    const bookingData = createFieldList.filter(
      (data) => data.fieldType == DataSetType.type_booking_system
    );
    const fieldKey = bookingData[0]["fieldKey"];

    var orderBy = tabIndex == 0 ? "createDate":fieldKey+"_selectedDate";

    var body = {
      collectionName: targetBookingCollectionName,
      page: data_page,
      pageCount: data_pageCount,
      showDeletedData: showDeletedData,
      orderBy: orderBy,
      orders: "desc",
    };

    if (tabIndex !== 0) {
        body.isActive = tabIndex == 1 ? true : false;
        body.fromDate = moment().startOf("day").toDate().getTime();
    }
    console.log("BLV do_loadDataList::  "+JSON.stringify(body));
    fetchAPI.do_fetch("post", tabIndex == 0 ? "admin/list-custom":"admin/list-booking", body).then(
      (res) => {
        set_isLoading(false);
        console.log("success: ", res.data);
        if (res.data == undefined || res.data == null) {
          setData_dataList([]);
        } else {
          setData_dataList(res.data.data_list);
          setData_dataListCount(res.data.count);
        }
      },
      (error) => {
        console.log("failed: ", error);
        set_isLoading(false);
        showAlert(false, Loc.failed, Loc.data_fetch_failed);
      }
    );
  }

  function processDataWithActions() {
    if (
      data_dataList == undefined ||
      data_dataList == null ||
      data_dataList.length == 0
    ) {
      return [];
    }
    const bookingData = createFieldList.filter(
      (data) => data.fieldType == DataSetType.type_booking_system
    );
    const lookupKey = bookingData[0]["target_look_up_key"];
    // console.log("data_lookupObj[lookupKey]:  "+JSON.stringify(createFieldList))
    const newData = data_dataList
      // .filter(v => v.isDeleted != true)
      .map((prop, key) => {
        // console.log("Booking list data prop: ", JSON.stringify(prop));
        return [
          moment(prop.date_timestamp).format("YYYY-MM-DD"),
          // prop.staffID,
          prop[lookupKey],
          // " ",
          // prop.staffName,
          moment(prop.roster.start_timestamp).format("HH:mm"),
          prop.roster.status,

          tabIndex == 0 ? <div className="actions-right">
            <Button
              justIcon
              round
              simple
              onClick={() => {
                // var state = {
                //   data: prop,
                //   config: locationState,
                // };
                // setItemToEdit(state);
                // setRedirect("/admin/booking-detail");
                onPressEditBooking(prop);
              }}
              color="warning"
              className="edit"
            >
              <Dvr />
            </Button>{" "}
            {/* use this button to remove the data row */}
            <Button
              justIcon
              round
              simple
              // onClick={() => {
              //   showConfirmDeleteAlert(
              //     prop.id,
              //     prop.staffID,
              //     prop.date_timestamp,
              //     prop.roster
              //   );
              // }}
              onClick={() => {
                onPressDeleteBooking(prop.id, prop[lookupKey], prop)
                set_isLoaded(false);
              }}
              color="danger"
              className="remove"
            >
              {showDeletedData ? <Recover /> : <Close />}
            </Button>{" "}
          </div> : <div>
            {_render_view_btn(prop, "/admin/booking-detail")}

          </div>,
        ];
      });
    return newData;
  }
  const tableHeader = [
    Loc.date,
    Loc.staff_id,
    // Loc.staff_name,
    Loc.roster_start_time,
    Loc.status,
    Loc.actions,
  ];

  function showConfirmDeleteAlert(id, staffID, date_timestamp, data) {
    setAlert_confirm(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        closeOnClickOutside={false}
        title={
          showDeletedData ? Loc.confirm_recover_title : Loc.confirm_delete_title
        }
        onConfirm={() => {
          hideAlert();
          deleteBookingRecord(id, staffID, date_timestamp, data);
        }}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        cancelBtnCssClass={classes_alert.button + " " + classes_alert.danger}
        confirmBtnText={
          showDeletedData
            ? Loc.confirm_recover_confirm
            : Loc.confirm_delete_confirm
        }
        cancelBtnText={Loc.cancel}
        showCancel
      >
        {Loc.are_you_sure}
      </SweetAlert>
    );
  }

  function showAlert(issuccess, title, content) {
    setAlert_info(
      <SweetAlert
        error={!issuccess}
        success={issuccess}
        style={{ display: "block", marginTop: "-100px" }}
        closeOnClickOutside={false}
        title={title}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnText={Loc.confirm}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        btnSize="lg"
      >
        {content}
      </SweetAlert>
    );
  }

  function _renderPagingTable() {
    return (
      <PagingTable
        tableHead={tableHeader}
        tableData={processDataWithActions()}
        customCellClasses={[
          classes_tableBtn.left,
          classes_tableBtn.center,
          classes_tableBtn.center,
          classes_tableBtn.center,
          classes_tableBtn.center,
          classes_tableBtn.right,
        ]}
        customClassesForCells={[0, 1, 2, 3, 4, 5]}
        customHeadCellClasses={[
          classes_tableBtn.left,
          classes_tableBtn.center,
          classes_tableBtn.center,
          classes_tableBtn.center,
          classes_tableBtn.center,
          classes_tableBtn.right,
        ]}
        customHeadClassesForCells={[0, 1, 2, 3, 4, 5]}
        gotoPageClicked={(targetPage) => {
          set_page(targetPage);
          set_isLoaded(false);
        }}
        changedPageCount={(newPageCount) => {
          set_page(0);
          set_pageCount(newPageCount);
          set_isLoaded(false);
        }}
        totalDataCount={data_dataListCount}
        page={data_page}
        pageCount={data_pageCount}
      />
    );
  }

  function _renderExtendedTable() {
    return (
      <Table
        tableHead={tableHeader}
        tableData={processDataWithActions()}
        // customCellClasses={[classes.center, classes.right, classes.right]}
        customCellClasses={[
          classes_tableBtn.left,
          classes_tableBtn.center,
          classes_tableBtn.center,
          classes_tableBtn.center,
          classes_tableBtn.center,
          classes_tableBtn.right,
        ]}
        customClassesForCells={[0, 1, 2, 3, 4, 5]}
        customHeadCellClasses={[
          classes_tableBtn.left,
          classes_tableBtn.center,
          classes_tableBtn.center,
          classes_tableBtn.center,
          classes_tableBtn.center,
          classes_tableBtn.right,
        ]}
        customHeadClassesForCells={[0, 1, 2, 3, 4, 5]}
      />
    );
  }

  const _renderSearchBar = () => {
    const bookingData = createFieldList.filter(
      (data) => data.fieldType == DataSetType.type_booking_system
    );
    const lookupKey = bookingData[0]["look_up_key"];

    const searchFieldList = [
      {
        itemName: Loc.search_by_date,
        id: "date",
        key: ["createDate"],
        type: "date",
        isPreloadField: false,
      },
      {
        itemName: "",
        id: "divider",
        key: ["divider"],
        type: "none",
        isPreloadField: false,
      },
      {
        itemName: tableHeader[1],
        id: lookupKey,
        key: [lookupKey],
        type: "text",
        isPreloadField: false,
      },
      {
        itemName: tableHeader[2],
        id: "staffName",
        key: ["staffName"],
        type: "text",
        isPreloadField: false,
      },
      {
        itemName: tableHeader[3],
        id: "start_timestamp",
        key: ["start_timestamp"],
        type: "text",
        isPreloadField: false,
      },
      {
        itemName: tableHeader[4],
        id: "status",
        key: ["status"],
        type: "text",
        isPreloadField: false,
      },
      
    ];

    return (
      <SearchBar
        searchFieldList={searchFieldList}
        apiPath={"admin/list-custom"}
        collectionName={targetBookingCollectionName}
        loadDataList={() => do_loadDataList()}
        searchResultDataList={(dataList) => {
          setData_dataList(dataList);
        }}
        searchResultDataCount={(count) => {
          setData_dataListCount(count);
        }}
        showDeletedData={showDeletedData}
        locationState={locationState}
        didSelectedShowDeletedData={(_showDeletedData) => {
          setShowDeletedData(_showDeletedData);
          set_isLoaded(false);
        }}
        showExtendedTable={(bool) => {
          setShowExtendedTable(bool);
        }}
        searchPeriod={(from, to) => {
          setData_startDate(from);
          setData_endDate(to);
        }}
      />
    );
  };

  const _render_view_btn = (prop, pathname) => {
    return (
      <Button
        justIcon
        round
        simple
        onClick={() => {

          var state = {
            data: {...prop,isReadOnly: true, isNewData: false},
            config: locationState,
          };
          setItemToEdit(state);
          setRedirect("/admin/booking-detail");

        }}
        color="info"
        className="remove"
        disabled={tabIndex < 1}
      >
        <Visibility />
      </Button>
    );
  }

  const classes = useStyles();
  const classes_alert = useStyles_alert();

  const showloading = () => {
    if (isLoading) {
      return (
        <SweetAlert
          style={{ display: "block", marginTop: "-100px" }}
          closeOnClickOutside={false}
          title={Loc.loading}
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnText={Loc.confirm}
          confirmBtnCssClass={
            classes_alert.button + " " + classes_alert.success
          }
          btnSize="lg"
          showConfirm={false}
          showCancel={false}
        >
          {Loc.please_wait}
        </SweetAlert>
      );
    } else {
      return null;
    }
  };
  return (
    <div>
      {check_redirect()}
      {alert_save}
      {alert_info}
      {alert_confirm}
      {showloading()}
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" text>
              <CardText color="rose">
                <h4 className={classes.cardTitle}>{"Booking List"}</h4>
              </CardText>
            </CardHeader>
            <CardBody>
              <Button
                color="success"
                round
                onClick={onPressCreateNewBooking}
              >
                <ICON_ADD />
                {"Add New Booking"}
              </Button>
              {/* <Button
                color="danger"
                round
                onClick={onPressCalendar}
              >
                <CalendarToday />
                {"Calendar View"}
              </Button> */}
              <NavPills
                color="warning"
                onIndexChange={(index) => {
                  console.log("Tab Change  " + index+ " "+data_page);
                    setTabIndex(index);
                    // set_page(0);
                    
                    set_isLoaded(false);
                }}
                tabs={[
                  {
                    tabButton: "ALL",
                    tabContent: (
                      <>
                        {_renderSearchBar()}
                        {_renderPagingTable()}
                      </>
                    ),
                  },
                  {
                    tabButton: "Active",
                    tabContent: (
                      <>
                        {
                          <Button
                            color="danger"
                            round
                            onClick={() => {
                                set_isLoaded(false);
                            }}
                          >
                            <Refresh />
                            {"Refresh"}
                          </Button>
                        }
                        {_renderPagingTable()}
                      </>
                    ),
                  },
                  {
                    tabButton: "Inactive",
                    tabContent: (
                      <>
                        {
                          <Button
                            color="danger"
                            round
                            onClick={() => {
                              set_isLoaded(false);
                            }}
                          >
                            <Refresh />
                            {"Refresh"}
                          </Button>
                        }
                        {_renderPagingTable()}
                      </>
                    ),
                  },
                ]}
              />
              <form>
                <GridContainer>{/* {renderTagView()} */}</GridContainer>
              </form>
            </CardBody>
            <CardFooter className={classes.justifyContentCenter}>
              <GridContainer
                spacing={2}
                direction="row"
                justify="center"
                alignItems="center"
              ></GridContainer>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
