import React from "react";
import { Redirect } from "react-router-dom";

// react plugin for creating charts
import ChartistGraph from "react-chartist";
// react plugin for creating vector maps
import { VectorMap } from "react-jvectormap";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Icon from "@material-ui/core/Icon";


// @material-ui/icons
// import ContentCopy from "@material-ui/icons/ContentCopy";
import Store from "@material-ui/icons/Store";
import MonetizationOn from "@material-ui/icons/MonetizationOn";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
// import InfoOutline from "@material-ui/icons/InfoOutline";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Refresh from "@material-ui/icons/Refresh";
import Edit from "@material-ui/icons/Edit";
import Place from "@material-ui/icons/Place";
import ArtTrack from "@material-ui/icons/ArtTrack";
import Language from "@material-ui/icons/Language";
import Chart from "@material-ui/icons/PieChart";
import DONE from "@material-ui/icons/Done";
import FAILED from "@material-ui/icons/Close";
import Password from "@material-ui/icons/Lock";
import SweetAlert from "react-bootstrap-sweetalert";

import QuestionMark from "@material-ui/icons/Cached";



// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CustomInput from "components/CustomInput/CustomInput.js";


import T_Muted from "components/Typography/Muted.js";
import T_Success from "components/Typography/Success.js";
import T_Danger from "components/Typography/Danger.js";
import dashboardPermissionData from "dashboardPermissions.js";

import Loc from "localization";
import fetchAPI from "connectionHandler/FetchAPI.js";
import firebase from "connectionHandler/firebase";
import Config from "itchi_config";
import { SystemType } from "Util/SystemType.js";

// import DragDropUpload from "components/CustomUpload/DragDropUpload.js";

import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart,
} from "variables/charts";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import styles_tableBtn from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import styles_switch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import styles_alert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import CardText from "components/Card/CardText";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle";

var styles_card = {
  ...dashboardStyle,
  ...loginPageStyle,
  cardTitle: {
    marginTop: "0",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);
const useStyles_tableBtn = makeStyles(styles_tableBtn);
const useStyles_switch = makeStyles(styles_switch);
const useStyles_card = makeStyles(styles_card);
const useStyles_alert = makeStyles(styles_alert);


export default function ChangePassword(props) {
  const classes_card = useStyles_card();
  const classes_tableBtn = useStyles_tableBtn();
  const classes_switch = useStyles_switch();
  const classes_alert = useStyles_alert();

  const classes = useStyles();
  const [data_todaySales, setData_todaySales] = React.useState(0);
  const [
    data_todayActiveCustomer,
    setData_todayActiveCustomer,
  ] = React.useState(Loc.loading);
  const [data_monthSales, setData_monthSales] = React.useState(Loc.loading);
  const [
    data_monthActiveCustomer,
    setData_monthActiveCustomer,
  ] = React.useState(Loc.loading);

  const [data_todaySales_store, setData_todaySales_store] = React.useState([]);
  const [data_adminInfo, setDate_adminInfo] = React.useState(null);
  const [isGotAdminInfo, setData_isGotAdminInfo] = React.useState(false);

  const [time_reloadTime, set_time_reloadTime] = React.useState(5);

  const [data_iaAutoFefresh, set_data_iaAutoFefresh] = React.useState(false);
  // promise(timer)
  const [promise_todaySales, set_promise_todaySales] = React.useState(null);
  const [
    promise_todayActiveCustomer,
    set_promise_todayActiveCustomer,
  ] = React.useState(null);
  const [promise_monthSales, set_promise_monthSales] = React.useState(null);
  const [
    promise_monthActiveCustomer,
    set_promise_monthActiveCustomer,
  ] = React.useState(null);
  const [
    promise_todaySales_stores,
    set_promise_todaySales_stores,
  ] = React.useState(null);

  // Redirect
  const [pathToRedirect, setRedirect] = React.useState("");
  const [stateType, setStateType] = React.useState("");
  const [locationState, setLocationState] = React.useState(null);

  // const [temp_fileArray, setTemp_fileArray] = React.useState([]);
  const [filesArray, setFilesArray] = React.useState([]);
  // const [testing, setTesting] = React.useState("");
  const [value, setValue] = React.useState("**Hello world!!!**");

  const [old_pw, setData_old_pw] = React.useState("");
  const [new_pw, setData_new_pw] = React.useState("");
  const [new_pw_confirm, setData_new_pw_confirm] = React.useState("");

  const [state_old_pw, setData_state_old_pw] = React.useState(false);
  const [state_new_pw, setData_state_new_pw] = React.useState(false);
  const [state_new_pw_confirm, setData_state_new_pw_confirm] = React.useState(false);
  const [alert_save, setSaveAlert] = React.useState(null);
  const hideAlert = () => {
    setSaveAlert(null);
  };


  

  React.useEffect(() => {

    setLocationState(props.location.state);


    handle_init();
    return function cleanup() {

    };
  }, [isGotAdminInfo]);

  function handle_init() {
    if (isGotAdminInfo) {
      if (data_adminInfo !== null) {

      } else {
        console.log("Admin info NULL!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
      }
    } else {
      get_adminInfo();
    }
  }

  function get_adminInfo() {
    // get admin info
    const body = {
      id: firebase.auth().currentUser.uid,
    };
    fetchAPI.do_fetch("post", "admin/get-adminInfo", body).then(
      (res) => {
        setDate_adminInfo(res.data);
        setData_isGotAdminInfo(true);
      },
      (error) => {
        firebase
          .auth()
          .signOut()
          .then(function () {
            console.log("Sign-out successful.");
            setData_isGotAdminInfo(true);
            // Sign-out successful.
          })
          .catch(function (error) {
            console.log("Sign-out fail, ", error);
            setData_isGotAdminInfo(true);
            // An error happened.
          });
      }
    );
  }

  const check_redirect = () => {
    if (pathToRedirect != "") {
      return <Redirect to={pathToRedirect} />;
    } else {
      return null;
    }
  };

  function getPermissions(targetType) {
    var permissions = [];

    if (data_adminInfo !== null) {
      dashboardPermissionData.map((p, k) => {
        if (p.type === targetType) {
          permissions.push(p);
        }
      });
    }

    return permissions;
  }

  function checkAllPermission() {
    if (
      data_adminInfo !== null &&
      data_adminInfo.permissions["all"] !== null &&
      data_adminInfo.permissions["all"] === true
    ) {
      return true;
    } else {
      return false;
    }
  }

  function _renderBtnCleanLocalStorage() {
    return (
      <GridItem sm={2} key={"CleanLocalStorage"}>
        <Button
          color="danger"
          round
          onClick={() => {
            localStorage.clear();
            window.location.reload();
          }}
        >
          <Refresh />
          {Loc.refresh_local_storage}
        </Button>
      </GridItem>
    );
  }


  function handleEditorChange({ html, text }) {
    console.log('handleEditorChange', html, text);
  }

  

  function render_change_password()
  {
    return (
      <GridContainer>
        <GridItem xs={6}>
          <Card>
            <CardHeader color="info" icon>
            <CardIcon color="info">
              <Password />
            </CardIcon>
              <h4 className={classes_card.cardIconTitle}>{Loc.change_password}</h4>
            </CardHeader>
            <CardBody>

            <GridContainer>
              <GridItem xs={12}>
                <CustomInput
                  // success={requiredState === "success"}
                  error={state_old_pw}
                  labelText={Loc.old_password}
                  id={"old_password"} //{`${fieldName}_name`}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value:old_pw,
                    onChange: (event) => {
                      setData_old_pw(event.target.value);
                      setData_state_old_pw(false);
                    },
                    type: "password",
                  }}
                />
              </GridItem>

              <GridItem xs={12}>
                <CustomInput
                  // success={requiredState === "success"}
                  error={state_new_pw}
                  labelText={Loc.new_password}
                  id={"new_password"} //{`${fieldName}_name`}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value:new_pw,
                    onChange: (event) => {
                      setData_new_pw(event.target.value);
                      setData_state_new_pw(false);
                    },
                    type: "password",
                  }}
                />
              </GridItem>

              <GridItem xs={12}>
              <CustomInput
                  // success={requiredState === "success"}
                  error={state_new_pw_confirm}
                  labelText={Loc.old_password}
                  id={"new_password_confirm"} //{`${fieldName}_name`}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value:new_pw_confirm,
                    onChange: (event) => {
                      setData_new_pw_confirm(event.target.value);
                      setData_state_new_pw_confirm(false);
                    },
                    type: "password",
                  }}
                />
              </GridItem>

              <GridItem xs={12}>
                <Button 
                  color={'primary'}
                  round
                  onClick={() => {
                      // check input
                      if (valid_input())
                      {
                        // reauth
                        const emailCredential = firebase.auth.EmailAuthProvider.credential(firebase.auth().currentUser.email, old_pw);
                        firebase.auth().currentUser.reauthenticateWithCredential(emailCredential).then(reAuthResult => {
                          console.log("Reauth success");
                          // updatePassword
                          firebase.auth().currentUser.updatePassword(new_pw).then(() => {
                            console.log("update pw success");
                            // redirect to dashboard
                            // setRedirect("admin/dashboard");
                            showAlert(true, Loc.change_password, Loc.change_password_success);
                          }).catch(function(error) {
                            var errorCode = error.code;
                            var errorMessage = error.message;
                            console.log("update fail, errorCode: ", errorCode);
                            console.log("update fail, errorMessage: ", errorMessage);
                            showAlert(true, Loc.change_password, Loc.change_password_fail);
                          });
                        }).catch(function(error) {
                          setData_state_old_pw(true);
                          var errorCode = error.code;
                          var errorMessage = error.message;
                          console.log("reauth fail, errorCode: ", errorCode);
                          console.log("reauth fail, errorMessage: ", errorMessage);
                          showAlert(true, Loc.change_password, Loc.change_password_fail);
                        });
                      }
                    }}
                  >
                  {Loc.confirm}
                </Button>
              </GridItem>
            </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        
      </GridContainer>
    );
  }

  function valid_input()
  {
    if (old_pw == "")
    {
      setData_state_old_pw(true);
      return false;
    }
    else if (new_pw == "")
    {
      setData_state_new_pw(true);
      return false;
    }
    else if (new_pw_confirm == "")
    {
      setData_state_new_pw_confirm(true);
      return false;
    }
    else if (new_pw.length < 8)
    {
      setData_state_new_pw(true);
    }
    else if (old_pw == new_pw)
    {
      setData_state_new_pw(true);
    }
    else if (new_pw != new_pw_confirm)
    {
      setData_state_new_pw_confirm(true);
      return false;
    }
    else
    {
      return true;
    }
  }

  function showAlert(issuccess, title, content) {
    setSaveAlert(
      <SweetAlert
        error={!issuccess}
        success={issuccess}
        style={{ display: "block", marginTop: "-100px" }}
        closeOnClickOutside={false}
        title={title}
        onConfirm={() => {
          setRedirect("admin/dashboard");
          hideAlert();
        }}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        cancelBtnCssClass={classes_alert.button + " " + classes_alert.info}
        // onCancel={() => {
        //   hideAlert();
        // }}
        confirmBtnText={issuccess ? Loc.done : Loc.confirm}
        cancelBtnText={Loc.continue}
        btnSize="lg"
        showCancel={false}
      >
        {content}
      </SweetAlert>
    );
  }

  return (
    <div>
      {alert_save}
      {render_change_password()}
      {check_redirect()}
    </div>
  );
}
