/*eslint-disable*/
import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";

// material ui icons
import MailOutline from "@material-ui/icons/MailOutline";
import Contacts from "@material-ui/icons/Contacts";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import styles_alert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import PictureUpload from "components/CustomUpload/PictureUpload.js";

import ICON_BACK from "@material-ui/icons/ArrowBackIos";
import ICON_DONE from "@material-ui/icons/Done";


const useStyles = makeStyles(styles);
const useStyles_alert = makeStyles(styles_alert);

import SweetAlert from "react-bootstrap-sweetalert";

import Loc from "localization";
import fetchAPI from "connectionHandler/FetchAPI.js";

export default function Staff_add(props) {


  const [isEdit, setIsEdit] = React.useState(false);

  const [data_id, setData_id] = React.useState("");
  const [data_profileUrl, setData_profileUrl] = React.useState("");
  const [data_name, setData_itemName] = React.useState("");
  const [data_phone, setData_phone] = React.useState("");
  const [data_email, setData_email] = React.useState("");
  const [data_gender, setData_gender] = React.useState("U");
  const [data_remark, setData_remark] = React.useState("");
  const [data_storeID, setData_storeID] = React.useState("");
  const [data_storeName, setData_storeName] = React.useState("");


  const [data_dataListArray, setData_dataListArray] = React.useState(null);

  const [data_IDCard, setData_IDCard] = React.useState("");
  const [data_bankAccount, setData_bankAccount] = React.useState("");

  const [pathToRedirect, setRedirect] = React.useState("");

  const [customerNameState, setCustomerNameState] = React.useState("");
  const [staffPhoneState, setStaffPhoneState] = React.useState("");
  const [historyState,setHistoryState] = React.useState(null)

  const [fetchingStatus, setFetchingStatus] = React.useState("");
  // alert
  const [alert_save, setSaveAlert] = React.useState(null);
  const hideAlert = () => {
    setSaveAlert(null);
  }

  React.useEffect(() => {  
    var preloadListStr = localStorage.getItem("preloadDataList")
    var preloadList = JSON.parse(preloadListStr);
    
    setHistoryState(props.history.location.state)

    if (data_dataListArray === null )
          {
              // const stateConfig = locationState.config;
              if (preloadList["Store"].length > 0) {
                setData_storeID(preloadList["Store"][0].id);
                setData_storeName(preloadList["Store"][0].itemName);
              }
              
              setData_dataListArray(preloadList);
          }
      // pre load data for edit
    //   console.log("Staff "+ JSON.stringify(props.location.state))
    if (props.location.state.data !== null)
    {
        var dataToEdit = props.location.state.data;
        setIsEdit(true);
        setData_id(dataToEdit.id);
        setData_gender(dataToEdit.gender);
        setData_itemName(dataToEdit.itemName);
        setData_phone(dataToEdit.phone);
        setData_email(dataToEdit.email);
        setData_remark(dataToEdit.remark);
        setData_profileUrl(dataToEdit.profileUrl);
        setData_IDCard(dataToEdit.IDCard);
        setData_bankAccount(dataToEdit.bankAccount);
        setData_storeID(dataToEdit.storeID);
        setData_storeName(dataToEdit.storeName);

    }
    // Specify how to clean up after this effect:
    return function cleanup() {
      
    };
  }, [data_dataListArray]);

  const validCheck = () => {
    var result = true;
    if (data_name === "")
    {
        setCustomerNameState("error");
        result = false;
    }
    else if (data_phone === "")
    {
        setStaffPhoneState("error");
        result = false;
    }
    return result;
  }

  const backClicked = () => {
    setRedirect("/admin/staff-list");
  }

  const doneClicked =() => {
    setFetchingStatus("fetching");
    if (validCheck())
    {
        const idToPass = isEdit ? data_id : null;
        console.log("idToPass: ", idToPass);
        const body = {
            "id":idToPass,
            "content":{
                itemName: data_name,
                gender:data_gender, 
                email:data_email, 
                phone:data_phone, 
                remark:data_remark,
                profileUrl:data_profileUrl,
                bankAccount:data_bankAccount,
                IDCard:data_IDCard,
                storeID:data_storeID,
                storeName:data_storeName
            }
        }
        fetchAPI.do_fetch('post', 'admin/create-staff', body)
        .then((res) => {
            console.log("success: ", res);
            setFetchingStatus("");
            showAlert(true, Loc.success, Loc.data_save_success);
        }, error => {
            console.log("failed: ", error);
            setFetchingStatus("");
            showAlert(false, Loc.failed, Loc.data_save_failed);
        })
    }
    else
    {
        setFetchingStatus("");
        showAlert(false, Loc.failed, Loc.data_save_failed);
    }
  }

  function showAlert(issuccess, title, content){
    setSaveAlert(
      <SweetAlert
        error={!issuccess}
        success={issuccess}
        style={{ display: "block", marginTop: "-100px" }}
        closeOnClickOutside={false}
        title={title}
        onConfirm={() => {
            if (issuccess)
            {
                // hideAlert()
                setRedirect("/admin/staff-list");
            }
            else
            {
                hideAlert()
            }
        }}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        cancelBtnCssClass={classes_alert.button + " " + classes_alert.info}
        onCancel={() => {
            if (issuccess)
            {
                setData_gender("U");
                setData_itemName("");
                setData_phone("");
                setData_email("");
                setData_remark("");
                setData_profileUrl("");
                setData_IDCard("");
                setData_bankAccount("");
                // setData_storeID("");
                // setData_storeName("");
                hideAlert()
            }
            else
            {
                hideAlert()
            }
            
        }}
        confirmBtnText={issuccess ? Loc.done : Loc.confirm}
        cancelBtnText={Loc.continue}
        btnSize="lg"
        showCancel={issuccess && !isEdit}
      >
        {content}
      </SweetAlert>
    );
  }
  const getStringToShow = (defaultStr, fieldList, fieldName, id) => {
    console.log(`getStringToShow ${fieldName} ===>${id}`);
    var strToShow = defaultStr;

    if (fieldList.length > 0) {
      const fieldNameList = getDropDownNameList(fieldName);
      strToShow = fieldNameList[0];
      fieldList.map((prop, key) => {
        if (prop.id === id) {
          strToShow = prop.itemName;
        }
      });
    }
    return strToShow;
  };

  const getDropDownNameList = (fieldName) => {
    const fieldList =
      data_dataListArray == null ? [] : data_dataListArray[fieldName];
    const fieldNameList = [];
    if (fieldList.length > 0) {
      fieldList.map((item) => {
        fieldNameList.push(item.itemName);
      });
    }
    return fieldNameList;
  };

  const getSelectMenuItem = (fieldName) => {
    const fieldList =
      data_dataListArray == null ? [] : data_dataListArray[fieldName];
    const menuItems = [];

    // console.log(`getSelectMenuItem   `+ JSON.stringify(data_dataListArray))

    if (fieldList && fieldList.length > 0) {
      fieldList.map((item, key) => {
        menuItems.push(
          <MenuItem
            key={key}
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected,
            }}
            value={item.id}
          >
            {item.itemName}
          </MenuItem>
        );
      });
    }

    return menuItems;
  };


  const handleChange_gender = event => {
    setData_gender(event.target.value);
  };

  const check_redirect = () => {
      if (pathToRedirect != "")
      {
          return (<Redirect to={{
            pathname: pathToRedirect,
            state: historyState.config,
          }} />);
      }
      else
      {
          return null;
      }
  }

  const imageDidUpload = (pathInStorage) => {
      if (pathInStorage != null)
      {
        setData_profileUrl(pathInStorage);
        console.log("imageDidUpload called, path: ", pathInStorage);
      }
  }
  
  const render_selectStore = () => {

      console.log("isERP!!!!!!!!!!");
      return (
        <GridItem xs={12}>
          <FormControl
            fullWidth
            className={
              classes.selectFormControl + " " + classes.formControlMargins
            }
          >
            <Select
              MenuProps={{
                className: classes.selectMenu,
              }}
              classes={{
                select: classes.select,
              }}
              value={data_storeID}
              onChange={(event) => {
                const selectedID = event.target.value;
                setData_storeName(
                  getStringToShow(
                    Loc.store_default,
                    data_dataListArray == null
                      ? []
                      : data_dataListArray["Store"],
                    "Store",
                    selectedID
                  )
                );
                setData_storeID(selectedID);
                console.log("selectedID  " + selectedID);
                console.log("setData_storeName  " + data_storeName);
              }}
              inputProps={{
                name: data_storeName,
                id: data_storeID,
              }}
            >
              {getSelectMenuItem("Store")}
            </Select>
          </FormControl>
        </GridItem>
      );
   
  };

    const classes = useStyles();
    const classes_alert = useStyles_alert();

    return (
    <div>
        {check_redirect()}
        {alert_save}
        <GridContainer>
        <GridItem xs={7} sm={7} md={7}>
            <Card>
            <CardHeader color="rose" text>
                <CardText color="rose">
                    <h4 className={classes.cardTitle}>{Loc.basic_info}</h4>
                </CardText>
            </CardHeader>
            <CardBody>
                <form>
                <GridContainer>
                    <GridItem xs={12} sm={6}>
                        <PictureUpload imageDidUpload={(res) => imageDidUpload(res)} imageUrl={data_profileUrl} targetFolder="Staff"/>
                    </GridItem>

                    <GridItem xs={12} sm={6}>
                        <div
                            className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                            }
                        >
                        <FormControlLabel
                        control={
                            <Radio
                            checked={data_gender === "U"}
                            onChange={handleChange_gender}
                            value="U"
                            name={Loc.secret}
                            aria-label="U"
                            icon={
                                <FiberManualRecord
                                className={classes.radioUnchecked}
                                />
                            }
                            checkedIcon={
                                <FiberManualRecord
                                className={classes.radioChecked}
                                />
                            }
                            classes={{
                                checked: classes.radio,
                                root: classes.radioRoot
                            }}
                            />
                        }
                        classes={{
                            label: classes.label,
                            root: classes.labelRoot
                        }}
                        label={Loc.secret}
                        />
                        </div>
                        <div
                            className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                            }
                        >
                        <FormControlLabel
                        control={
                            <Radio
                            checked={data_gender === "M"}
                            onChange={handleChange_gender}
                            value="M"
                            name={Loc.male}
                            aria-label="M"
                            icon={
                                <FiberManualRecord
                                className={classes.radioUnchecked}
                                />
                            }
                            checkedIcon={
                                <FiberManualRecord
                                className={classes.radioChecked}
                                />
                            }
                            classes={{
                                checked: classes.radio,
                                root: classes.radioRoot
                            }}
                            />
                        }
                        classes={{
                            label: classes.label,
                            root: classes.labelRoot
                        }}
                        label={Loc.male}
                        />
                        </div>
                        <div
                            className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                            }
                        >
                        <FormControlLabel
                            control={
                                <Radio
                                checked={data_gender === "F"}
                                onChange={handleChange_gender}
                                value="F"
                                name={Loc.female}
                                aria-label="F"
                                icon={
                                    <FiberManualRecord
                                    className={classes.radioUnchecked}
                                    />
                                }
                                checkedIcon={
                                    <FiberManualRecord
                                    className={classes.radioChecked}
                                    />
                                }
                                classes={{
                                    checked: classes.radio,
                                    root: classes.radioRoot
                                }}
                                />
                            }
                            classes={{
                                label: classes.label,
                                root: classes.labelRoot
                            }}
                            label={Loc.female}
                        />
                        </div>
                    </GridItem>
                    {/* <GridItem xs={12}>
                    <FormControl
                    fullWidth
                    className={
                      classes.selectFormControl +
                      " " +
                      classes.formControlMargins
                    }
                  >
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={data_storeID}
                      onChange={(event) => {
                        const selectedID = event.target.value;
                        setData_storeName(
                          getStringToShow(
                            Loc.store_default,
                            data_dataListArray == null
                              ? []
                              : data_dataListArray["Store"],
                            "Store",
                            selectedID
                          )
                        );
                        setData_storeID(selectedID);
                        console.log("selectedID  " + selectedID);
                        console.log("setData_storeName  " + data_storeName);
                      }}
                      inputProps={{
                        name: data_storeName,
                        id: data_storeID,
                      }}
                    >
                      {getSelectMenuItem("Store")}
                    </Select>
                  </FormControl>

                    </GridItem> */}
                    <GridItem xs={12}>
                    <CustomInput
                        // success={requiredState === "success"}
                        error={customerNameState === "error"}
                        labelText={Loc.staff_name+"*"}
                        id="staff_name"
                        formControlProps={{
                        fullWidth: true
                        }}
                        inputProps={{
                            value: data_name,
                            onChange: event => {
                                setCustomerNameState("success");
                                setData_itemName(event.target.value);
                            },
                            type: "text",
                        }}
                    />
                    </GridItem>
                    {render_selectStore()}
                    <GridItem xs={12}>
                    <CustomInput
                        error={staffPhoneState === "error"}
                        labelText={Loc.staff_phone + "* " + "(" + Loc.hints_staff_phone_usage + ")"}
                        id="staff_phone"
                        formControlProps={{
                        fullWidth: true
                        }}
                        inputProps={{
                            value: data_phone,
                            onChange: event => {
                                setStaffPhoneState("success");
                                setData_phone(event.target.value);
                            },
                            type: "tel",
                        }}
                    />
                    </GridItem>

                    <GridItem xs={12}>
                    <CustomInput
                        labelText={Loc.staff_email}
                        id="staff_email"
                        formControlProps={{
                        fullWidth: true
                        }}
                        inputProps={{
                            value: data_email,
                            onChange: event => {
                                setData_email(event.target.value);
                            },
                            type: "email",
                        }}
                    />
                    </GridItem>

                    <GridItem xs={12}>
                    <CustomInput
                        labelText={Loc.staff_IDCard}
                        id="staff_IDCard"
                        formControlProps={{
                        fullWidth: true
                        }}
                        inputProps={{
                            value: data_IDCard,
                            onChange: event => {
                                setData_IDCard(event.target.value);
                            },
                            type: "text",
                        }}
                    />
                    </GridItem>

                    <GridItem xs={12}>
                    <CustomInput
                        labelText={Loc.staff_bank_account}
                        id="staff_bank_account"
                        formControlProps={{
                        fullWidth: true
                        }}
                        inputProps={{
                            value: data_bankAccount,
                            onChange: event => {
                                setData_bankAccount(event.target.value);
                            },
                            type: "text",
                        }}
                    />
                    </GridItem>

                    <GridItem xs={12}>
                    <CustomInput
                        labelText={Loc.remark}
                        id="remark"
                        formControlProps={{
                        fullWidth: true
                        }}
                        inputProps={{
                            value: data_remark,
                            onChange: event => {
                                setData_remark(event.target.value);
                            },
                            type: "text",
                        }}
                    />
                    </GridItem>

                </GridContainer>
                
                </form>
            </CardBody>
            <CardFooter className={classes.justifyContentCenter}>
                <GridContainer spacing={2}
                direction="row"
                justify="center"
                alignItems="center">
                    <GridItem xs={6} align="center">
                        <Button onClick={backClicked}>
                        <ICON_BACK />
                        {Loc.back}
                        </Button>
                    </GridItem>

                    <GridItem xs={6} align="center">
                        <Button color="rose" onClick={doneClicked} disabled={fetchingStatus==="fetching" ? true : false}>
                        <ICON_DONE />
                        {isEdit ? Loc.save : Loc.create}
                        </Button>
                    </GridItem>
                </GridContainer>
            </CardFooter>
            </Card>
        </GridItem>
        </GridContainer>
    </div>
  );
}