import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Edit";
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import PagingTable from "views/CommonUseComponents/PagingTable.js";
import SearchBar from "views/CommonUseComponents/SearchBar.js";
import Table from "components/Table/Table.js";
import moment from "moment";

import { dataTable } from "variables/general.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import SweetAlert from "react-bootstrap-sweetalert";

import Loc from "localization";
import fetchAPI from "connectionHandler/FetchAPI.js";
import DataSetType from "Data_set_type";

import styles_alert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styles_tableBtn from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import styles_checkbox from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";

import ICON_ADD from "@material-ui/icons/Add";
import Recover from "@material-ui/icons/Replay";
import ICON_OUTPUT from "@material-ui/icons/Print";

import { saveAs } from "file-saver";
import { ExportToCsv } from "export-to-csv";
import Papa from "papaparse/papaparse.min";

import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Check from "@material-ui/icons/Check";
import Visibility from "@material-ui/icons/Visibility";
import { ViewArrayRounded } from "@material-ui/icons";
import BookingList from "views/BookingSystem/BookingListView.js";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};

const useStyles = makeStyles(styles);
const useStyles_alert = makeStyles(styles_alert);
const useStyles_tableBtn = makeStyles(styles_tableBtn);
const useStyles_checkbox = makeStyles(styles_checkbox);
export default function Custom_list(props) {
  // alert
  const [alert_info, setAlert_info] = React.useState(null);
  const [alert_confirm, setAlert_confirm] = React.useState(null);
  const [alert_upload, setAlert_upload] = React.useState(null);

  const hideAlert = () => {
    setAlert_info(null);
    setAlert_confirm(null);
    setAlert_upload(null);
  };

  const [pathToRedirect, setRedirect] = React.useState("");
  const [itemToEdit, setItemToEdit] = React.useState(null);

  const [data_page, set_page] = React.useState(0);
  const [data_pageCount, set_pageCount] = React.useState(50);
  const [data_isLoaded, set_isLoaded] = React.useState(false);
  const [isLoading, set_isLoading] = React.useState(false);
  const [data_dataList, setData_dataList] = React.useState(null);
  const [data_dataListCount, setData_dataListCount] = React.useState(0);
  const [localizationState, setLocalizationState] = React.useState(null);
  const [showDeletedData, setShowDeletedData] = React.useState(false);
  const [showExtendedTable, setShowExtendedTable] = React.useState(false);
  const [isBookingUI, setIsBookingUI] = React.useState(false);
  const [
    targetBookingCollectionName,
    setTargetBookingCollectionName,
  ] = React.useState("");
  const [data_startDate, setData_startDate] = React.useState(new Date());
  const [data_endDate, setData_endDate] = React.useState(new Date());
  const [data_createFieldList, setData_createFieldList] = React.useState(null);
  const [data_selectedData, setData_selectedData] = React.useState(null);
  const [totalProcessCount, setTotalProcessCount] = React.useState(0);
  const [currentProcessCount, setCurrentProcessCount] = React.useState(0);
  const [showExcelImport, set_showExcelImport] = React.useState(false);
  const [showExcelExport, set_showExcelExport] = React.useState(false);
  const [includeAccountCreate,setIncludeAccountCreate] = React.useState(false);

  const [checked, setChecked] = React.useState([]);
  const [preloadList, set_preloadList] = React.useState(null);
  const [preloadDataList, set_preloadDataList] = React.useState(null);

  const [
    isPermissionContentRequired,
    set_isPermissionContentRequired,
  ] = React.useState("");
  const [
    isPermissionContentRequired_otherTableCollectionName,
    set_isPermissionContentRequired_otherTableCollectionName,
  ] = React.useState("");

  const [data_adminRole, setData_adminRole] = React.useState([]);

  const classes_checkbox = useStyles_checkbox();

  React.useEffect(() => {
    if (localizationState == null) {
      const {
        createFieldList,
        showExcelImport,
        showExcelExport,
        isBookingUI,
        collectionName,
      } = props.location.state;
      setLocalizationState(props.location.state);
      setData_createFieldList(createFieldList);
      // console.log(
      //   "props.location.state:  " + JSON.stringify(props.location.state)
      // );
      if (props.location.state.adminInfo.permissions != null) {
        setData_adminRole(props.location.state.adminInfo.permissions);
      }

      if (showExcelImport) {
        set_showExcelImport(showExcelImport);
      } else {
        set_showExcelImport(false);
      }

      if (showExcelExport) {
        set_showExcelExport(showExcelExport);
      } else {
        set_showExcelExport(false);
      }

      console.log("isBookingUI: " + isBookingUI);
      if (isBookingUI) {
        setIsBookingUI(isBookingUI);
      } else {
        setIsBookingUI(false);
      }

      // console.log("isBookingSystem: " + JSON.stringify(isBookingSystem));
      if (
        collectionName !== undefined &&
        collectionName !== null &&
        collectionName !== ""
      ) {
        setTargetBookingCollectionName(collectionName);
      }

      // console.log("Custom list isPermissionRequired: " + JSON.stringify(props.location.state.permission_content));
    } else {
      // console.log(`Custom_list props =======>   ${JSON.stringify(props.location.state)}`)
      if (localizationState) {
        //Filter the collection name : type is select_from_other_dataset_single
        var otherTableCollectionNameList = [];

        // console.log(`localizationState.createFieldList: ${JSON.stringify(localizationState.createFieldList)}`);

        localizationState.createFieldList.map((item) => {
          const type = item.fieldType;
          if (type == DataSetType.type_select_from_other_dataset_single) {
            const isExist = otherTableCollectionNameList.filter(
              (collectionName) =>
                collectionName == item.otherTableCollectionName
            );
            if (isExist.length == 0) {
              otherTableCollectionNameList.push(item.otherTableCollectionName);
            }
          }
          if (type == DataSetType.type_account_email || type == DataSetType.type_account_phone) {
            setIncludeAccountCreate(true);
          }
          // check if permission content is required
          if (type == DataSetType.type_select_from_other_dataset_permission) {
            console.log("check permission content required");
            set_isPermissionContentRequired("permission_target");
            set_isPermissionContentRequired_otherTableCollectionName(
              item.otherTableCollectionName
            );
          }
        });

        if (
          otherTableCollectionNameList.length > 0 &&
          preloadDataList == null
        ) {
          console.log("otherTableCollectionNameList Case");
          //Need Call the api to get the cache data
          getListOfCollection(otherTableCollectionNameList, {}).then(
            (list_result) => {
              // console.log("list_result: " + JSON.stringify(list_result));
              set_preloadDataList(list_result);
              if (data_dataList != null) {
                processDataWithActions(list_result);
              }

              if (!data_isLoaded && data_pageCount >= 0 && data_page >= 0) {
                set_isLoaded(true);
                do_loadDataList();
              }
            }
          );
        } else {
          console.log("No preload list Case");

          if (data_dataList != null) {
            processDataWithActions();
          }

          if (!data_isLoaded && data_pageCount >= 0 && data_page >= 0) {
            set_isLoaded(true);
            do_loadDataList();
          }
        }

        // console.log("otherTableCollectionNameList  " + JSON.stringify(otherTableCollectionNameList));

        // if (data_dataList != null) {
        //   processDataWithActions();
        // }

        // if (!data_isLoaded && data_pageCount >= 0 && data_page >= 0) {
        //   set_isLoaded(true);
        //   do_loadDataList();
        // }
      }
    }
    // Specify how to clean up after this effect:
    return function cleanup() {};
  }, [
    data_pageCount,
    data_page,
    data_dataList,
    localizationState,
    showDeletedData,
    data_isLoaded,
    checked,
    preloadDataList,
    // selectList
  ]);

  //Excel
  var xls = require("exceljs");

  const inputAlert = () => {
    setAlert_upload(
      <>
        <SweetAlert
          style={{ display: "block", marginTop: "-100px" }}
          title={Loc.input_excel_data_handle}
          onConfirm={() => downloadTemplate()}
          onCancel={() => hideAlert()}
          confirmBtnCssClass={
            classes_alert.button + " " + classes_alert.success
          }
          cancelBtnCssClass={classes_alert.button + " " + classes_alert.danger}
          confirmBtnText={Loc.download_excel_data}
          cancelBtnText={Loc.back}
          showCancel
        >
          <input type="file" onChange={onFileChange} />
          <br />
          {Loc.or}
        </SweetAlert>
      </>
    );
  };

  function downloadTemplate(withData) {
    var csvData = [];
    var fieldNameCsvRowList = ["FIELD_NAME"];
    var fieldKeyCsvRowStr = ["FIELD_KEY"];
    var fieldTypeCsvRowStr = ["FIELD_TYPES"];

    data_createFieldList.map((fieldData) => {
      // if (fieldData.isReadOnly == undefined )
      // {
      const type = fieldData.fieldType;
      if (
        type == DataSetType.type_string ||
        type == DataSetType.type_bool ||
        type == DataSetType.type_number ||
        type == DataSetType.type_account_phone ||
        type == DataSetType.type_account_email ||
        type == DataSetType.type_account_password ||
        type == DataSetType.type_MC ||
        type == DataSetType.type_list ||
        type == DataSetType.type_multiline_text ||
        type == DataSetType.type_select_from_other_dataset_single ||
        type == DataSetType.type_firebase_geopoint ||
        type == DataSetType.type_list_object ||
        type == DataSetType.type_time_picker ||
        type == DataSetType.type_timestamp

      ) {
        const { detailFieldList, fieldName, fieldKey } = fieldData;

        if (type == DataSetType.type_MC) {
          var options = "";
          detailFieldList.map((detail, index) => {
            options += detail.key;
            if (index < detailFieldList.length - 1) {
              options += ",";
            }
          });
          fieldNameCsvRowList.push(fieldName + `(${options})`);
          fieldTypeCsvRowStr.push("string");
          fieldKeyCsvRowStr.push(fieldData.fieldKey);
        }
        // else if (type == DataSetType.type_list){

        //   // data_dataList.map((data, index) => {
        //    if (data[fieldKey]){
        //       const targetList = data[fieldKey];
        //       fieldNameCsvRowList.push(fieldName);
        //       fieldTypeCsvRowStr.push("list");
        //       fieldKeyCsvRowStr.push(fieldKey);

        //       // targetList.map((target,index) => {
        //       //   fieldNameCsvRowList.push(fieldName+"_"+index);
        //       //   // fieldKeyCsvRowStr.push("")
        //       //   fieldTypeCsvRowStr.push("string");
        //       //   fieldKeyCsvRowStr.push(fieldKey+"_"+index);

        //       // })
        //     }
        //   // })

        // }
        else {
          fieldNameCsvRowList.push(fieldData.fieldName);
          fieldTypeCsvRowStr.push(type);
          fieldKeyCsvRowStr.push(fieldData.fieldKey);
        }
      }
      // }
    });

    csvData.push(fieldNameCsvRowList);
    csvData.push(fieldKeyCsvRowStr);
    csvData.push(fieldTypeCsvRowStr);

    if (withData) {
      data_dataList
        // .filter(v => v.isDeleted != true)
        .map((prop, key) => {
          const item_id = prop.id;

          // var createFieldList = localizationState.createFieldList;
          var customItemsList = [];

          fieldKeyCsvRowStr.map((fieldKey, _index) => {
            const _type = fieldTypeCsvRowStr[_index];

            // if (item.fieldType === DataSetType.type_bool) {
            //   customItemsList.push(prop[fieldKey].toString());
            // } else

            if (
              prop[fieldKey] === null ||
              prop[fieldKey] === "" ||
              prop[fieldKey] === undefined
            ) {
              customItemsList.push("");
            } else {
              if (_type == DataSetType.type_list) {
                // console.log(
                //   "data_dataList type: " + JSON.stringify(prop[fieldKey])

                // );
                customItemsList.push(prop[fieldKey].join(","));
              } else if (
                _type == DataSetType.type_select_from_other_dataset_single
              ) {
                const dic_selectFromOther = prop[fieldKey];
                if (dic_selectFromOther) {
                  customItemsList.push(
                    dic_selectFromOther.targetCollection +
                      " -> " +
                      dic_selectFromOther.targetID
                  );
                }
              } else if (_type == DataSetType.type_firebase_geopoint) {
                var map_pt = prop[fieldKey];
                var lat = map_pt["_latitude"];
                var lng = map_pt["_longitude"];
                var str_pt = lat + "," + lng;
                customItemsList.push(str_pt);
              } else if (_type == DataSetType.type_list_object) {
                var list = prop[fieldKey];

                console.log(
                  "DataSetType.type_list_object: " + JSON.stringify(list)
                );

                customItemsList.push(JSON.stringify(list));
              } else {
                customItemsList.push(prop[fieldKey]);
              }
            }
          });
          csvData.push(customItemsList);
        });
      // console.log("csvData  :"+JSON.stringify(csvData));
    }

    // setData_csvData(csvData)

    const options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: false,
      showTitle: false,
      title: "",
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: false,
      filename: cardTitleStr + " template",
      // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
    };

    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(csvData);
  }

  async function operation() {
    var workbook = new xls.Workbook();

    workbook.created = new Date();

    workbook.modified = new Date();

    const worksheet = workbook.addWorksheet("sheet");

    //calcualte part

    const headerEndColumn = String.fromCharCode(
      65 + (getExcelColumns().length - 1)
    );
    //merge cell
    worksheet.mergeCells("A1:" + headerEndColumn + "1");
    worksheet.mergeCells("A2:" + headerEndColumn + "2");

    worksheet.columns = getExcelColumns();

    //columns
    const titleCol = worksheet.getColumn(2);

    // // report_itemName
    titleCol.header = cardTitleStr;
    titleCol.alignment = { vertical: "middle", horizontal: "center" };

    // dateRow.value =
    //   moment(data_startDate).format("YYYY/MM/DD") +
    //   " ~ " +
    //   moment(data_endDate).format("YYYY/MM/DD"); //the dates
    // dateRow.alignment = { vertical: "middle", horizontal: "center" };

    //Fixed Items
    const rows = [getTableHeaderList(true), getEmptyStringArray()];
    // console.log("getExcelData()   "+JSON.stringify(getExcelData()))
    worksheet.addRows(rows);
    //add data part
    worksheet.addRows(getExcelData());

    // worksheet.addRows([
    //   ["", "", "", ""],
    //   [
    //     net_profit_or_lost < 0 ? Loc.report_net_loss : Loc.report_net_income,
    //     "",
    //     "",
    //     roundValue(net_profit_or_lost).toLocaleString(),
    //   ],

    // ]);

    //export excel
    const buffer = await workbook.xlsx.writeBuffer();
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const fileExtension = ".xlsx";

    const blob = new Blob([buffer], { type: fileType });

    const fileName = `${cardTitleStr}_${moment(data_startDate).format(
      "YYYY-MM-DD-hh-mm-ss"
    )}~${moment(data_endDate).format("YYYY-MM-DD-hh-mm-ss")}`;
    saveAs(blob, fileName + fileExtension);
  }

  function getExcelColumns() {
    var columns = [{ header: "", key: "number", width: 20 }];

    if (localizationState && localizationState.createFieldList !== null) {
      var createFieldList = localizationState.createFieldList;

      createFieldList.map((item) => {
        if (item.isShowInTableView) {
          columns.push({ header: "", key: item.fieldName, width: 20 });
        }
      });
    }

    return columns;
  }

  function getEmptyStringArray() {
    var strArray = [];
    if (getExcelColumns().length > 0) {
      for (let i = 0; i < getExcelColumns().length; i++) {
        strArray.push("");
      }
      return strArray;
    }
  }

  function getExcelData() {
    const newData = data_dataList.map((prop, key) => {
      var createFieldList = localizationState.createFieldList;
      var customItemsList = [];
      createFieldList.map((item) => {
        if (item.isShowInTableView) {
          if (item.fieldType === DataSetType.type_bool) {
            customItemsList.push(prop[item.fieldKey].toString());
          } else if (
            prop[item.fieldKey] === null ||
            prop[item.fieldKey] === ""
          ) {
            customItemsList.push("-");
          } else {
            customItemsList.push(prop[item.fieldKey]);
          }
        }
      });
      // console.log("customItemsList    "+JSON.stringify(customItemsList))
      var newDataList = [key + 1, ...customItemsList];

      return newDataList;
    });
    return newData;
  }

  function do_loadDataList() {
    console.log(JSON.stringify(props.location.state.collectionName));

    if (localizationState.collectionName !== null) {
      const target_detail = localizationState.createFieldList.find(
        (data) => data.fieldKey == "permission_target"
      );
      set_isLoading(true);
      const body = {
        collectionName: localizationState.collectionName,
        page: data_page,
        pageCount: data_pageCount,
        showDeletedData: showDeletedData,
        orderBy: localizationState.orderBy,
        orders: localizationState.orders,
        permissionContent:
          localizationState.adminInfo.permission_content != undefined &&
          localizationState.adminInfo.permission_content != null
            ? localizationState.adminInfo.permission_content.targetID
            : null,
        permissionTargetDetail: target_detail,
      };
      fetchAPI.do_fetch("post", "admin/list-custom", body).then(
        (res) => {
          set_isLoading(false);
          console.log("success: ", res.data);
          if (res.data == undefined || res.data == null) {
            setData_dataList([]);
          } else {
            setData_dataList(res.data.data_list);
            setData_dataListCount(res.data.count);
          }
        },
        (error) => {
          console.log("failed: ", error);
          set_isLoading(false);
          showAlert(false, Loc.failed, Loc.data_fetch_failed);
        }
      );
    }
  }

  function getListOfCollection(collectionsList, onHoldList) {
    var newList = collectionsList;
    var newOnHoldList = onHoldList;

    // console.log(`do_loadListOfCollection =====>`);

    const targetCollection = newList[0];
    return new Promise((onDone) => {
      // set_isLoading(true);
      const body = {
        data_targetCollection: targetCollection,
      };

      fetchAPI.do_fetch("post", "admin/list-all", body).then(
        (res) => {
          const dataList = res.data.data_list;
          newOnHoldList[targetCollection] = dataList.filter(
            (v) => v.isDeleted != true
          );
          newList.splice(0, 1);

          if (newList.length > 0) {
            getListOfCollection(newList, newOnHoldList).then(
              (finalOnHoldList) => {
                onDone(finalOnHoldList);
              }
            );
          } else {
            onDone(newOnHoldList);
          }
          //   onDone(res.data.data_list);
        },
        (error) => {
          console.log("failed: ", error);
          set_isLoading(false);
          onDone(null);
        }
      );
    });
  }

  function do_deleteData(targetID, uid, data) {
    return new Promise((onDone) => {

    console.log(
      "do_deleteData --------" +
        targetID +
        "  " +
        uid +
        " data " +
        JSON.stringify(data)
    );
    if (localizationState.collectionName !== null) {
      var body = {
        idList: [targetID],
        collectionName: localizationState.collectionName,
        targetID: targetID,
      };

      const isBookingSystem = data_createFieldList.filter(
        (data) => data.fieldType == DataSetType.type_booking_system
      );
      // console.log("isBookingSystem: " + JSON.stringify(isBookingSystem));
      if (
        isBookingSystem !== undefined &&
        isBookingSystem !== null &&
        isBookingSystem.length > 0
      ) {
        var lookupObj = {};
        lookupObj["targetBooking"] = isBookingSystem[0]["fieldKey"];
        lookupObj["targetRosterCollectionName"] =
          isBookingSystem[0]["target_roster"];
        lookupObj["rosterLookup"] = isBookingSystem[0]["roster_look_up_key"];
        lookupObj["lookupID"] = data[isBookingSystem[0]["roster_look_up_key"]];

        // lookupObj["date_timestamp"] = data.date_timestamp;
        var dateKey = lookupObj["targetBooking"] + "_selectedDate";
        lookupObj["orderBy"] = data[lookupObj[dateKey]];
        lookupObj["roster"] = data.roster;

        body = { ...body, isBookingSystem: true, ...lookupObj };
      }
      if (uid !== null && uid !== "") {
        var list_uid = [];
        list_uid.push(uid);
        body["uidList"] = list_uid;
      }
      body["includeAccountCreate"] = includeAccountCreate;

      const path = showDeletedData
        ? "admin/recover-deleted"
        : "admin/del-custom";
      fetchAPI.do_fetch("post", path, body).then(
        (res) => {
          console.log("del ------ success: ", res.data);
          
          onDone(true);
          // showAlert(
          //   true,
          //   Loc.success,
          //   showDeletedData ? Loc.data_recover_success : Loc.data_delete_success
          // );
        },
        (error) => {
          console.log("failed: ", error);
          showAlert(
            false,
            Loc.failed,
            showDeletedData ? Loc.data_recover_failed : Loc.data_delete_failed
          );
          onDone(false);
        }
      );
      
    }
  });
  }

  function del_multi_doc(checkedList, onHoldList) {
    if (checked.length > 0) {
      var newList = checkedList;
      var newOnHoldList = onHoldList;

      const targetID = newList[0];

      return new Promise((onDone) => {
        // if (localizationState.collectionName !== null) {
        // var body = {
        //   idList: newList,
        //   collectionName: localizationState.collectionName,
        // };

        // const path = "admin/del-custom";
        // fetchAPI.do_fetch("post", path, body).then(
        //   (res) => {
        //     console.log("del_multi_doc: " + targetID);
        do_deleteData(targetID, []).then((isSuccess) => { 

          newList.splice(0, 1);
          if (newList.length > 0) {
            del_multi_doc(newList, newOnHoldList).then((finalOnHoldList) => {
              onDone(finalOnHoldList);
            });
          } else {
            
            onDone(newOnHoldList);
          }
        });
          
        // }
        // ,
        // (error) => {
        //   console.log("failed: ", error);
        //   showAlert(
        //     false,
        //     Loc.failed,
        //     showDeletedData
        //       ? Loc.data_recover_failed
        //       : Loc.data_delete_failed
        //   );
        // }
        // );
        // }
      });
    }
  }

  const handleToggle = (value) => {
    const currentIndex = checked.indexOf(value);
    var newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
    console.log("handleToggle  called  " + JSON.stringify(newChecked));
  };

  function getMCString(createFieldItem, key) {
    const targetList = createFieldItem.detailFieldList;
    var targetObj = targetList.find((data) => data.key === key + "");
    var displayStr = targetObj ? targetObj.value : "";

    return displayStr;
  }

  function onPressAddNew() {
    console.log("onPressAddNew called");
    if (localizationState) {
      const { pathname } = props.location;
      var obj = {
        config: localizationState,
      };
      var data = {
        signupLater: localizationState.signupLater,
        isNewData: true,
        isEdit:false,
      };

      const isBookingSystem = data_createFieldList.filter(
        (data) => data.fieldType == DataSetType.type_booking_system
      );
      console.log("isBookingSystem: " + JSON.stringify(isBookingSystem));
      if (
        isBookingSystem !== undefined &&
        isBookingSystem !== null &&
        isBookingSystem.length > 0
      ) {
        var lookupObj = {};
        lookupObj["targetBooking"] = isBookingSystem[0]["fieldKey"];
        lookupObj["targetRosterCollectionName"] =
          isBookingSystem[0]["target_roster"];
        lookupObj["rosterLookup"] = isBookingSystem[0]["roster_look_up_key"];
        data = { ...data, isBookingSystem: true, lookupObj: lookupObj };
      }

      obj.data = data;
      setItemToEdit(obj);
      setRedirect(`${pathname}-new`);
    }
  }

  const _render_deleteAll_btn = (pathname) => {
    if (check_permission("delete", pathname)) {
      return (
        <Button
          color="rose"
          round
          onClick={() => {
            set_isLoading(true);
            del_multi_doc(checked, []).then((list) => {
              console.log("delete all selected data");

              setChecked([]);
              set_isLoaded(false);
              
            });
          }}
          disabled={!check_permission("delete", pathname)}
        >
          {Loc.delete_selected_data}
        </Button>
      );
    } else {
      return null;
    }
  };

  const _render_addNew_btn = (pathname) => {
    if (check_permission("create", pathname)) {
      return (
        <Button
          key={"addNewBtn"}
          color="success"
          round
          onClick={() => {
            onPressAddNew();
          }}
          disabled={!check_permission("create", pathname)}
        >
          <ICON_ADD />
          {Loc.add_new}
        </Button>
      );
    } else {
      return null;
    }
  };

  const _render_edit_btn = (prop, localizationState, pathname) => {
    if (check_permission("update", pathname)) {
      return (
        <Button
          justIcon
          round
          simple
          onClick={() => {
            var state = {
              data: { ...prop, isNewData: false,isEdit:true },
              config: localizationState,
            };

            setItemToEdit(state);
            setRedirect(`${props.location.pathname}-new`);
          }}
          color="warning"
          className="edit"
          disabled={!check_permission("update", pathname)}
        >
          <Dvr />
        </Button>
      );
    } else {
      return null;
    }
  };

  const _render_delete_btn = (item_id, prop, showDeletedData, pathname) => {
    if (check_permission("delete", pathname)) {
      return (
        <Button
          justIcon
          round
          simple
          onClick={() => {
            showConfirmDeleteAlert(item_id, prop.uid, prop);
          }}
          color="danger"
          className="remove"
          disabled={!check_permission("delete", pathname)}
        >
          {showDeletedData ? <Recover /> : <Close />}
        </Button>
      );
    } else {
      return null;
    }
  };

  const _render_view_btn = (prop, pathname) => {
    return (
      <Button
        justIcon
        round
        simple
        onClick={() => {
          var state = {
            data: { ...prop, isReadOnly: true, isNewData: false,isEdit:true},
            config: localizationState,
          };

          setItemToEdit(state);
          setRedirect(`${pathname}-new`);
        }}
        color="info"
        className="remove"
        disabled={!check_permission("read", pathname)}
      >
        <Visibility />
      </Button>
    );
  };

  function check_permission(permissionType, pathToCheck) {
    var checkPass = false;
    // console.log(`data_adminRole: ${JSON.stringify(data_adminRole)}` + " pathToCheck: " + pathToCheck)
    if (data_adminRole["all"]) {
      return true;
    }

    // for Role Type permission checking
    if (data_adminRole != null && Array.isArray(data_adminRole))
    {
      data_adminRole.forEach((r) => {
        if ("/admin" + r.path == pathToCheck && r[permissionType] == true) {
          checkPass = true;
        }
      });
    }
    // for old Type permission check
    else
    {
      var theKey = pathToCheck.replace("/admin", "");
      // console.log("theKey: " + theKey);
      if (data_adminRole[theKey])
      {
        checkPass = true;
      }
    }

    // console.log("Check permission called: " + permissionType + " " + checkPass);
    // console.log("checkPass: " + pathToCheck + " " + checkPass)
    return checkPass;
  }

  function processDataWithActions(_preloadDataList) {
    // console.log("processDataWithActions  called  "+JSON.stringify(checked))
    var _selectList = {};
    data_dataList.map((data) => {
      _selectList[data.id] = false;
    });

    // console.log("_selectedList  " + JSON.stringify(_selectList))
    // setSelectList(_selectList)

    const newData = data_dataList
      // .filter(v => v.isDeleted != true)
      .map((prop, key) => {
        const item_id = prop.id;

        var createFieldList = localizationState.createFieldList;
        var customItemsList = [];
        createFieldList.map((item) => {
          if (item.isShowInTableView) {
            if (item.fieldType === DataSetType.type_bool) {
              // console.log("Testing check: " + prop[item.fieldKey]);
              if (
                prop[item.fieldKey] != undefined &&
                prop[item.fieldKey] != null
              ) {
                customItemsList.push(prop[item.fieldKey].toString());
              } else {
                customItemsList.push("-");
              }
            } else if (item.fieldType === DataSetType.type_MC) {
              customItemsList.push(getMCString(item, prop[item.fieldKey]));
              // console.log("DataSetType.type_MC  " + JSON.stringify(getMCString(item,prop[item.fieldKey].toString())))
            } else if (
              item.fieldType === DataSetType.type_list ||
              item.fieldType === DataSetType.type_dragdrop_upload
            ) {
              const targetList = prop[item.fieldKey];
              if (targetList) {
                const listUI = targetList.map((text) => {
                  return (
                    <GridItem xs={12} key={text}>
                      {/* <p> */}
                      {text}
                      {/* </p> */}
                    </GridItem>
                  );
                });
                customItemsList.push(listUI);
              } else {
                customItemsList.push("-");
              }
            } else if (item.fieldType == DataSetType.type_firebase_geopoint) {
              var map_pt = prop[item.fieldKey];
              var lat = map_pt["_latitude"];
              var lng = map_pt["_longitude"];
              var str_pt = lat + "," + lng;
              customItemsList.push(str_pt);
            } else if (
              item.fieldType ==
              DataSetType.type_select_from_other_dataset_single
            ) {
              const dic_selectFromOther = prop[item.fieldKey];
              var targetName = "";
              if (dic_selectFromOther) {
                // console.log("check dic_selectFromOther : " + JSON.stringify(dic_selectFromOther));
                if (preloadDataList != null) {
                  const targetDataList =
                    preloadDataList[dic_selectFromOther.targetCollection];

                  // console.log("check data targetDataList: " + JSON.stringify(targetDataList));
                  var targetData = null;
                  if (targetDataList != null) {
                    targetData = targetDataList.find(
                      (data) => data.id === dic_selectFromOther.targetID
                    );
                    // console.log(`targetData: ${JSON.stringify(targetData)}`)
                  }
                  if (targetData != undefined && targetData !== null) {
                    targetName = targetData[item.otherTableShowFieldName];
                    // var isExist = Object.keys(targetData).find(k => k == dic_selectFromOther.targetCollection.replace("data_", "") + "_name");
                    // console.log("isExist: " + isExist);
                    // if (isExist != -1) {
                    //     targetName = targetData[dic_selectFromOther.targetCollection.replace("data_", "") + "_name"];
                    // } else {
                    //     targetName = "-";
                    // }
                  } else {
                    targetName = "-";
                  }
                }

                //getTargetString(dic_selectFromOther.targetCollection.replace("data_", "") + "_name", dic_selectFromOther.targetID, _preloadDataList[dic_selectFromOther.targetCollection]);
                customItemsList.push(targetName);
              } else {
                customItemsList.push("-");
              }
            } else if (item.fieldType == DataSetType.type_time_picker) { 
              if (prop[item.fieldKey] != undefined && prop[item.fieldKey] != null) {
                customItemsList.push(moment(prop[item.fieldKey]).format("HH:mm a"));
              } else {
                customItemsList.push("-");
              }
            }  else if (item.fieldType == DataSetType.type_timestamp) { 
              if (prop[item.fieldKey] != undefined && prop[item.fieldKey] != null) {
                customItemsList.push(moment(prop[item.fieldKey]).format("YYYY-MM-DD HH:mm a"));
              } else {
                customItemsList.push("-");
              }
            }  else if (
              prop[item.fieldKey] === null ||
              prop[item.fieldKey] === ""
            ) {
              customItemsList.push("-");
            } else if (item.fieldType == DataSetType.type_list_object) {
              var list = prop[item.fieldKey];
              customItemsList.push(JSON.stringify(list));
            } else {
              customItemsList.push(prop[item.fieldKey]);
            }
          }
        });
        var newDataList = [
          key + 1,
          ...customItemsList,
          // we've added some custom button actions
        ];

        // add checkbox && edit,delete action if not tableRead Only
        if (
          (localizationState && localizationState.tableReadOnly == null) ||
          (localizationState && localizationState.tableReadOnly == false)
        ) {
          const checkBoxUI = (
            <GridItem xs={12} sm={6}>
              <div
                className={
                  classes_checkbox.checkboxAndRadio +
                  " " +
                  classes_checkbox.checkboxAndRadioHorizontal
                }
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      key={key}
                      className={classes.positionAbsolute}
                      tabIndex={-1}
                      onClick={() => handleToggle(item_id)}
                      checked={checked.indexOf(item_id) != -1}
                      checkedIcon={
                        <Check className={classes_checkbox.checkedIcon} />
                      }
                      icon={
                        <Check className={classes_checkbox.uncheckedIcon} />
                      }
                      classes={{
                        checked: classes_checkbox.checked,
                        root: classes_checkbox.checkRoot,
                      }}
                    />
                  }
                />
              </div>
            </GridItem>
          );

          const actionUI = (
            <div className="actions-right">
              {/* use this button to add a edit kind of action */}
              {_render_view_btn(prop, props.location.pathname)}{" "}
              {_render_edit_btn(
                prop,
                localizationState,
                props.location.pathname
              )}{" "}
              {_render_delete_btn(
                item_id,
                prop,
                showDeletedData,
                props.location.pathname
              )}
              {localizationState.showPrintReceipt && (
                <Button
                  justIcon
                  round
                  simple
                  onClick={() => {
                    console.log("print receipt");
                    const body = {
                      id: null,
                      content: prop,
                    };
                    fetchAPI
                      .do_fetch_local("post", "/get_printer", {}, "30000")
                      .then((result) => {
                        const printer = result.data.printer;
                        fetchAPI
                          .do_fetch("post", "admin/print_receipt", {
                            ...body,
                            printer: printer,
                          })
                          .then(
                            (result) => {
                              const printer_buff = result.data.printer_buff;

                              const print_job_body = {
                                printer_buff: printer_buff,
                                isBarcode: false,
                              };

                              fetchAPI
                                .do_fetch_local(
                                  "post",
                                  "/printJob",
                                  print_job_body,
                                  "30000"
                                )
                                .then((result) => {
                                  console.log(
                                    "printer: " + JSON.stringify(result)
                                  );
                                });
                            },
                            (error) => {}
                          );
                      });
                  }}
                  color="danger"
                  className="remove"
                >
                  <ICON_OUTPUT />
                </Button>
              )}
            </div>
          );

          newDataList = [[checkBoxUI], ...newDataList, ...[actionUI]];
        } else {
          const actionUI = (
            <div className="actions-right">
              {/* use this button to add a edit kind of action */}
              <Button
                justIcon
                round
                simple
                onClick={() => {
                  var state = {
                    data: { ...prop, isReadOnly: true, isNewData: false,isEdit:true },
                    config: localizationState,
                  };

                  setItemToEdit(state);
                  setRedirect(`${props.location.pathname}-new`);
                }}
                color="info"
                className="remove"
              >
                <Visibility />
              </Button>

              {localizationState.showPrintReceipt && (
                <Button
                  justIcon
                  round
                  simple
                  onClick={() => {
                    console.log("print receipt");
                    const body = {
                      id: null,
                      content: prop,
                    };
                    fetchAPI
                      .do_fetch_local("post", "/get_printer", {}, "30000")
                      .then((result) => {
                        const printer = result.data.printer;
                        fetchAPI
                          .do_fetch("post", "admin/print_receipt", {
                            ...body,
                            printer: printer,
                          })
                          .then(
                            (result) => {
                              const printer_buff = result.data.printer_buff;

                              const print_job_body = {
                                printer_buff: printer_buff,
                                isBarcode: false,
                              };

                              fetchAPI
                                .do_fetch_local(
                                  "post",
                                  "/printJob",
                                  print_job_body,
                                  "30000"
                                )
                                .then((result) => {
                                  console.log(
                                    "printer: " + JSON.stringify(result)
                                  );
                                });
                            },
                            (error) => {}
                          );
                      });
                  }}
                  color="danger"
                  className="remove"
                >
                  <ICON_OUTPUT />
                </Button>
              )}
            </div>
          );

          newDataList = [...newDataList, ...[actionUI]];
        }

        return newDataList;
      });

    setData(newData);
  }

  function showConfirmDeleteAlert(targetID, uid, data) {
    setAlert_confirm(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        closeOnClickOutside={false}
        title={
          showDeletedData ? Loc.confirm_recover_title : Loc.confirm_delete_title
        }
        onConfirm={() => {
          hideAlert();
          do_deleteData(targetID, uid, data).then((isSuccess) => { 
            if (isSuccess) {
              if (!isBookingUI) {
                do_loadDataList();
              } else {
                set_isLoaded(false);
              }
            }
          });
        }}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        cancelBtnCssClass={classes_alert.button + " " + classes_alert.danger}
        confirmBtnText={
          showDeletedData
            ? Loc.confirm_recover_confirm
            : Loc.confirm_delete_confirm
        }
        cancelBtnText={Loc.cancel}
        showCancel
      >
        {Loc.are_you_sure}
      </SweetAlert>
    );
  }

  function showAlert(issuccess, title, content) {
    setAlert_info(
      <SweetAlert
        error={!issuccess}
        success={issuccess}
        closeOnClickOutside={false}
        style={{ display: "block", marginTop: "-100px" }}
        title={title}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnText={Loc.confirm}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        btnSize="lg"
      >
        {content}
      </SweetAlert>
    );
  }

  const check_redirect = () => {
    if (pathToRedirect != "") {
      if (itemToEdit != null) {
        return (
          <Redirect
            to={{
              pathname: pathToRedirect,
              state: itemToEdit,
            }}
          />
        );
      } else {
        return <Redirect to={pathToRedirect} />;
      }
    } else {
      return null;
    }
  };
  const showloading = () => {
    var processPercentage = Math.ceil(
      (currentProcessCount / totalProcessCount) * 100
    );
    if (isLoading) {
      return (
        <SweetAlert
          style={{ display: "block", marginTop: "-100px" }}
          closeOnClickOutside={false}
          title={Loc.loading}
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnText={Loc.confirm}
          confirmBtnCssClass={
            classes_alert.button + " " + classes_alert.success
          }
          btnSize="lg"
          showConfirm={false}
          showCancel={false}
        >
          {Loc.please_wait}
          <br />
          {isNaN(processPercentage) ? "" : processPercentage + " %"}
        </SweetAlert>
      );
    } else {
      return null;
    }
  };

  function getTableHeaderList(withoutAction) {
    var tableHeaderList = [];
    if (localizationState && localizationState.tableReadOnly) {
      tableHeaderList = ["#"];
    } else {
      tableHeaderList = ["", "#"];
    }

    if (localizationState && localizationState.createFieldList !== null) {
      var createFieldList = localizationState.createFieldList;
      // console.log(`customFieldList  ${JSON.stringify(data_dataList)}`)
      createFieldList.map((item) => {
        if (item.isShowInTableView) {
          tableHeaderList.push(item.fieldName);
        }
      });
    }
    if (!withoutAction || withoutAction == null) {
      tableHeaderList.push(Loc.actions);
    }

    return tableHeaderList;
  }

  function getCustomCellClassesList() {
    var customHeadCellClassesList = [classes_tableBtn.left];
    if (
      (localizationState && localizationState.tableReadOnly == null) ||
      (localizationState && localizationState.tableReadOnly == false)
    ) {
      customHeadCellClassesList.push(classes_tableBtn.left);
    }

    if (localizationState && localizationState.createFieldList != null) {
      var createFieldList = localizationState.createFieldList;
      createFieldList.map((item) => {
        if (item.isShowInTableView) {
          customHeadCellClassesList.push(classes_tableBtn.center);
        }
      });
      customHeadCellClassesList.push(classes_tableBtn.right);
    }

    // console.log(`checking customHeadCellClassesList: ${JSON.stringify(customHeadCellClassesList)}`)

    return customHeadCellClassesList;
  }

  function getCustomCellStylePos() {
    var customHeaderCellPos = [0];
    if (
      (localizationState && localizationState.tableReadOnly == null) ||
      (localizationState && localizationState.tableReadOnly == false)
    ) {
      customHeaderCellPos.push(1);
    }

    if (localizationState && localizationState.createFieldList != null) {
      var createFieldList = localizationState.createFieldList;
      createFieldList.map((item) => {
        if (item.isShowInTableView) {
          customHeaderCellPos.push(customHeaderCellPos.length);
        }
      });
      customHeaderCellPos.push(customHeaderCellPos.length);
    }

    // console.log(`checking customHeaderCellPos: ${JSON.stringify(customHeaderCellPos)}`)

    return customHeaderCellPos;
  }

  const _renderSearchBar = () => {
    var searchFieldList = [
      {
        itemName: Loc.search_by_date,
        id: "date",
        key: ["createDate"],
        type: "date",
        isPreloadField: false,
      },
    ];

    if (localizationState && localizationState !== null) {
      var createFieldList = localizationState.createFieldList;

      createFieldList.map((data) => {
        const { fieldName, fieldKey, fieldType, isShowInTableView } = data;
        var _fieldKey = fieldKey == "uid" ? "u_i_d" : fieldKey;
        var customSearchField = {
          itemName: fieldName,
          id: _fieldKey,
          key: [_fieldKey],
          type: fieldType,
          isPreloadField: false,
          isCustomField: true,
          otherTableCollectionName: data.otherTableCollectionName,
          otherTableShowFieldName: data.otherTableShowFieldName,
        };
        if (isShowInTableView) {
          searchFieldList.push(customSearchField);
        }
      });

      // console.log("render search bar " + JSON.stringify(createFieldList));
      const target_detail = createFieldList.find(
        (data) => data.fieldKey == "permission_target"
      );

      return (
        <SearchBar
          preloadDataList={preloadDataList}
          searchFieldList={searchFieldList}
          apiPath={"admin/list-custom"}
          loadDataList={() => do_loadDataList()}
          searchResultDataList={(dataList) => {
            setData_dataList(dataList);
          }}
          searchResultDataCount={(count) => {
            setData_dataListCount(count);
          }}
          showDeletedData={showDeletedData}
          locationState={localizationState}
          didSelectedShowDeletedData={(_showDeletedData) => {
            setShowDeletedData(_showDeletedData);
            set_isLoaded(false);
          }}
          showExtendedTable={(bool) => {
            setShowExtendedTable(bool);
          }}
          searchPeriod={(from, to) => {
            setData_startDate(from);
            setData_endDate(to);
          }}
          collectionName={localizationState.collectionName}
          permissionContent={
            localizationState.adminInfo.permission_content != undefined &&
            localizationState.adminInfo.permission_content != null
              ? localizationState.adminInfo.permission_content.targetID
              : null
          }
          permissionTargetDetail={target_detail}
        />
      );
    }
  };

  function _renderPagingTable() {
    return (
      <PagingTable
        tableHead={getTableHeaderList()}
        tableData={data}
        customCellClasses={getCustomCellClassesList()}
        customClassesForCells={getCustomCellStylePos()}
        customHeadCellClasses={getCustomCellClassesList()}
        customHeadClassesForCells={getCustomCellStylePos()}
        gotoPageClicked={(targetPage) => {
          set_page(targetPage);
          set_isLoaded(false);
        }}
        changedPageCount={(newPageCount) => {
          set_page(0);
          set_pageCount(newPageCount);
          set_isLoaded(false);
        }}
        totalDataCount={data_dataListCount}
        page={data_page}
        pageCount={data_pageCount}
      />
    );
  }

  function _renderExtendedTable() {
    return (
      <Table
        tableHead={getTableHeaderList()}
        tableData={data}
        customCellClasses={getCustomCellClassesList()}
        customClassesForCells={getCustomCellStylePos()}
        customHeadCellClasses={getCustomCellClassesList()}
        customHeadClassesForCells={getCustomCellStylePos()}
      />
    );
  }

  const updateData = (result) => {
    var dataList = result.data;
    console.log("csv updated data => " + JSON.stringify(dataList));

    //get data from row four
    const uploadTimes = Math.ceil((dataList.length - 2) / 100);
    // console.log("csv uploadTimes => "+uploadTimes);

    var rowList = [];
    for (var i = 0; i < uploadTimes; i++) {
      rowList.push(i * 100);
    }

    var headerMap = [null];
    var typeMap = {};

    const dataKeys = dataList[0];
    const dataTypes = dataList[1];
    var index = 0;
    for (const key in dataKeys) {
      const dataType = dataTypes[key];

      if (index > 0) {
        if (isVaildField(dataType)) {
          headerMap[index] = dataKeys[key];
          typeMap[dataKeys[key]] = dataType;
        } else {
          headerMap[index] = null;
          typeMap[dataKeys[key]] = null;
        }
      }

      index++;
    }
    // console.log(`typeMap: ${JSON.stringify(typeMap)}`)
    var csvObjList = [];
    // console.log("dataList  "+JSON.stringify(dataList[0]))

    // console.log("dataList.length: " + dataList.length);

    for (var i = 2; i < dataList.length; i++) {
      var data = dataList[i];
      var csvObj = {};

      var index = 0;

      // check if permission_content is required
      if (isPermissionContentRequired != "") {
        console.log(
          "isPermissionContentRequired: " + isPermissionContentRequired
        );
        const data_content = {
          targetID: localizationState.adminInfo.permission_content.targetID
            ? localizationState.adminInfo.permission_content.targetID
            : null,
          targetCollection: isPermissionContentRequired_otherTableCollectionName,
        };
        csvObj[isPermissionContentRequired] = data_content;
      }

      for (const key in data) {
        var _key = headerMap[index];
        // var _type = typeMap[index]
        if (_key != null) {
          // console.log("csv _key => "+_key);
          // if(isVaildField(_type)){
          const dataType = dataTypes[key];
          // console.log("dataType: " + dataType);
          var data_content = data[key] == "" ? null : data[key];
          if (dataType == DataSetType.type_number || dataType == DataSetType.type_timestamp) {
            data_content = parseFloat(data[key]);
          } else if (dataType == DataSetType.type_list) {
            const targetData = data[key]; // should be strings
            data_content = targetData.split(",");
          } else if (dataType == DataSetType.type_firebase_geopoint) {
            const targetData = data[key];
            var arr = targetData.split(",");
            data_content = {
              isGeoPt: true,
              _latitude: parseFloat(arr[0]),
              _longitude: parseFloat(arr[1]),
            };
          } else if (
            dataType == DataSetType.type_select_from_other_dataset_single
          ) {
            const targetData = data[key];
            var arr = targetData.split(" -> ");
            data_content = {
              targetCollection: arr[0],
              targetID: arr[1],
            };

            // console.log(`checking selectFromOtherTable: ${JSON.stringify(data_content)}`);
          } else if (dataType == DataSetType.type_bool) {
            var isTrueSet = data[key].toLowerCase() === "true";
            data_content = isTrueSet;
          } else if (dataType == DataSetType.type_list_object) {
            if (
              data[key] !== undefined &&
              data[key] != null &&
              data[key] != ""
            ) {
              console.log("1662 data[key]: " + data[key]);
              data_content = JSON.parse(data[key]);
            } else {
              data_content = [];
            }
          } else if (dataType == DataSetType.type_booking_system) {
            data_content = {
              isBookingSystem: true,
            };
          }
          csvObj[_key] = data_content;
        }
        index++;
      }

      if (Object.keys(csvObj).length != 0) {
        csvObjList.push(csvObj);
      }

      console.log(`csvObjList: ${JSON.stringify(csvObjList)}`);
      // pureDataList.push(data)
    }
    // console.log("csv data => "+JSON.stringify(csvObjList)+" uploadTimes: "+(dataList.length));

    if (uploadTimes > 0) {
      setTotalProcessCount(uploadTimes);
      set_isLoading(true);
      uploadCsvDatas(csvObjList, rowList, [], 0, typeMap).then((item) => {
        set_isLoading(false);
        setCurrentProcessCount(0);
        setTotalProcessCount(0);
        set_isLoaded(false);
      });
    }

    // console.log("csv csvObjList => "+JSON.stringify(csvObjList));
  };

  function isVaildField(type) {
    var isVaild = false;

    if (
      type == DataSetType.type_string ||
      type == DataSetType.type_bool ||
      type == DataSetType.type_number ||
      type == DataSetType.type_account_phone ||
      type == DataSetType.type_account_email ||
      type == DataSetType.type_account_password ||
      type == DataSetType.type_list ||
      type == DataSetType.type_multiline_text ||
      type == DataSetType.type_select_from_other_dataset_single ||
      type == DataSetType.type_firebase_geopoint ||
      type == DataSetType.type_list_object || 
      type == DataSetType.type_time_picker ||
      type == DataSetType.type_timestamp
    ) {
      isVaild = true;
    }

    return isVaild;
  }

  const handle_firebaseUserRegister = (
    data_account_email,
    data_account_password,
    data_account_phone,
    callback
  ) => {
    console.log("handle_firebaseUserRegister called");
    const body = {
      id: null,
      content: {
        email: data_account_email === "" ? null : data_account_email,
        password: data_account_password === "" ? null : data_account_password,
        phone: data_account_phone == "" ? null : data_account_phone,
      },
    };

    // console.log(`body ${JSON.stringify(body)}`)

    fetchAPI.do_fetch("post", "admin/register-user", body).then(
      (res) => {
        console.log("success: ", res);
        callback(res.data.uid);
      },
      (error) => {
        callback(null);
        console.log("failed: ", error);
      }
    );
  };

  function batchProcess_firebaseRegister(list_org, list_processed, typeMap) {
    return new Promise((onDone) => {
      var newDataList = list_org;
      if (newDataList.length > 0) {
        var newData = newDataList[0];
        newDataList.splice(0, 1);
        var email = "";
        var password = "";
        var phone = "";

        for (const _key in newData) {
          // const value = data[_key]
          const type = typeMap[_key];
          if (type == DataSetType.type_account_phone) {
            phone = newData[_key];
          }

          if (type == DataSetType.type_account_email) {
            email = newData[_key];
          }

          if (type == DataSetType.type_account_password) {
            password = newData[_key];
          }
        }

        handle_firebaseUserRegister(email, password, phone, (uid) => {
          if (uid !== null) {
            console.log("uid: " + uid);
            newData["uid"] = uid;
            list_processed.push(newData);
          } else {
            newData["uid"] = "-1";
            list_processed.push(newData);
          }

          if (newDataList.length > 0) {
            return batchProcess_firebaseRegister(
              newDataList,
              list_processed,
              typeMap
            ).then((final_list_processed) => {
              onDone(final_list_processed);
            });
          } else {
            onDone(list_processed);
          }
        });
      }
    });
  }

  function handle_insertToDatabase(
    newDataList,
    processDataList,
    rowList,
    onHoldList,
    _currentProcessCount,
    typeMap
  ) {
    console.log("handle_insertToDatabase called");
    return new Promise((onDone) => {
      var newProcessCount = _currentProcessCount;
      var newList = rowList;
      var newOnHoldList = onHoldList;
      var newProcessCount = _currentProcessCount;

      // filter fail user create
      var list_filter_without_fail_account = [];
      newDataList.forEach((data) => {
        if (data.uid != "-1") {
          list_filter_without_fail_account.push(data);
        }
      });

      if (list_filter_without_fail_account.length > 0) {
        const body = {
          collectionName: localizationState.collectionName,
          contentList: list_filter_without_fail_account,
        };
        fetchAPI.do_fetch("post", "admin/create-custom-process", body).then(
          (res) => {
            newProcessCount = newProcessCount + 1;
            setCurrentProcessCount(newProcessCount);
            newList.splice(0, 1);

            if (newList.length > 0) {
              // var currentListCount = newList.length
              uploadCsvDatas(
                processDataList,
                newList,
                newOnHoldList,
                newProcessCount,
                typeMap
              ).then((finalOnHoldList) => {
                onDone(finalOnHoldList);
              });
            } else {
              // setCurrentProcessCount(newProcessCount)
              onDone(newProcessCount);
            }
          },
          (error) => {
            console.log("failed: ", error);
            set_isLoading(false);
            showAlert(false, Loc.failed, Loc.data_fetch_failed);
          }
        );
      } else {
        newProcessCount = newProcessCount + 1;
        setCurrentProcessCount(newProcessCount);
        newList.splice(0, 1);

        if (newList.length > 0) {
          // var currentListCount = newList.length
          uploadCsvDatas(
            processDataList,
            newList,
            newOnHoldList,
            newProcessCount,
            typeMap
          ).then((finalOnHoldList) => {
            onDone(finalOnHoldList);
          });
        } else {
          // setCurrentProcessCount(newProcessCount)
          onDone(newProcessCount);
        }
      }
    });
  }

  function uploadCsvDatas(
    processDataList,
    rowList,
    onHoldList,
    _currentProcessCount,
    typeMap
  ) {
    return new Promise((onDone) => {
      var newList = rowList;

      var newDataList = [];

      for (var i = newList[0]; i < newList[0] + 100; i++) {
        if (i < processDataList.length) {
          var data = processDataList[i];
          newDataList.push(data);
        }
      }
      var list_to_process_firebase = [];
      // console.log("batchProcess_firebaseRegister newDataList "+JSON.stringify(newDataList));
      var dataListForImport = [];
      newDataList.map((data) => {
        var email = "";
        var password = "";
        var phone = "";

        for (const _key in data) {
          // const value = data[_key]
          const type = typeMap[_key];
          if (type == DataSetType.type_account_phone) {
            phone = data[_key];
          }

          if (type == DataSetType.type_account_email) {
            email = data[_key];
          }

          if (type == DataSetType.type_account_password) {
            password = data[_key];
          }
        }
        // check and process firebase register
        if ((email != "" && password != "") || phone != "") {
          list_to_process_firebase.push(data);
        } else {
          dataListForImport.push(data);
        }

        console.log(
          "list_to_process_firebase.length: " + list_to_process_firebase.length
        );
        console.log(
          `list_to_process_firebase: ${JSON.stringify(
            list_to_process_firebase
          )}`
        );
      });
      // console.log("dataListForImport: "+JSON.stringify);

      if (list_to_process_firebase.length > 0) {
        batchProcess_firebaseRegister(
          list_to_process_firebase,
          [],
          typeMap
        ).then((list_finished) => {
          console.log(`list_finished: ${JSON.stringify(list_finished)}`);
          // newDataList = {...newDataList, ...list_finished};
          // list_finished.forEach(d => {
          //   newDataList.push(d);
          // });
          console.log("list_finished: " + list_finished);
          dataListForImport = list_finished;
          handle_insertToDatabase(
            dataListForImport,
            processDataList,
            rowList,
            onHoldList,
            _currentProcessCount,
            typeMap
          ).then((res) => {
            onDone(res);
          });
        });
      } else {
        // console.log("handle_insertToDatabase: "+ dataListForImport.length);

        handle_insertToDatabase(
          dataListForImport,
          processDataList,
          rowList,
          onHoldList,
          _currentProcessCount,
          typeMap
        ).then((res) => {
          onDone(res);
        });
      }
    });
  }

  const onFileChange = (event) => {
    // Update the state
    // this.setState({ selectedFile: event.target.files[0] });
    // const fileList = event.target.files;
    // console.log("fileList  :"+fileList)
    const csvfile = event.target.files[0];
    if (csvfile) {
      hideAlert();
      Papa.parse(csvfile, {
        complete: updateData,
        header: true,
      });
    }
    // fileList.map(csvfile=>{

    // })
  };

  function getTargetString(targetKey, targetID, targetList) {
    console.log("targetList: " + JSON.stringify(targetList));
    // var targetString = "";

    // console.log("targetData: " + targetData[targetKey]);
    // if (targetData[targetKey] && targetData[targetKey] != null) {
    //   return targetData[targetKey];

    // } else {
    //   return "";

    // }
  }

  const [data, setData] = React.useState([]);
  const classes = useStyles();
  const classes_alert = useStyles_alert();
  const classes_tableBtn = useStyles_tableBtn();

  var cardTitleStr = "";
  if (localizationState && localizationState.localizationKey !== null) {
    cardTitleStr = localizationState.localizationKey;
    if (Loc.getString(localizationState.localizationKey) !== null) {
      cardTitleStr = Loc.getString(localizationState.localizationKey);
    }
  }

  const renderDefaultUI = () => {
    return (
      <CardBody>
        {((localizationState && localizationState.tableReadOnly == null) ||
          (localizationState && localizationState.tableReadOnly == false)) &&
          _render_addNew_btn(props.location.pathname)}

        {showExcelExport && (
          <Button
            color="success"
            round
            onClick={() => {
              // operation();
              downloadTemplate(true);
            }}
          >
            {Loc.download_excel}
          </Button>
        )}

        {/* <button onClick={this.onFileUpload}> 
                  Upload! 
                </button>  */}
        {showExcelImport && (
          <Button color="rose" round onClick={inputAlert}>
            {Loc.input_excel_data_handle}
          </Button>
        )}
        {((localizationState && localizationState.tableReadOnly == null) ||
          (localizationState && localizationState.tableReadOnly == false)) && (
          <>
            <Button
              color="rose"
              round
              onClick={() => {
                var newChecked = [];
                data_dataList.map((item) => {
                  const id = item.id;
                  newChecked.push(id);
                });
                setChecked(newChecked);
                // no need to load data
                // set_isLoaded(false);
              }}
            >
              {Loc.select_all_data}
            </Button>
            {_render_deleteAll_btn(props.location.pathname)}
          </>
        )}

        {_renderSearchBar()}
        {showExtendedTable ? _renderExtendedTable() : _renderPagingTable()}
      </CardBody>
    );
  };

  return (
    <div>
      {check_redirect()}
      {showloading()}
      {alert_info}
      {alert_confirm}
      {alert_upload}
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{cardTitleStr}</h4>
            </CardHeader>
            {isBookingUI ? (
              <BookingList
                targetBookingCollectionName={targetBookingCollectionName}
                createFieldList={data_createFieldList}
                preloadData={preloadDataList}
                onPressCalendar={() => {
                  var lookupObj = {};
                  const isBookingSystem = data_createFieldList.filter(
                    (data) => data.fieldType == DataSetType.type_booking_system
                  );
                  if (
                    isBookingSystem !== undefined &&
                    isBookingSystem !== null &&
                    isBookingSystem.length > 0
                    ) {
                      
                    console.log("isBookingSystem: " + JSON.stringify(isBookingSystem));
                    lookupObj["targetBooking"] = isBookingSystem[0]["fieldKey"];
                    lookupObj["targetBookingCollectionName"] =
                      targetBookingCollectionName;
                      lookupObj["targetRosterCollectionName"] =
                      isBookingSystem[0]["target_roster"];
                    lookupObj["rosterLookup"] =
                      isBookingSystem[0]["roster_look_up_key"];
                    // lookupObj["lookupID"] =
                    //   data[isBookingSystem[0]["roster_look_up_key"]];

                    // lookupObj["date_timestamp"] = data.date_timestamp;
                    var dateKey = lookupObj["targetBooking"] + "_selectedDate";
                    lookupObj["orderBy"] = dateKey;
                    // lookupObj["roster"] = data.roster;
                    // console.log("isBookingSystem localizationState: " + JSON.stringify(localizationState))
                    lookupObj["redirectPath"] = "/admin/"+localizationState.pathName;
                    console.log("lookupObj: " + JSON.stringify(lookupObj))
                  }
                  var state = {
                    data: { lookupObj: lookupObj },
                    config: localizationState,
                  };

                  setItemToEdit(state);
                  setRedirect("/admin/booking-calendar");
                }}
                onPressCreateNewBooking={() => {
                  onPressAddNew();
                }}
                onPressEditBooking={(prop) => {
                  var state = {
                    data: { ...prop, isNewData: false,isEdit:true },
                    config: localizationState,
                  };

                  setItemToEdit(state);
                  setRedirect(`${props.location.pathname}-new`);
                }}
                onPressDeleteBooking={(id, uid, data) => {
                  console.log("data_selectedData " + JSON.stringify(data));
                  showConfirmDeleteAlert(id, uid, data);
                }}
                needRefresh={!data_isLoaded}
              />
            ) : (
              renderDefaultUI()
            )}
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
