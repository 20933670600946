/*eslint-disable*/
import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';
import InputLabel from "@material-ui/core/InputLabel";
import Datetime from "react-datetime";
import FormControl from "@material-ui/core/FormControl";
import moment from 'moment';
import Dropdown from 'views/CommonUseComponents/Dropdown.js';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// material ui icons
import MailOutline from "@material-ui/icons/MailOutline";
import Contacts from "@material-ui/icons/Contacts";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";


// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import ReactTable from "components/ReactTable/ReactTable.js";

// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import styles_alert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import ImageUpload from "components/CustomUpload/ImageUpload.js";

import ICON_BACK from "@material-ui/icons/ArrowBackIos";
import ICON_DONE from "@material-ui/icons/Done";
import Danger from "components/Typography/Danger";
import Success from "components/Typography/Success";

const useStyles = makeStyles(styles);
const useStyles_alert = makeStyles(styles_alert);

import SweetAlert from "react-bootstrap-sweetalert";

import Loc from "localization";
import fetchAPI from "connectionHandler/FetchAPI.js";

export default function Commission_add(props) {

      // type validation
  const [isEdit, setIsEdit] = React.useState(false);

  const [data_id, setData_id] = React.useState("");
  const [data_name, setData_itemName] = React.useState("");
  const [data_amount, setData_amount] = React.useState("");
  const [data_amount_org, setData_amount_org] = React.useState("");
  const [data_remark, setData_remark] = React.useState("");

  const data_targetCollection = "Commission";

  const [data_date, setData_date] = React.useState(new Date());
  const [dateState, setdateState] = React.useState("");
  const [data_storeID, setData_storeID] = React.useState("");
  const [data_staffID, setData_staffID] = React.useState("");
  const [data_storeName, setData_storeName] = React.useState("");
  const [data_staffName, setData_staffName] = React.useState("");
  const [data_commissionType, setData_commissionType] = React.useState("pay_commission");
  const [data_expenditureID, setData_expenditureID] = React.useState("");
  

  const [pathToRedirect, setRedirect] = React.useState("");
  const [emptyState, setEmptyState] = React.useState("");
  const [fetchingStatus, setFetchingStatus] = React.useState("");

  const collectionsToLoad = ["Store", "Staff"];
  const [data_dataListArray, setData_dataListArray] = React.useState(null);
  const [isPreLoadedData, setIsPreLoadedData] = React.useState(false);
  const [isLoadedEditData, setIsLoadedEditData] = React.useState(false);

  const [locationState, setLocationState] = React.useState(null);
  const [historyState, setHistoryState] = React.useState(null);
  // alert
  const [alert_save, setSaveAlert] = React.useState(null);
  const hideAlert = () => {
    setSaveAlert(null);
  }

  React.useEffect(() => {  
    var preloadListStr = localStorage.getItem("preloadDataList")
    var preloadList = JSON.parse(preloadListStr);

    if (!locationState || locationState == null) {
        setLocationState(props.location.state);
        setHistoryState(props.history.location.state);
        // getConfigList();
      } else{

        if (data_dataListArray === null ) {
            // const stateConfig = locationState.config;
            // console.log("stateConfig  " + JSON.stringify(stateConfig))
    
            if (preloadList["Store"].length > 0) {
              setData_storeID(preloadList["Store"][0].id);
              setData_storeName(preloadList["Store"][0].itemName);
            }
    
            if (preloadList["Staff"].length > 0) {
              setData_staffID(preloadList["Staff"][0].id);
              setData_staffName(preloadList["Staff"][0].itemName);
            }
    
            setData_dataListArray(preloadList);
          }

          if (data_dataListArray !== null && locationState !== null && locationState.data !== null && !isLoadedEditData)
          {
                const stateData = locationState.data;

              setIsEdit(true);
              setIsLoadedEditData(true);
      
              setData_id(stateData.id);
              setData_itemName(stateData.itemName);
              setData_amount(stateData.amount);
              setData_amount_org(stateData.amount);
              setData_remark(stateData.remark);
              setData_date(stateData.date);
              setData_storeID(stateData.storeID);
              setData_staffID(stateData.staffID);
              setData_storeName(stateData.storeName);
              setData_staffName(stateData.staffName);
              setData_commissionType(stateData.type);
              setData_expenditureID(stateData.expenditureID);
          }
      }

    // pre load data for edit

    


    // Specify how to clean up after this effect:
    return function cleanup() {
        
    };
  }, [data_dataListArray,locationState]);

  const validCheck = () => {
    var result = true;
    if (data_amount == "")
    {
        setEmptyState("error");
        showAlert(false, Loc.failed, Loc.errorMsg_empty);
        result = false;
    }
    else if (data_amount <= 0)
    {
        showAlert(false, Loc.failed, Loc.errorMsg_value_negative);
        result = false;
    }
    else if (dateState === "error")
    {
        showAlert(false, Loc.failed, Loc.errorMsg_date_empty);
        result = false;
    }

    return result;
  }

  const backClicked = () => {
    setRedirect("/admin/commission-list");
  }

  const doneClicked =() => {
    setFetchingStatus("fetching");
    if (validCheck())
    {
        const expenditureBody = get_expenditureBody();
        const expenditureID = expenditureBody.id;
        const expenditureContent = expenditureBody.content;

        do_submit(expenditureID, expenditureContent).then((commissionID) => {
            if (commissionID != null)
            {
                setFetchingStatus("");
                showAlert(true, Loc.success, Loc.data_save_success);
            }
            else
            {
                setFetchingStatus("");
                showAlert(false, Loc.failed, Loc.data_save_failed);
            }
        });
    }
    else
    {
        setFetchingStatus("");
    }
  }

  const getSelectMenuItem = (fieldName) => {
    const fieldList =
      data_dataListArray == null ? [] : data_dataListArray[fieldName];
    const menuItems = [];

    if (fieldList && fieldList.length > 0) {
      if (fieldName === "Staff") {
        // Sort staff by store
        const restructuredData = Object.values(fieldList.reduce((acc, cur) => {
          if (!acc["other"]) { //without store yet
            acc["other"] = { storeName: "Other", staffList: [] };
          }
          if (!acc[cur.storeName]) {
            acc[cur.storeName] = { storeName: cur.storeName, staffList: [] };
          }
          if (cur.storeName != undefined && cur.storeName != null && cur.storeName != "") {
            acc[cur.storeName].staffList.push({ ...cur });
          } else {
            acc["other"].staffList.push({ ...cur });
          }
          return acc;
        }, {}));
        // console.log(`restructuredData ${JSON.stringify(restructuredData)}`)
        restructuredData.map((data, key) => {
          const { storeName, staffList } = data;
          menuItems.push(<InputLabel
            htmlFor="simple-select"
            className={classes.selectLabel}
          >
            {storeName}
          </InputLabel>);
          staffList.map((item, key) => {
            menuItems.push(
              <MenuItem
                key={key + item.id}
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected,
                }}
                value={item.id}
              >
                {item.itemName}
              </MenuItem>
            );
          });
        });
      } else {
        fieldList.map((item, key) => {
          menuItems.push(
            <MenuItem
              key={key}
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value={item.id}
            >
              {item.itemName}
            </MenuItem>
          );
        });
        
      }
    }

    return menuItems;
  };
  const getStringToShow = (defaultStr, fieldList, fieldName, id) => {
    // console.log(`getStringToShow ${fieldName} ===>${id}`);
    var strToShow = defaultStr;

    if (fieldList.length > 0) {
      const fieldNameList = getDropDownNameList(fieldName);
      strToShow = fieldNameList[0];
      fieldList.map((prop, key) => {
        if (prop.id === id) {
          strToShow = prop.itemName;
        }
      });
    }
    return strToShow;
  };


  const getDropDownNameList = (fieldName) => {
    const fieldList =
      data_dataListArray == null ? [] : data_dataListArray[fieldName];
    const fieldNameList = [];
    if (fieldList.length > 0) {
      fieldList.map((item) => {
        fieldNameList.push(item.itemName);
      });
    }
    return fieldNameList;
  };

  function get_expenditureBody()
  {
    if (data_commissionType === "pay_commission")
    {
        var idToPass = isEdit ? data_expenditureID : null;
        idToPass = (idToPass === "") ? null : idToPass;

        const body = {
            "id":idToPass,
            "content":{
                "itemName":Loc.commission_expenditure,
                "amount":data_amount,
                "date":new Date(data_date).getTime(),
                "storeID":data_storeID,
                "staffID":data_staffID,
                "storeName":data_storeName,
                "staffName":data_staffName,
                "type":"commission",
                "remark":""
            }
        }

        return body;
    }
    else if (data_expenditureID !== "")
    {
        const body = {
            "id":data_expenditureID,
            "content":{
            }
        }

        return body;
    }
    else
    {
        const body = {
            "id":null,
            "content":null,
        }
        return body;
    }
  }

  function do_submit(expenditureID, expenditureContent)
  {
    return new Promise((onDone) => {
        const oldValue = (data_commissionType === "pay_commission") ? (-data_amount_org) : data_amount_org;
        const newValue = (data_commissionType === "pay_commission") ? (-data_amount) : data_amount
        const diffValue = newValue - oldValue;

        const idToPass = isEdit ? data_id : null;
        console.log("idToPass: ", idToPass);
        const body = {
            "id":idToPass,
            "content":{
                "itemName":data_name,
                "amount":data_amount,//(data_commissionType === "pay_commission") ? -data_amount : data_amount,
                "remark":data_remark,
                "date":new Date(data_date).getTime(),
                "staffID":data_staffID,
                "storeID":data_storeID,
                "storeName":data_storeName,
                "staffName":data_staffName,
                "type":data_commissionType,
                "diffValue":diffValue,
            },
            "expenditureContent": expenditureContent,
            "expenditureID":expenditureID,
        }

        console.log("do_submit body: ", body);

        fetchAPI.do_fetch('post', 'admin/create-commission', body)
        .then((res) => {
            console.log("success: ", res);
            onDone(res.data.item_id);
        }, error => {
            console.log("failed: ", error);
            onDone(null);
        })
    });
  }

  function showAlert(issuccess, title, content){
    setSaveAlert(
      <SweetAlert
        error={!issuccess}
        success={issuccess}
        style={{ display: "block", marginTop: "-100px" }}
        closeOnClickOutside={false}
        title={title}
        onConfirm={() => {
            if (issuccess)
            {
                // hideAlert()
                setRedirect("/admin/commission-list");
            }
            else
            {
                hideAlert()
            }
        }}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        cancelBtnCssClass={classes_alert.button + " " + classes_alert.info}
        onCancel={() => {
            if (issuccess)
            {
                setData_itemName("");
                setData_amount("");
                setData_remark("");
                setData_date(new Date());
                // window.location.reload(false);
                hideAlert()
            }
            else
            {
                hideAlert()
            }
            
        }}
        confirmBtnText={issuccess ? Loc.done : Loc.confirm}
        cancelBtnText={Loc.continue}
        btnSize="lg"
        showCancel={issuccess && !isEdit}
      >
        {content}
      </SweetAlert>
    );
  }

  const handleChangeRadio = event => {
    setData_commissionType(event.target.value);
  };

  const check_redirect = () => {
      if (pathToRedirect != "")
      {
          return (<Redirect to={{ pathname: pathToRedirect, state: historyState.config }} />);
      }
      else
      {
          return null;
      }
  }

  const imageDidUpload = (pathInStorage) => {
      if (pathInStorage != null)
      {
        setData_imageUrl(pathInStorage);
        console.log("imageDidUpload called, path: ", pathInStorage);
      }
  }

  const getDebtorName = (targetID) => {
      var targetname = "";
      if (data_customerList != null)
      {
        data_customerList.map((customer, key) => {
            if (customer.id === targetID)
            {
                targetname = customer.itemName;
            }
        });
      }

      return targetname;
  }
  
    const classes = useStyles();
    const classes_alert = useStyles_alert();

    return (
    <div>
        {check_redirect()}
        {alert_save}
        <GridContainer>
        <GridItem xs={6}>
            <Card>
            <CardHeader color="rose" text>
                <CardText color="rose">
                    <h4 className={classes.cardTitle}>{Loc.receivable_add}</h4>
                </CardText>
            </CardHeader>
            <CardBody>
                <form>
                <GridContainer>

                    <GridItem sm={4}>
                    <FormControl
                    fullWidth
                    className={
                      classes.selectFormControl +
                      " " +
                      classes.formControlMargins
                    }
                  >
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={data_storeID}
                      onChange={(event) => {
                        const selectedID = event.target.value;
                        setData_storeName(
                          getStringToShow(
                            Loc.store_default,
                            data_dataListArray == null
                              ? []
                              : data_dataListArray["Store"],
                            "Store",
                            selectedID
                          )
                        );
                        setData_storeID(selectedID);
                        console.log("selectedID  " + selectedID);
                        console.log("setData_storeName  " + data_storeName);
                      }}
                      inputProps={{
                        name: data_storeName,
                        id: data_storeID,
                      }}
                    >
                      {getSelectMenuItem("Store")}
                    </Select>
                  </FormControl>
                    </GridItem>
                    <GridItem sm={4}>
                    <FormControl
                    fullWidth
                    className={
                      classes.selectFormControl +
                      " " +
                      classes.formControlMargins
                    }
                  >
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={data_staffID}
                      onChange={(event) => {
                        const selectedID = event.target.value;
                        setData_staffID(selectedID);
                        setData_staffName(
                          getStringToShow(
                            Loc.staff_default,
                            data_dataListArray == null
                              ? []
                              : data_dataListArray["Staff"],
                            "Staff",
                            selectedID
                          )
                        );
                      }}
                      inputProps={{
                        name: data_staffName,
                        id: data_staffID,
                      }}
                    >
                      {getSelectMenuItem("Staff")}
                      {/* {} */}
                    </Select>
                  </FormControl>
                    </GridItem>
                    <GridItem sm={4}>
                        <FormControl fullWidth>
                            <Datetime
                                timeFormat={true}
                                defaultValue={data_date}
                                value={data_date == null ? new Date(): moment(data_date)}
                                inputProps={{ 
                                    placeholder: Loc.sales_date,
                                }}
                                onChange={m => {
                                    if (moment.isMoment(m))
                                    {
                                        setdateState("success");
                                        setData_date(m.toDate());
                                    }
                                    else{
                                        setdateState("error");
                                    }
                                }}
                            />
                        </FormControl>
                    </GridItem> 

                   

                    <GridItem xs={12} sm={6}>
                        <div
                            className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                            }
                        >
                        <FormControlLabel
                            control={
                                <Radio
                                checked={data_commissionType === "pay_commission"}
                                onChange={handleChangeRadio}
                                value="pay_commission"
                                name={Loc.pay_commission}
                                aria-label="pay_commission"
                                icon={
                                    <FiberManualRecord
                                    className={classes.radioUnchecked}
                                    />
                                }
                                checkedIcon={
                                    <FiberManualRecord
                                    className={classes.radioChecked}
                                    />
                                }
                                classes={{
                                    checked: classes.radio,
                                    root: classes.radioRoot
                                }}
                                />
                            }
                            classes={{
                                label: classes.label,
                                root: classes.labelRoot
                            }}
                            label={Loc.pay_commission}//{<Success></Success>}
                        />
                        </div>

                        <div
                            className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                            }
                        >
                        <FormControlLabel
                        control={
                            <Radio
                            checked={data_commissionType === "sales_commission"}
                            onChange={handleChangeRadio}
                            value="sales_commission"
                            name={Loc.sales_commission}
                            aria-label="sales_commission"
                            icon={
                                <FiberManualRecord
                                className={classes.radioUnchecked}
                                />
                            }
                            checkedIcon={
                                <FiberManualRecord
                                className={classes.radioChecked}
                                />
                            }
                            classes={{
                                checked: classes.radio,
                                root: classes.radioRoot
                            }}
                            />
                        }
                        classes={{
                            label: classes.label,
                            root: classes.labelRoot
                        }}
                        label={Loc.sales_commission}
                        />
                        </div>
                        <div
                            className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                            }
                        >
                        <FormControlLabel
                            control={
                                <Radio
                                checked={data_commissionType === "redeem_commission"}
                                onChange={handleChangeRadio}
                                value="redeem_commission"
                                name={Loc.redeem_commission}
                                aria-label="redeem_commission"
                                icon={
                                    <FiberManualRecord
                                    className={classes.radioUnchecked}
                                    />
                                }
                                checkedIcon={
                                    <FiberManualRecord
                                    className={classes.radioChecked}
                                    />
                                }
                                classes={{
                                    checked: classes.radio,
                                    root: classes.radioRoot
                                }}
                                />
                            }
                            classes={{
                                label: classes.label,
                                root: classes.labelRoot
                            }}
                            label={Loc.redeem_commission}
                        />
                        </div>
                        <div
                            className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                            }
                        >
                        <FormControlLabel
                            control={
                                <Radio
                                checked={data_commissionType === "adjust_commission"}
                                onChange={handleChangeRadio}
                                value="adjust_commission"
                                name={Loc.adjust_commission}
                                aria-label="adjust_commission"
                                icon={
                                    <FiberManualRecord
                                    className={classes.radioUnchecked}
                                    />
                                }
                                checkedIcon={
                                    <FiberManualRecord
                                    className={classes.radioChecked}
                                    />
                                }
                                classes={{
                                    checked: classes.radio,
                                    root: classes.radioRoot
                                }}
                                />
                            }
                            classes={{
                                label: classes.label,
                                root: classes.labelRoot
                            }}
                            label={Loc.adjust_commission}
                        />
                        </div>
                    </GridItem>

                    <GridItem xs={12}>
                        <CustomInput
                            error={emptyState === "error"}
                            labelText={Loc.receivable_amount+"*"}
                            id="receivable_amount"
                            formControlProps={{
                            fullWidth: true
                            }}
                            inputProps={{
                                value: data_amount,
                                onChange: event => {
                                    setEmptyState("success");
                                    setData_amount(event.target.value);
                                },
                                type: "number",
                            }}
                        />
                    </GridItem>  

                    <GridItem xs={12}>
                        <CustomInput
                            // success={requiredState === "success"}
                            labelText={Loc.commission_name}
                            id="commission_name"
                            formControlProps={{
                            fullWidth: true
                            }}
                            inputProps={{
                                value: data_name,
                                onChange: event => {
                                    setData_itemName(event.target.value);
                                },
                                type: "text",
                            }}
                        />
                    </GridItem>  

                    <GridItem xs={12}>
                    <CustomInput
                        labelText={Loc.remark}
                        id="remark"
                        formControlProps={{
                        fullWidth: true
                        }}
                        inputProps={{
                            value: data_remark,
                            onChange: event => {
                                setData_remark(event.target.value);
                            },
                            type: "text",
                        }}
                    />
                    </GridItem>                 

                </GridContainer>
                
                </form>
            </CardBody>
            <CardFooter className={classes.justifyContentCenter}>
                <GridContainer spacing={2}
                direction="row"
                justify="center"
                alignItems="center">
                    <GridItem xs={6} align="center">
                        <Button onClick={backClicked}>
                        <ICON_BACK />
                        {Loc.back}
                        </Button>
                    </GridItem>

                    <GridItem xs={6} align="center">
                        <Button color="rose" onClick={doneClicked} disabled={fetchingStatus==="fetching" ? true : false}>
                        <ICON_DONE />
                        {isEdit ? Loc.save : Loc.create}
                        </Button>
                    </GridItem>
                </GridContainer>
            </CardFooter>
            </Card>
        </GridItem>
        </GridContainer>
    </div>
  );
}