/*eslint-disable*/
import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// @material-ui/core components
// .
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomTextField from "components/CustomTextField/CustomTextField";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import IconButton from "@material-ui/core/IconButton";

// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import styles_alert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styles_switch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";

import Datetime from "react-datetime";
import moment from "moment";
import firebase from "connectionHandler/firebase";
import Config from "itchi_config";

import ICON_BACK from "@material-ui/icons/ArrowBackIos";
import ICON_DONE from "@material-ui/icons/Done";

const useStyles = makeStyles(styles);
const useStyles_alert = makeStyles(styles_alert);

import SweetAlert from "react-bootstrap-sweetalert";

import Loc from "localization";
import fetchAPI from "connectionHandler/FetchAPI.js";
import DataSetType from "Data_set_type";

export default function PushNotification_new(props) {
    const [alert_save, setSaveAlert] = React.useState(null);
    const hideAlert = () => {
        setSaveAlert(null);
    };

    const [isEdit, setIsEdit] = React.useState(false);
    const [pathToRedirect, setRedirect] = React.useState("");
    const [fetchingStatus, setFetchingStatus] = React.useState("");
    const [localizationState, setLocalizationState] = React.useState(null);
    const [historyState, setHistoryState] = React.useState(null);

    const classes = useStyles();
    const classes_alert = useStyles_alert();

    // Data for this page
    const [data_title, setData_title] = React.useState("");
    const [data_message, setData_message] = React.useState("");
    const [data_topic, setData_topic] = React.useState("");
    const [data_deviceType, setData_deviceType] = React.useState("all");

    const [data_registrationToken, setData_registrationToken] = React.useState("");
    const [data_notificationData, setData_notificationData] = React.useState("");

    

    const [inputStates_title, setInputStates_title] = React.useState(false);
    const [inputStates_message, setInputStates_message] = React.useState(false);
    const [inputStates_topic, setInputStates_topic] = React.useState(false);
    const [inputStates_registrationToken, setInputStates_registrationToken] = React.useState(false);
    const [inputStates_notificationData, setInputStates_notificationData] = React.useState(false);


    const [notificationType, setData_notificationType] = React.useState("topic");




    // ----end----

    React.useEffect(() => {
        if (props.location.state != null)
        {
            var data_state = props.location.state;

            setData_notificationType(data_state.data.notificationType);
        }
        else
        {
            setLocalizationState(props.location.state);
        }

        setHistoryState(props.history.location.state);
    }, [localizationState, historyState]);



    const backClicked = () => {
        var newRedirect = props.location.pathname.replace("-new", "") + "-list";

        setRedirect(newRedirect);
    };

    const doneClicked = () => {
        setFetchingStatus("fetching");
        if (validate_input())
        {
            var body = {
                title: data_title,
                message: data_message,
            }
            var apiPath = "admin/create-push-notification";

            if (notificationType == "topic")
            {
                body.topicPath = data_topic;
                body.deviceType = data_deviceType;

                apiPath += "-topic";
            }
            else
            {
                body.registrationToken = data_registrationToken;
                body.notificationData = data_notificationData;

                apiPath += "-specific";
            }



            fetchAPI.do_fetch("post", apiPath, body).then(
                (res) => {
                  console.log("success: ", res);
                  setFetchingStatus("");
                  showAlert(true, Loc.success, Loc.push_notification_success);
                  // setData_itemName("");
                },
                (error) => {
                  console.log("failed: ", error);
                  setFetchingStatus("");
                  showAlert(false, Loc.failed, Loc.push_notification_fail);
                }
              );
        }
        else
        {
            setFetchingStatus("");
        }
    };

    function validate_input()
    {
        var result = true;

        if (data_title === "")
        {
            setInputStates_title(true);
            result = false;
        } 
        if (data_message === "")
        {
            setInputStates_message(true);

            result = false;
        }

        if (notificationType == "topic")
        {
            if (data_topic === "")
            {
                setInputStates_topic(true);
    
                result = false;
            }
        }
        else if (notificationType == "specific")
        {
            if (data_registrationToken === "")
            {
                setInputStates_registrationToken(true);
    
                result = false;
            }
        }
        
        return result;
    }

    function showAlert(issuccess, title, content) {
        setSaveAlert(
          <SweetAlert
            error={!issuccess}
            success={issuccess}
            style={{ display: "block", marginTop: "-100px" }}
            closeOnClickOutside={false}
            title={title}
            onConfirm={() => {
              if (issuccess) {
                setRedirect(`${props.location.pathname.replace("-new", "") + "-list"}`);
              } else {
                hideAlert();
              }
            }}
            confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
            cancelBtnCssClass={classes_alert.button + " " + classes_alert.info}
            onCancel={() => {
              if (issuccess) {
                // setData_title("");
                // setData_message("");
                // setData_topic("");

                hideAlert();
              } else {
                hideAlert();
              }
            }}
            confirmBtnText={issuccess ? Loc.done : Loc.confirm}
            cancelBtnText={Loc.continue}
            btnSize="lg"
            showCancel={issuccess && !isEdit}
          >
            {content}
          </SweetAlert>
        );
      }
    
      const check_redirect = () => {
        if (pathToRedirect != "") {
          return (
            <Redirect
              to={{
                pathname: pathToRedirect,
                state: historyState.config,
              }}
            />
          );
        } else {
          return null;
        }
      };

    function render_types_content()
    {
        console.log("notificationType: " + notificationType);
        if (notificationType == "topic")
        {
            return (
                <>
                    {/* <GridItem xs={12}>
                    <InputLabel
                        htmlFor="simple-select"
                        className={classes.selectLabel}
                    >
                        {Loc.push_notification_deviceType}
                    </InputLabel>
                    <FormControl fullWidth className={classes.selectFormControl}>
                        <Select
                            MenuProps={{
                                className: classes.selectMenu,
                            }}
                            classes={{
                                select: classes.select,
                            }}
                            value={data_deviceType}
                            onChange={(event) => {
                            // console.log("_render_cardBody_selectFromOther_single onChange triggered");
                                setData_deviceType(event.target.value);
                            }}
                            inputProps={{
                                name: Loc.push_notification_deviceType,
                                id: data_deviceType,
                            }}
                        >
                            <MenuItem
                                key={"all"}
                                classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected,
                                }}
                                value={"all"}
                            >
                                {Loc.push_notification_deviceType_all}
                            </MenuItem>

                            <MenuItem
                                key={"ios_only"}
                                classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected,
                                }}
                                value={"ios_only"}
                            >
                                {Loc.push_notification_deviceType_ios}
                            </MenuItem>

                            <MenuItem
                                key={"android_only"}
                                classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected,
                                }}
                                value={"android_only"}
                            >
                                {Loc.push_notification_deviceType_android}
                            </MenuItem>
                        </Select>
                    </FormControl>
                    </GridItem> */}

                    <GridItem xs={12}>
                        <CustomInput
                            // success={data_title != ""}
                            error={inputStates_topic}
                            labelText={Loc.push_notification_topic}
                            id="push_notification_topic" //{`${fieldName}_name`}
                            formControlProps={{
                                fullWidth: true,
                            }}
                            inputProps={{
                                placeholder: "topic_name/topic_ID",
                                value: data_topic,
                                onChange: (event) => {
                                    setInputStates_topic(false);
                                    setData_topic(event.target.value);
                                },
                                type: "text",
                            }}
                        />
                    </GridItem>
                </>
            )
        }
        else if (notificationType == "specific")
        {
            return (
                <>
                    <GridItem xs={12}>
                        <CustomInput
                            // success={data_title != ""}
                            error={inputStates_registrationToken}
                            labelText={Loc.push_notification_registrationToken}
                            id="push_notification_registrationToken" //{`${fieldName}_name`}
                            formControlProps={{
                                fullWidth: true,
                            }}
                            inputProps={{
                                value: data_registrationToken,
                                onChange: (event) => {
                                    setInputStates_registrationToken(false);
                                    setData_registrationToken(event.target.value);
                                },
                                type: "text",
                            }}
                        />
                    </GridItem>

                    <GridItem xs={12}>
                        <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                        >
                            {Loc.push_notification_notificationData}
                        </InputLabel>
                        <Card>
                            <CardBody>
                                <TextField
                                    error={inputStates_notificationData}
                                    id="push_notification_notificationData" //{`${fieldName}_name`}
                                    multiline={true}
                                    rows={3}
                                    fullWidth={true}
                                    inputProps={{
                                        value:data_notificationData,
                                        onChange: (event) => {
                                            setInputStates_notificationData(false);
                                            setData_notificationData(event.target.value)
                                        },
                                        type:"text"
                                    }}
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                </>
            )
        }
    }


    return (
    <div>
        {check_redirect()}
        {alert_save}
        <GridContainer>
        <GridItem xs={12}>
            <Card>
            <CardHeader color="rose" text>
                <CardText color="rose">
                <h4 className={classes.cardTitle}>{Loc.push_notification_form_title}</h4>
                </CardText>
            </CardHeader>

            <CardBody>
                <form>
                <GridContainer>
                    <GridItem xs={12}>
                        <CustomInput
                            // success={data_title != ""}
                            error={inputStates_title}
                            labelText={Loc.push_notification_title + " *"}
                            id="push_notification_title" //{`${fieldName}_name`}
                            formControlProps={{
                                fullWidth: true,
                            }}
                            inputProps={{
                                value: data_title,
                                onChange: (event) => {
                                    setInputStates_title(false);
                                    setData_title(event.target.value);
                                },
                                type: "text",
                            }}
                        />
                    </GridItem>

                    <GridItem xs={12}>
                        <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                        >
                            {Loc.push_notification_message + " *"}
                        </InputLabel>

                        <Card>
                            <CardBody>
                                <TextField
                                    error={inputStates_message}
                                    // labelText={Loc.push_notification_message + " *"}
                                    id="push_notification_message" //{`${fieldName}_name`}
                                    multiline={true}
                                    rows={3}
                                    fullWidth={true}
                                    // formControlProps={{
                                    //   fullWidth: true,
                                    // }}
                                    inputProps={{
                                        value:data_message,
                                        onChange: (event) => {
                                            setInputStates_message(false);
                                            setData_message(event.target.value)
                                        },
                                        type:"text"
                                    }}
                                />
                            </CardBody>
                        </Card>
                        
                    </GridItem>

                    
                    {render_types_content()}
                    
                </GridContainer>
                </form>
            </CardBody>

            <CardFooter className={classes.justifyContentCenter}>
                <GridContainer
                spacing={2}
                direction="row"
                justify="center"
                alignItems="center"
                >
                    <GridItem xs={6} align="center">
                        <Button onClick={backClicked} disabled={fetchingStatus === "fetching" ? true : false}>
                        <ICON_BACK />
                        {Loc.back}
                        </Button>
                    </GridItem>

                    <GridItem xs={6} align="center">
                        <Button
                            color="rose"
                            onClick={doneClicked}
                            disabled={fetchingStatus === "fetching" ? true : false}
                        >
                            <ICON_DONE />
                            {Loc.push_notification_send}
                        </Button>
                    </GridItem>
                </GridContainer>
            </CardFooter>
            </Card>
        </GridItem>
        </GridContainer>
    </div>
    );
}
