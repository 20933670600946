import Loc from "localization";

import ICON_ADD from "@material-ui/icons/Add";
import ICON_STAFFLIST from "@material-ui/icons/ListAlt";
import ICON_SALES from "@material-ui/icons/TrendingUp";
import ICON_CUSTOMER_NEW from "@material-ui/icons/PersonAdd";
import ICON_CUSTOMER_REPAYMENT from "@material-ui/icons/AttachMoney";
import ICON_SALES_LIST from '@material-ui/icons/Receipt';
import ICON_SEARCH from '@material-ui/icons/Search';

const dashboardPermissionData = [
    {
      path: "/sales-list",
      name: Loc.sales_records,
      color: "success",
      icon: ICON_SALES_LIST,
      type:"shortcut",
      stateType: "type_1"
    },
    {
      path: "/sales-new",
      name: Loc.sales_add,
      color: "success",
      icon: ICON_ADD,
      type:"shortcut",
      stateType: "type_2"
    },
    {
      path: "/customer-new",
      name: Loc.customer_new,
      color: "info",
      icon: ICON_CUSTOMER_NEW,
      type:"shortcut",
      stateType: "type_2"
    },
    {
      path: "/receivable-new",
      name: Loc.receivable_repayment,
      color: "info",
      icon: ICON_CUSTOMER_REPAYMENT,
      type:"shortcut",
      stateType: "type_2"
    },
    {
      path: "/customer-coupon",
      name: Loc.check_customer,
      color: "primary",
      icon: ICON_SEARCH,
      type:"shortcut",
      stateType: "type_2"
    },
    {
      path: "/admin-staff-onDuty-list",
      name: Loc.staff_onDuty_list,
      color: "warning",
      icon: ICON_STAFFLIST,
      type:"shortcut",
      stateType: "type_1"
    },






    {
      path: "/statistics_sales",
      name: Loc.statistics_sales,
      color: "primary",
      icon: ICON_SALES,
      type:"statistics_sales"
    },

    // receivable_repayment
    // 
];

export default dashboardPermissionData;