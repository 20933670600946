import {routes} from "routes.js";
import {SystemType} from "Util/SystemType.js";
import Config from "itchi_config";
import React from "react";
import Custom_list from 'views/CustomPage/Custom_list.js';
import Custom_add from "views/CustomPage/Custom_add.js";
import Custom_setting_add from "views/CustomPage/Custom_setting_add.js";
import Loc from "localization";

var newDataList = [];//originRoutes;

function getMergedRoutesList(
  _setDataManagementList,
  _setDataManagementTypeList,
  _setCustomSettingConfigList
) 
{
  newDataList = [routes[0]];//originRoutes;
  const setDataManagementList = _setDataManagementList; // Sub cell data
  const setDataManagementTypeList = _setDataManagementTypeList; //Main cell data
  const setCustomSettingConfigList = _setCustomSettingConfigList;//Custom setting
  if (
    setDataManagementTypeList !== null
  ) 
  {
    var setDataTypeList = []

    var setDataCreateFieldList = [];
   

    setDataManagementTypeList.map((item) => {
      const setDataTypeItem = {
        collapse: true,
        isShowInSide: true,
        name: Loc.getString(item.setDataName) === null ? item.setDataName : Loc.getString(item.setDataName),
        icon: getSetDataIcon(item.iconName),
        state: item.id,
        views: [],
        setDataManagementTypeID:item.id
      };
      
      setDataTypeList.push(setDataTypeItem)
    });
    
   if (
    setDataManagementList !== null
  ) {
    setDataManagementList.map((dataItem,index) => {
    // console.log(`setDataManagementList  ${index}`)
    setDataTypeList.map((type,index)=>{
            
                
                // console.log(`customsList setDataTypeList =======> ${JSON.stringify(type)}`)
                if (dataItem.setDataManagementTypeID == type.setDataManagementTypeID){ //same Main cell will set same 
                    
                    // console.log(`customsList dataItem =======> ${JSON.stringify(dataItem)}`)
                        const setDataItemView_list = {
                        isShowInSide: true,
                        path: `/${dataItem.pathName}`,
                        name: Loc.getString(dataItem.localizationKey) === null ? dataItem.localizationKey : Loc.getString(dataItem.localizationKey),
                        mini: dataItem.shortTerm,
                        component: Custom_list,
                        layout: "/admin",
                        config: dataItem,
                        flag: SystemType.Custom,
                      };
                      
                      const setDataItemView_add = {
                        isShowInSide: false,
                        path: `/${dataItem.pathName}-new`,
                        name: Loc.add_new,
                        mini: dataItem.shortTerm,
                        component: Custom_add,
                        layout: "/admin",
                        flag: SystemType.Custom,
                      };

                    setDataTypeList[index].views.push(setDataItemView_list)
                    setDataTypeList[index].views.push(setDataItemView_add)
                    
                }  
            })
          });
        }
       
        if(setCustomSettingConfigList && setCustomSettingConfigList !== null){
          console.log("route setCustomSettingConfigList  "+JSON.stringify(setCustomSettingConfigList))
          setCustomSettingConfigList.map(configItem=>{
            setDataTypeList.map((type,index)=>{
              // console.log(`customsList setDataTypeList =======> ${JSON.stringify(type)}`)
              if (configItem.setDataManagementTypeID == type.setDataManagementTypeID){ //same Main cell will set same 
                  console.log(`customsList dataItem =======> ${JSON.stringify(configItem)}`)
                    const setDataItemView_add = {
                      isShowInSide: true,
                      path: `/${configItem.pathName}-new`,
                      name: configItem.localizationKey,
                      mini: configItem.shortTerm,
                      component: Custom_setting_add,
                      layout: "/admin",
                      flag: SystemType.Custom,
                      config: configItem,
                    };

                  setDataTypeList[index].views.push(setDataItemView_add)
                  
              }  
          
        }
        
        );

          })
        }

        //Merge the routes list
        
        if(setDataTypeList.length > 0){
            setDataTypeList.map(item=>{
                newDataList.push(item);
            });
        }

        for(var i=1; i<routes.length; i++)
        {
          newDataList.push(routes[i]);
        }

        // filter routes with flag
        var filteredList = filterWithFlag(newDataList);

        // console.log(`filteredList ${JSON.stringify(filteredList)}`)
        // return newDataList;
        return filteredList;
  }

  

  // setDataManagementList !== null &&
    // setDataManagementList.length > 0
  function getSetDataIcon(iconName) {
    try {
        const CustomIcon = require(`@material-ui/icons/${iconName}`).default;
        return CustomIcon;
      } catch (ex) {
        return null;
      }
  }

  //   console.log(` data_dataList  ${JSON.stringify(data_dataList)}`);
  // var newRoutesList = {
  //
  //   }
}

function filterWithFlag(targetList)
  {
    var filteredList = [];
    targetList.map((item, key) => {
      // console.log("item: " + item);
      if (item.collapse)
      {
        const filteredViews = filterWithFlag(item.views);
        var newItem = item;
        newItem.views = filteredViews;
        filteredList.push(newItem);
      }
      else
      {
        if (Config.targetFlags.includes(item.flag))
        {
          filteredList.push(item);
        }
      }
    });

    return filteredList;
  }

export { getMergedRoutesList, filterWithFlag };
