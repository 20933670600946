const DataSetType = {
  type_string: "string",
  type_number: "number",
  type_bool: "bool",
  type_MC: "MC",
  type_date: "date",
  type_account_phone: "account_phone",
  type_account_email: "account_email",
  type_account_password: "account_password",
  type_account_password_confirm: "account_password_confirm",
  type_upload_image_single: "upload_image_single",
  type_upload_image_multi: "upload_image_multi",
  type_select_from_other_dataset: "select_from_other_dataset",
  type_select_from_other_dataset_single: "select_from_other_dataset_single",
  type_select_from_other_dataset_permission: "select_from_other_dataset_permission",
  type_list: "list",
  type_multiline_text: "text_area",
  type_dragdrop_upload: "dragdrop_upload",
  type_md_editor: "md_editor",
  type_firebase_geopoint: "firebase_geopoint",
  type_list_object: "list_object",
  type_account_permission_role: "account_permission_role",
  type_booking_system: "booking_system",
  type_roster: "roster",
  type_uuid: "uuid",
  type_time_picker: "time_picker",
  type_timestamp: "timestamp",
};

export default DataSetType;
