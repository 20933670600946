/*eslint-disable*/
import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";

// material ui icons
import MailOutline from "@material-ui/icons/MailOutline";
import Contacts from "@material-ui/icons/Contacts";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

// import routes from "routes.js";
import dashboardPermissionData from "dashboardPermissions.js";
import bossPermissionData from "bossPermissions.js";
// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import styles_alert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styles_checkbox from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";

import PictureUpload from "components/CustomUpload/PictureUpload.js";

import ICON_BACK from "@material-ui/icons/ArrowBackIos";
import ICON_DONE from "@material-ui/icons/Done";
import * as RoutesHelper from "Helper/RoutesHelper";

const useStyles = makeStyles(styles);
const useStyles_alert = makeStyles(styles_alert);
const useStyles_checkbox = makeStyles(styles_checkbox);

import SweetAlert from "react-bootstrap-sweetalert";

import Loc from "localization";
import fetchAPI from "connectionHandler/FetchAPI.js";
import Config from "itchi_config";
import { SystemType } from "Util/SystemType.js";

export default function Admin_permission_add(props) {
  const [isEdit, setIsEdit] = React.useState(false);

  const [data_id, setData_id] = React.useState("");
  const [data_adminLevelName, setData_adminLevelName] = React.useState("");
  const [data_permissionList, setData_permissionList] = React.useState({});
  const [
    data_dashboardPermissionList,
    setData_dashboardPermissionList,
  ] = React.useState({});
  const [
    data_bossPermissionList,
    setData_bossPermissionList,
  ] = React.useState({});
  const [
    data_customButtonPermissionList,
    setData_customButtonPermissionList,
  ] = React.useState({});

  const [adminLevelNameState, setAdminLevelNameState] = React.useState("");

  const [
    data_permissionCheckList,
    setData_permissionCheckList,
  ] = React.useState(null);
  const [
    data_dashboardPermissionCheckList,
    setData_dashboardPermissionCheckList,
  ] = React.useState(null);
  const [
    data_bossPermissionCheckList,
    setData_bossPermissionCheckList,
  ] = React.useState(null);

  const [
    data_customButtonCheckList,
    setData_customButtonCheckList,
  ] = React.useState(null);

  const [
    data_SetDataManagementList,
    setData_SetDataManagementList,
  ] = React.useState(null);
  const [
    data_SetDataManagementTypeList,
    setData_SetDataManagementTypeList,
  ] = React.useState(null);
  const [data_CustomSettingConfigList,setData_CustomSettingConfigList] = React.useState(null);
  const [data_CustomButtonConfigList,setData_CustomButtonConfigList] = React.useState(null);


  const [pathToRedirect, setRedirect] = React.useState("");
  const [fetchingStatus, setFetchingStatus] = React.useState("");
  const [isLoading, set_isLoading] = React.useState(false);
  const [historyState,setHistoryState] = React.useState(null)

  const classes = useStyles();
  const classes_alert = useStyles_alert();
  const classes_checkbox = useStyles_checkbox();

  // alert
  const [alert_save, setSaveAlert] = React.useState(null);
  const [isERP, setIsERP] = React.useState(null);
  const hideAlert = () => {
    setSaveAlert(null);
  };

  React.useEffect(() => {
    setHistoryState(props.history.location.state)
    if (data_CustomSettingConfigList == null){
      get_CustomSettingConfig((settingList) => {
        get_CustomButtonConfig((buttonList) => {
          setup(settingList,buttonList);

        });
      })

    }else{
      // setup();
    }
   
        // console.log(`get_SetDataManagementConfig  ${routes}`)
      // init page permission list
      
  
    if (isERP == null) {
      var _isERP = Config.targetFlags.includes(SystemType.ERP);
      setIsERP(_isERP);
    }
    // Specify how to clean up after this effect:
    return function cleanup() {};
  }, [data_CustomSettingConfigList]);

  function setup(settingList,buttonList){
    get_SetDataManagementConfig(settingList, (routes) => {
      const allRoutes = getRoutes(routes, [], "/dashboard");
      var defaultPermissionList = { "/dashboard": true };
      allRoutes.map((p, k) => {
        defaultPermissionList[p.path] = false;
      });

      // init dashboard permission list
      var defaultDashboardPermissionList = {};
      dashboardPermissionData.map((p, k) => {
        defaultDashboardPermissionList[p.path] = false;
      });

      console.log(
        "defaultDashboardPermissionList: ",
        defaultDashboardPermissionList
      );

      var defaultBossPermissionList = {};
      bossPermissionData.map((p, k) => {
        defaultBossPermissionList["isEnabled"] = false;
      });
  
      setData_permissionList(defaultPermissionList);
      setData_dashboardPermissionList(defaultDashboardPermissionList);
      setData_permissionCheckList(
        permissionCheckList(allRoutes, defaultPermissionList)
      );
      setData_dashboardPermissionCheckList(
        dashboardPermissionCheckList(
          dashboardPermissionData,
          defaultDashboardPermissionList
        )
      );
      setData_bossPermissionCheckList(
        bossPermissionCheckList(
          bossPermissionData,
          defaultBossPermissionList
        )
      );
      var defaultButtonPermissionList = {};
      buttonList.map((p, k) => {
        // console.log("p: ", JSON.stringify(p.button_name));
        defaultButtonPermissionList[p.button_name] = false;
      });
      
      setData_customButtonCheckList(
        customButtonCheckList(
          buttonList,
          defaultButtonPermissionList
        )
      )
          // pre load data for edit
  if (props.location.state.data != null) {

    var dataToEdit = props.location.state.data;
      setIsEdit(true);
      setData_id(dataToEdit.id);
      setData_adminLevelName(dataToEdit.itemName);
      setData_permissionList(dataToEdit.permissions);
      // console.log(
      //   "dataToEdit.permissions: ",
      //   dataToEdit.permissions
      // );
      // console.log(
      //   "dataToEdit.dashboardPermissions: ",
      //   dataToEdit.dashboardPermissions
      // );
      console.log(
        "dataToEdit.permissions: ",
        JSON.stringify(dataToEdit)
      );
      setData_permissionCheckList(
        permissionCheckList(allRoutes, dataToEdit.permissions)
      );
      setData_dashboardPermissionCheckList(
        dashboardPermissionCheckList(
          dashboardPermissionData,
          dataToEdit.dashboardPermissions == null
            ? defaultDashboardPermissionList
            : dataToEdit.dashboardPermissions
        )
      );

      setData_bossPermissionCheckList(
        bossPermissionCheckList(
          bossPermissionData,
          dataToEdit.bossPermissions == null
            ? defaultBossPermissionList
            : dataToEdit.bossPermissions
        )
      );
    
    setData_customButtonCheckList(
      customButtonCheckList(
        buttonList,
        dataToEdit.customButtonPermissions == null
        ? defaultButtonPermissionList
        : dataToEdit.customButtonPermissions
      )
    );

    }

  })
  }

  const validCheck = () => {
    var result = true;
    if (data_adminLevelName == "") {
      result = false;
    }
    return result;
  };

  const backClicked = () => {
    setRedirect("/admin/admin-permission-list");
  };

  const doneClicked = () => {
    setFetchingStatus("fetching");
    if (validCheck()) {
      const idToPass = isEdit ? data_id : null;
      console.log("idToPass: ", idToPass);
      // console.log("bossPermissions ==>" + JSON.stringify(data_bossPermissionList))
      const body = {
        id: idToPass,
        content: {
          itemName: data_adminLevelName,
          permissions: data_permissionList,
          dashboardPermissions: data_dashboardPermissionList,
          bossPermissions: data_bossPermissionList,
          customButtonPermissions: data_customButtonPermissionList
        },
      };
      fetchAPI.do_fetch("post", "admin/create-admin-level", body).then(
        (res) => {
          console.log("success: ", res);
          setFetchingStatus("");
          showAlert(true, Loc.success, Loc.data_save_success);
        },
        (error) => {
          console.log("failed: ", error);
          setFetchingStatus("");
          showAlert(false, Loc.failed, Loc.data_save_failed);
        }
      );
    } else {
      setAdminLevelNameState("error");
      setFetchingStatus("");
      showAlert(false, Loc.failed, Loc.data_save_failed);
    }
  };
  function get_CustomSettingConfig(Callback)
  {
    set_isLoading(true);
    const body = {
      data_targetCollection: "CustomSettingConfig",
    }
    fetchAPI.do_fetch('post', 'admin/list-all', body)
    .then((res) => {
        console.log("get_CustomSettingConfig success: ", res.data.data_list);
        var newDataList = res.data.data_list;
        newDataList = newDataList.filter(d => (d.isDeleted == false || d.isDeleted == undefined));
        setData_CustomSettingConfigList(newDataList);
        console.log("setData_CustomSettingConfigList: ", newDataList);

        Callback(newDataList);
    }, error => {
        console.log("failed: ", error);
        showAlert(false, Loc.failed, Loc.data_fetch_failed);
    })
  }

  function get_CustomButtonConfig(Callback)
  {
    set_isLoading(true);
    const body = {
      data_targetCollection: "CustomButtonConfig",
    }
    fetchAPI.do_fetch('post', 'admin/list-all', body)
    .then((res) => {
        console.log("get_CustomButtonConfig success: ", res.data.data_list);
        var newDataList = res.data.data_list;
        newDataList = newDataList.filter(d => (d.isDeleted == false || d.isDeleted == undefined));
        setData_CustomButtonConfigList(newDataList);
        console.log("setData_CustomButtonConfigList: ", newDataList);

        Callback(newDataList);
    }, error => {
        console.log("failed: ", error);
        showAlert(false, Loc.failed, Loc.data_fetch_failed);
    })
  }

  function get_SetDataManagementConfig(settingList,Callback) {
    set_isLoading(true);
    const body = {
        getFullList:true
    };
    fetchAPI
      .do_fetch("post", "admin/list-set-data-management-config", body)
      .then(
        (res) => {
          // set_isLoading(false);
          // console.log("success: ", res.data);
          setData_SetDataManagementList(res.data.data_list);
          get_SetDataManagementTypeConfig(settingList,res.data.data_list,((routes)=>{
            Callback(routes)
          }))
        },
        (error) => {
          console.log("failed: ", error);
          // set_isLoading(false);
          showAlert(false, Loc.failed, Loc.data_fetch_failed);
        }
      );
  }

  function get_SetDataManagementTypeConfig(settingList,_setDataManagementList,Callback) {
    set_isLoading(true);
    const body = {
        getFullList:true
    };
    fetchAPI
      .do_fetch("post", "admin/list-set-data-management-type-config", body)
      .then(
        (res) => {
          set_isLoading(false);
          // console.log("settingList: ", settingList);
          setData_SetDataManagementTypeList(res.data.data_list);
          const routes = RoutesHelper.getMergedRoutesList(_setDataManagementList,res.data.data_list,settingList);
          Callback(routes)
        },
        (error) => {
          console.log("failed: ", error);
          set_isLoading(false);
          showAlert(false, Loc.failed, Loc.data_fetch_failed);
          callback(false);
        }
      );
  }

  function showAlert(issuccess, title, content) {
    setSaveAlert(
      <SweetAlert
        error={!issuccess}
        success={issuccess}
        style={{ display: "block", marginTop: "-100px" }}
        title={title}
        closeOnClickOutside={false}
        onConfirm={() => {
          if (issuccess) {
            // hideAlert()
            setRedirect("/admin/admin-permission-list");
          } else {
            hideAlert();
          }
        }}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        cancelBtnCssClass={classes_alert.button + " " + classes_alert.info}
        onCancel={() => {
          if (issuccess) {
            setData_adminLevelName("");
            setData_permissionList({});
            setAdminLevelNameState("");
            hideAlert();
          } else {
            hideAlert();
          }
        }}
        confirmBtnText={issuccess ? Loc.done : Loc.confirm}
        cancelBtnText={Loc.continue}
        btnSize="lg"
        showCancel={issuccess && !isEdit}
      >
        {content}
      </SweetAlert>
    );
  }

  const check_redirect = () => {
    if (pathToRedirect != "") {
      return <Redirect to={{
        pathname: pathToRedirect,
        state: historyState.config,
      }} />;
    } else {
      return null;
    }
  };

  const getRoutes = (routes, returnArray, ignorePath) => {
    var newReturnArray = returnArray;
    routes.map((prop, key) => {
      if (prop.collapse) {
        newReturnArray = getRoutes(prop.views, newReturnArray, ignorePath);
      }
      //   else if (prop.path === ignorePath)
      //   {
      //     var newList = data_permissionList;
      //     newList[ignorePath] = true;
      //     setData_permissionList(newList);
      //   }
      else if (
        prop.layout === "/admin" &&
        prop.path !== ignorePath &&
        prop.isShowInSide
      ) {
        const item = {
          path: prop.path,
          name: prop.name,
        };
        newReturnArray.push(item);
      }
    });

    return newReturnArray;
  };

  const handleTogglePermission = (targetPath, permissionList, allRoutes) => {
    var newList = permissionList;
    newList[targetPath] = newList[targetPath] === true ? false : true;
    setData_permissionList(newList);
    setData_permissionCheckList(permissionCheckList(allRoutes, permissionList));
  };

  const handleToggleDashboardPermission = (
    targetPath,
    permissionList,
    allRoutes
  ) => {
    var newList = permissionList;
    newList[targetPath] = newList[targetPath] === true ? false : true;
    setData_dashboardPermissionList(newList);
    setData_dashboardPermissionCheckList(
      dashboardPermissionCheckList(allRoutes, permissionList)
    );
  };

  const handleToggleBossPermission = (
    targetPath,
    permissionList,
    allRoutes
  ) => {
    var newList = permissionList;
    newList.isEnabled = !newList.isEnabled
    console.log("new Lisy ===>  "+ JSON.stringify(newList) )
    setData_bossPermissionList(newList);
    setData_bossPermissionCheckList(
      bossPermissionCheckList(allRoutes, permissionList)
    );
  };


  const handleToggleCustomButtonPermission = (
    targetPath,
    permissionList,
    allRoutes
  ) => {
    var newList = permissionList;
    newList[targetPath] = newList[targetPath] === true ? false : true;
    setData_customButtonPermissionList(newList);
    setData_customButtonCheckList(
      customButtonCheckList(allRoutes, permissionList)
    );
  };

  const permissionCheckList = (allRoutes, permissionList) => {
    const checkboxList = allRoutes.map((r, k_r) => {
      return (
        <div
          key={k_r}
          className={
            classes_checkbox.checkboxAndRadio +
            " " +
            classes_checkbox.checkboxAndRadioHorizontal
          }
        >
          <FormControlLabel
            control={
              <Checkbox
                tabIndex={-1}
                onClick={() =>
                  handleTogglePermission(r.path, permissionList, allRoutes)
                }
                checked={permissionList[r.path]}
                checkedIcon={<Check className={classes_checkbox.checkedIcon} />}
                icon={<Check className={classes_checkbox.uncheckedIcon} />}
                classes={{
                  checked: classes_checkbox.checked,
                  root: classes_checkbox.checkRoot,
                }}
              />
            }
            classes={{ label: classes_checkbox.label }}
            label={r.name}
          />
        </div>
      );
    });
    return (
      <div>
        <h5>
          <small>{Loc.select_permission_page}</small>
        </h5>
        {checkboxList}
      </div>
    );
  };

  const dashboardPermissionCheckList = (
    allDashboardPermission,
    permissionList
  ) => {
    const checkboxList = allDashboardPermission.map((r, k_r) => {
      return (
        <div
          key={k_r}
          className={
            classes_checkbox.checkboxAndRadio +
            " " +
            classes_checkbox.checkboxAndRadioHorizontal
          }
        >
          <FormControlLabel
            control={
              <Checkbox
                tabIndex={-1}
                onClick={() =>
                  handleToggleDashboardPermission(
                    r.path,
                    permissionList,
                    allDashboardPermission
                  )
                }
                checked={permissionList[r.path]}
                checkedIcon={<Check className={classes_checkbox.checkedIcon} />}
                icon={<Check className={classes_checkbox.uncheckedIcon} />}
                classes={{
                  checked: classes_checkbox.checked,
                  root: classes_checkbox.checkRoot,
                }}
              />
            }
            classes={{ label: classes_checkbox.label }}
            label={r.name}
          />
        </div>
      );
    });
    return (
      <div>
        <h5>
          <small>{Loc.select_permission_dashboard}</small>
        </h5>
        {checkboxList}
      </div>
    );
  };

  const bossPermissionCheckList = (
    allBossPermission,
    permissionList
  ) => {
    console.log("bossPermissionCheckList  "+JSON.stringify(permissionList))
    const checkboxList = allBossPermission.map((r, k_r) => {
      return (
        <div
          key={k_r}
          className={
            classes_checkbox.checkboxAndRadio +
            " " +
            classes_checkbox.checkboxAndRadioHorizontal
          }
        >
          <FormControlLabel
            control={
              <Checkbox
                tabIndex={-1}
                onClick={() =>
                  handleToggleBossPermission(
                    r.isEnabled,
                    permissionList,
                    allBossPermission
                  )
                }
                checked={permissionList["isEnabled"]}
                checkedIcon={<Check className={classes_checkbox.checkedIcon} />}
                icon={<Check className={classes_checkbox.uncheckedIcon} />}
                classes={{
                  checked: classes_checkbox.checked,
                  root: classes_checkbox.checkRoot,
                }}
              />
            }
            classes={{ label: classes_checkbox.label }}
            label={r.name}
          />
        </div>
      );
    });
    return (
      <div>
        <h5>
          <small>{Loc.select_permission_boss}</small>
        </h5>
        {checkboxList}
      </div>
    );
  };

  const customButtonCheckList = (
    allBossPermission,
    permissionList
  ) => {
    console.log("customButtonCheckList  "+JSON.stringify(permissionList))
    const checkboxList = allBossPermission.map((r, k_r) => {
      return (
        <div
          key={k_r}
          className={
            classes_checkbox.checkboxAndRadio +
            " " +
            classes_checkbox.checkboxAndRadioHorizontal
          }
        >
          <FormControlLabel
            control={
              <Checkbox
                tabIndex={-1}
                onClick={() =>
                  handleToggleCustomButtonPermission(
                    r.button_name,
                    permissionList,
                    allBossPermission
                  )
                }
                checked={permissionList[r.button_name]}
                checkedIcon={<Check className={classes_checkbox.checkedIcon} />}
                icon={<Check className={classes_checkbox.uncheckedIcon} />}
                classes={{
                  checked: classes_checkbox.checked,
                  root: classes_checkbox.checkRoot,
                }}
              />
            }
            classes={{ label: classes_checkbox.label }}
            label={r.button_name}
          />
        </div>
      );
    });
    return (
      <div>
        <h5>
          <small>{Loc.select_permission_button}</small>
        </h5>
        {checkboxList}
      </div>
    );
  };

  const showloading = () => {
    if (isLoading) {
      return (
        <SweetAlert
          style={{ display: "block", marginTop: "-100px" }}
          closeOnClickOutside={false}
          title={Loc.loading}
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnText={Loc.confirm}
          confirmBtnCssClass={
            classes_alert.button + " " + classes_alert.success
          }
          btnSize="lg"
          showConfirm={false}
          showCancel={false}
        >
          {Loc.please_wait}
        </SweetAlert>
      );
    } else {
      return null;
    }
  };

  return (
    <div>
      {check_redirect()}
      {showloading()}
      {alert_save}
      <GridContainer>
        <GridItem xs={6}>
          <Card>
            <CardHeader color="rose" text>
              <CardText color="rose">
                <h4 className={classes.cardTitle}>{Loc.basic_info}</h4>
              </CardText>
            </CardHeader>
            <CardBody>
              <form>
                <GridContainer>
                  {/* Admin level name */}
                  <GridItem xs={12}>
                    <CustomInput
                      // success={requiredState === "success"}
                      error={adminLevelNameState === "error"}
                      labelText={Loc.admin_level_name + "*"}
                      id="admin_level_name"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: data_adminLevelName,
                        onChange: (event) => {
                          setAdminLevelNameState("success");
                          setData_adminLevelName(event.target.value);
                        },
                        type: "text",
                      }}
                    />
                  </GridItem>

                  <GridItem xs={3}>
                    <GridContainer>
                      {/* Admin permission list */}
                      <GridItem xs={12}>{data_permissionCheckList}</GridItem>
                    </GridContainer>
                  </GridItem>

                  {isERP ? (<><GridItem xs={3}>
                    <GridContainer>
                      {/* Dashboard permission list */}
                      <GridItem xs={12}>
                        {data_dashboardPermissionCheckList}
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                  <GridItem xs={3}>
                    <GridContainer>
                      {/* Dashboard permission list */}
                      <GridItem xs={12}>
                        {data_bossPermissionCheckList}
                      </GridItem>
                    </GridContainer>
                  </GridItem></>):null}
                  <GridItem xs={3}>
                    <GridContainer>
                      {/* Dashboard permission list */}
                      <GridItem xs={12}>
                        {data_customButtonCheckList}
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </form>
            </CardBody>
            <CardFooter className={classes.justifyContentCenter}>
              <GridContainer
                spacing={2}
                direction="row"
                justify="center"
                alignItems="center"
              >
                <GridItem xs={6} align="center">
                  <Button onClick={backClicked}>
                    <ICON_BACK />
                    {Loc.back}
                  </Button>
                </GridItem>

                <GridItem xs={6} align="center">
                  <Button
                    color="rose"
                    onClick={doneClicked}
                    disabled={fetchingStatus === "fetching" ? true : false}
                  >
                    <ICON_DONE />
                    {isEdit ? Loc.save : Loc.create}
                  </Button>
                </GridItem>
              </GridContainer>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
