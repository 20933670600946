/*eslint-disable*/
import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";

import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import InputLabel from "@material-ui/core/InputLabel";
import Datetime from "react-datetime";
import FormControl from "@material-ui/core/FormControl";
import moment from "moment";
import StoreDropdown from "views/CommonUseComponents/StoreDropdown.js";
import Dropdown from "views/CommonUseComponents/Dropdown.js";
import TagsInput from "react-tagsinput";

// material ui icons
import MailOutline from "@material-ui/icons/MailOutline";
import Contacts from "@material-ui/icons/Contacts";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import SwitchUI from "@material-ui/core/Switch";
import ICON_ADD from "@material-ui/icons/Add";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";

// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import styles_alert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styles_switch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import ImageUpload from "components/CustomUpload/ImageUpload.js";

import ICON_BACK from "@material-ui/icons/ArrowBackIos";
import ICON_DONE from "@material-ui/icons/Done";

const useStyles = makeStyles(styles);
const useStyles_alert = makeStyles(styles_alert);
const useStyles_switch = makeStyles(styles_switch);

import SweetAlert from "react-bootstrap-sweetalert";

import Loc from "localization";
import fetchAPI from "connectionHandler/FetchAPI.js";

import Chip from "@material-ui/core/Chip";
import FaceIcon from "@material-ui/icons/Face";

export default function BookingDetail(props) {
  // type validation
  const [isEdit, setIsEdit] = React.useState(false);

  const [data_id, setData_id] = React.useState("");
  const [data_name, setData_itemName] = React.useState("");

  const [data_date, setData_date] = React.useState(null);
  const [data_staffID, setData_staffID] = React.useState("");
  const [data_customID, setData_customID] = React.useState("");
  const [data_customName, setData_customName] = React.useState("");
  const [data_storeID, setData_storeID] = React.useState("");
  const [data_storeName, setData_storeName] = React.useState("");
  const [data_staffName, setData_staffName] = React.useState("");
  const [data_rosterList, setData_rosterList] = React.useState([]);
  const [data_previousRosterId, setData_previousRosterId] = React.useState("");
  const [data_previousRosterDate, setData_previousRosterDate] = React.useState(
    null
  );

  const [currentIndex, setCurrentIndex] = React.useState(-1);
  const [data_dataListArray, setData_dataListArray] = React.useState(null);
  const [data_isPreLoadData, setData_preLoadData] = React.useState(false);
  const [isLoading, set_isLoading] = React.useState(false);

  const [pathToRedirect, setRedirect] = React.useState("");

  const [dateState, setdateState] = React.useState("");

  const [fetchingStatus, setFetchingStatus] = React.useState("");
  const [data_adminInfo, setData_adminInfo] = React.useState(null);
  // const [isPreLoadedData, setIsPreLoadedData] = React.useState(false);
  // const [data_store, setData_store] = React.useState(null);
  // const [data_staff, setData_staff] = React.useState(null);
  const [locationState, setLocationState] = React.useState(null);
  const [historyState, setHistoryState] = React.useState(null);
  const [data_customizedTable, setData_customizedTable] = React.useState([]);
  const [data_lookupObj, setData_lookupObj] = React.useState(null);
  // alert
  const [alert_save, setSaveAlert] = React.useState(null);
  const [data_showButton, setData_showButton] = React.useState(true);
  const [isReadOnlyMode, set_isReadOnlyMode] = React.useState(false);
  const hideAlert = () => {
    setSaveAlert(null);
  };
  const {
    preloadCollectionList,
    bookingDate,
    showButton,
    targetMatchID,
    isComponent,
    dataToEdit,
    isEditMode,
    isReadOnly,
    lookupObj,
    backPathname,
    onPressSubmit,
    onPressChip,
    onChangeDate,
  } = props;

  React.useEffect(() => {
    // var preloadListStr = localStorage.getItem("preloadDataList");
    // var preloadList = JSON.parse(preloadListStr);

    // if (!locationState || locationState == null) {
    //   setLocationState(props.location.state);
    //   setHistoryState(props.history.location.state);

    //   // setData_adminInfo(props.location.state.config.adminInfo)
    // } else {
    console.log("isComponent  " + isComponent);
    if (data_dataListArray === null) {
      // const stateConfig = locationState.config;
      if (showButton !== undefined) {
        setData_showButton(showButton);
      }
      // console.log("BookingDetail props: ", JSON.stringify(props));
      var _preloadCollectionList =
        preloadCollectionList == undefined
          ? ["Staff", "data_roster"]
          : preloadCollectionList;
      getListOfCollection([..._preloadCollectionList,"SetDataManagementConfig"], {}).then((preloadList) => {
        var staffList = preloadList["Staff"];
        // const customTables = preloadList["SetDataManagementConfig"];
        // setData_customizedTable(customTables);
        setData_staffID(staffList[0].id);
        setData_staffName(staffList[0].itemName);
        setData_dataListArray(preloadList);
        if (bookingDate !== undefined) {
          setData_date(bookingDate);
        }
        if (lookupObj !== undefined) {
          console.log("BD lookupObj " + JSON.stringify(lookupObj));
          setData_lookupObj(lookupObj);
        }
      });

      //   var adminInfo = props.location.state.config.adminInfo;

      //   if(adminInfo.storeID){
      //     console.log("Admin Store :"+ adminInfo.storeName)
      //     setData_storeID(adminInfo.storeID);
      //     setData_storeName(adminInfo.storeName);
      //   }
      //   console.log("data_adminInfo "+JSON.stringify(props.location.state.config.adminInfo))

      // if (preloadList["Staff"].length > 0) {

      // }

      // }

      // set to edit
    } else {
      if (data_lookupObj == undefined || data_lookupObj == null) {
        return;
      }
      // const stateData = props.location.state.data;

      if (isEditMode && dataToEdit !== undefined && dataToEdit !== null) {
        set_isReadOnlyMode(isReadOnly);

        // var dataToEdit = stateData;
        setIsEdit(true);
        setData_preLoadData(true);
        console.log("Book detail dataToEdit: ", JSON.stringify(dataToEdit));
        console.log("data_lookupObj:  " + JSON.stringify(data_lookupObj));
        setData_id(dataToEdit.id);
        setData_date(moment(dataToEdit.date_timestamp));
        setData_staffID(dataToEdit[data_lookupObj["rosterLookup"]]);
        setData_staffName(dataToEdit.staffName);
        setData_previousRosterId(dataToEdit.roster.id);
        setData_previousRosterDate(dataToEdit[data_lookupObj["orderBy"]]);
        console.log("dataToEdit[data_lookupObj[orderBy]]:  "+JSON.stringify(dataToEdit[data_lookupObj["orderBy"]]))
        set_isLoading(true);
        get_roster_list(
          dataToEdit[data_lookupObj["rosterLookup"]],
          dataToEdit[data_lookupObj["orderBy"]],
          (rosterList) => {
            var index = 0;
            rosterList.map((item, key) => {
              if (item.id == dataToEdit.roster.id) {
                index = key;
              }
            });
            console.log("get_roster_list index: ", index);
            setCurrentIndex(index);
          }
        );
      }
    }

    // Specify how to clean up after this effect:
    return function cleanup() {};
  }, [data_dataListArray,dataToEdit,data_lookupObj]);

  React.useEffect(() => {
    set_isLoading(true);
    get_roster_list(data_staffID, data_date, (list) => {
      setCurrentIndex(-1);
      if (onPressChip) {
        onPressChip(null,data_previousRosterId,data_previousRosterDate);
        onChangeDate(new Date(data_date).getTime());
      }
    });
  }, [targetMatchID]);

  // function getCustomizedTableList() {
  //   const body = {
  //     data_targetCollection: "SetDataManagementConfig",
  //   };

  //   fetchAPI.do_fetch("post", "admin/list-all", body).then(
  //     (res) => {
  //       set_isLoading(false);
  //       console.log("success: ", res.data);
  //       setData_customizedTable(res.data.data_list);
  //     },
  //     (error) => {
  //       console.log("failed: ", error);
  //       set_isLoading(false);
  //       showAlert(false, Loc.failed, Loc.data_fetch_failed);
  //     }
  //   );
  // }

  function getListOfCollection(collectionsList, onHoldList) {
    var newList = collectionsList;
    var newOnHoldList = onHoldList;

    // console.log(`do_loadListOfCollection =====>`);
    const targetCollection = newList[0];
    return new Promise((onDone) => {
      // set_isLoading(true);
      const body = {
        data_targetCollection: targetCollection,
      };

      fetchAPI.do_fetch("post", "admin/list-all", body).then(
        (res) => {
          const dataList = res.data.data_list;
          newOnHoldList[targetCollection] = dataList.filter(
            (v) => v.isDeleted != true
          );
          newList.splice(0, 1);

          if (newList.length > 0) {
            getListOfCollection(newList, newOnHoldList).then(
              (finalOnHoldList) => {
                onDone(finalOnHoldList);
              }
            );
          } else {
            onDone(newOnHoldList);
          }
          //   onDone(res.data.data_list);
        },
        (error) => {
          console.log("failed: ", error);
          set_isLoading(false);
          onDone(null);
        }
      );
    });
  }

  function do_loadStoreList() {
    return new Promise((onDone) => {
      const body = {
        data_targetCollection: "Store",
      };
      fetchAPI.do_fetch("post", "admin/list-all", body).then(
        (res) => {
          console.log("success: ", res.data);
          onDone(res.data.data_list);
        },
        (error) => {
          console.log("failed: ", error);
          showAlert(false, Loc.failed, "Load store faild");
          onDone(null);
        }
      );
    });
  }

  const validCheck = () => {
    var result = true;
    console.log("VC:  "+data_staffID+" "+data_rosterList.length +" "+data_date+" "+currentIndex)
    if (
      data_staffID == "" ||
      data_rosterList.length == 0 ||
      data_date == null ||
      currentIndex == -1
    ) {
      result = false;
    } else if (dateState === "error") {
      result = false;
    }
    return result;
  };

  const backClicked = () => {
    setRedirect(backPathname);
  };

  const doneClicked = () => {
    setFetchingStatus("fetching");
    if (validCheck()) {
      // console.log("data_date  :"  +data_date)
      const idToPass = isEdit ? data_id : null;
      console.log("idToPass: ", idToPass);
      const date = new Date(data_date);
      console.log(
        "done:::: " + moment(new Date(data_date).getTime()).format("YYYY-MM-DD")
      );
      var obj = {
        date_timestamp: new Date(data_date).getTime(),
        
        // staffName: data_staffName,
        // staffID: data_staffID,
        roster: data_rosterList[currentIndex],
      };

      // if (isComponent) {
        obj["targetRosterCollectionName"] = data_lookupObj["targetRosterCollectionName"];
        obj["targetBookingCollectionName"] = data_lookupObj["targetBookingCollectionName"];
        obj["targetBooking"] = data_lookupObj["target_booking"];
        obj["rosterLookup"] = data_lookupObj["rosterLookup"];
        obj["lookupID"] = isComponent ? targetMatchID :data_staffID ;
      
        obj["lookupID"] = isComponent ? targetMatchID :data_staffID ;
      // }
        obj["orderBy"] = data_lookupObj["orderBy"];//new Date(data_date).getTime();
      obj[obj["orderBy"]] = new Date(data_date).getTime();
      var body = {
        id: idToPass,
        content: obj,
      };

      if (isEdit) {
        body = {
          ...body,
          previousRosterId: data_previousRosterId,
          previousRosterDate: data_previousRosterDate,
        };
      }
      console.log("Result " + JSON.stringify(body));

      fetchAPI.do_fetch("post", "admin/create-booking", body).then(
        (res) => {
          console.log("success: ", res);
          setFetchingStatus("");
          showAlert(true, Loc.success, Loc.data_save_success);

          // setData_itemName("");
        },
        (error) => {
          console.log("failed: ", error);
          setFetchingStatus("");
          showAlert(false, Loc.failed, Loc.data_save_failed);
          // if (onPressSubmit !== undefined) {
          //   onPressSubmit();
          // }
        }
      );
    } else {
      //   setItemNameState("error");
      setFetchingStatus("");
      showAlert(false, Loc.failed, Loc.data_save_failed);
    }
  };

  function showAlert(issuccess, title, content) {
    setSaveAlert(
      <SweetAlert
        error={!issuccess}
        success={issuccess}
        style={{ display: "block", marginTop: "-100px" }}
        closeOnClickOutside={false}
        title={title}
        onConfirm={() => {
          if (issuccess) {
            // hideAlert()
            // setRedirect("/admin");
            if (onPressSubmit !== undefined) {
              onPressSubmit();
              hideAlert();

            } else {
              hideAlert();
              
            }
          } else {
            hideAlert();
          }
        }}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        cancelBtnCssClass={classes_alert.button + " " + classes_alert.info}
        onCancel={() => {
          if (issuccess) {
            setData_date(null);
            setData_rosterList([]);
            if (onPressChip) {
              onPressChip(null,data_previousRosterId,data_previousRosterDate);
              onChangeDate(new Date(data_date).getTime());
            }
            // window.location.reload(false);
            hideAlert();
          } else {
            hideAlert();
          }
        }}
        confirmBtnText={issuccess ? Loc.done : Loc.confirm}
        cancelBtnText={Loc.continue}
        btnSize="lg"
        showCancel={issuccess && !isEdit && !(onPressSubmit !== undefined)}
      >
        {content}
      </SweetAlert>
    );
  }

  const check_redirect = () => {
    if (pathToRedirect != "") {
      return <Redirect to={{ pathname: pathToRedirect, state: null }} />;
    } else {
      return null;
    }
  };

  const getStringToShow = (defaultStr, fieldList, fieldName, id) => {
    console.log(`getStringToShow ${fieldName} ===>${id}`);
    var strToShow = defaultStr;

    if (fieldList.length > 0) {
      const fieldNameList = getDropDownNameList(fieldName);
      strToShow = fieldNameList[0];
      fieldList.map((prop, key) => {
        if (prop.id === id) {
          strToShow = prop.itemName;
        }
      });
    }
    return strToShow;
  };

  const getDropDownNameList = (fieldName) => {
    const fieldList =
      data_dataListArray == null ? [] : data_dataListArray[fieldName];
    const fieldNameList = [];
    if (fieldList.length > 0) {
      fieldList.map((item) => {
        fieldNameList.push(item.itemName);
      });
    }
    return fieldNameList;
  };

  const handleDelete = (i) => {
    const newTags = [...tags];
    newTags.splice(i, 1);
    setTags(newTags);
  };

  const getSelectMenuItem = (fieldName,fieldKey = "itemName") => {
    const fieldList =
      data_dataListArray == null ? [] : data_dataListArray[fieldName];
    const menuItems = [];

    // console.log(`getSelectMenuItem   `+ JSON.stringify(item[fieldKey]))
    
    if (fieldList && fieldList.length > 0) {
      fieldList.map((item, key) => {
        menuItems.push(
          <MenuItem
            key={key}
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected,
            }}
            value={item.id}
          >
            {item[fieldKey]}
          </MenuItem>
        );
      });
    }

    return menuItems;
  };

  //   const [tags, setTags] = React.useState(["tag1", "tag2", "tag3"]);

  const renderInput = ({ addTag, ...props }) => {
    return <></>; // 渲染成一個空的元素
  };
  function defaultRenderTag(props) {
    let {
      tag,
      key,
      disabled,
      onRemove,
      classNameRemove,
      getTagDisplayValue,
      ...other
    } = props;
    return (
      <span key={key} {...other}>
        {getTagDisplayValue(tag)}
        {!disabled && (
          <a className={classNameRemove} onClick={(e) => onRemove(key)} />
        )}
      </span>
    );
  }

  function renderTagView() {
    if (data_rosterList == undefined || data_rosterList == null ||data_rosterList.length == 0) {
      return null;
    }
    var dataList = data_rosterList;
    console.log("renderTagView "+JSON.stringify(dataList));
    const chips = dataList.map((item, key) => {
      var start_timeStr = moment(item.start_timestamp).format("HH:mm");
      var status = item.status;
      //   var color = "#D5D5D5";
      var color = "#42a5f5";

      console.log("renderTagView currentIndex: ", currentIndex);

      if (status == "booked") {
        color = "#D5D5D5";
      }
      if (key == currentIndex) {
        color = "#66bb6a";
      }

      //   else if (key == 1) {
      //     color = "#ba68c8";
      //   } else if (key == 2) {
      //     color = "#42a5f5";
      //   } else {
      //     color = "#ef5350";
      //   }
      return (
        <Chip
          key={"chip_" + key}
          style={{ backgroundColor: color, color: "white", margin: "5px" }}
          label={start_timeStr}
          onClick={
            isReadOnlyMode
              ? () => {}
              : () => {
                  // console.info("You clicked the Chip. " + key);
                  if (!isEdit) {
                    if (status == "available") {
                      setCurrentIndex(key);
                      if (onPressChip) {
                        onPressChip(data_rosterList[key],data_previousRosterId,data_previousRosterDate);
                        onChangeDate(new Date(data_date).getTime());
                      }
                    }
                  } else {
                    if (status == "available") {
                      //change old one to available
                      if (currentIndex != -1) {
                        var newList = [...data_rosterList];
                        newList[currentIndex].status = "available";
                        setData_rosterList(newList);
                        setCurrentIndex(key);

                        if (onPressChip) {
                          onPressChip(newList[key],data_previousRosterId,data_previousRosterDate);
                          onChangeDate(new Date(data_date).getTime());

                        }
                      } else {
                        console.log("currentIndex: > 1 ", currentIndex);
                        if (onPressChip) {
                          onPressChip(data_rosterList[currentIndex],data_previousRosterId,data_previousRosterDate);
                          onChangeDate(new Date(data_date).getTime());

                        }
                      }
                    }
                  }
                }
          }
        />
      );
    });

    return (
      <GridItem xs={12}>
        <br />
        <legend>Timeslots</legend>
        <Card>
          <CardBody>{chips}</CardBody>
        </Card>
      </GridItem>
    );
  }

  function get_roster_list(staffID, date, callback) {
    
    if (data_lookupObj == undefined || data_lookupObj == null) {
      set_isLoading(false);
      return
    }
    var body = {};
    if (!isComponent) { 
      body = {
        targetRoster: data_lookupObj["targetRosterCollectionName"],
        rosterLookup: data_lookupObj["rosterLookup"],
        lookupID: data_staffID,
        date_timestamp: new Date(date).getTime(),
      }
      body[data_lookupObj["orderBy"]] = new Date(date).getTime();
    } else {
      body = {
        targetRoster: data_lookupObj["target_roster"],
        rosterLookup: data_lookupObj["roster_look_up_key"],
        lookupID: targetMatchID,
        date_timestamp: new Date(date).getTime(),
      }
      body[data_lookupObj["orderBy"]] = new Date(date).getTime();

    }
      // staffID: isComponent ? targetMatchID : staffID,
      

    console.log("get_roster_list body: ", JSON.stringify(body));
    fetchAPI.do_fetch("post", "admin/get-staff-roster", body).then(
      (res) => {
        console.log("success: ", res);
        setFetchingStatus("");
        set_isLoading(false);

        if (res.data != undefined && res.data != null) {
          setData_rosterList(res.data.rosterList);
          callback(res.data.rosterList);
        } else {
          setData_rosterList([]);
          if (onPressChip) {
            onPressChip(null,data_previousRosterId,data_previousRosterDate);
            onChangeDate(new Date(data_date).getTime());

          }
        }
      },
      (error) => {
        console.log("failed: ", error);
        setFetchingStatus("");
        set_isLoading(false);

        showAlert(false, Loc.failed, Loc.data_save_failed);
      }
    );
  }

  const classes = useStyles();
  const classes_alert = useStyles_alert();

  const showloading = () => {
    if (isLoading) {
      return (
        <SweetAlert
          style={{ display: "block", marginTop: "-100px" }}
          closeOnClickOutside={false}
          title={Loc.loading}
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnText={Loc.confirm}
          confirmBtnCssClass={
            classes_alert.button + " " + classes_alert.success
          }
          btnSize="lg"
          showConfirm={false}
          showCancel={false}
        >
          {Loc.please_wait}
        </SweetAlert>
      );
    } else {
      return null;
    }
  };
  return (
    <div>
      {check_redirect()}
      {alert_save}
      {showloading()}
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" text>
              <CardText color="rose">
                <h4 className={classes.cardTitle}>{"Booking Detail"}</h4>
              </CardText>
            </CardHeader>
            <CardBody>
              <form>
                <GridContainer>
                  {!isComponent && (<GridItem sm={12}>
                      <FormControl
                        fullWidth
                        className={
                          classes.selectFormControl +
                          " " +
                          classes.formControlMargins
                        }
                      >
                        {/* <Select
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          value={data_customID}
                          onChange={(event) => {
                            const selectedID = event.target.value;
                            const data = data_dataListArray["SetDataManagementConfig"].filter((item) => item.id == selectedID)[0];
                            // console.log("The customizedv data: ", JSON.stringify(data));
                            if (data !== undefined) {
                             const bookingData = data.createFieldList.filter((item) => item.fieldType == "booking_system")[0];
                              console.log("The customizedv data: ", JSON.stringify(bookingData));
                              const {roster_look_up_key, target_look_up_key, target_roster, fieldKey} = bookingData;
                              const obj_lookup = {
                                "roster_look_up_key": roster_look_up_key,
                                "target_look_up_key": target_look_up_key,
                                "target_roster": target_roster,
                                "target_booking": fieldKey,
                              } 
                              setData_lookupObj(obj_lookup);
                            }
                            setData_customID(selectedID);
                            // setData_staffID(selectedID);
                            setData_customName(
                              getStringToShow(
                                "Custom Table",
                                data_dataListArray == null
                                  ? []
                                  : data_dataListArray["SetDataManagementConfig"],
                                "SetDataManagementConfig",
                                selectedID
                              )
                            );
                            // setData_date(null);
                            // setData_rosterList([]);
                          }}
                          inputProps={{
                            name: data_staffName,
                            id: data_staffID,
                          }}
                          disabled={isReadOnlyMode}
                        >
                          {getSelectMenuItem("SetDataManagementConfig","localizationKey")}
                        </Select> */}
                      </FormControl>
                    </GridItem>
                  )}
                  {!isComponent && (
                    <GridItem sm={12}>
                      <FormControl
                        fullWidth
                        className={
                          classes.selectFormControl +
                          " " +
                          classes.formControlMargins
                        }
                      >
                        <Select
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          value={data_staffID}
                          onChange={(event) => {
                            const selectedID = event.target.value;
                            setData_staffID(selectedID);
                            setData_staffName(
                              getStringToShow(
                                Loc.staff_default,
                                data_dataListArray == null
                                  ? []
                                  : data_dataListArray["Staff"],
                                "Staff",
                                selectedID
                              )
                            );
                            setData_date(null);
                            setData_rosterList([]);
                          }}
                          inputProps={{
                            name: data_staffName,
                            id: data_staffID,
                          }}
                          disabled={isReadOnlyMode}
                        >
                          {getSelectMenuItem("Staff")}
                        </Select>
                      </FormControl>
                    </GridItem>
                  )}
                  <GridItem sm={12}>
                    <br />
                    <InputLabel className={classes.label}>
                      Booking Date
                    </InputLabel>
                    <br />
                    {/* <br /> */}
                    <FormControl fullWidth>
                      <Datetime
                        timeFormat={false}
                        utc={true}
                        value={data_date}
                        inputProps={{
                          disabled: isReadOnlyMode,
                          placeholder: "Please Select time",
                        }}
                        onChange={(event) => {
                          // console.log("event: ", event);
                          var date = event;
                          setData_date(event);
                          if (onChangeDate) {
                            onChangeDate(new Date(date).getTime());
                          }
                          set_isLoading(true);
                          get_roster_list(data_staffID, date, (list) => {
                            setCurrentIndex(-1);
                            if (onPressChip) {
                              onPressChip(null,data_previousRosterId,data_previousRosterDate);
                              onChangeDate(new Date(data_date).getTime());

                            }
                          });
                          //Call api to get the list of roster
                          // console.log("date: ", new Date(date).getTime());
                        }}
                      />
                    </FormControl>
                  </GridItem>
                  {/* {renderAddTagRow()}
                  {renderAddTagRow(true)} */}
                  {renderTagView()}
                </GridContainer>
              </form>
            </CardBody>
            <CardFooter className={classes.justifyContentCenter}>
              {data_showButton && (
                <GridContainer
                  spacing={2}
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  {onPressSubmit == undefined && (
                    <GridItem xs={6} align="center">
                      <Button onClick={backClicked}>
                        <ICON_BACK />
                        {Loc.back}
                      </Button>
                    </GridItem>
                  )}

                  {!isReadOnlyMode && (
                    <GridItem
                      xs={onPressSubmit == undefined ? 6 : 12}
                      align="center"
                    >
                      <Button
                        color="rose"
                        onClick={doneClicked}
                        disabled={fetchingStatus === "fetching" ? true : false}
                      >
                        <ICON_DONE />
                        {Loc.create}
                      </Button>
                    </GridItem>
                  )}
                </GridContainer>
              )}
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
